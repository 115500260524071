import { Alert, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Ul = styled('ul')({
    marginTop: 0,
    marginBottom: 0,
});

export const VicInformationPanel: React.FC = () => {
    return (
        <>
            <Typography variant="h6">
                <FormattedMessage id="vic.safety.telephone" />
            </Typography>
            <Ul>
                <li>
                    <FormattedMessage id="vic.safety.telephone.red" />
                </li>
                <li>
                    <FormattedMessage id="vic.safety.telephone.cern" />
                </li>
                <li>
                    <FormattedMessage id="vic.safety.telephone.not.cern" />
                </li>
            </Ul>
            <Typography variant="h6">
                <FormattedMessage id="vic.safety.provide.information.title" />
            </Typography>
            <Ul>
                <li>
                    <FormattedMessage id="vic.safety.provide.information.value" />
                </li>
            </Ul>
            <Typography variant="h6">
                <FormattedMessage id="vic.safety.clarify.title" />
            </Typography>
            <Ul>
                <li>
                    <FormattedMessage id="vic.safety.clarify.accident.nature" />
                </li>
                <li>
                    <FormattedMessage id="vic.safety.clarify.victim.position" />
                </li>
                <li>
                    <FormattedMessage id="vic.safety.clarify.victim.displace" />
                </li>
            </Ul>
            <Typography variant="h6">
                <FormattedMessage id="vic.safety.confirm.title" />
            </Typography>
            <Ul>
                <li>
                    <FormattedMessage id="vic.safety.confirm.example" />
                </li>
                <li>
                    <FormattedMessage id="vic.safety.confirm.meeting.point" />
                </li>
            </Ul>
            <Box mt={3}>
                <Alert severity="warning">
                    <FormattedMessage id="vic.safety.dont.hang.up" />
                </Alert>
            </Box>
        </>
    );
};

import CERNMap, { MapOptions } from 'cernmap';
import React, { useEffect, useRef } from 'react';
import { useApplicationSettings } from './application/ApplicationSettingsProvider';
import { ResponsiveReadOnlyDialog } from './ResponsiveReadOnlyDialog';

interface MapIntegrationContentProps {
    options: MapOptions;
    setMap: (map: CERNMap | null) => void;
    onLoaded: () => void;
}

const MapIntegrationContent: React.FC<MapIntegrationContentProps> = ({ options, setMap, onLoaded }) => {
    const { environment } = useApplicationSettings();
    const ref = useRef<HTMLDivElement>(null);
    const gisAppUrl =
        environment !== 'LOCAL' ? 'https://gis-integrated.web.cern.ch' : 'https://test-gis-integrated.web.cern.ch';
    useEffect(() => {
        if (ref.current !== null) {
            const map = new CERNMap(ref.current, gisAppUrl, {
                ...options,
                // See IMPACT-1964
                onError: onLoaded,
                onMapLoaded: onLoaded, // Called at the very end, only once per mount
                onLocationDisplayed: onLoaded, // Called every time the location has been updated, except if the location is not found
                // Needed to use the new SSO and make sure that it picks up the already-logged user
                sso: 'Keycloak',
            });
            setMap(map);
            return () => {
                map.destroy();
                setMap(null);
            };
        }
    }, [ref, setMap]);

    return <div ref={ref} style={{ height: '100%' }} />;
};

interface MapDialogProps {
    open: boolean;
    onClose: () => void;
    setMap: (map: CERNMap | null) => void;
    keepMounted: boolean;
    isLoading: boolean;
    /**
     * Can be called multiple times.
     */
    onLoaded: () => void;
    options?: MapOptions;
    title?: string;
}
export const MapDialog: React.FC<MapDialogProps> = ({
    open,
    onClose,
    setMap,
    isLoading,
    onLoaded,
    keepMounted,
    options = {},
    title = 'Map',
}) => {
    return (
        <ResponsiveReadOnlyDialog
            open={open}
            onClose={onClose}
            title={title}
            isLoading={isLoading}
            keepMounted={keepMounted}
        >
            <MapIntegrationContent options={options} setMap={setMap} onLoaded={onLoaded} />
        </ResponsiveReadOnlyDialog>
    );
};

import React from 'react';
import { IntlShape } from 'react-intl';
import { WdpVersionGridReadDTO } from '../../api/dto';
import { CreateDocumentButtonAdornment } from '../../components/CreateDocumentButtonAdornment';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchWdpVersionsById } from '../../lib/api';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { documentTypeProperties } from './document';

const wdpTypeProperties = (
    { multiple }: { multiple?: boolean },
    intl: IntlShape
): AsyncAutocompleteFormElement['typeProperties'] =>
    documentTypeProperties({
        fetchOptions: fetchWdpVersionsById,
        getOptionLabel: ({ wdpMasterId, versionNumber }: WdpVersionGridReadDTO) =>
            intl.formatMessage({ id: 'wdp.titleExisting' }, { masterId: wdpMasterId, versionNumber }),
        label: intl.formatMessage({ id: 'wdp.name' }),
        multiple,
    });

interface WdpSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
    create?: string;
}

export const wdpSchema = (
    { path, label, required, multiple, create }: WdpSchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: {
        ...wdpTypeProperties({ multiple }, intl),
        endAdornment: ({ disabled, readOnly }) => (
            <CreateDocumentButtonAdornment
                title={intl.formatMessage({ id: 'wdp.createAndLink' })}
                to={create ?? null}
                {...{ disabled, readOnly }}
            />
        ),
    },
});

interface WdpFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const wdpFilterSchema = ({ id, label }: WdpFilterSchemaParameters, intl: IntlShape): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: WdpVersionGridReadDTO) => value.id,
    typeProperties: wdpTypeProperties({}, intl),
});

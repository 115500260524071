import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { updateDocumentAttachments } from '../attachments';
import { backendClient as client } from '../clients';
import {
    CommentUpdateDTO,
    EgroupWithMembersDTO,
    ListOfValuesReadDTO,
    NdcContextReadDTO,
    NdcDistributionListReadDTO,
    NdcReadDTO,
    NdcUpdateDTO,
    PersonReadDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const NDC_PATH = 'ndc';

export type NdcDistributionTargetType = any;
export interface NdcDistributionTargetFormValue {
    typeCode: string;
    value: NdcDistributionTargetType;
}

export const getNdcValidationRules: GetOne<
    { idOrNdcNumber: string | undefined },
    PreciseRulesDTO<NdcContextReadDTO>
> = async ({ idOrNdcNumber, config }) =>
    client
        .get(buildPath([NDC_PATH, 'rules']), { ...config, params: { idOrNdcNumber } })
        .then((response: AxiosResponse) => response.data);

export const getNdc: GetOne<{ id: number }, NdcReadDTO> = async ({ id, config }) =>
    client.get(buildPath([NDC_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getNdcByIdOrNdcNumber: GetOne<{ idOrNdcNumber: string }, NdcReadDTO> = async ({ idOrNdcNumber, config }) =>
    client
        // Notice the trailing empty string '', this is not a mistake
        .get(buildPath([NDC_PATH, '']), { ...config, params: { idOrNdcNumber } })
        .then((response: AxiosResponse) => response.data);

export const getNdcs: GetManyPaged<NdcReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([NDC_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const updateNdc: UpdateOne<{ id: number; taskId: string; data: NdcUpdateDTO }, NdcReadDTO> = async ({
    id,
    taskId,
    data,
    config,
}) =>
    client
        .put(buildPath([NDC_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitNdc: UpdateOne<{ id: number; data: NdcUpdateDTO }, NdcReadDTO> = async ({ id, data, config }) =>
    client.put(buildPath([NDC_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitNdc: UpdateOne<{ id: number; data: NdcUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([NDC_PATH, id, 'validate']), data, config);

export const createNdc: CreateOne<{ data: any }, NdcReadDTO> = async ({ data, config }) =>
    client.post(buildPath([NDC_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const getNdcPdf: GetOne<{ id: number }, Blob> = async ({ id, config }) =>
    client
        .get(buildPath([NDC_PATH, id, 'pdf']), { ...config, responseType: 'blob' })
        .then((response: AxiosResponse) => response.data);

export const getNdcInterventionTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([NDC_PATH, 'intervention-types']), config).then((response: AxiosResponse) => response.data);

export const getNdcTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([NDC_PATH, 'types']), config).then((response: AxiosResponse) => response.data);

export const getNdcStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([NDC_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getNdcDistributionLineTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([NDC_PATH, 'distribution-lines', 'types']), config)
        .then((response: AxiosResponse) => response.data);

export const getNdcNetworks: GetManyUnpaged<{ orgUnit?: number }, ListOfValuesReadDTO> = async ({
    config,
    orgUnit,
} = {}) => {
    return client
        .get(buildPath([NDC_PATH, 'networks']), { ...config, params: { orgUnit } })
        .then((response: AxiosResponse) => response.data);
};

export const ndcAttachmentsApi = updateDocumentAttachments(NDC_PATH);

export const getNdcDistributionInfoBefore: GetOne<{ data: any }, NdcDistributionListReadDTO> = async ({
    data,
    config,
}) =>
    client
        .post(buildPath([NDC_PATH, 'distribution-lines']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getNdcFacilityCoordinators: GetManyUnpaged<{ data: NdcUpdateDTO }, PersonReadDTO> = async ({
    data,
    config,
}) =>
    client
        .post(buildPath([NDC_PATH, 'facility-coordinators']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getNdcOccupantsOfBuildings: GetManyUnpaged<{ data: NdcUpdateDTO }, EgroupWithMembersDTO> = async ({
    data,
    config,
}) =>
    client
        .post(buildPath([NDC_PATH, 'buildings-occupants']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getNdcOccupantsOfBuildingsLevels: GetManyUnpaged<{ data: NdcUpdateDTO }, EgroupWithMembersDTO> = async ({
    data,
    config,
}) =>
    client
        .post(buildPath([NDC_PATH, 'buildings-level-occupants']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getNdcTSOsAndDTSOs: GetManyUnpaged<{ data: NdcUpdateDTO }, PersonReadDTO> = async ({ data, config }) =>
    client.post(buildPath([NDC_PATH, 'tsos-and-dtsos']), data, config).then((response: AxiosResponse) => response.data);

export const getNdcDistributionInfoAfter: GetOne<{ id: number }, NdcDistributionListReadDTO> = async ({ id, config }) =>
    client.get(buildPath([NDC_PATH, id, 'distribution']), config).then((response: AxiosResponse) => response.data);

export const cancelNdc: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([NDC_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const finishNdc: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([NDC_PATH, id, 'finish']), comment, config).then((response: AxiosResponse) => response.data);

export const allowAnyLocations: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client
        .put(buildPath([NDC_PATH, id, 'allow-any-locations']), config)
        .then((response: AxiosResponse) => response.data);

export const allowSelectableLocations: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client
        .put(buildPath([NDC_PATH, id, 'allow-selectable-locations']), config)
        .then((response: AxiosResponse) => response.data);

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ApplicationSettingsDTO } from './dto';
import { GetOne } from './types';

const RESOURCE_NAME = 'application';

export const getApplicationSettings: GetOne<{}, ApplicationSettingsDTO> = async ({ config } = {}) => {
    return client.get(buildPath([RESOURCE_NAME, 'settings']), config).then((response: AxiosResponse) => response.data);
};

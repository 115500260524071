import { IntlShape } from 'react-intl';
import { DimrVersionGridActivityReadDTO, DimrVersionGridReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchDimrVersionsById } from '../../lib/api';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { documentTypeProperties } from './document';

interface DimrCustomProperties {
    activities?: boolean;
    onlyDrafts?: boolean;
}

const dimrTypeProperties = (
    options: DimrCustomProperties,
    intl: IntlShape
): AsyncAutocompleteFormElement['typeProperties'] =>
    documentTypeProperties<DimrVersionGridReadDTO | DimrVersionGridActivityReadDTO>({
        fetchOptions: (searchTerm) => fetchDimrVersionsById(searchTerm, !!options.activities, !!options.onlyDrafts),
        getOptionLabel: ({ dimrMaster, versionNumber }) =>
            intl.formatMessage({ id: 'title.dimrView' }, { masterId: dimrMaster.id, versionNumber }),
        label: intl.formatMessage({ id: 'dimr.name' }),
    });

interface DimrSchemaParameters
    extends DimrCustomProperties,
        Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const dimrSchema = (
    { path, label, required, activities, onlyDrafts }: DimrSchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: dimrTypeProperties({ activities, onlyDrafts }, intl),
});

interface DimrFilterSchemaParameters extends DimrCustomProperties, Pick<TFilterElement, 'id' | 'label'> {}

export const dimrFilterSchema = (
    { id, label, activities, onlyDrafts }: DimrFilterSchemaParameters,
    intl: IntlShape
): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: DimrVersionGridReadDTO) => value.id,
    typeProperties: dimrTypeProperties({ activities, onlyDrafts }, intl),
});

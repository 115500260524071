import { Path, PathValue } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import { WdpVersionReadDTO } from '../../../../api/dto';
import { fieldsMetadata, TableHeader, WdpDataKey } from '../WdpInterfaces';
export const accessValue = <O, P extends Path<O>>(obj: O, field: P): PathValue<O, P> =>
    field.split('.').reduce((acc, key) => acc?.[key], obj as any);

export const createEnum = <T extends string>(keys: T[]): { [K in T]: K } =>
    keys.reduce((acc, el) => ({ ...acc, [el]: el }), {} as { [K in T]: K });

export const tableHeaderStyle = {
    background: '#515151',
    color: 'lightgrey',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
};

/**
 * Recomputes all the row IDs (= "idx") using their index. The backend enforces this format.
 * @param wdp
 */
export const recomputeRowIndexes = (wdp: WdpVersionReadDTO): WdpVersionReadDTO => {
    const fields = Object.values(fieldsMetadata);
    // For each `dataKey`, store a bijective mapping from internal frontend ID to actual row index
    const mapping = Object.fromEntries(
        fields.map(({ dataKey, idKey }) => [
            dataKey,
            Object.fromEntries(
                wdp[dataKey].map((o, index) => [(o as any as Record<typeof idKey, number>)[idKey], index])
            ),
        ])
    ) satisfies Partial<Record<WdpDataKey, Record<number, number>>> as Record<WdpDataKey, Record<number, number>>;
    const partialUpdatedWdp: Partial<WdpVersionReadDTO> = Object.fromEntries(
        fields.map(({ dataKey, idKey }) => {
            const data = wdp[dataKey];
            const newData = data.map((row, index) => {
                // Updated self ID
                const partialUpdatedRowId = { [idKey]: index };
                // Updated referenced arrays
                const partialUpdatedRowArrays = Object.fromEntries(
                    fields.flatMap(({ relationshipName, idKey: childIdKey, dataKey: childDataKey }) => {
                        if (relationshipName !== undefined && relationshipName in row) {
                            const relationshipValue = (
                                row as any as Record<typeof relationshipName, Record<typeof childIdKey, number>[]>
                            )[relationshipName]?.map((o) => {
                                const oldInternalId: number = o[childIdKey];
                                const childIndex: number = mapping[childDataKey][oldInternalId];
                                return { [childIdKey]: childIndex };
                            });
                            return [[relationshipName, relationshipValue] as const];
                        } else {
                            return [];
                        }
                    })
                );
                // Updated referenced parent
                const partialUpdatedRowParent =
                    'parent' in row && row.parent
                        ? { parent: { [idKey]: mapping[dataKey][(row.parent as Record<typeof idKey, number>)[idKey]] } }
                        : {};
                return { ...row, ...partialUpdatedRowId, ...partialUpdatedRowArrays, ...partialUpdatedRowParent };
            });
            return [dataKey, newData];
        })
    );
    return { ...wdp, ...partialUpdatedWdp };
};

export const formatHeaderLabel = (
    { labelTranslationKey, unitTranslationKey }: Pick<TableHeader, 'labelTranslationKey' | 'unitTranslationKey'>,
    intl: IntlShape
): string => {
    if (labelTranslationKey !== null) {
        const rawLabel = intl.formatMessage({ id: labelTranslationKey });
        return unitTranslationKey !== undefined
            ? intl.formatMessage(
                  { id: 'common.labelUnit' },
                  { label: rawLabel, unit: intl.formatMessage({ id: unitTranslationKey }) }
              )
            : rawLabel;
    } else {
        return '';
    }
};

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useDeviceType = () => {
    const theme = useTheme();
    return {
        isMobile: useMediaQuery(theme.breakpoints.down('md')),
        isMediumScreen: useMediaQuery(theme.breakpoints.down('lg')),
        mobileScreenBreakPoint: theme.breakpoints.values.md,
    };
};

import { Lock } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { LockoutGridReadDTO, LockoutReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDateTime } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getLockoutStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const LockoutIcon = Lock;

export const getLockoutInformation = (
    lockout: LockoutReadDTO | LockoutGridReadDTO,
    intl: IntlShape
): EntityInformation => {
    return {
        url: getRoute({
            path: ROUTES.lockout.view,
            params: { id: `${lockout.id}` },
        }),
        name: intl.formatMessage({ id: 'lockout.titleExisting' }, { id: lockout.id }),
        title: lockout.activity.title,
        status: getLockoutStatus(lockout, intl),
        creation: lockout,
        details: [
            {
                labelKey: 'document.field.type',
                value: getLovLabel(translateValue(lockout.lockoutType, intl.locale)),
                type: true,
            },
            {
                labelKey: 'lockout.field.requestStart',
                value: lockout.requestStartDate ? formatDateTime(lockout.requestStartDate, intl) : '',
            },
            {
                labelKey: 'lockout.field.requestEnd',
                value: lockout.requestEndDate ? formatDateTime(lockout.requestEndDate, intl) : '',
            },
        ],
    };
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Info } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    createFirePermit,
    getFirePermitPdf,
    submitFirePermit,
    updateFirePermit,
} from '../../api/documents/firePermits';
import {
    DocumentType,
    FirePermitContextReadDTO,
    FirePermitReadDTO,
    FirePermitWorkerTypeCode,
    ListOfValuesReadDTO,
    TaskType,
} from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { CommentList } from '../../components/comments/CommentList';
import { DefaultAccordion } from '../../components/DefaultAccordion';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { FirePermitRecommendationsContent } from '../../components/documents/FirePermitRecommendationsContent';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { LocalizedDocumentPdfButton } from '../../components/documents/LocalizedDocumentPdfButton';
import { InactiveActivityWarning } from '../../components/InactiveActivityWarning';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useDocumentCreationToast } from '../../hooks/useDocumentCreationToast';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    isFirePermitLocationPartOfActivity,
    makeFirePermitDefaultValuesFromClonedFirePermit,
    makeFirePermitDefaultValuesFromLinkedActivity,
    mapFpToUpdateDTO,
} from '../../lib/records/documents/fp';
import { FirePermitContentHeader } from '../../pages/documents/firePermit/FirePermitContentHeader';
import { FirePermitPageData } from '../../pages/documents/firePermit/FirePermitPageData';
import { FirePermitPageVariant } from '../../pages/documents/firePermit/FirePermitPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { firePermitFormSchema } from '../schemas/documents/firePermit';

interface FirePermitFormProps {
    data: FirePermitPageData;
    defaultWorkerType: ListOfValuesReadDTO | undefined;
    rules: PreciseRulesDTO<FirePermitContextReadDTO>;
    refreshData: () => void;
}

export const FirePermitForm: React.FC<FirePermitFormProps> = ({
    data: pageData,
    defaultWorkerType,
    rules,
    refreshData,
}) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const intl = useIntl();
    const { currentUser } = useApplicationSettings();
    const { variant } = pageData;
    const navigate = useNavigate();
    const defaultValues = useMemo(
        () =>
            pageData.variant === FirePermitPageVariant.READ
                ? pageData.firePermitData
                : pageData.variant === FirePermitPageVariant.CLONE
                ? makeFirePermitDefaultValuesFromClonedFirePermit(pageData.firePermitData, currentUser)
                : makeFirePermitDefaultValuesFromLinkedActivity(
                      pageData.activityData,
                      currentUser,
                      defaultWorkerType,
                      pageData.locationData
                  ),
        [pageData]
    );
    const tasks = useTasks(
        pageData.variant === FirePermitPageVariant.READ
            ? {
                  documentTypes: [DocumentType.FP],
                  documentId: pageData.firePermitData.id,
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );
    const context: FirePermitContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: null,
                      task: tasks.currentTask?.type?.type ?? null,
                  }
                : null,
        [rules.context, tasks.currentTask]
    );
    const { form, attributes } = useFormRules<FirePermitReadDTO>({
        formProps: { mode: 'onSubmit', defaultValues },
        rules,
        originalValues: defaultValues,
        context: context as any,
    });
    const { handleSubmit, clearErrors, formState, watch } = form;

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.FP);

    const sendDocumentCreationToast = useDocumentCreationToast('firePermit.name');

    const { mutateAsync: saveFirePermit, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === FirePermitPageVariant.READ) {
                const updateOrSubmit = submit ? submitFirePermit : updateFirePermit;
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateOrSubmit({
                    id: pageData.firePermitData.id,
                    taskId: tasks.currentTask.id,
                    data: mapFpToUpdateDTO(formData, false),
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                return createFirePermit({ data: mapFpToUpdateDTO(formData, true) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === FirePermitPageVariant.READ) {
                    refreshData();
                } else {
                    sendDocumentCreationToast();
                    navigate(
                        getRoute({
                            path: ROUTES.firePermit.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const canClone = defaultValues.availableActions.canClone;
    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== FirePermitPageVariant.READ;
    const isLoading = isSaveLoading || tasks.disabled;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && formIsDirty,
    });
    const activity = watch('activity');
    const workerType = watch('worker');
    const location = watch('location');
    const company = watch('company');

    useEffect(() => {
        if (company) {
            const email = company.technicalEmail ? company.technicalEmail : company.email || '';
            form.setValue('firePermitCompanyEmails', [{ companyEmail: email }]);
        }
    }, [company]);

    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const {
        data: isLocationPartOfActivity,
        isLoading: isLocationPartOfActivityLoading,
        isError: isLocationPartOfActivityFailed,
    } = useQuery(
        ['isLocationPartOfActivity', activity, location],
        () => isFirePermitLocationPartOfActivity({ activity, location }),
        {
            enabled: location != null,
        }
    );

    const showLocationWarning =
        !isLocationPartOfActivityLoading && !isLocationPartOfActivityFailed && !isLocationPartOfActivity;

    const fireInspectionAttributes = attributes?.fireInspectionPremises;
    const fireInspectionValue = fireInspectionAttributes?.editable || !!fireInspectionAttributes?.newValue;
    const showCloseoutPanel = fireInspectionValue || (context ? context.task === TaskType.FP_CLOSEOUT : false);

    const uiSchemaFirePermit = useMemo(
        () =>
            firePermitFormSchema(
                intl,
                showCloseoutPanel,
                FirePermitWorkerTypeCode.EXTERNAL === workerType?.code,
                showLocationWarning
            ),
        [intl, showCloseoutPanel, workerType, showLocationWarning]
    );

    const isSavable = useIsSavableFromRules(attributes);
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <FirePermitContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === FirePermitPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null}
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        context={context}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === FirePermitPageVariant.CREATE ||
                                        variant === FirePermitPageVariant.CLONE
                                            ? COMMON_DOCUMENT_BUTTONS.CREATE
                                            : COMMON_DOCUMENT_BUTTONS.SAVE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveFirePermit({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                                {pageData.variant !== FirePermitPageVariant.READ && pageData.activityData !== null ? (
                                    <>{!pageData.activityData.isActive && <InactiveActivityWarning />}</>
                                ) : null}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === FirePermitPageVariant.READ && canClone && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                        onClick={() => {
                                            window.open(
                                                getRoute({
                                                    path: ROUTES.firePermit.clone,
                                                    params: { id: String(pageData.firePermitData.id) },
                                                })
                                            );
                                        }}
                                        disabled={formIsDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
                                    />
                                )}
                                {variant === FirePermitPageVariant.READ && !!pageData.firePermitData.pdf && (
                                    <LocalizedDocumentPdfButton
                                        queryKey={['fp', pageData.firePermitData.id]}
                                        fetchPdf={(language) =>
                                            getFirePermitPdf({
                                                id: pageData.firePermitData.id,
                                                langCode: language,
                                            })
                                        }
                                        isPdfPreview={pageData.firePermitData.pdf.isPdfPreview}
                                        canGenerateInBothLanguages={true}
                                        dirty={formIsDirty}
                                    />
                                )}
                                {/* TODO */}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.FirePermit}
                            firePermit={
                                variant === FirePermitPageVariant.CREATE
                                    ? { activity: pageData.activityData }
                                    : defaultValues
                            }
                        />
                        <ValidationErrorsPanel schema={uiSchemaFirePermit} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaFirePermit} form={form} disabled={isLoading} />
                            {variant === FirePermitPageVariant.READ && (
                                <WorkflowHistory
                                    documentType={DocumentType.FP}
                                    documentId={pageData.firePermitData.id}
                                />
                            )}
                            {variant === FirePermitPageVariant.READ && (
                                <CommentList
                                    documentTypes={[DocumentType.FP]}
                                    documentId={pageData.firePermitData.id}
                                    currentTask={tasks.currentTask}
                                    disabled={isLoading}
                                />
                            )}
                        </FieldsAttributesContextProvider>
                        <Box sx={{ mt: 2 }}>
                            <DefaultAccordion
                                title={intl.formatMessage({ id: 'firePermit.panel.information' })}
                                icon={<Info color="action" />}
                            >
                                <FirePermitRecommendationsContent />
                            </DefaultAccordion>
                        </Box>
                    </>
                }
                Drawer={
                    variant === FirePermitPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};

import {
    CellStyle,
    CellTemplate,
    Compatible,
    DateCell,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';
import { IntlShape } from 'react-intl';
import { formatDate } from '../../lib/date';

export interface CustomDateCell extends DateCell {
    type: 'date';
    setData: (data: any) => void;
}

const cellStyle: CellStyle = {};

export class CustomDateCellTemplate implements CellTemplate<CustomDateCell> {
    intl: IntlShape;

    setOpen = (b: boolean) => {};

    setCell = (cell: Compatible<CustomDateCell>) => {};

    constructor(props: { intl: IntlShape; setOpen: any; setCell: any }) {
        this.intl = props.intl;
        this.setOpen = props.setOpen;
        this.setCell = props.setCell;
    }

    getCompatibleCell(uncertainCell: Uncertain<CustomDateCell>): Compatible<CustomDateCell> {
        let text = '';
        if (uncertainCell.date && !isNaN(new Date(uncertainCell.date).getTime())) {
            text = formatDate(uncertainCell.date, this.intl);
        }
        return { ...uncertainCell, text, value: NaN, setData: uncertainCell.setData! };
    }

    isFocusable?(cell: Compatible<CustomDateCell>): boolean {
        return true;
    }

    update?(
        cell: Compatible<CustomDateCell>,
        cellToMerge: UncertainCompatible<CustomDateCell>
    ): Compatible<CustomDateCell> {
        if (cellToMerge.type === 'date') return this.getCompatibleCell({ ...cell, ...cellToMerge });
        return cell;
    }

    openDialog(cell: Compatible<CustomDateCell>) {
        this.setOpen(true);
        this.setCell(cell);
    }

    handleKeyDown?(
        cell: Compatible<CustomDateCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<CustomDateCell>; enableEditMode: boolean } {
        if (keyCode === keyCodes.BACKSPACE || keyCode === keyCodes.DELETE) {
            return { cell: this.getCompatibleCell({ ...cell, date: undefined }), enableEditMode: false };
        }
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: true };
        if (keyCode === keyCodes.F2) {
            this.openDialog(cell);
        }
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<CustomDateCell>,
        eventData: string
    ): { cell: Compatible<CustomDateCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented.');
    }

    getStyle?(cell: Compatible<CustomDateCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<CustomDateCell>, isInEditMode: boolean): string {
        return `${cell.className} date`.trim();
    }

    render(
        cell: Compatible<CustomDateCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<CustomDateCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div
                onDoubleClick={() => this.openDialog(cell)}
                style={{ width: '100%', height: '100%', marginTop: '6px' }}
            >
                <div>{cell.text}</div>
            </div>
        );
    }
}

import { getParticipantRoles } from '../../api/activities-nim';
import { ListOfValuesReadDTO } from '../../api/dto';
import { Locale } from '../../i18n/Locale';
import { getLovLabel } from '../../lib/label';
import { translateValue } from '../../lib/language';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const activityParticipantRoleTypeProperties = (locale: Locale): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'code',
    fetchOptions: (searchTerm, exactId, idField, values) =>
        getParticipantRoles({ activityType: (values?.[0] as ListOfValuesReadDTO | null | undefined)?.code ?? null }),
    watchPaths: ['type'],
    fetchUseCases: [FetchUseCase.ON_OPEN],
    getOptionLabel: (value) => getLovLabel(translateValue(value, locale)),
    multiple: false,
});

interface ActivityParticipantRoleSchemaParameters
    extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const activityParticipantRoleSchema = (
    { path, label, required }: ActivityParticipantRoleSchemaParameters,
    locale: Locale
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: activityParticipantRoleTypeProperties(locale),
});

import { sfLike, sfOr } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

export const makeEgroupDefaultFilter = (searchParam: string): string =>
    sfLike('name', `${escapeSfqbString(searchParam, true)}*`).toString();

export const makeSensorsDefaultFilter = (searchParam: string): string =>
    sfLike('sensorName', `${escapeSfqbString(searchParam, true)}*`).toString();

export const makeContractsDefaultFilter = (searchParam: string): string =>
    sfOr([
        sfLike('contractNumber', `${escapeSfqbString(searchParam, true)}*`),
        sfLike('employer.name', `${escapeSfqbString(searchParam, true)}*`),
        sfLike('cernContractResponsible.searchLabel', `${escapeSfqbString(searchParam, true)}*`),
    ]).toString();

import { Replay, Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Stack,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ReactElement, ReactNode } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormGenerator } from '../../forms/FormGenerator';
import { TFormElement } from '../../forms/types';
import { useDeviceType } from '../../hooks/useDeviceType';

export interface SimpleFiltersDialogProps {
    title?: string;
    open: boolean;
    form: UseFormReturn<any, object>;
    formSchema: TFormElement;
    onClose: () => void;
    onCancel: () => void;
    onUpdateFilters: () => void;
    onResetFilters: () => void;
    savedSearchSlot?: ReactNode;
    alertSlot?: ReactNode;
    formGeneratorKey?: number;
    isLoading?: boolean;
}

export const SimpleFiltersDialog = ({
    title,
    open,
    form,
    formSchema,
    onClose,
    onCancel,
    onUpdateFilters,
    onResetFilters,
    savedSearchSlot,
    alertSlot,
    formGeneratorKey,
    isLoading,
}: SimpleFiltersDialogProps): ReactElement => {
    const { isMobile } = useDeviceType();

    const handleSearch = () => {
        onUpdateFilters();
        onClose();
    };

    return (
        <Dialog fullScreen={isMobile} open={open} onClose={onClose} maxWidth="sm" fullWidth keepMounted>
            {isLoading ? (
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        <Stack spacing={2} direction="row" justifyContent="space-between">
                            {title ? (
                                <Typography variant="h6" color="textSecondary">
                                    {title}
                                </Typography>
                            ) : null}
                            {savedSearchSlot}
                        </Stack>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box>
                            {alertSlot}
                            <FormProvider {...form}>
                                <FormGenerator key={formGeneratorKey || 0} form={form} rootElement={formSchema} />
                            </FormProvider>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between' }}>
                        <Box>
                            <Button onClick={onResetFilters} startIcon={<Replay />}>
                                <FormattedMessage id="common.reset" />
                            </Button>
                        </Box>
                        <Box>
                            <Button onClick={onCancel} startIcon={<CloseIcon />} sx={{ color: grey[600] }}>
                                <FormattedMessage id="common.cancel" />
                            </Button>
                            <Button onClick={handleSearch} startIcon={<Search />}>
                                <FormattedMessage id="search.button" />
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

import { Box, Tab, Tabs } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useLayoutEffect, useState } from 'react';
import { useFieldArray, UseFormReturn, useFormState } from 'react-hook-form';
import { FormGenerator } from '../FormGenerator';
import { FieldArrayTabsElement, FormElementCommon } from '../types';
import { injectCurrentPath, joinFieldPath } from '../utils';

interface FieldArrayTabsProps {
    form: UseFormReturn;
    element: FormElementCommon & FieldArrayTabsElement;
}

export const FieldArrayTabs: React.FC<FieldArrayTabsProps> = ({ form, element }) => {
    const { control, getValues } = form;
    const { path, element: child, tabLabel, initialTab, disabled, readOnly } = element;
    const { fields } = useFieldArray({
        control,
        name: path,
    });
    // TODO display these errors
    const { errors } = useFormState({
        name: path,
    });
    // If the array is empty then the initial tab is 0 (this is fine, see below)
    const [currentTab, setCurrentTab] = useState(
        initialTab !== undefined && 0 <= initialTab && initialTab < fields.length ? initialTab : 0
    );
    useLayoutEffect(() => {
        if (currentTab >= fields.length) {
            setCurrentTab(fields.length - 1); // Closest tab
        }
    }, [currentTab, fields.length, setCurrentTab]);

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={(_, selectedIndex) => setCurrentTab(selectedIndex)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ backgroundColor: grey[!disabled ? 200 : 50] }}
            >
                {fields.map((field, indexF) => {
                    const tabPath = `${path}.${indexF}`;
                    return (
                        <Tab
                            data-ignore-blur={true}
                            key={field.id}
                            label={tabLabel(getValues(tabPath))}
                            disabled={disabled}
                            id={tabPath}
                        />
                    );
                })}
            </Tabs>
            <Box sx={{ p: 2 }}>
                {fields.length > 0 && (
                    <FormGenerator
                        key={currentTab} // Crucial, otherwise the tab content will be stale
                        form={form}
                        rootElement={injectCurrentPath(element)(child, currentTab)}
                        currentPath={joinFieldPath([path, currentTab])}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                )}
            </Box>
        </>
    );
};

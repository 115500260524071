import { IS37StatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const Is37StatusColorMapping: Record<IS37StatusCode, StatusColor> = {
    [IS37StatusCode.DRAFT]: StatusColor.PRIMARY,
    [IS37StatusCode.WAITING_FOR_APPROVAL]: StatusColor.WARNING,
    [IS37StatusCode.APPROVED]: StatusColor.SUCCESS,
    [IS37StatusCode.WAITING_FOR_DISABLING]: StatusColor.WARNING,
    [IS37StatusCode.DISABLED]: StatusColor.SUCCESS,
    [IS37StatusCode.WAITING_FOR_SCHEDULING]: StatusColor.SUCCESS,
    [IS37StatusCode.SCHEDULED]: StatusColor.SUCCESS,
    [IS37StatusCode.WAITING_FOR_RECOMMISSIONING]: StatusColor.WARNING,
    [IS37StatusCode.RECOMMISSIONED]: StatusColor.SUCCESS,
    [IS37StatusCode.CLOSED]: StatusColor.NORMAL,
    [IS37StatusCode.CANCELLED]: StatusColor.ERROR,
    [IS37StatusCode.REJECTED]: StatusColor.ERROR,
};

import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { I18nMessageDTO } from '../../api/dto';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { LocalizationTable } from '../../components/LocalizationTable';
import { TRANSLATIONS } from '../../i18n/I18nProvider';
import { Locale } from '../../i18n/Locale';

export const LocalizationFrontendPage: React.FC = () => {
    const intl = useIntl();
    const data: Record<string, I18nMessageDTO> = useMemo(() => {
        const keys = Object.keys(TRANSLATIONS.en);
        const translations: Record<Locale, Record<(typeof keys)[number], string>> = TRANSLATIONS;
        return Object.fromEntries(
            keys.map(
                (key) =>
                    [
                        key,
                        {
                            english: translations.en[key],
                            french: translations.fr[key],
                        },
                    ] as const
            )
        );
    }, []);
    return (
        <>
            <ImpactHelmet title={intl.formatMessage({ id: 'meta.localization.frontend.title' })} />
            <LocalizationTable data={data} />
        </>
    );
};

import React, { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

// To be removed once the Activity is released
export const useDocumentCreationToast = (documentKey: MessageDescriptor['id']) => {
    const intl = useIntl();
    return useCallback(
        () =>
            toast.success(
                <div>
                    <strong>
                        {intl.formatMessage(
                            { id: 'document.success.create.title' },
                            { documentType: intl.formatMessage({ id: documentKey }) }
                        )}
                    </strong>
                    <div style={{ margin: '10px 0' }} />
                    {intl.formatMessage(
                        { id: 'document.success.create' },
                        { documentType: intl.formatMessage({ id: documentKey }) }
                    )}{' '}
                </div>,
                { autoClose: 20000 }
            ),
        [intl]
    );
};

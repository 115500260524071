import { sfAnd, sfEqual, sfLike, sfOr } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';
import { removeAccents } from '../text';

const createNameQuery = (name: string) => {
    const searchLabelFieldName = 'searchLabel';
    const escapedInput = escapeSfqbString(name, true);
    const separators = [' ', '-'];
    return sfOr([
        sfLike(searchLabelFieldName, `${escapedInput}*`), // Label starts with
        ...separators.map((separator) => sfLike(searchLabelFieldName, `*${separator}${escapedInput}*`)), // Or label contains a substring that starts with a separator
    ]);
};

export const makePersonNameFilter = (name: string): string => {
    const unaccentedName = removeAccents(name);
    const parts = removeAccents(unaccentedName).split(/[^A-Za-z]+/);
    if (parts.length <= 1) {
        return createNameQuery(unaccentedName).toString();
    }
    const conditions = parts.map((part) => createNameQuery(part));
    return sfAnd(conditions).toString();
};

export const makePersonIdFilter = (id: string): string => {
    return sfEqual('id', escapeSfqbString(id)).toString();
};

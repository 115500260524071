import { Business } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { SupplierReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchSupplierByName } from '../../lib/api';
import { getSupplierLabel } from '../../lib/label';
import { AsyncAutocompleteFetchOptions, FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const supplierTypeProperties = ({
    multiple,
    fetchOptions,
    getLabel,
}: {
    multiple?: boolean;
    fetchOptions?: AsyncAutocompleteFetchOptions<any>;
    getLabel?: (supplier: SupplierReadDTO) => string;
} = {}): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchOptions ?? fetchSupplierByName,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getLabel ?? getSupplierLabel,
    multiple,
    autocompleteProps: {
        forcePopupIcon: false,
    },
    endAdornment: ({ field, disabled }) => {
        const { value: supplier } = field as { value: SupplierReadDTO | undefined };
        return (
            <>
                {!!supplier && (
                    <Tooltip
                        title={
                            <Stack direction="column">
                                <Box>{supplier.phone}</Box>
                                <Box>{supplier.email}</Box>
                                {supplier.technicalEmail !== supplier.email && <Box>{supplier.technicalEmail}</Box>}
                            </Stack>
                        }
                    >
                        <Business color={!disabled ? 'action' : 'disabled'} sx={{ cursor: 'help' }} />
                    </Tooltip>
                )}
            </>
        );
    },
});

interface SupplierSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
}

export const supplierSchema = ({
    path,
    label,
    required,
    readOnly,
    disabled,
    multiple,
}: SupplierSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    readOnly,
    disabled,
    typeProperties: supplierTypeProperties({ multiple }),
});

interface SupplierFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {
    fetchOptions?: AsyncAutocompleteFetchOptions<any>;
    getLabel?: (supplier: SupplierReadDTO) => string;
    valueGetter?: (value: SupplierReadDTO) => string;
}

export const supplierFilterSchema = ({
    id,
    label,
    fetchOptions,
    getLabel,
    valueGetter,
}: SupplierFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: valueGetter ?? ((value: SupplierReadDTO) => value.code),
    typeProperties: {
        ...supplierTypeProperties({ fetchOptions, getLabel }),
        autocompleteProps: {
            disableCloseOnSelect: false,
            forcePopupIcon: false,
        },
    },
});

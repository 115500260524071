import { Compatible, Uncertain } from '@silevis/reactgrid';
import { WdpVersionReadDTO, WdpWorkStepReferencedReadDTO, WdpWorkStepReferenceDTO } from '../../api/dto';
import { formatVersionedId } from '../../lib/records/documents/versioned';
import { MultiDropdownCell, MultiDropdownCellTemplate } from './MultiDropdownCellTemplate';

export interface WorkStepsCell extends Omit<MultiDropdownCell, 'type'> {
    type: 'worksteps';
    valueList: unknown[];
}

export class WorkStepsCellTemplate extends MultiDropdownCellTemplate {
    wdp: Pick<WdpVersionReadDTO, 'wdpMasterId' | 'versionNumber'>;

    constructor(
        props: ConstructorParameters<typeof MultiDropdownCellTemplate>[0] & {
            wdp: Pick<WdpVersionReadDTO, 'wdpMasterId' | 'versionNumber'>;
        }
    ) {
        super(props);
        this.wdp = props.wdp;
    }

    private isEnabled(cell: Pick<Compatible<MultiDropdownCell>, 'valueList'>): boolean {
        const workSteps = cell.valueList as (WdpWorkStepReferenceDTO | WdpWorkStepReferencedReadDTO)[];
        return (
            workSteps.length === 0 ||
            !workSteps.some(
                (workStep) =>
                    'wdpMasterId' in workStep &&
                    'versionNumber' in workStep &&
                    (workStep.wdpMasterId !== this.wdp.wdpMasterId || workStep.versionNumber !== this.wdp.versionNumber)
            )
        );
    }

    private renderVersionLabel(cell: Compatible<MultiDropdownCell>): string {
        const workSteps = cell.valueList as WdpWorkStepReferencedReadDTO[];
        const wdp: Pick<WdpVersionReadDTO, 'wdpMasterId' | 'versionNumber'> = workSteps[0];
        return formatVersionedId(wdp.wdpMasterId, wdp.versionNumber);
    }

    override getCompatibleCell(uncertainCell: Uncertain<MultiDropdownCell>): Compatible<MultiDropdownCell> {
        const otherWorkSteps = !!uncertainCell.valueList && !this.isEnabled(uncertainCell as any);
        const cell = super.getCompatibleCell(
            otherWorkSteps
                ? {
                      ...uncertainCell,
                      values: (uncertainCell.valueList! as WdpWorkStepReferencedReadDTO[]).map((v) => ({
                          ...v,
                          name: v.description,
                      })),
                  }
                : {
                      ...uncertainCell,
                      values: uncertainCell.values?.filter((v: any) => v.isRoot),
                  }
        );
        return {
            ...cell,
            text: otherWorkSteps ? `${this.renderVersionLabel(cell)} - ${cell.text}` : cell.text,
            nonEditable: cell.nonEditable || otherWorkSteps,
        };
    }
}

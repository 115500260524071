import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ActivityDTO } from './dto';
import { ActionOne, GetManyUnpaged, GetOne } from './types';

const USER_PATH = 'user';
const FAVOURITES_PATH = [USER_PATH, 'activities', 'favourites'];

export const getFavourites: GetManyUnpaged<{}, ActivityDTO> = async ({ config } = {}) => {
    return client.get(buildPath(FAVOURITES_PATH), { ...config }).then((response: AxiosResponse) => response.data);
};

export const createFavourite: ActionOne<{ activity: number }, any> = async ({ activity, config }) => {
    return client
        .post(buildPath([...FAVOURITES_PATH, activity]), { ...config })
        .then((response: AxiosResponse) => response);
};

export const deleteFavourite: ActionOne<{ activity: number }, any> = async ({ activity, config }) => {
    return client
        .delete(buildPath([...FAVOURITES_PATH, activity]), { ...config })
        .then((response: AxiosResponse) => response);
};

export const existsFavourite: GetOne<{ activity: number }, any> = async ({ activity, config }) => {
    return client
        .get(buildPath([...FAVOURITES_PATH, activity]), { ...config })
        .then((response: AxiosResponse) => response);
};

import React, { createContext, useCallback, useContext, useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';

type ConfirmationDialogContextValue = (args: { prompt: React.ReactNode; onConfirm: () => void }) => void;

const ConfirmationDialogContext = createContext<ConfirmationDialogContextValue | undefined>(undefined);

export const useConfirmationDialog = (): ConfirmationDialogContextValue => {
    const value = useContext(ConfirmationDialogContext);
    if (value === undefined) {
        throw new Error();
    }
    return value;
};

interface ConfirmationDialogContextProviderProps {
    children: React.ReactNode;
}

export const ConfirmationDialogContextProvider: React.FC<ConfirmationDialogContextProviderProps> = ({ children }) => {
    type ClientData = Parameters<ConfirmationDialogContextValue>[0];
    const [open, setOpen] = useState(false);
    const [clientData, setClientData] = useState<ClientData | undefined>(undefined);
    const handleCancel = useCallback(() => setOpen(false), [setOpen]);
    const handleConfirm = useCallback(() => {
        if (clientData === undefined) {
            throw new Error();
        }
        clientData.onConfirm();
        setOpen(false);
    }, [clientData, setOpen]);
    const openDialog = useCallback(
        (data: ClientData) => {
            setClientData(data);
            setOpen(true);
        },
        [setClientData, setOpen]
    );
    return (
        <ConfirmationDialogContext.Provider value={openDialog}>
            {children}
            <ConfirmationDialog open={open} onClose={handleCancel} onConfirmation={handleConfirm}>
                {clientData?.prompt}
            </ConfirmationDialog>
        </ConfirmationDialogContext.Provider>
    );
};

import { Breakpoint } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Compatible } from '@silevis/reactgrid';
import React from 'react';
import { dateFormat } from '../../forms/fields/ImpactDatePicker';
import { CustomDateCell } from './CustomDateCellTemplate';
import { DialogInputLayout } from './DialogInputLayout';

interface ConfirmationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    cell: Compatible<CustomDateCell> | undefined;
}

export const DialogCustomDate: React.FC<ConfirmationDialogProps> = ({ open, cell, setOpen }) => {
    const handleCancel = () => {
        if (!!cell) cell.setData(cell.date);
        setOpen(false);
    };

    const field = {
        name: '',
        onBlur: () => 1,
        onChange: cell ? cell.setData : () => {},
        value: cell ? cell.date : '',
        ref: () => 1,
    };

    return (
        <DialogInputLayout
            open={open}
            setOpen={setOpen}
            onConfirm={() => setOpen(false)}
            onCancel={handleCancel}
            readOnly={cell?.nonEditable}
            maxWidth={'xs' as Breakpoint}
        >
            <DatePicker
                {...field}
                reduceAnimations={true}
                format={dateFormat}
                readOnly={cell?.nonEditable}
                slotProps={{
                    field: {
                        readOnly: true,
                    },
                    textField: {
                        ...(cell?.nonEditable ? { placeholder: '' } : {}),
                        fullWidth: true,
                        size: 'small',
                    },
                    inputAdornment: {
                        sx: {
                            display: cell?.nonEditable ? 'none' : undefined,
                        },
                    },
                }}
            />
        </DialogInputLayout>
    );
};

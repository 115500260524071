import { Item, sfAnd, sfEqual, sfLike } from 'spring-filter-query-builder';
import { DimrStatusCode } from '../../api/dto';
import { escapeSfqbString } from '../sfqb';

export const makeDimrVersionCodeFilter = (searchValue: string, onlyDrafts: boolean): string => {
    let query: Item = sfLike('versionCode', `${escapeSfqbString(searchValue, true)}*`);
    if (onlyDrafts) {
        query = sfAnd([query, sfEqual('status.code', escapeSfqbString(DimrStatusCode.DRAFT))]);
    }
    return query.toString();
};

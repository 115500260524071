import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { AccessPointReadDTO } from './dto';
import { GetManyPaged, GetManyUnpaged } from './types';

export const ACCESS_POINTS_PATH = 'access-points';

export const getAccessPoints: GetManyPaged<AccessPointReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACCESS_POINTS_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);

export const getLocationAccessPoints: GetManyUnpaged<{ locationId: number | null }, AccessPointReadDTO> = async ({
    locationId,
    config,
}) => {
    if (locationId == null) {
        return [];
    }
    return client
        .get(buildPath([ACCESS_POINTS_PATH, 'location', locationId]), config)
        .then((response: AxiosResponse) => response.data);
};

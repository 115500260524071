import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { WdpIcon } from '../../../lib/information/wdp';
import { getWdpStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';
import { WdpPageData } from './WdpPageData';
import { WdpPageVariant } from './WdpPageVariant';

interface WdpContentHeaderProps {
    data: WdpPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
    longTitle?: string;
}

export const WdpContentHeader: React.FC<WdpContentHeaderProps> = ({ data, longTitle, ...rest }) => {
    const intl = useIntl();
    const { variant } = data;
    const navigate = useNavigate();
    const status = data.variant === WdpPageVariant.READ ? getWdpStatus(data.wdpData, intl) : undefined;
    const shortTitle = intl.formatMessage(
        {
            id: (
                {
                    [WdpPageVariant.CREATE]: 'title.wdpCreate',
                    [WdpPageVariant.CLONE]: 'title.wdpClone',
                    [WdpPageVariant.READ]: 'title.wdpView',
                } as const
            )[variant],
        },
        variant === WdpPageVariant.READ || variant === WdpPageVariant.CLONE
            ? {
                  masterId: data.wdpData.wdpMasterId,
                  versionNumber: data.wdpData.versionNumber,
              }
            : undefined
    );
    return (
        <DocumentHeader
            documentType={DocumentType.WDP}
            documentId={variant === WdpPageVariant.READ ? data.wdpData.id : null}
            documentVersion={
                variant === WdpPageVariant.READ
                    ? { masterId: data.wdpData.wdpMasterId, versionNumber: data.wdpData.versionNumber }
                    : undefined
            }
            icon={<WdpIcon />}
            title={`${shortTitle}${longTitle ? ` - ${longTitle}` : ''}`}
            shortTitle={shortTitle}
            version={
                variant === WdpPageVariant.READ
                    ? {
                          currentVersionNumber: data.wdpData.versionNumber,
                          versions: data.wdpData.versionNumbers.map(({ versionNumber, cancelled }) => ({
                              versionNumber,
                              status: getWdpStatus({ cancelled }, intl),
                          })),
                          onSelect: (versionNumber) =>
                              navigate(
                                  getRoute({
                                      path: ROUTES.wdp.view,
                                      params: {
                                          id: data.wdpData.wdpMasterId,
                                          version: versionNumber,
                                      },
                                  })
                              ),
                      }
                    : undefined
            }
            status={status}
            creation={variant === WdpPageVariant.READ ? data.wdpData : undefined}
            {...rest}
        />
    );
};

import { getCoreRowModel, TableOptions, useReactTable } from '@tanstack/react-table';
import { getImpactTableDefaultColumn } from '../components/tables/table/utils';

interface UseImpactTableProps<TData>
    extends Pick<
        TableOptions<TData>,
        'data' | 'columns' | 'defaultColumn' | 'state' | 'onSortingChange' | 'onColumnVisibilityChange'
    > {}

export const useImpactTable = <TData>({
    data,
    columns,
    defaultColumn = getImpactTableDefaultColumn(),
    state = {},
    onSortingChange,
    onColumnVisibilityChange,
}: UseImpactTableProps<TData>) => {
    const table = useReactTable<TData>({
        data,
        columns,
        defaultColumn,
        manualSorting: true,
        state,
        onColumnVisibilityChange,
        enableSortingRemoval: true,
        enableMultiSort: false,
        onSortingChange,
        getCoreRowModel: getCoreRowModel(),
    });

    return table;
};

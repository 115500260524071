import { CernUserProfile } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { IMPACT_ADMINISTRATOR_ROLE, IMPACT_SELF_ASSIGNABLE_ROLE } from '../api/keycloak';
import { ENVIRONMENT_SLUGS, useApplicationSettings } from '../components/application/ApplicationSettingsProvider';

export const useSelfAssignableRole = () => {
    const { user } = useAuth();
    const { environment } = useApplicationSettings();
    const roleToCheck =
        ENVIRONMENT_SLUGS[environment] === ENVIRONMENT_SLUGS.PRODUCTION
            ? IMPACT_ADMINISTRATOR_ROLE
            : IMPACT_SELF_ASSIGNABLE_ROLE;
    return (user!.profile as CernUserProfile).cern_roles.includes(roleToCheck);
};

export enum EntityType {
    Activity,
    ActivityNim,
    Is37,
    Is37ChangeRequest,
    Dimr,
    Wdp,
    Lockout,
    NoteDeCoupure,
    FirePermit,
    Vic,
    WorkOrder,
    Task,
}

import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

export const NoPaddingTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 600,
        padding: 0,
    },
});

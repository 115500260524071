import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { AttachmentDTO, AttachmentsIdsDTO, AttachmentUploadDTO } from './dto';
import { GetOne, RequestMethod, UpdateOne } from './types';

const ATTACHMENTS_PATH = 'attachments';
const ATTACHMENTS_SUBPATH = 'attachments';

export const getAttachment: GetOne<{ id: number }, any> = async ({ id, config }) =>
    client
        .get(buildPath([ATTACHMENTS_PATH, id]), {
            ...config,
            responseType: 'blob',
        })
        .then((response) => response.data);

export const createAttachment: RequestMethod<
    {
        file: File;
        description: string;
    },
    AttachmentDTO
> = ({ file, description, config }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('body', new Blob([JSON.stringify({ description })], { type: 'application/json' }));
    return client.post(buildPath([ATTACHMENTS_PATH]), formData, config).then((response) => response.data);
};

export const updateDocumentAttachments = (...path: string[]) => {
    const linkAttachments: RequestMethod<{ id: number; taskId: string | undefined } & AttachmentsIdsDTO, number[]> = ({
        id,
        taskId,
        attachmentsIds,
        config = {},
    }) => {
        return client
            .put(
                buildPath([...path, id, ATTACHMENTS_SUBPATH, 'link']),
                {
                    attachmentsIds: attachmentsIds,
                },
                { ...config, params: { taskId } }
            )
            .then((response: AxiosResponse) => response.data);
    };

    const unlinkAttachments: RequestMethod<
        { id: number; taskId: string | undefined } & AttachmentsIdsDTO,
        number[]
    > = ({ id, taskId, attachmentsIds, config = {} }) => {
        return client
            .put(
                buildPath([...path, id, ATTACHMENTS_SUBPATH, 'unlink']),
                {
                    attachmentsIds: attachmentsIds,
                },
                { ...config, params: { taskId } }
            )
            .then((response: AxiosResponse) => response.data);
    };

    const updateAttachment: UpdateOne<
        {
            id: number;
            attachmentId: number;
            taskId: string | undefined;
            data: AttachmentUploadDTO;
        },
        AttachmentDTO
    > = ({ id, attachmentId, taskId, data, config }) => {
        return client
            .put(buildPath([...path, id, ATTACHMENTS_SUBPATH, attachmentId]), data, {
                ...config,
                params: { taskId },
            })
            .then((response) => response.data);
    };

    return {
        linkAttachments,
        unlinkAttachments,
        updateAttachment,
    };
};

import { AxiosResponse } from 'axios';
import { getAccessPoints } from '../api/access-points';
import { getActivities } from '../api/activities';
import { getCompensatoryMeasureTypes, getContracts } from '../api/activities-nim';
import { getDimrVersions, getDimrVersionsWithActivities } from '../api/documents/dimrs';
import { getIs37Sensors } from '../api/documents/is37s';
import { getCryoEquipment } from '../api/documents/lockouts';
import { getPersonsEros, getPersonsHse, getPersonsLR, getPersonsTso } from '../api/documents/vics';
import { getWdpVersions } from '../api/documents/wdps';
import {
    AccessPointReadDTO,
    ActivityReadDTO,
    ApiErrorDTO,
    CompensatoryMeasureTypeReadDTO,
    ContractNimReadDTO,
    CryoEquipmentLocalReadDTO,
    CsamSensorReadDTO,
    DimrVersionGridActivityReadDTO,
    DimrVersionGridReadDTO,
    EgroupDTO,
    EquipmentReadDTO,
    InterventionPeriodNimReadDTO,
    ListOfValuesReadDTO,
    LocationDTO,
    OrgUnitReferenceDTO,
    PersonReadDTO,
    SupplierReadDTO,
    SystemBsSrcReadDTO,
    WdpVersionGridReadDTO,
} from '../api/dto';
import { getEgroups } from '../api/egroups';
import { getActiveEquipments, getEquipments } from '../api/equipment';
import { getFacilityInterventionPeriods, getInterventionPeriodNames } from '../api/interventionPeriods';
import { getLocationsByName } from '../api/locations';
import { getOrgUnits } from '../api/orgUnits';
import { getFrsOfficers, getPersons, getPersonsForFiltering } from '../api/persons';
import { getSuppliers } from '../api/suppliers';
import { getSystems } from '../api/system';
import { Pageable } from '../api/types';
import {
    makeAccessPointDefaultFilter,
    makeActiveOrDraftActivityIdFilter,
    makeActivityIdFilter,
    makeCompensatoryMeasureTypeDefaultFilter,
    makeInterventionPeriodDefaultFilter,
} from './filters/activity';
import { makeDimrVersionCodeFilter } from './filters/dimr';
import { makeContractsDefaultFilter, makeEgroupDefaultFilter, makeSensorsDefaultFilter } from './filters/egroup';
import { makeLockoutDefaultFilter } from './filters/lockout';
import { makeOrgUnitFilter, makeOrgUnitIdFilter } from './filters/orgUnit';
import { makePersonIdFilter, makePersonNameFilter } from './filters/person';
import { createSupplierCodeQuery, createSupplierNameQuery } from './filters/supplier';
import { makeSystemFilter } from './filters/system';
import { makeWdpVersionCodeFilter } from './filters/wdp';

export const fetchPeopleByName = async (searchTerm: string, exactId: boolean | undefined): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersons({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchPeopleForFiltering = async (
    searchTerm: string,
    exactId: boolean | undefined
): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsForFiltering({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchFrsOfficersByName = async (
    searchTerm: string,
    exactId: boolean | undefined
): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getFrsOfficers({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchTsoByName = async (searchTerm: string, exactId: boolean | undefined): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsTso({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchHseByName = async (searchTerm: string, exactId: boolean | undefined): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsHse({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchErosByName = async (searchTerm: string, exactId: boolean | undefined): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsEros({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchLocationResponsibleByName = async (
    searchTerm: string,
    exactId: boolean | undefined
): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsLR({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchOrgUnits = async (
    searchTerm: string,
    exactId: boolean | undefined,
    idField: string | undefined
): Promise<OrgUnitReferenceDTO[]> => {
    const result: Pageable<OrgUnitReferenceDTO> = await getOrgUnits({
        filter: exactId ? makeOrgUnitIdFilter(searchTerm, idField) : makeOrgUnitFilter(searchTerm),
        sort: ['orgLevel,asc', 'code,asc'],
    });
    return result.content;
};

export const fetchSystems = async (searchTerm: string): Promise<SystemBsSrcReadDTO[]> => {
    const result: Pageable<SystemBsSrcReadDTO> = await getSystems({
        filter: makeSystemFilter(searchTerm),
        sort: ['bPath,asc'],
    });
    return result.content;
};

export const fetchEquipment = async (searchTerm: string): Promise<EquipmentReadDTO[]> => {
    const equipment: EquipmentReadDTO[] = await getEquipments({
        searchTerm,
    });
    return equipment;
};

export const fetchActiveEquipment = async (searchTerm: string): Promise<EquipmentReadDTO[]> => {
    const equipment: EquipmentReadDTO[] = await getActiveEquipments({
        searchTerm,
    });
    return equipment;
};

export const fetchLocationsByName = async ({
    searchTerm,
    selectable,
}: {
    searchTerm: string;
    selectable: boolean;
}): Promise<LocationDTO[]> => {
    return await getLocationsByName({
        query: searchTerm,
        onlySelectable: selectable,
    });
};
export const fetchSensorsByName = async (searchTerm: string): Promise<CsamSensorReadDTO[]> => {
    const result: Pageable<CsamSensorReadDTO> = await getIs37Sensors({
        filter: makeSensorsDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchContractsByString = async (searchTerm: string): Promise<ContractNimReadDTO[]> => {
    const result: Pageable<ContractNimReadDTO> = await getContracts({
        filter: makeContractsDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchAccessPointsByName = async (searchTerm: string): Promise<AccessPointReadDTO[]> => {
    const result: Pageable<AccessPointReadDTO> = await getAccessPoints({
        filter: makeAccessPointDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchEgroups = async (searchTerm: string): Promise<EgroupDTO[]> => {
    const result: Pageable<EgroupDTO> = await getEgroups({
        filter: makeEgroupDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchCompensatoryMeasureTypeByLabel = async (
    searchTerm: string
): Promise<CompensatoryMeasureTypeReadDTO[]> => {
    const result: Pageable<CompensatoryMeasureTypeReadDTO> = await getCompensatoryMeasureTypes({
        filter: makeCompensatoryMeasureTypeDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchActivitiesById = async (searchTerm: string): Promise<ActivityReadDTO[]> => {
    const result: Pageable<ActivityReadDTO> = await getActivities({
        filter: makeActivityIdFilter(searchTerm),
    });
    return result.content;
};

export const fetchActiveOrDraftActivitiesById = async (searchTerm: string): Promise<ActivityReadDTO[]> => {
    const result: Pageable<ActivityReadDTO> = await getActivities({
        filter: makeActiveOrDraftActivityIdFilter(searchTerm),
    });
    return result.content;
};

export const fetchDimrVersionsById = async <B extends boolean>(
    searchTerm: string,
    activities: B,
    onlyDrafts?: boolean
): Promise<B extends true ? DimrVersionGridActivityReadDTO[] : B extends false ? DimrVersionGridReadDTO[] : never> => {
    const result = await (activities ? getDimrVersionsWithActivities : getDimrVersions)({
        filter: makeDimrVersionCodeFilter(searchTerm, !!onlyDrafts),
    });
    return result.content satisfies DimrVersionGridReadDTO[] | DimrVersionGridActivityReadDTO[] as any;
};

export const fetchWdpVersionsById = async (searchTerm: string): Promise<WdpVersionGridReadDTO[]> => {
    const result: Pageable<WdpVersionGridReadDTO> = await getWdpVersions({
        filter: makeWdpVersionCodeFilter(searchTerm),
    });
    return result.content;
};

export const isErrorResponseWithItems = (response: AxiosResponse): response is AxiosResponse<ApiErrorDTO<any>> =>
    !!response?.data?.errorItems;

export const fetchSupplierByName = async (searchTerm: string): Promise<SupplierReadDTO[]> => {
    const result: Pageable<SupplierReadDTO> = await getSuppliers({
        filter: createSupplierNameQuery(searchTerm),
    });
    return result.content;
};

export const fetchSupplierByCode = async (searchTerm: string): Promise<SupplierReadDTO[]> => {
    const result: Pageable<SupplierReadDTO> = await getSuppliers({
        filter: createSupplierCodeQuery(searchTerm),
    });
    return result.content;
};

export const fetchCryoEquipmentByNameOrDescription = async (
    searchTerm: string
): Promise<CryoEquipmentLocalReadDTO[]> => {
    const result: Pageable<CryoEquipmentLocalReadDTO> = await getCryoEquipment({
        filter: makeLockoutDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchInterventionPeriodsByFacility = async (
    searchTerm: string,
    exactId?: boolean,
    idField?: string,
    values?: any[]
) =>
    getFacilityInterventionPeriods({
        facilityCode: (values?.[0] as ListOfValuesReadDTO | null | undefined)?.code ?? null,
    });

export const fetchInterventionPeriodNames = async (searchTerm: string): Promise<InterventionPeriodNimReadDTO[]> => {
    const result: Pageable<InterventionPeriodNimReadDTO> = await getInterventionPeriodNames({
        filter: makeInterventionPeriodDefaultFilter(searchTerm),
        sort: ['periodStart,desc', 'periodName,asc'],
    });
    return result.content;
};

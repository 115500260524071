import { Functions, People, Settings } from '@mui/icons-material';
import * as React from 'react';
import { IntlShape } from 'react-intl';
import { getFacilities } from '../../../api/values';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { dimrSchema } from '../dimr';
import { personSchema } from '../person';
import { numberWithUnitSchemaFor } from '../unit';
import { valueSchema } from '../value';

export const wdpFormSchema = (intl: IntlShape, showWarning?: boolean): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'wdp.panel.editors' }),
                typeProperties: {
                    icon: <People color="action" />,
                    collapsed: true, // Initially collapsed
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: personSchema({
                                path: 'wdpEditors',
                                label: intl.formatMessage({ id: 'wdp.field.editors' }),
                                multiple: true,
                            }),
                        },
                    ],
                },
            },
        },
        {
            xs: 12,
            md: 6,
            element: {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'document.panel.general' }),
                typeProperties: {
                    icon: <Settings color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.field.title' }),
                                path: 'title',
                            },
                        },
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    path: 'wdpFacilities',
                                    label: intl.formatMessage({ id: 'document.field.facilities' }),
                                    fetchOptions: getFacilities,
                                    multiple: true,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 12,
                            element: dimrSchema(
                                {
                                    path: 'dimr',
                                    label: intl.formatMessage({ id: 'dimr.name' }),
                                    activities: true,
                                    onlyDrafts: true,
                                },
                                intl
                            ),
                        },
                    ],
                },
            },
        },
        {
            xs: 12,
            md: 6,
            element: {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'wdp.panel.totals' }),
                additionalText: showWarning ? intl.formatMessage({ id: 'wdp.panel.totals.warning' }) : undefined,
                typeProperties: {
                    icon: <Functions color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        // TODO decide where these should be computed (ideally, backend)
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.avgEstDoseRate' }),
                                    path: 'avgEstDoseRate',
                                    unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxIndividualWorkTime' }),
                                    path: 'maxIndividualWorkTime',
                                    unit: intl.formatMessage({ id: 'document.unit.hour' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.totalCollectiveWorkTime' }),
                                    path: 'totalCollectiveWorkTime',
                                    unit: intl.formatMessage({ id: 'document.unit.personHour' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstIndividualDose' }),
                                    path: 'maxEstIndividualDose',
                                    unit: intl.formatMessage({ id: 'document.unit.microSievert' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.estCollectiveDose' }),
                                    path: 'estCollectiveDose',
                                    unit: intl.formatMessage({ id: 'document.unit.personMicroSievert' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstDoseRate' }),
                                    path: 'maxEstDoseRate',
                                    unit: intl.formatMessage({ id: 'document.unit.microSievertPerHour' }),
                                    disabled: true,
                                    pattern: '',
                                },
                                intl
                            ),
                        },
                    ],
                },
            },
        },
    ],
});

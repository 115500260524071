import { Box, Button, Divider, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { GridMenu, GridMenuItem, GridMenuItemType } from '../../WdpInterfaces';
import { useWdpTabMenuContext } from './WdpTabMenuContext';

interface WdpTabMenuItemProps {
    item: GridMenuItem;
    disabled?: boolean;
}

const WdpTabMenuItem: React.FC<WdpTabMenuItemProps> = ({ item, disabled }) => {
    const intl = useIntl();
    const contextValue = useWdpTabMenuContext();
    switch (item.type) {
        case GridMenuItemType.Menu:
            const { menu } = item;
            return (
                <Stack direction={menu.direction}>
                    {menu.items.map((child, i) => (
                        <Fragment key={i}>
                            {!!menu.divider &&
                                i > 0 &&
                                (menu.direction === 'row' ? (
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                ) : (
                                    <Divider />
                                ))}
                            <WdpTabMenuItem item={child} disabled={disabled} />
                        </Fragment>
                    ))}
                </Stack>
            );
        case GridMenuItemType.Button:
            const { button } = item;
            const { Icon } = button;
            const label = button.tooltip ?? (typeof button.label === 'string' ? button.label : button.label(intl));
            return (
                <Tooltip title={label} placement="right">
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            name={label}
                            onClick={() => button.onClick(contextValue)}
                            disabled={button.disabled || disabled}
                            color="inherit"
                            sx={{ minWidth: '48px' }}
                        >
                            <Icon />
                        </Button>
                    </Box>
                </Tooltip>
            );
    }
};

interface WdpTabMenuProps {
    menu: GridMenu;
    disabled?: boolean;
    sx?: SxProps<Theme>;
}

export const WdpTabMenu: React.FC<WdpTabMenuProps> = ({ menu, disabled, ...rest }) => {
    return (
        <Box
            {...rest}
            sx={{
                ...rest.sx,
                top: 120,
                position: 'sticky',
                zIndex: 4,
                border: '1px solid #e1e1e1',
                borderLeft: 'none',
                borderRight: 'none',
                boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.2)',
                padding: 2,
                backgroundColor: 'white',
            }}
        >
            <WdpTabMenuItem disabled={disabled} item={{ type: GridMenuItemType.Menu, menu }} />
        </Box>
    );
};

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { frFR as muiFr } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { frFR as muiXFr } from '@mui/x-date-pickers/locales';
import { fr as dateFnsFr } from 'date-fns/locale';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { LanguageCode } from '../api/dto';
import { useApplicationSettings } from '../components/application/ApplicationSettingsProvider';
import { Locale } from './Locale';
import en from './translations/en.json';
import fr from './translations/fr.json';

export const TRANSLATIONS = {
    fr: { ...en, ...fr },
    en,
} satisfies Record<Locale, typeof fr & Record<string, string>>;
// English should be assignable to any other language (here, only French)

interface I18nContextProps {
    children: React.ReactNode;
}

export const I18nProvider: React.FC<I18nContextProps> = ({ children }) => {
    const theme = useTheme();
    const {
        currentUser: { preferredLanguage },
        preferredLanguage: preferredLanguageImpact,
    } = useApplicationSettings();
    const userImpactPreferredLanguage: Locale | null =
        preferredLanguageImpact !== null
            ? ({ [LanguageCode.ENGLISH]: 'en', [LanguageCode.FRENCH]: 'fr' } as const)[preferredLanguageImpact.code] ??
              null
            : null;
    const userPreferredLanguage: Locale = preferredLanguage.toLowerCase() as Locale;
    const locale = userImpactPreferredLanguage ?? userPreferredLanguage;
    const messages = TRANSLATIONS[locale] as typeof en; // Cast as English (we allow missing translations in French)
    const adapterLocale = { en: undefined, fr: dateFnsFr }[locale];
    const localeText = { en: undefined, fr: muiXFr.components.MuiLocalizationProvider.defaultProps.localeText }[locale];
    // We redefine `ThemeProvider` to augment it
    const localizedTheme = createTheme(...{ en: [theme], fr: [theme, muiFr] }[locale]);
    return (
        <IntlProvider locale={locale} messages={messages}>
            <ThemeProvider theme={localizedTheme}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={adapterLocale}
                    localeText={localeText}
                >
                    {children}
                </LocalizationProvider>
            </ThemeProvider>
        </IntlProvider>
    );
};

import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { useFormState } from 'react-hook-form';

const formGroupReducer: Reducer<Set<string>, { added: string[] } | { removed: string[] }> = (state, action) => {
    // The reducer should be pure, reason why we create a new set
    const clonedState = new Set(state);
    if ('added' in action) {
        action.added.forEach((v) => clonedState.add(v));
    } else {
        action.removed.forEach((v) => clonedState.delete(v));
    }
    return clonedState;
};

const useFormGroupReducer = () => {
    const [state, dispatch] = useReducer(formGroupReducer, new Set<string>());
    const dispatchers = useMemo(() => {
        const add = (added: string[]) => dispatch({ added });
        const remove = (removed: string[]) => dispatch({ removed });
        return { add, remove };
    }, [dispatch]);
    return [state, dispatchers] as const;
};

const FormGroupErrorContext = React.createContext<
    readonly [Set<string>, { add: (added: string[]) => void; remove: (removed: string[]) => void }]
>([new Set(), { add: () => {}, remove: () => {} }]);

interface FormGroupErrorContextProviderProps {
    children: (isError: boolean) => React.ReactNode;
}

/**
 * This component should be placed inside a RHF form. It will keep track of the children fields that call {@link useFormGroupErrorEffect}.
 * The rendering method of this component is a function where the argument indicates whether there is at least one child having an error.
 * @param children
 */
export const FormGroupErrorContextProviderConsumer: React.FC<FormGroupErrorContextProviderProps> = ({ children }) => {
    const value = useFormGroupReducer();
    const [state] = value;
    // React contexts will shadow any parent context; so what we do here is to forward the state of the child to its parent
    const parent = useContext(FormGroupErrorContext); // Obtain the parent context, or fall back to no-op
    const [, { add, remove }] = parent;
    useEffect(() => {
        add(Array.from(state));
        return () => {
            remove(Array.from(state));
        };
    }, [state, add, remove]);
    const { errors } = useFormState();
    const error = Array.from(state).some((v) => errors[v] !== undefined);
    return <FormGroupErrorContext.Provider value={value}>{children(error)}</FormGroupErrorContext.Provider>;
};

/**
 * Every RHF field that appears inside a {@FormGroupErrorContextProviderConsumer} context should call this hook.
 * It has no return value, but it will register the component in all the parent contexts, so that errors can be forwarded
 * to the parent.
 * @param path The (absolute) path of the field
 */
export const useFormGroupErrorEffect = (path: string | undefined) => {
    const [, { add, remove }] = useContext(FormGroupErrorContext);
    useEffect(() => {
        if (path !== undefined) {
            add([path]);
            return () => {
                remove([path]);
            };
        }
    }, [path, add, remove]);
};

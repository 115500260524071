import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getFirePermits, getFirePermitStatuses } from '../../../api/documents/firePermits';
import { DocumentType, FirePermitGridReadDTO, SavedFilterType } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { locationFilterSchema } from '../../../forms/schemas/location';
import { personFilterSchema } from '../../../forms/schemas/person';
import { supplierFilterSchema } from '../../../forms/schemas/supplier';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { getFirePermitStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: 'id',
        label: intl.formatMessage({ id: 'firePermit.field.id' }),
        type: FilterTypeEnum.NUMBER,
    },
    valueFilterSchema(
        {
            id: 'status.code',
            label: intl.formatMessage({ id: 'document.field.status' }),
            fetchOptions: getFirePermitStatuses,
        },
        intl.locale
    ),
    activityFilterSchema(
        {
            id: 'activity.id',
            label: intl.formatMessage({ id: 'activity.name' }),
        },
        intl
    ),
    valueFilterSchema(
        {
            id: 'activity.facility.code',
            label: intl.formatMessage({ id: 'document.field.activityFacility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    personFilterSchema({
        id: 'createdBy.searchLabel',
        label: intl.formatMessage({ id: 'document.field.creator' }),
    }),
    {
        id: 'activity.title',
        label: intl.formatMessage({ id: 'document.field.activityTitle' }),
        type: FilterTypeEnum.STRING,
    },
    personFilterSchema({
        id: 'responsible.searchLabel',
        label: intl.formatMessage({ id: 'document.field.responsible' }),
    }),
    {
        id: 'startDate',
        label: intl.formatMessage({ id: 'document.field.startDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'endDate',
        label: intl.formatMessage({ id: 'document.field.endDate' }),
        type: FilterTypeEnum.DATE,
    },
    locationFilterSchema({
        id: 'location.id',
        label: intl.formatMessage({ id: 'document.field.location' }),
    }),
    supplierFilterSchema({
        id: 'company.code',
        label: intl.formatMessage({ id: 'document.field.company' }),
    }),
    // TODO inconsistencies
];

const columnHelper = createColumnHelper<FirePermitGridReadDTO>();

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: intl.formatMessage({ id: 'firePermit.field.id' }),
        size: 100,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.firePermit.view,
                    params: { id: original.id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    // TODO inconsistencies
    columnHelper.accessor((row) => getLovLabel(translateValue(row.status, intl.locale)), {
        id: 'status.label',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 140,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
        cell: ({ row }) => <StatusChip {...getFirePermitStatus(row.original, intl)} size="small" />,
    }),
    columnHelper.accessor((row) => row.activity.id, {
        id: 'activity.id',
        header: intl.formatMessage({ id: 'activity.name' }),
        size: 80,
        cell: ({ getValue }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activity.view,
                    params: { id: String(getValue()) },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor(
        (row) => (row.activity.facility ? getLovLabel(translateValue(row.activity.facility, intl.locale)) : ''),
        {
            id: 'activity.facility.label',
            header: intl.formatMessage({ id: 'document.field.activityFacility' }),
            size: 80,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
        }
    ),
    columnHelper.accessor((row) => row.createdBy?.searchLabel ?? '', {
        id: 'createdBy.searchLabel',
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.createdBy && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.activity.title ?? '', {
        id: 'activity.title',
        header: intl.formatMessage({ id: 'document.field.activityTitle' }),
        size: 240,
    }),
    columnHelper.accessor((row) => row.responsible.searchLabel ?? '', {
        id: 'responsible.searchLabel',
        header: intl.formatMessage({ id: 'document.field.responsible' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.responsible && (
                <PersonTooltip person={original.responsible}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.startDate ?? '', {
        id: 'startDate',
        header: intl.formatMessage({ id: 'document.field.startDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.endDate ?? '', {
        id: 'endDate',
        header: intl.formatMessage({ id: 'document.field.endDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
];

export const FirePermitSearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.firePermitSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'firePermit.name' })}
            columns={columns(intl)}
            pageKey="FirePermitSearchPage"
            documentType={DocumentType.FP}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getFirePermits}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.FirePermit, firePermit: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.FP}
        />
    );
};

import { sfAnd, sfEqual, sfLike, sfNotEqual, sfOr } from 'spring-filter-query-builder';
import { ActivityStatusCode } from '../../api/dto';
import { escapeSfqbString } from '../sfqb';

export const makeActivityDefaultFilter = (searchValue: string): string => {
    return sfOr([
        sfLike('title', `${escapeSfqbString(searchValue, true)}*`),
        sfLike('id', `${escapeSfqbString(searchValue, true)}*`),
    ]).toString();
};

export const makeActivityIdFilter = (searchValue: string): string =>
    sfLike('id', `${escapeSfqbString(searchValue, true)}*`).toString();

export const makeActiveOrDraftActivityIdFilter = (searchValue: string): string =>
    sfAnd([
        sfLike('id', `${escapeSfqbString(searchValue, true)}*`),
        sfOr([
            // There is a bug with SFQB "not" operator, therefore we use that instead
            sfAnd(
                [
                    ActivityStatusCode.NEW,
                    ActivityStatusCode.CLOSED,
                    ActivityStatusCode.REJECTED,
                    ActivityStatusCode.CANCELLED,
                    ActivityStatusCode.FINISHED,
                ].map((code) => sfNotEqual('status.code', code))
            ),
            sfEqual('status.code', ActivityStatusCode.DRAFT),
        ]),
    ]).toString();

export const makeAccessPointDefaultFilter = (searchValue: string): string =>
    sfLike('name', `${escapeSfqbString(searchValue, true)}*`).toString();

export const makeCompensatoryMeasureTypeDefaultFilter = (searchValue: string): string =>
    sfLike('label', `${escapeSfqbString(searchValue, true)}*`).toString();

export const makeInterventionPeriodDefaultFilter = (searchValue: string): string =>
    sfLike('periodName', `*${escapeSfqbString(searchValue, true)}*`).toString();

import axios from 'axios';
import qs from 'query-string';
import { getUser } from '../lib/auth';

export const backendClient = axios.create({
    baseURL: import.meta.env.VITE_BACKEND,
    paramsSerializer: { serialize: (params) => qs.stringify(params) }, // Strip the array part of parameters (`[]`)
});

backendClient.interceptors.request.use(
    (config) => {
        const user = getUser();
        const token = user?.access_token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

import { Close } from '@mui/icons-material';
import { Alert, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';

interface INotificationHeader {}
interface Notification {
    id: string;
    message: string;
}

const LAST_CLOSED = 'LAST_CLOSED_NOTIFICATION';

export const NotificationHeader: React.FunctionComponent<INotificationHeader> = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [notification, setNotification] = useState<Notification | null>(null);
    const [lastClosedId, setLastClosedId] = useState(window.localStorage.getItem(LAST_CLOSED));

    // useAsyncEffect(async (aborter: AbortController) => {
    //     try {
    //         const result: any = await notificationsAPI.getNotification({
    //             config: {
    //                 signal: aborter.signal,
    //             },
    //         });
    //         setNotification(result);
    //     } catch (error) {
    //         toast.error('There was an error fetching notifications');
    //     }
    // });

    const removeHeader = () => {
        if (notification !== null) {
            window.localStorage.setItem(LAST_CLOSED, notification.id);
            setLastClosedId(notification.id);
        }
    };
    const show = useMemo(
        () => (notification === null || (lastClosedId !== null && notification.id === lastClosedId) ? false : true),
        [notification, lastClosedId]
    );

    if (!show) return null;
    return (
        <Alert
            severity="info"
            sx={{ marginTop: 0, width: '100%' }}
            action={
                <IconButton size="small" color="inherit" aria-label="close notification header" onClick={removeHeader}>
                    <Close />
                </IconButton>
            }
        >
            {notification ? notification.message : 'Loading message...'}
        </Alert>
    );
};

import { ActivityReadDTO, ListOfValuesReadDTO, LocationDTO, LockoutReadDTO, LockoutUpdateDTO } from '../../../api/dto';

export const mapLockoutToUpdateDTO = (lockout: any, isCreation: boolean = false): LockoutUpdateDTO & LockoutReadDTO => {
    const { activity, ...lockoutWithoutActivity } = lockout as any;
    return isCreation ? lockout : lockoutWithoutActivity;
};

export const makeLockoutValues = (lockoutReadDTO: LockoutReadDTO): LockoutReadDTO => {
    return {
        ...lockoutReadDTO,
        // TODO revisit this method
    };
};

export const makeLockoutDefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    lockoutType: ListOfValuesReadDTO
) => {
    return {
        activity: linkedActivity,
        lockoutLocations:
            linkedActivity?.locations
                ?.map(({ location }) => location)
                .filter((location): location is LocationDTO => location !== null) ?? [],
        availableActions: {
            attachable: false,
            canClone: false,
        },
        lockoutType,
    } satisfies Partial<LockoutReadDTO>;
};
export const makeLockoutDefaultValuesFromClonedLockout = (lockoutReadDTO: LockoutReadDTO) => {
    const { id, version, ...rest } = lockoutReadDTO;
    return {
        ...rest,
        equipmentToLock: [],
        powerConvertersText: null,
        lockout1level: null,
        lockout2level: null,
        grantedWorkingTime: null,
        grantedWorkingTimeStart: null,
        grantedWorkingTimeEnd: null,
        lockExecutionDate: null,
        lockEdmsProcedure: null,
        lockOtherProcedure: null,
        lockoutComments: null,
        unlockExecutionDate: null,
        unlockEdmsProcedure: null,
        unlockOtherProcedure: null,
        unlockExecutionComments: null,
        lockType: null,
        full: null,
        availableActions: {
            attachable: false,
            canClone: false,
        },
    } satisfies Omit<LockoutReadDTO, 'id' | 'version'>;
};

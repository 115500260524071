import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface WdpTabMenuContextValue {
    isOpenActivityImportDialog: boolean;
    setOpenActivityImportDialog: (open: boolean) => void;
}

const WdpTabMenuContext = createContext<WdpTabMenuContextValue | null>(null);

interface WdpTabMenuContextProviderProps {
    children: ReactNode;
}

export const WdpTabMenuContextProvider: React.FC<WdpTabMenuContextProviderProps> = ({ children }) => {
    const [isOpenActivityImportDialog, setOpenActivityImportDialog] = useState(false);
    return (
        <WdpTabMenuContext.Provider
            value={{ isOpenActivityImportDialog, setOpenActivityImportDialog } satisfies WdpTabMenuContextValue}
        >
            {children}
        </WdpTabMenuContext.Provider>
    );
};

export const useWdpTabMenuContext = () => {
    const value = useContext(WdpTabMenuContext);
    if (value === null) {
        throw new Error();
    }
    return value;
};

import { Button, Popover, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface DescriptionPopoverProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSubmit: (description: string) => void;
    initialValue: string;
    loading: boolean;
    error: boolean;
}
export const DescriptionPopover: React.FC<DescriptionPopoverProps> = ({
    anchorEl,
    onClose,
    onSubmit,
    initialValue,
    loading,
    error,
}) => {
    const [description, setDescription] = useState(initialValue);

    const handleClose = () => {
        if (!loading) {
            onClose();
        }
    };

    return (
        <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    padding: 2,
                },
            }}
        >
            <div>
                <TextField
                    label="Description"
                    variant="standard"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    disabled={loading}
                    error={error}
                />
            </div>
            <Stack direction="row" spacing={1} sx={{ marginTop: 2 }} justifyContent="flex-end">
                <Button onClick={handleClose} sx={{ color: grey[600] }} disabled={loading}>
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button onClick={() => onSubmit(description)} disabled={loading}>
                    <FormattedMessage id="document.attachment.action.saveDescription" />
                </Button>
            </Stack>
        </Popover>
    );
};

import { DocumentType } from '../../api/dto';
import { ActivityStatusColorMapping } from './ActivityStatusColorMapping';
import { DIMRStatusColorMapping } from './DIMRStatusColorMapping';
import { FirePermitStatusColorMapping } from './FirePermitStatusColorMapping';
import { Is37ChangeRequestStatusColorMapping } from './Is37ChangeRequestStatusColorMapping';
import { Is37StatusColorMapping } from './Is37StatusColorMapping';
import { LockoutStatusColorMapping } from './LockoutStatusColorMapping';
import { NdcStatusColorMapping } from './NdcStatusColorMapping';
import { StatusColor } from './StatusColor';
import { VicStatusColorMapping } from './VicStatusColorMapping';

export const DocumentColorMapping: Partial<Record<DocumentType, Record<string, StatusColor>>> = {
    [DocumentType.NDC]: NdcStatusColorMapping,
    [DocumentType.IS37]: Is37StatusColorMapping,
    [DocumentType.IS37_CR]: Is37ChangeRequestStatusColorMapping,
    [DocumentType.FP]: FirePermitStatusColorMapping,
    [DocumentType.DIMR]: DIMRStatusColorMapping,
    //[DocumentType.RPA]: null,
    //[DocumentType.WDP]: null,
    [DocumentType.LOK]: LockoutStatusColorMapping,
    [DocumentType.VIC]: VicStatusColorMapping,
    [DocumentType.ACTIVITY]: ActivityStatusColorMapping,
};

import { Alert, Link } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useApplicationSettings } from '../components/application/ApplicationSettingsProvider';
import { getServiceNowKnowledgeBase } from '../routes/externalRoutes';
import { getOIMUrl } from '../routes/oimRoutes';
import { TaskSearchPage } from './documents/task/TaskSearchPage';

interface TasksPageProps {}

export const TasksPage: React.FC<TasksPageProps> = () => {
    const { environment } = useApplicationSettings();
    return (
        <TaskSearchPage
            header={
                <Alert severity="info" sx={{ mb: 1, mt: 1 }}>
                    <FormattedMessage
                        id="task.infoLegacy"
                        values={{
                            a1: (children) => (
                                <Link href={getOIMUrl('user/showDashboard', {}, {}, window.origin, environment)}>
                                    {children}
                                </Link>
                            ),
                            a2: (children) => <Link href={getServiceNowKnowledgeBase('KB0009818')}>{children}</Link>,
                        }}
                    />
                </Alert>
            }
        />
    );
};

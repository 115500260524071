import { TextField } from '@mui/material';
import { Compatible } from '@silevis/reactgrid';
import React, { useEffect, useState } from 'react';
import { DialogInputLayout } from './DialogInputLayout';
import { LongInputCell } from './LongInputCellTemplate';

interface ConfirmationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    cell: Compatible<LongInputCell> | undefined;
}

export const DialogLongInput: React.FC<ConfirmationDialogProps> = ({ open, cell, setOpen }) => {
    const [inputValue, setInputValue] = useState(cell ? cell.text : '');

    useEffect(() => {
        setInputValue(cell ? cell.text : '');
    }, [cell, open, setOpen]);

    const handleCancel = () => {
        if (!!cell) cell.setData(cell.text);
        setOpen(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        cell ? cell.setData(newValue) : () => {};
    };

    const field = {
        name: '',
        onBlur: () => 1,
        onChange: handleChange,
        value: inputValue,
        ref: () => 1,
    };

    return (
        <DialogInputLayout
            open={open}
            setOpen={setOpen}
            onConfirm={() => setOpen(false)}
            onCancel={handleCancel}
            readOnly={cell?.nonEditable}
        >
            <TextField
                {...field}
                value={field.value || ''}
                size="small"
                multiline
                disabled={cell?.nonEditable}
                fullWidth
                sx={{
                    flexGrow: 1,
                }}
                InputLabelProps={{ shrink: true }}
            />
        </DialogInputLayout>
    );
};

import {
    ArrowDropDownCircleTwoTone,
    BuildTwoTone,
    ConstructionTwoTone,
    CorporateFareTwoTone,
    CropFreeTwoTone,
    DomainTwoTone,
    FoundationTwoTone,
    LocalShippingTwoTone,
    MeetingRoomTwoTone,
    ParkTwoTone,
    PlaceTwoTone,
    Public,
    ScienceTwoTone,
} from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { LocationDTO, LocationTypeCode } from '../../api/dto';
import { getLocationChildren, getLocationRoots, LocationWithNodeDTO } from '../../api/locations';
import { NoPaddingTooltip } from '../../components/layout/elements/NoPaddingTooltip';
import { LocationInfoCard } from '../../components/LocationInfoCard';
import { useMapDialogContext } from '../../components/MapDialogContext';
import { FilterTypeEnum } from '../../components/searching/filters';
import { TreeWidget } from '../../components/TreeWidget';
import { useDeviceType } from '../../hooks/useDeviceType';
import { fetchLocationsByName } from '../../lib/api';
import { getLocationLabel } from '../../lib/label';
import { areLocationsEqual, isLeafLocation } from '../../lib/records/location';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const LOCATION_TYPE_ICON: Record<LocationTypeCode, ReactElement | undefined> = {
    [LocationTypeCode.BARAQUE]: <FoundationTwoTone />,
    [LocationTypeCode.BUILDING]: <DomainTwoTone />,
    [LocationTypeCode.CUSTOM_EXPERIMENT]: <ScienceTwoTone />,
    [LocationTypeCode.CUSTOM_ZONE_SECURITY]: undefined,
    [LocationTypeCode.CUSTOM_POINT_GAS]: undefined,
    [LocationTypeCode.UNDERGROUND_EQUIPMENT]: <ArrowDropDownCircleTwoTone />,
    [LocationTypeCode.GREEN_SPACE]: <ParkTwoTone />,
    [LocationTypeCode.FLOOR]: <CorporateFareTwoTone />,
    [LocationTypeCode.GROUPING_FACILITY]: <PlaceTwoTone />,
    [LocationTypeCode.GROUPING_FACILITY_NODE]: <CorporateFareTwoTone />,
    [LocationTypeCode.ROOM]: <MeetingRoomTwoTone />,
    [LocationTypeCode.MACHINE]: <BuildTwoTone />,
    [LocationTypeCode.STRUCTURE]: <ConstructionTwoTone />,
    [LocationTypeCode.SITE]: <DomainTwoTone />,
    [LocationTypeCode.ROAD]: <LocalShippingTwoTone />,
    [LocationTypeCode.ZONE]: <CropFreeTwoTone />,
};

const locationTypeProperties = (allowAnyLocation: boolean = false): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: (searchTerm) => fetchLocationsByName({ searchTerm, selectable: !allowAnyLocation }),
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getLocationLabel,
    autocompleteProps: {
        isOptionEqualToValue: areLocationsEqual,
        forcePopupIcon: false,
    },
    endAdornment: ({ field, multiple, matchId, updateValue = () => {}, disabled, readOnly }) => {
        const intl = useIntl();
        const { value: currentLocationValue, name } = field;
        const { openDialog } = useMapDialogContext();
        const { isMobile } = useDeviceType();
        return (
            <>
                <TreeWidget
                    title={intl.formatMessage({ id: 'document.location.tooltip' })}
                    disabled={disabled || readOnly}
                    TreeProps={{
                        loadRootNodes: () => getLocationRoots(),
                        loadChildrenNodes: (parent: LocationWithNodeDTO) => getLocationChildren({ parent: parent }),
                        getNodeLabel: (location: LocationWithNodeDTO) => (
                            <Stack
                                direction={isMobile ? 'column' : 'row'}
                                spacing={isMobile ? 0 : 1}
                                alignItems={isMobile ? 'left' : 'center'}
                            >
                                <Typography noWrap>{location.impactAlias || location.code}</Typography>
                            </Stack>
                        ),
                        getNodeId: (location: LocationWithNodeDTO) => String(location.id),
                        isLeafNode: isLeafLocation,
                        selectedNodes: currentLocationValue
                            ? multiple
                                ? currentLocationValue
                                : [currentLocationValue]
                            : [],
                        onSelect: (locations: LocationWithNodeDTO[]) => {
                            if (!locations) return;
                            if (locations.length === 1 && !multiple) {
                                updateValue && updateValue(matchId ? locations[0].id : locations[0]);
                            } else {
                                updateValue &&
                                    updateValue(matchId ? locations.map((e: LocationWithNodeDTO) => e.id) : locations);
                            }
                        },
                        keys: ['locations-picker', name],
                        isSelectable: (location) => allowAnyLocation || location.selectable,
                        multiSelect: multiple,
                        tooltipContent: (location) => <LocationInfoCard location={location} />,
                        icon: (location) => LOCATION_TYPE_ICON[location.locationType],
                    }}
                />
                {!multiple && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <NoPaddingTooltip
                            title={currentLocationValue ? <LocationInfoCard location={currentLocationValue} /> : null}
                        >
                            {/* To not make the IconButton take full width and display the shadow weirdly it should be
                wrapped in a Box. display flex and alignItems center make sure that the icon is displayed
                centered and not on top */}
                            <span>
                                <IconButton
                                    onClick={() => currentLocationValue && openDialog(currentLocationValue)}
                                    size="small"
                                    disabled={!currentLocationValue || disabled}
                                    sx={{ my: -1, ml: 0.5 }}
                                >
                                    <Public />
                                </IconButton>
                            </span>
                        </NoPaddingTooltip>
                    </Box>
                )}
            </>
        );
    },
});

interface LocationSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
    allowAnyLocation?: boolean;
}

export const locationSchema = ({
    path,
    label,
    required,
    multiple,
    allowAnyLocation,
}: LocationSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: { ...locationTypeProperties(allowAnyLocation), multiple },
});

interface LocationFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const locationFilterSchema = ({ id, label }: LocationFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: LocationDTO) => value.id,
    typeProperties: locationTypeProperties(),
});

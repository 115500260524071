import { Box } from '@mui/material';
import * as React from 'react';
import { ImpactLink } from '../components/ImpactLink';
import { ROUTES } from '../routes/routes';

interface IHomepageProps {}

export const Homepage: React.FunctionComponent<IHomepageProps> = () => {
    return (
        <Box m={2} display="flex" flexDirection="column" sx={{ height: '100%' }}>
            <ImpactLink to={ROUTES.is37.search}>Search IS37s</ImpactLink>
            <ImpactLink to={ROUTES.noteDeCoupure.search}>Search NdCs</ImpactLink>
            <ImpactLink to={ROUTES.activity.search}>Search Activities</ImpactLink>
        </Box>
    );
};

import { Box, keyframes } from '@mui/material';
import * as React from 'react';

// Taken from https://github.com/mui/material-ui/issues/36914

interface PulseProps {
    enabled: boolean;
    scale?: number;
    duration?: number;
    children: React.ReactNode;
}

export const Pulse = ({ enabled, scale = 1.25, duration = 400, children }: PulseProps) => {
    const pulse = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(${scale});
    }
    100% {
      transform: scale(1);
    }
  `;

    return (
        <Box
            sx={{
                animation: enabled ? `${pulse} ${duration}ms ease` : undefined,
            }}
        >
            {children}
        </Box>
    );
};

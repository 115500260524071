import { Engineering } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { ActivityGridReadDTO, ActivityReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { translateValue } from '../language';
import { getActivityStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const ActivityIcon = Engineering;

export const getActivityInformation = (
    activity: ActivityReadDTO | ActivityGridReadDTO,
    intl: IntlShape
): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.activity.view, params: { id: activity.id } }),
        name: intl.formatMessage({ id: 'activity.titleExisting' }, { id: activity.id }),
        title: activity.title,
        status: getActivityStatus(activity, intl),
        creation: activity,
        details: [
            {
                labelKey: 'document.field.facility',
                value: activity.facility ? translateValue(activity.facility, intl.locale).label : null,
                type: true,
            },
            {
                labelKey: 'document.field.responsible',
                value:
                    ('responsible' in activity
                        ? activity.responsible
                        : 'activityResponsible' in activity
                        ? activity.activityResponsible
                        : undefined
                    )?.searchLabel ?? '',
                italic: true,
            },
            ...(activity.interventionPeriod
                ? [
                      {
                          labelKey: 'activity.field.interventionPeriod' as const,
                          value: activity.interventionPeriod.periodName,
                      },
                  ]
                : []),
            ...(!activity.interventionPeriod && activity.proposedInterventionPeriod
                ? [
                      {
                          labelKey: 'activity.field.proposedInterventionPeriod' as const,
                          value: activity.proposedInterventionPeriod.periodName,
                      },
                  ]
                : []),

            // TODO
        ],
    };
};

import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import React, { createContext, useContext } from 'react';
import packagejson from '../../../package.json';
import { getApplicationSettings } from '../../api/application';
import { EnvironmentDTO } from '../../api/dto';

export const ENVIRONMENT_SLUGS = {
    LOCAL: 'local',
    DEVELOPMENT: 'dev',
    TEST: 'test',
    PRODUCTION: 'prod',
} as const satisfies Record<EnvironmentDTO, string>;

export const ApplicationSettingsContext = createContext<
    UseQueryResult<Awaited<ReturnType<typeof getApplicationSettings>>> | undefined
>(undefined);

export const useAsyncApplicationSettings = () => {
    const value = useContext(ApplicationSettingsContext);
    if (value === undefined) {
        throw new Error(); // Out of context
    }
    return value;
};

export const useApplicationSettings = () => {
    const { data: settings } = useAsyncApplicationSettings();
    if (settings === undefined) {
        throw new Error(); // Settings are undefined
    }
    return settings;
};

interface ApplicationSettingsContextProviderProps {
    children: React.ReactNode;
}
export const ApplicationSettingsProvider: React.FC<ApplicationSettingsContextProviderProps> = ({ children }) => {
    const frontendVersion = packagejson.version;

    const usedQuery = useQuery(['application-settings'], () => getApplicationSettings(), {
        onSuccess: (data) => {
            if (data.frontendSentryDSN) {
                Sentry.init({
                    dsn: data.frontendSentryDSN,
                    environment: data.environment.toLowerCase(),
                    release: frontendVersion,
                });
            }
        },
    });

    return <ApplicationSettingsContext.Provider value={usedQuery}>{children}</ApplicationSettingsContext.Provider>;
};

import { Check, ChevronRight, ExpandMore, InfoTwoTone } from '@mui/icons-material';
import { TreeItem, treeItemClasses, TreeItemContentProps, TreeItemProps, TreeView, useTreeItem } from '@mui/lab';
import {
    Alert,
    alpha,
    Box,
    CircularProgress,
    IconButton,
    LinearProgress,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { Key, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTreeData } from 'react-stately';
import { toast } from 'react-toastify';
import { NoPaddingTooltip } from './layout/elements/NoPaddingTooltip';

interface TreeNode<T extends object> {
    key: Key;
    parentKey: Key;
    value: T;
    children: TreeNode<T>[];
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, multiSelect } = props;

    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
        useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // setting the crtlKey on the handler forces the multiselect in mui's logic producing the right nodeIds
        if (multiSelect) {
            const newEvent = { ...event, ctrlKey: true };
            handleSelection(newEvent);
        } else {
            handleSelection(event);
        }
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            component="div"
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            {icon ? (
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    <IconButton size="small">{icon}</IconButton>
                </div>
            ) : (
                <div className={classes.iconContainer} />
            )}
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                onClick={handleSelectionClick}
                width="100%"
            >
                <Typography component="div" className={classes.label}>
                    {label}
                </Typography>
                {selected ? <Check color="primary" fontSize="small" /> : null}
            </Stack>
        </Stack>
    );
});

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 17,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function CustomTreeItem(props: TreeItemProps) {
    return <StyledTreeItem ContentComponent={CustomContent} {...props} />;
}
export interface TreeStructureProps<TNode> {
    loadRootNodes: () => Promise<TNode[]>;
    loadChildrenNodes: (parent: TNode) => Promise<TNode[]>;
    getNodeLabel: (node: TNode) => JSX.Element | string;
    getNodeId: (node: TNode) => Key;
    isLeafNode: (node: TNode) => boolean;
    isSelectable?: (node: TNode) => boolean;
    selectedNodes?: TNode[];
    onSelect?: (node: TNode[]) => void;
    multiSelect?: boolean;
    keys?: string[];
    tooltipContent?: (node: TNode) => ReactElement | null;
    icon?: (node: TNode) => ReactElement | undefined;
}

export const TreeStructure = <TNode extends object>({
    loadRootNodes,
    loadChildrenNodes,
    getNodeId,
    getNodeLabel,
    isLeafNode,
    isSelectable,
    onSelect,
    selectedNodes,
    multiSelect = false,
    keys = [],
    tooltipContent,
    icon,
}: TreeStructureProps<TNode>): ReactElement => {
    const tree = useTreeData<TNode>({
        initialItems: [],
        getKey: (item) => getNodeId(item),
    });
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [childrenLoaded, setChildrenLoaded] = useState<Key[]>([]);
    const [childrenLoading, setChildrenLoading] = useState<Key[]>([]);
    const [controlled] = useState<boolean>(Boolean(selectedNodes));

    const { isLoading, isError: treeDataFailed } = useQuery(['load-root-nodes', ...keys], loadRootNodes, {
        onSuccess: (rootNodes: TNode[]) => {
            tree.insert(null, 0, ...rootNodes);
        },
    });

    useEffect(() => {
        const nodeIds: string[] = selectedNodes?.map((node) => String(getNodeId(node))) || [];
        setSelected(nodeIds);
    }, [selectedNodes]);

    const updateSelected = (nodeIds: string[]) => {
        if (!controlled) {
            setSelected(nodeIds);
        }
        onSelect && onSelect(nodeIds.map((id) => tree.getItem(id).value));
    };

    const renderTree = (node: TreeNode<TNode>) => {
        const label = (
            <Stack direction="row">
                {tooltipContent && (
                    <NoPaddingTooltip title={tooltipContent(node.value)}>
                        <Box sx={{ color: grey[400], ml: -1, mr: 1, display: 'flex' }}>
                            {icon && icon(node.value) !== undefined ? <>{icon(node.value)}</> : <InfoTwoTone />}
                        </Box>
                    </NoPaddingTooltip>
                )}
                {getNodeLabel(node.value)}
            </Stack>
        );
        return (
            <CustomTreeItem
                key={node.key}
                nodeId={String(node.key)}
                label={label}
                // this prop can't be properly typed so the 'as' is needed as a workaround, check this post
                // https://stackoverflow.com/questions/69481071/material-ui-how-to-pass-custom-props-to-a-custom-treeitem
                ContentProps={
                    {
                        multiSelect: multiSelect,
                    } as TreeItemContentProps
                }
            >
                {!childrenLoaded.includes(node.key) && !isLeafNode(node.value) ? (
                    // We need to trick the TreeView component in order to show the expand button
                    // At least we use it for loading feedback
                    <CustomTreeItem
                        disabled
                        key={`${node.key}-loading`}
                        nodeId={`${node.key}-loading`}
                        label={
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <CircularProgress size={12} />
                                <Typography>Fetching...</Typography>
                            </Stack>
                        }
                        // this prop can't be properly typed so the 'as' is needed as a workaround, check this post
                        // https://stackoverflow.com/questions/69481071/material-ui-how-to-pass-custom-props-to-a-custom-treeitem
                        ContentProps={
                            {
                                multiSelect: multiSelect,
                            } as TreeItemContentProps
                        }
                    />
                ) : (
                    node.children.map((n) => renderTree(n))
                )}
            </CustomTreeItem>
        );
    };
    const renderRoots = (roots: TreeNode<TNode>[]) => roots.map((root) => renderTree(root));

    const handleToggle = async (_: any, nodeIds: string[]) => {
        setExpanded(nodeIds);
        const [nodeId] = nodeIds.filter((x) => !expanded.includes(x));
        if (!nodeId) return;

        const parentNode = tree.getItem(nodeId);
        if (!childrenLoaded.includes(nodeId) && !childrenLoading.includes(nodeId)) {
            setChildrenLoading((prev) => [...prev, nodeId]);
            try {
                const children = await loadChildrenNodes(parentNode.value);
                tree.append(nodeId, ...children);
                setChildrenLoading((prev) => prev.filter((e) => e !== nodeId));
                setChildrenLoaded((prev) => [...prev, nodeId]);
            } catch (e) {
                toast.error("Couldn't fetch children nodes");
            }
        }
    };

    const handleSelect = (_: any, nodeIds: string[]) => {
        if (!multiSelect) {
            const item = tree.getItem(nodeIds[0]);
            if (!isSelectable || isSelectable(item.value)) {
                if (selected[0] === nodeIds[0]) {
                    updateSelected([]);
                } else {
                    updateSelected([nodeIds[0]]);
                }
            }
        } else {
            const validSelections = nodeIds.filter((id) => {
                const item = tree.getItem(id);
                return !!item && (!isSelectable || isSelectable(item.value));
            });
            if (validSelections.length === 1 && selected.length === 1 && validSelections[0] === selected[0]) {
                updateSelected([]);
            } else {
                updateSelected(validSelections);
            }
        }
    };

    if (isLoading) {
        return <LinearProgress />;
    } else if (treeDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="common.error" />
            </Alert>
        );
    }
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect // using it to keep the same data structure all the time
        >
            {renderRoots(tree.items)}
        </TreeView>
    );
};

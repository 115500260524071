import { FormatDateOptions, IntlShape } from 'react-intl';

const DATE_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
} as const satisfies FormatDateOptions;

const DATE_TIME_OPTIONS = {
    ...DATE_OPTIONS,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
} as const satisfies FormatDateOptions;

const formatDateToParts = (date: string | Date, options: FormatDateOptions, intl: IntlShape) =>
    Object.fromEntries(intl.formatDateToParts(date, options).map(({ type, value }) => [type, value])) as Record<
        Intl.DateTimeFormatPartTypes,
        string
    >;

// Format: dd-MMM-yyyy
// (note that the actual length of the "M" part is dependent on the locale)
const formatDatePart = ({ day, month, year }: { day: string; month: string; year: string }): string =>
    [day, month, year].join('-');

// Format: HH:mm
const formatTimePart = ({ hour, minute }: { hour: string; minute: string }): string => [hour, minute].join(':');

export const formatDate = (date: string | Date, intl: IntlShape): string =>
    formatDatePart(formatDateToParts(date, DATE_OPTIONS, intl));

export const formatDateTime = (date: string | Date, intl: IntlShape): string => {
    const parts = formatDateToParts(date, DATE_TIME_OPTIONS, intl);
    return [formatDatePart(parts), formatTimePart(parts)].join(' ');
};

import { History, Lens, Pending } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
    Alert,
    Box,
    IconButton,
    LinearProgress,
    Paper,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDocumentWorkflow } from '../api/documentWorkflows';
import { WorkflowDTO, WorkflowHistoryEntryDTO } from '../api/dto';
import { useAdminRole } from '../hooks/useAdminRole';
import { translateInfoline } from '../lib/language';
import { DefaultAccordion } from './DefaultAccordion';
import { ImpactFormattedDate } from './ImpactFormattedDate';

interface WorkflowStateIconProps {
    isWaiting?: boolean;
}

const WorkflowStateIcon: React.FC<WorkflowStateIconProps> = ({ isWaiting }) => {
    const [anchorEl, setAnchorEl] = React.useState<SVGElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<SVGElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const Icon = isWaiting ? Pending : Lens;

    return (
        <div>
            <Icon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ color: isWaiting ? green[500] : undefined, cursor: 'help' }}
                color={isWaiting ? undefined : 'primary'}
            />
            <Popover
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>
                    <FormattedMessage
                        id={isWaiting ? 'document.workflow.status.waiting' : 'document.workflow.status.completed'}
                    />
                </Typography>
            </Popover>
        </div>
    );
};

interface WorkflowHistoryProps {
    documentType: Parameters<typeof getDocumentWorkflow>[0]['documentType'];
    documentId: number;
}

export const WorkflowHistory: React.FC<WorkflowHistoryProps> = ({ documentType, documentId }) => {
    const intl = useIntl();
    const workflowSorter = useCallback((workflows: WorkflowDTO[]): WorkflowDTO[] => {
        // Time ascending
        const historyEntryComparator = (line: WorkflowHistoryEntryDTO): number => new Date(line.created).getTime();
        // Ascending by earliest line
        const workflowComparator = (workflow: WorkflowDTO): number =>
            workflow.historyEntries.map(historyEntryComparator).sort()[0] ?? Number.MAX_SAFE_INTEGER;
        return workflows
            .map(({ historyEntries, ...workflow }) => ({
                historyEntries: historyEntries
                    .slice()
                    .sort((a, b) => historyEntryComparator(a) - historyEntryComparator(b)),
                ...workflow,
            }))
            .sort((a, b) => workflowComparator(a) - workflowComparator(b));
    }, []);
    const {
        isLoading,
        isError,
        data: workflows,
    } = useQuery(['workflows', documentType, documentId], () =>
        getDocumentWorkflow({ documentType, id: documentId }).then(workflowSorter)
    );

    const renderTimestamp = (timestamp: string | Date) => {
        const date = new Date(timestamp);
        return (
            <time dateTime={date.toISOString()}>
                <ImpactFormattedDate date={date} time />
            </time>
        );
    };
    const userIsAdmin = useAdminRole();

    return (
        <Box sx={{ mt: 2 }}>
            <DefaultAccordion
                title={intl.formatMessage({ id: 'document.workflow.panel' })}
                icon={<History color="action" />}
            >
                {isLoading ? (
                    <LinearProgress />
                ) : isError ? (
                    <Alert severity="error">
                        <FormattedMessage id="document.workflow.error" />
                    </Alert>
                ) : workflows.length == 0 ? (
                    <Alert severity="info">
                        <FormattedMessage id="document.workflow.empty" />
                    </Alert>
                ) : (
                    workflows.map((workflow, i) => (
                        <TableContainer
                            key={workflow.id}
                            component={Paper}
                            variant="outlined"
                            sx={{ m: 0, mt: i === 0 ? 0 : 2 }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                {userIsAdmin && (
                                                    <Tooltip
                                                        title={intl.formatMessage({
                                                            id: 'document.workflow.openCamunda',
                                                        })}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                color: deepOrange[500],
                                                            }}
                                                            onClick={() => window.open(workflow.workflowUrl!)}
                                                        >
                                                            <AccountTreeIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Box>
                                                    {workflows && workflows.length > 1 ? (
                                                        <FormattedMessage
                                                            id="document.workflow.labelWithIndex"
                                                            values={{ index: i + 1 }}
                                                        />
                                                    ) : (
                                                        <FormattedMessage id="document.workflow.label" />
                                                    )}
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workflow.historyEntries
                                        .map((entry) => translateInfoline(entry, intl.locale))
                                        .map(({ message, created, currentlyNotified }, j) => (
                                            <TableRow
                                                key={j}
                                                sx={{
                                                    backgroundColor: currentlyNotified ? green[50] : undefined,
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <TableCell
                                                    align="center"
                                                    width={1}
                                                    sx={{ pt: 1, pb: 0.5, pr: 1, pl: 2 }}
                                                >
                                                    <WorkflowStateIcon isWaiting={currentlyNotified} />
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 'medium', px: 1, width: '0.1%' }}>
                                                    {renderTimestamp(created)}
                                                </TableCell>
                                                <TableCell sx={{ py: 0, px: 1 }}>{message}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ))
                )}
            </DefaultAccordion>
        </Box>
    );
};

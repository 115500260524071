import { getBulkValidation } from '../../api/equipment';
import { fetchActiveEquipment, fetchCryoEquipmentByNameOrDescription, fetchEquipment } from '../../lib/api';
import { getEquipmentLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface EquipmentSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
    active?: boolean;
}

export const equipmentSchema = ({
    path,
    label,
    required,
    multiple,
    active,
}: EquipmentSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: {
        idField: 'code',
        fetchOptions: active ? fetchActiveEquipment : fetchEquipment,
        validatePastedCodes: getBulkValidation,
        fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
        multiple,
        getOptionLabel: getEquipmentLabel,
        autocompleteProps: {
            forcePopupIcon: false,
        },
    },
});

export const cryoLockoutEquipmentSchema = ({
    path,
    label,
    required,
    multiple,
}: EquipmentSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: {
        idField: 'code',
        fetchOptions: fetchCryoEquipmentByNameOrDescription,
        validatePastedCodes: getBulkValidation,
        fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
        multiple,
        getOptionLabel: getEquipmentLabel,
        autocompleteProps: {
            forcePopupIcon: false,
        },
    },
});

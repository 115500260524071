import {
    AccountTree,
    AddBox,
    Assignment,
    Book,
    Bookmark,
    Business,
    ExpandLess,
    ExpandMore,
    Hardware,
    RoomPreferences,
    Schedule,
    Sensors,
    Tour,
    Visibility,
} from '@mui/icons-material';
import {
    Box,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ImpactRoleCode, PersonRichReadDTO } from '../../../api/dto';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { ActivityIcon } from '../../../lib/information/activity';
import { DimrIcon } from '../../../lib/information/dimr';
import { FirePermitIcon } from '../../../lib/information/firePermit';
import { Is37Icon } from '../../../lib/information/is37';
import { LockoutIcon } from '../../../lib/information/lockout';
import { NdcIcon } from '../../../lib/information/noteDeCoupure';
import { VicIcon } from '../../../lib/information/vic';
import { WdpIcon } from '../../../lib/information/wdp';
import { ROUTES, RouteType } from '../../../routes/routes';
import { useApplicationSettings } from '../../application/ApplicationSettingsProvider';
import { CreateDropdown } from '../../CreateDropdown';
import { TaskMenuEntry } from '../../tasks/TaskMenuEntry';

interface ISideMenuProps {
    closeMenu: () => void;
}

interface MenuEntry {
    labelKey: MessageDescriptor['id'];
    icon: React.ReactElement;
    route: RouteType;
    createLink?: RouteType | false;
    visible?: (currentUser: PersonRichReadDTO) => boolean;
}
interface MenuSection {
    nameKey?: MessageDescriptor['id'];
    entries: MenuEntry[];
    visible?: (currentUser: PersonRichReadDTO) => boolean;
    open: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
}

const hasAnyRole = (currentUser: PersonRichReadDTO, roles: string[]) =>
    !!currentUser.roleCodes?.some((role) => roles.includes(role));

const documents: MenuEntry[] = [
    {
        labelKey: 'sidebar.activities',
        icon: <ActivityIcon />,
        route: ROUTES.activity.search,
        createLink: ROUTES.activity.create,
    },
    { labelKey: 'sidebar.favouriteActivities', icon: <Bookmark />, route: ROUTES.activity.favourites },
    {
        labelKey: 'sidebar.scheduler',
        icon: <Schedule />,
        route: ROUTES.activity.scheduler,
        visible: (currentUser) =>
            hasAnyRole(currentUser, [
                ImpactRoleCode.IMPACT_FACILITY_COORDINATOR,
                ImpactRoleCode.IMPACT_ADMIN,
                ImpactRoleCode.IMPACT_LOCATION_COORDINATOR,
            ]),
    },
    {
        labelKey: 'sidebar.logbook',
        icon: <Book />,
        route: ROUTES.logbook,
        visible: (currentUser) =>
            hasAnyRole(currentUser, [
                ImpactRoleCode.IMPACT_FACILITY_COORDINATOR,
                ImpactRoleCode.IMPACT_ADMIN,
                ImpactRoleCode.SITE_COORDINATOR,
                ImpactRoleCode.SITE_COORD_READ,
            ]),
    },
    { labelKey: 'sidebar.activityCluster', icon: <AccountTree />, route: ROUTES.activity.clusters },
    { labelKey: 'sidebar.dimrs', icon: <DimrIcon />, route: ROUTES.dimr.search, createLink: false },
    { labelKey: 'sidebar.wdps', icon: <WdpIcon />, route: ROUTES.wdp.search, createLink: ROUTES.wdp.create },
    { labelKey: 'sidebar.vics', icon: <VicIcon />, route: ROUTES.vic.search, createLink: false },
    { labelKey: 'sidebar.lockouts', icon: <LockoutIcon />, route: ROUTES.lockout.search, createLink: false },
    {
        labelKey: 'sidebar.firePermits',
        icon: <FirePermitIcon />,
        route: ROUTES.firePermit.search,
        createLink: false,
    },
    {
        labelKey: 'sidebar.is37s',
        icon: <Is37Icon />,
        route: ROUTES.is37.search,
        createLink: false,
    },
    {
        labelKey: 'sidebar.ndcs',
        icon: <NdcIcon />,
        route: ROUTES.noteDeCoupure.search,
        createLink: false,
    },
    { labelKey: 'sidebar.tasks', icon: <TaskMenuEntry />, route: ROUTES.tasks },
];
const reports: MenuEntry[] = [
    { labelKey: 'sidebar.radiationDoses', icon: <Sensors />, route: ROUTES.radiationDoses },
    { labelKey: 'sidebar.accessControl', icon: <RoomPreferences />, route: ROUTES.accessControl },
    { labelKey: 'sidebar.locations', icon: <Business />, route: ROUTES.locations },
    {
        labelKey: 'sidebar.professionalVisits',
        icon: <Tour />,
        route: ROUTES.professionalVisits,
        visible: (currentUser) =>
            hasAnyRole(currentUser, [ImpactRoleCode.PRT_RO_CERN_USER, ImpactRoleCode.IMPACT_ADMIN]),
    },
];

const facilityAdministration: MenuEntry[] = [
    {
        labelKey: 'sidebar.roleAssignments',
        icon: <Assignment />,
        route: ROUTES.roleAssignments,
        visible: (currentUser) =>
            hasAnyRole(currentUser, [
                ImpactRoleCode.IMPACT_FACILITY_COORDINATOR,
                ImpactRoleCode.FACILITY_ADMIN,
                ImpactRoleCode.IMPACT_ADMIN,
            ]),
    },
    {
        labelKey: 'sidebar.interventionPeriods',
        icon: <Hardware />,
        route: ROUTES.interventionPeriods,
        visible: (currentUser) =>
            hasAnyRole(currentUser, [
                ImpactRoleCode.IMPACT_FACILITY_COORDINATOR,
                ImpactRoleCode.FACILITY_ADMIN,
                ImpactRoleCode.IMPACT_ADMIN,
            ]),
    },
    {
        labelKey: 'sidebar.fieldVisibilities',
        icon: <Visibility />,
        route: ROUTES.fieldVisibilities,
        visible: (currentUser) => hasAnyRole(currentUser, [ImpactRoleCode.IMPACT_AUDIT, ImpactRoleCode.IMPACT_ADMIN]),
    },
];

export const SideMenu: React.FunctionComponent<ISideMenuProps> = ({ closeMenu }) => {
    const { currentUser } = useApplicationSettings();
    const isMobile: boolean = useDeviceType().isMobile;
    const { pathname } = useLocation();

    const [reportsPanelOpen, setReportsPanelOpen] = useState(true);
    const [facilityPanelOpen, setFacilityPanelOpen] = useState(false);

    const sections: MenuSection[] = [
        { entries: documents, open: true },
        { nameKey: 'sidebar.reports', entries: reports, open: reportsPanelOpen, setOpen: setReportsPanelOpen },
        {
            nameKey: 'sidebar.facilityAdministration',
            entries: facilityAdministration,
            visible: (user) =>
                hasAnyRole(user, [
                    ImpactRoleCode.IMPACT_FACILITY_COORDINATOR,
                    ImpactRoleCode.IMPACT_ADMIN,
                    ImpactRoleCode.FACILITY_ADMIN,
                    ImpactRoleCode.IMPACT_AUDIT,
                ]),
            open: facilityPanelOpen,
            setOpen: setFacilityPanelOpen,
        },
    ];

    const bestRouteMatch: string | null = useMemo(
        () =>
            sections
                .flatMap((section) => section.entries)
                .map((entry) => entry.route)
                .filter((route) => matchPath({ path: route, end: false }, pathname))
                .sort((a, b) => b.length - a.length)[0] ?? null,
        [pathname]
    );

    const navigate = useNavigate();
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Toolbar />
            <Box sx={{ px: 1.33, py: 2 }}>
                <CreateDropdown closeMenu={closeMenu} />
            </Box>

            {sections.map((section, sectionIdx) => {
                if (section.visible && !section.visible(currentUser))
                    return <React.Fragment key={sectionIdx}></React.Fragment>;
                return (
                    <Box key={sectionIdx}>
                        <List
                            subheader={
                                <>
                                    <Divider />
                                    {section.nameKey !== undefined && (
                                        <ListItemButton
                                            onClick={() =>
                                                section.setOpen ? section.setOpen(!section.open) : () => {}
                                            }
                                        >
                                            <Typography variant="subtitle2" sx={{ lineHeight: '40px' }}>
                                                <FormattedMessage id={section.nameKey} />
                                            </Typography>
                                            {section.open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    )}
                                </>
                            }
                            sx={{ pb: 0 }}
                        >
                            <Collapse in={section.open}>
                                {section.entries.map(
                                    ({ labelKey, icon, route, createLink, visible = () => true }, idx) => {
                                        if (!visible(currentUser)) return <React.Fragment key={idx}></React.Fragment>;
                                        return (
                                            <ListItem
                                                key={idx}
                                                secondaryAction={
                                                    createLink !== undefined && (
                                                        <Tooltip
                                                            title={
                                                                createLink === false && (
                                                                    <FormattedMessage id="sidebar.needsActivityMessage" />
                                                                )
                                                            }
                                                            placement="right"
                                                        >
                                                            <span>
                                                                <IconButton
                                                                    edge="end"
                                                                    disabled={createLink === false}
                                                                    onClick={() => {
                                                                        if (createLink !== false) {
                                                                            if (isMobile) {
                                                                                closeMenu();
                                                                            }
                                                                            navigate(createLink);
                                                                        }
                                                                    }}
                                                                >
                                                                    <AddBox />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton
                                                    key={idx}
                                                    onClick={() => {
                                                        if (isMobile) closeMenu();
                                                    }}
                                                    selected={bestRouteMatch === route}
                                                    component={Link}
                                                    to={route}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <ListItemIcon>{icon}</ListItemIcon>
                                                    <FormattedMessage id={labelKey} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    }
                                )}
                            </Collapse>
                        </List>
                    </Box>
                );
            })}
        </Box>
    );
};

import { BorderOuter } from '@mui/icons-material';
import { Badge, BadgeProps, Box, Divider, Tab, Tabs } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { MessageDescriptor, useIntl } from 'react-intl';
import { WdpVersionReadDTO } from '../../../api/dto';
import { DefaultAccordion } from '../../../components/DefaultAccordion';
import { WdpTabBaseProps, WdpTabProps } from './tabs/WdpTab';
import { FieldMetadata, MenuButton, WdpDataKey, WdpTabsProps } from './WdpInterfaces';
import { WdpTabKey, WdpTabs } from './WdpTabsConfig';

const StyledBadge = styled(Badge)<BadgeProps>({
    '& .MuiBadge-badge': {
        top: -2,
        right: -2,
    },
});

export interface WdpTabV {
    Component: React.FC<WdpTabProps>;
    Icon: typeof SvgIcon;
    labelTranslationKey: MessageDescriptor['id'];
    props?: WdpTabBaseProps | undefined;
}

const WdpTabsRegion: React.FC<WdpTabsProps> = ({
    wdp,
    setWdp,
    errors,
    disabled,
    readOnly,
    cellLocation,
    setCellLocation,
    attributes,
    lastSubmittedWdp,
    selectedTab,
    setSelectedTab,
    focusedCell,
    showWarning,
    handleUndoChanges,
    handleRedoChanges,
    spreadsheetHistory,
    setSpreadsheetHistory,
    historyEntryIndex,
    setHistoryEntryIndex,
}) => {
    const intl = useIntl();
    const tab: WdpTabV = WdpTabs[selectedTab];
    const CurrentTab = tab.Component;
    const currentTabProps = tab.props;

    const { watch } = useFormContext<WdpVersionReadDTO>();
    const dimr = watch('dimr');

    const switchTab = (event: React.SyntheticEvent, newValue: WdpTabKey) => setSelectedTab(newValue);

    const getRowCount = (metadata: FieldMetadata | undefined): number | undefined =>
        metadata ? wdp[metadata.dataKey as WdpDataKey].length : undefined;
    const isTabDisabled = (key: keyof typeof WdpTabs): boolean => {
        switch (key) {
            case 'RP_ASSESSMENTS':
                return !dimr; // In case the user selects a DIMR we can immediately enable this tab
            case 'AS_PERFORMED':
                return !wdp.availableActions.showAsPerformedTab;
            default:
                return false;
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <DefaultAccordion
                title={intl.formatMessage({ id: 'wdp.panel.spreadsheets' })}
                icon={<BorderOuter color="action" />}
                disableGutters
                disabled={disabled}
                additionalText={showWarning ? intl.formatMessage({ id: 'wdp.panel.spreadsheets.warning' }) : undefined}
                customStyles={{
                    top: 0,
                    position: 'sticky',
                    zIndex: 4,
                }}
            >
                <Tabs
                    value={selectedTab}
                    onChange={switchTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        backgroundColor: grey[!disabled ? 200 : 50],
                        top: 48,
                        position: 'sticky',
                        zIndex: 4,
                    }}
                >
                    {Object.entries(WdpTabs).map(([key, { Icon, labelTranslationKey, props }]) => (
                        <Tab
                            key={key}
                            icon={
                                <StyledBadge badgeContent={getRowCount(props?.metadata)} max={1000 - 1} color="primary">
                                    <Icon />
                                </StyledBadge>
                            }
                            label={intl.formatMessage({ id: labelTranslationKey })}
                            value={key}
                            disabled={disabled || isTabDisabled(key as keyof typeof WdpTabs)}
                        />
                    ))}
                </Tabs>
                <Divider />
                <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
                    {wdp && (
                        <CurrentTab
                            key={selectedTab}
                            wdp={wdp}
                            setWdp={setWdp}
                            customTypes={wdp}
                            errors={errors}
                            disabled={disabled}
                            readOnly={readOnly}
                            cellLocation={cellLocation}
                            setCellLocation={setCellLocation}
                            lastSubmittedWdp={lastSubmittedWdp}
                            focusedCell={focusedCell}
                            handleUndoChanges={handleUndoChanges}
                            handleRedoChanges={handleRedoChanges}
                            spreadsheetHistory={spreadsheetHistory}
                            setSpreadsheetHistory={setSpreadsheetHistory}
                            historyEntryIndex={historyEntryIndex}
                            setHistoryEntryIndex={setHistoryEntryIndex}
                            selectedTab={selectedTab}
                            // Default (for convenience):
                            menuEntries={[]}
                            headers={[]}
                            newRowSupplier={() => ({})}
                            // The following should never happen (why do we have that??)
                            metadata={{ label: '' as any, dataKey: '', idKey: '', labelKey: '', labelNestedKey: '' }}
                            attributes={attributes}
                            // Config
                            {...currentTabProps}
                        />
                    )}
                </Box>
            </DefaultAccordion>
        </Box>
    );
};

export { WdpTabsRegion, type MenuButton, type WdpTabsProps };

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { I18nMessageDTO } from '../api/dto';
import { StripedTableRow } from './StripedTableRow';

interface LocalizationTableProps {
    data: Record<string, I18nMessageDTO>;
}

export const LocalizationTable: React.FC<LocalizationTableProps> = ({ data }) => {
    return (
        <Table stickyHeader size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage id="meta.localization.common.key" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="language.english" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="language.french" />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(data).map((key) => (
                    <StripedTableRow key={key}>
                        <TableCell>
                            <code>{key}</code>
                        </TableCell>
                        <TableCell>{data[key].english}</TableCell>
                        <TableCell>{data[key].french}</TableCell>
                    </StripedTableRow>
                ))}
            </TableBody>
        </Table>
    );
};

import { Cancel, MenuBook, Save } from '@mui/icons-material';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { ResponsiveButton } from '../../components/ResponsiveButton';
import { RadiationDoseForm } from '../../forms/documents/RadiationDoseForm';
import { useDeviceType } from '../../hooks/useDeviceType';

type ReportType = 'webView' | 'pdf' | 'excel';
const reportTypes = { webView: 'Web View', pdf: 'PDF', excel: 'Excel' };

interface RadiationDoseReportProps {}
const RadiationDoseReport: React.FC<RadiationDoseReportProps> = () => {
    return (
        <Box>
            <Typography variant="h5" sx={{ marginTop: 3 }}>
                Collective Activity Dose Summary
            </Typography>
            <Typography>
                Doses are confidential information, please avoid disclosure. To be used only for the performance of your
                professional obligations.
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 3 }}>
                Individual Dose Summary
            </Typography>
            <Typography>
                Doses are confidential information, please avoid disclosure. To be used only for the performance of your
                professional obligations.
            </Typography>
            <Alert severity="success" icon={false}>{`Below 50%: ${1}`}</Alert>
            <Alert severity="warning" icon={false}>{`Between 50% and 75%: ${1}`}</Alert>
            <Alert severity="warning" icon={false} variant="filled">{`Between 75% and 100%: ${1}`}</Alert>
            <Alert severity="error" icon={false} variant="filled">{`More then 100% of the dose estimate: ${1}`}</Alert>

            <Typography variant="h5" sx={{ marginTop: 3 }}>
                Individual Historical Dose Summary
            </Typography>
            <Typography>
                Doses are confidential information, please avoid disclosure. To be used only for the performance of your
                professional obligations.
            </Typography>
            <Typography sx={{ marginTop: 2 }}>Last month:</Typography>
            <Alert severity="success" icon={false} variant="standard">{`Below 900: ${1}`}</Alert>
            <Alert severity="warning" icon={false} variant="standard">{`Between 900 and 1900: ${1}`}</Alert>
            <Alert severity="error" icon={false} variant="standard">{`Over 1900: ${1}`}</Alert>

            <Typography sx={{ marginTop: 2 }}>Last 12 months:</Typography>
            <Alert severity="success" icon={false} variant="standard">{`Below 2500: ${1}`}</Alert>
            <Alert severity="warning" icon={false} variant="standard">{`Between 2500 and 3000: ${1}`}</Alert>
            <Alert severity="error" icon={false} variant="standard">{`Over 3000: ${1}`}</Alert>
        </Box>
    );
};

export const RadiationDosesPage: React.FunctionComponent = () => {
    const [reportType, setReportType] = React.useState<ReportType>('webView');
    const [displayReport, setDisplayReport] = React.useState(false);
    const { isMobile } = useDeviceType();

    const showWebViewReport = () => {
        setDisplayReport(true);
    };
    const generateReport = () => {
        if (reportType === 'webView') showWebViewReport();
    };

    return (
        <DocumentPageStructure
            Header={
                <>
                    <Typography>Radiation Dose Reports</Typography>
                </>
            }
            Body={
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        Doses are confidential information, please avoid disclosure. To be used only for the performance
                        of your professional obligations.
                    </Alert>

                    <RadiationDoseForm />

                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        spacing={1}
                        sx={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}
                    >
                        <Stack direction="row" spacing={1}>
                            {Object.keys(reportTypes).map((report) => {
                                return (
                                    <Button
                                        key={report}
                                        onClick={() => setReportType(report as ReportType)}
                                        variant={reportType === report ? 'contained' : 'outlined'}
                                    >
                                        {reportTypes[report as ReportType]}
                                    </Button>
                                );
                            })}
                        </Stack>

                        <Box sx={{ flexGrow: 1 }} />

                        <Stack direction="row" spacing={1}>
                            <ResponsiveButton icon={<Save />}>Save</ResponsiveButton>
                            <ResponsiveButton icon={<Cancel />}>Reset</ResponsiveButton>
                            <ResponsiveButton icon={<MenuBook />} onClick={generateReport}>
                                Generate Report
                            </ResponsiveButton>
                        </Stack>
                    </Stack>

                    {displayReport ? <RadiationDoseReport /> : null}
                </Box>
            }
        />
    );
};

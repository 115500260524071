import { ScheduleSend, SvgIconComponent } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { DocumentType, DocumentVersionReferenceDTO } from '../../../api/dto';
import { getRoute, ROUTES } from '../../../routes/routes';
import { ActivityIcon } from '../../information/activity';
import { DimrIcon } from '../../information/dimr';
import { FirePermitIcon } from '../../information/firePermit';
import { Is37Icon } from '../../information/is37';
import { LockoutIcon } from '../../information/lockout';
import { NdcIcon } from '../../information/noteDeCoupure';
import { TaskIcon } from '../../information/task';
import { EntityType } from '../../information/types/EntityType';
import { VicIcon } from '../../information/vic';
import { WdpIcon } from '../../information/wdp';
import { WorkOrderIcon } from '../../information/workOrder';

export interface DocumentIdentifier {
    documentType: DocumentType;
    documentId: number;
    documentVersion: DocumentVersionReferenceDTO | null;
}

export const getDocumentTypeName = (documentType: DocumentType, intl: IntlShape): string => {
    switch (documentType) {
        case DocumentType.NDC:
            return intl.formatMessage({ id: 'ndc.name' });
        case DocumentType.IS37:
            return intl.formatMessage({ id: 'is37.name' });
        case DocumentType.IS37_CR:
            return intl.formatMessage({ id: 'is37.changeRequest.name' });
        case DocumentType.FP:
            return intl.formatMessage({ id: 'firePermit.name' });
        case DocumentType.DIMR:
            return intl.formatMessage({ id: 'dimr.name' });
        case DocumentType.RPA:
            return intl.formatMessage({ id: 'dimr.rpa.name' });
        case DocumentType.WDP:
            return intl.formatMessage({ id: 'wdp.name' });
        case DocumentType.LOK:
            return intl.formatMessage({ id: 'lockout.name' });
        case DocumentType.VIC:
            return intl.formatMessage({ id: 'vic.name' });
        case DocumentType.ACTIVITY:
            return intl.formatMessage({ id: 'activity.name' });
    }
};

export const getDocumentName = (
    { documentType, documentId, documentVersion }: DocumentIdentifier,
    intl: IntlShape
): string => {
    switch (documentType) {
        case DocumentType.NDC:
            return intl.formatMessage({ id: 'ndc.titleExisting' }, { ndcNumber: documentId });
        case DocumentType.IS37:
            return intl.formatMessage({ id: 'is37.titleExisting' }, { is37Id: documentId });
        case DocumentType.IS37_CR:
            return intl.formatMessage({ id: 'is37.changeRequest.titleExisting' }, { changeRequestId: documentId });
        case DocumentType.FP:
            return intl.formatMessage({ id: 'firePermit.titleExisting' }, { firePermitId: documentId });
        case DocumentType.DIMR:
            if (documentVersion === null) throw new Error();
            return intl.formatMessage(
                { id: 'title.dimrView' },
                { masterId: documentVersion.masterId, versionNumber: documentVersion.versionNumber }
            );
        case DocumentType.RPA:
            throw new Error(); // Unsupported operation
        case DocumentType.WDP:
            if (documentVersion === null) throw new Error();
            return intl.formatMessage(
                { id: 'title.wdpView' },
                { masterId: documentVersion.masterId, versionNumber: documentVersion.versionNumber }
            );
        case DocumentType.LOK:
            return intl.formatMessage({ id: 'lockout.titleExisting' }, { id: documentId });
        case DocumentType.VIC:
            return intl.formatMessage({ id: 'vic.titleExisting' }, { id: documentId });
        case DocumentType.ACTIVITY:
            return intl.formatMessage({ id: 'activity.titleExisting' }, { id: documentId });
    }
};

export const getDocumentUrl = ({
    documentType,
    documentId,
    documentVersion,
}: DocumentIdentifier): ReturnType<typeof getRoute> => {
    switch (documentType) {
        case DocumentType.NDC:
            return getRoute({ path: ROUTES.noteDeCoupure.view, params: { id: String(documentId) } });
        case DocumentType.IS37:
            return getRoute({ path: ROUTES.is37.view, params: { id: String(documentId) } });
        case DocumentType.IS37_CR:
            return getRoute({ path: ROUTES.is37.view, params: { id: String(documentId) } });
        case DocumentType.FP:
            return getRoute({ path: ROUTES.firePermit.view, params: { id: String(documentId) } });
        case DocumentType.DIMR:
            if (documentVersion === null) throw new Error();
            return getRoute({
                path: ROUTES.dimr.view,
                params: { id: String(documentVersion.masterId), version: String(documentVersion.versionNumber) },
            });
        case DocumentType.RPA:
            throw new Error(); // Unsupported operation
        case DocumentType.WDP:
            if (documentVersion === null) throw new Error();
            return getRoute({
                path: ROUTES.wdp.view,
                params: { id: String(documentVersion.masterId), version: String(documentVersion.versionNumber) },
            });
        case DocumentType.LOK:
            return getRoute({ path: ROUTES.lockout.view, params: { id: String(documentId) } });
        case DocumentType.VIC:
            return getRoute({ path: ROUTES.vic.view, params: { id: String(documentId) } });
        case DocumentType.ACTIVITY:
            return getRoute({ path: ROUTES.activity.view, params: { id: String(documentId) } });
    }
};

export const getDocumentIcon = (type: EntityType): SvgIconComponent =>
    ({
        [EntityType.Activity]: ActivityIcon,
        [EntityType.ActivityNim]: ActivityIcon,
        [EntityType.Is37]: Is37Icon,
        [EntityType.Is37ChangeRequest]: ScheduleSend,
        [EntityType.Dimr]: DimrIcon,
        [EntityType.Wdp]: WdpIcon,
        [EntityType.Lockout]: LockoutIcon,
        [EntityType.NoteDeCoupure]: NdcIcon,
        [EntityType.FirePermit]: FirePermitIcon,
        [EntityType.Vic]: VicIcon,
        [EntityType.WorkOrder]: WorkOrderIcon,
        [EntityType.Task]: TaskIcon,
    }[type]);

import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PersonReadDTO } from '../api/dto';

interface PersonTooltipProps {
    person: PersonReadDTO;
    children: React.ReactElement;
}

export const PersonTooltip: React.FC<PersonTooltipProps> = ({ person, children }) => {
    const intl = useIntl();
    const tooltipText = (
        <Stack direction="column" spacing={0}>
            <Typography variant="caption">{person.searchLabel}</Typography>
            <Typography variant="caption">
                <FormattedMessage
                    id="common.labelValue"
                    values={{ label: intl.formatMessage({ id: 'document.person.orgUnit' }), value: person.orgUnitCode }}
                />
            </Typography>
            <Typography variant="caption">
                <FormattedMessage
                    id="common.labelValue"
                    values={{ label: intl.formatMessage({ id: 'document.person.phone' }), value: person.phone1 }}
                />
            </Typography>

            {person.phone3Display ? (
                <Typography variant="caption">
                    <FormattedMessage
                        id="common.labelValue"
                        values={{
                            label: intl.formatMessage({ id: 'document.person.mobile' }),
                            value: person.phone3Display,
                        }}
                    />
                </Typography>
            ) : (
                <></>
            )}
        </Stack>
    );

    return <Tooltip title={tooltipText}>{children}</Tooltip>;
};

import { Add } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface CreateDocumentButtonAdornmentProps {
    title: string;
    to: string | null;
    disabled: boolean | undefined;
    readOnly: boolean | undefined;
}

export const CreateDocumentButtonAdornment: React.FC<CreateDocumentButtonAdornmentProps> = ({
    title,
    to,
    disabled,
    readOnly,
}) => {
    return !readOnly && to !== null ? (
        <Tooltip title={title}>
            <span>
                <IconButton
                    onClick={() => window.open(to, '_blank')}
                    size="small"
                    disabled={disabled}
                    sx={{ my: -1, ml: 0.5 }}
                >
                    <Add />
                </IconButton>
            </span>
        </Tooltip>
    ) : null;
};

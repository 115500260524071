// This solution is a temporary workaround due to the broken nature of SFQB (it does not escape strings)

// Why this works: https://github.com/turkraft/spring-filter/blob/main/src/main/antlr4/FilterTokens.g4#L32
export const escapeSfqbString = (unescaped: string, like: boolean = false): string => {
    let escaped: string = unescaped;
    if (like) {
        // We have no way of escaping these characters, so the most meaningful action is to replace them by regular spaces
        escaped = escaped.replaceAll(/[*%?]/g, ' ');
    }
    return escaped.replaceAll('\\', '\\\\').replaceAll(`'`, `\\'`);
};

export const escapeSfqbStringOrNumber = <T extends number | never = never>(
    unescaped: string | T,
    like: boolean = false
): string | T => (typeof unescaped != 'number' ? escapeSfqbString(unescaped, like) : unescaped);

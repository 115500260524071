import { Compatible } from '@silevis/reactgrid';
import React, { useEffect, useState } from 'react';
import { AutocompleteElement } from '../../forms/fields/AutocompleteElement';
import { DialogInputLayout } from './DialogInputLayout';
import { MultiDropdownCell } from './MultiDropdownCellTemplate';

interface ConfirmationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    cell: Compatible<MultiDropdownCell> | undefined;
}

export const DialogMultiDropdownInput: React.FC<ConfirmationDialogProps> = ({ open, cell, setOpen }) => {
    const [inputValue, setInputValue] = useState(cell ? cell.valueList : []);

    useEffect(() => {
        setInputValue(cell ? cell.valueList : []);
    }, [cell, open, setOpen]);

    const handleCancel = () => {
        if (!!cell) cell.setData(cell.valueList);
        setOpen(false);
    };

    const handleChange = (newValue: unknown[]) => {
        setInputValue(newValue);
        cell ? cell.setData(newValue) : () => {};
    };

    const field = {
        name: '',
        onBlur: () => 1,
        onChange: handleChange,
        value: inputValue,
        ref: () => 1,
    };

    return (
        <DialogInputLayout
            open={open}
            setOpen={setOpen}
            onConfirm={() => setOpen(false)}
            onCancel={handleCancel}
            readOnly={cell?.nonEditable}
        >
            <AutocompleteElement
                field={field}
                disabled={cell?.nonEditable}
                strongValidateOptions={true}
                fieldState={{
                    invalid: false,
                    isTouched: false,
                    isDirty: false,
                }}
                idField={cell?.idKey}
                labelField={cell?.labelKey}
                options={cell ? cell.values?.filter((value: any) => !!value.name) : []}
                multiple={true}
                matchId={false}
                autocompleteProps={{
                    fullWidth: true,
                    size: 'small',
                    openOnFocus: true,
                }}
            />
        </DialogInputLayout>
    );
};

import { AddCircleOutline, ContentCopy, Delete } from '@mui/icons-material';
import { Alert, Box, Button, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { FieldError, useFieldArray, UseFormReturn, useFormState } from 'react-hook-form';
import { DashedButton } from '../../components/DashedButton';
import { useDeviceType } from '../../hooks/useDeviceType';
import { FormGenerator } from '../FormGenerator';
import { FieldArrayElement, FormElementCommon } from '../types';
import { injectCurrentPath, joinFieldPath } from '../utils';

interface FieldArrayProps {
    form: UseFormReturn;
    element: FormElementCommon & FieldArrayElement;
    disabled?: boolean;
    required?: boolean;
}
export const FieldArray: React.FC<FieldArrayProps> = ({
    form,
    element,
    disabled: propDisabled,
    required: propRequired,
}) => {
    const { control, getValues } = form;
    const {
        path,
        element: child,
        appendLabel,
        removeLabel,
        cloneable,
        inline,
        cloneLabel,
        initialValue = {},
        disabled,
        readOnly: elementReadOnly,
        shallowReadOnly,
        required,
    } = element;
    const readOnly = elementReadOnly || shallowReadOnly || !!propDisabled;
    const { fields, append, remove } = useFieldArray({
        control,
        name: path,
    });
    const { errors } = useFormState({
        name: path,
    });
    const { isMobile } = useDeviceType();
    const error = errors[path] as FieldError | undefined;
    const fieldBorderStyle = { borderLeft: 4, borderColor: grey[400], p: 2, pr: 0 };
    const clone = (index: number) => {
        const values = getValues(path) as unknown[];
        const value = values[index];
        append(value);
    };
    const buttonsStyle = { minWidth: { xs: '100%', md: 0 }, height: '100%' };

    return (
        <div id={path} style={{ flexGrow: 1 }}>
            <Stack spacing={2} display="flex">
                {!!error && !!error.message && <Alert severity="error">{error.message}</Alert>}
                {fields.map((field, indexF) => (
                    <React.Fragment key={field.id}>
                        {!inline ? (
                            <>
                                <Stack
                                    direction={isMobile ? 'column' : 'row'}
                                    justifyContent="space-between"
                                    key={field.id}
                                    spacing={2}
                                    sx={fieldBorderStyle}
                                >
                                    <Box flex={1}>
                                        <FormGenerator
                                            form={form}
                                            rootElement={injectCurrentPath(element)(child, indexF)}
                                            currentPath={joinFieldPath([path, indexF])}
                                        />
                                    </Box>
                                    {!readOnly ? (
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {!!cloneable && (
                                                <Tooltip title={cloneLabel} placement="top">
                                                    <Box sx={{ height: '100%', width: '100%' }}>
                                                        <Button
                                                            onClick={() => clone(indexF)}
                                                            disabled={disabled}
                                                            color="primary"
                                                            sx={buttonsStyle}
                                                        >
                                                            <ContentCopy />
                                                        </Button>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                            <Tooltip title={removeLabel} placement="top">
                                                <Box sx={{ height: '100%', width: '100%' }}>
                                                    <Button
                                                        onClick={() => remove(indexF)}
                                                        disabled={disabled}
                                                        color="error"
                                                        sx={buttonsStyle}
                                                    >
                                                        <Delete />
                                                    </Button>
                                                </Box>
                                            </Tooltip>
                                        </Stack>
                                    ) : null}
                                </Stack>
                            </>
                        ) : (
                            <Stack direction="row" key={field.id} spacing={1}>
                                <Box flex={1}>
                                    <FormGenerator
                                        form={form}
                                        rootElement={injectCurrentPath(element)(child, indexF)}
                                        currentPath={joinFieldPath([path, indexF])}
                                    />
                                </Box>
                                {!readOnly ? (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {!!cloneable && (
                                            <Tooltip title={cloneLabel} placement="top">
                                                <Box sx={{ height: '100%', width: '100%' }}>
                                                    <IconButton
                                                        onClick={() => clone(indexF)}
                                                        disabled={disabled}
                                                        color="primary"
                                                    >
                                                        <ContentCopy />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={removeLabel} placement="top">
                                            <Box sx={{ height: '100%', width: '100%' }}>
                                                <IconButton
                                                    onClick={() => remove(indexF)}
                                                    disabled={disabled}
                                                    color="error"
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </Stack>
                                ) : null}
                            </Stack>
                        )}
                    </React.Fragment>
                ))}
                {!readOnly ? (
                    <>
                        {fields.length > 0 && isMobile && !inline && <Divider />}
                        <DashedButton
                            onClick={() => append(initialValue)}
                            disabled={disabled}
                            startIcon={<AddCircleOutline />}
                        >
                            {appendLabel}
                            {/* `required` in this context means that there must be at least one value */}
                            {(required || propRequired) && fields.length === 0 ? ' *' : null}
                        </DashedButton>
                    </>
                ) : null}
            </Stack>
        </div>
    );
};

import {
    Cell,
    CellStyle,
    CellTemplate,
    Compatible,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';
import { PersonReadDTO } from '../../api/dto';

const cellStyle: CellStyle = {};

export interface PersonCell extends Cell {
    type: 'person';
    person?: PersonReadDTO;
    setData: (data: PersonReadDTO | undefined) => void;
}

export class PersonAutocompleteTemplate implements CellTemplate<PersonCell> {
    canOpen = false;

    setOpen = (b: boolean) => {};

    setCell = (cell: Compatible<PersonCell>) => {};

    constructor(props: { canOpen: boolean; setOpen: any; setCell: any }) {
        this.canOpen = props.canOpen;
        this.setOpen = props.setOpen;
        this.setCell = props.setCell;
    }

    getCompatibleCell(uncertainCell: Uncertain<PersonCell>): Compatible<PersonCell> {
        const text: string = uncertainCell.person?.searchLabel ?? '';
        const value: any = uncertainCell.person ?? NaN;
        return { ...uncertainCell, text, value, setData: uncertainCell.setData! };
    }

    isFocusable?(cell: Compatible<PersonCell>): boolean {
        return true;
    }

    update?(cell: Compatible<PersonCell>, cellToMerge: UncertainCompatible<PersonCell>): Compatible<PersonCell> {
        if (cellToMerge.type === 'person') return this.getCompatibleCell({ ...cell, ...cellToMerge });
        return cell;
    }

    openDialog(cell: Compatible<PersonCell>) {
        this.setOpen(true);
        this.setCell(cell);
    }

    handleKeyDown?(
        cell: Compatible<PersonCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<PersonCell>; enableEditMode: boolean } {
        if (keyCode === keyCodes.BACKSPACE || keyCode === keyCodes.DELETE) {
            return { cell: this.getCompatibleCell({ ...cell, person: undefined }), enableEditMode: false };
        }
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: true };
        if (keyCode === keyCodes.F2 && this.canOpen) {
            this.openDialog(cell);
        }
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<PersonCell>,
        eventData: string
    ): { cell: Compatible<PersonCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented.');
    }

    getStyle?(cell: Compatible<PersonCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<PersonCell>, isInEditMode: boolean): string {
        return `${cell.className} person`.trim();
    }

    // eslint-disable-next-line class-methods-use-this
    render(
        cell: Compatible<PersonCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<PersonCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div
                onDoubleClick={() => {
                    if (this.canOpen) {
                        this.openDialog(cell);
                    }
                }}
                style={{ height: '100%', width: '100%', marginTop: '6px' }}
            >
                <div>{cell.text}</div>
            </div>
        );
    }
}

import { Badge } from '@mui/material';
import React from 'react';
import { TaskIcon } from '../../lib/information/task';
import { useTaskContext } from '../TaskContext';

interface TaskMenuEntryProps {}

export const TaskMenuEntry: React.FC<TaskMenuEntryProps> = () => {
    const { taskQueryResults } = useTaskContext();
    const { data: tasks } = taskQueryResults;

    return (
        <Badge badgeContent={tasks?.totalElements ?? 0} color="primary">
            <TaskIcon />
        </Badge>
    );
};

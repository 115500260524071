import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { SavedFilterReadDTO, SavedFilterType, SavedFilterUpdateDTO } from './dto';
import { CreateOne, DeleteOne, GetManyUnpaged, UpdateOne } from './types';

const FILTER_RESOURCE = 'saved-filters';

export const createSavedFilter: CreateOne<{ data: SavedFilterUpdateDTO }, SavedFilterReadDTO> = async ({
    data,
    config,
}) => client.post(buildPath([FILTER_RESOURCE]), data, config).then((response: AxiosResponse) => response.data);

export const getSavedFiltersForDocument: GetManyUnpaged<{ filterType: SavedFilterType }, SavedFilterReadDTO> = async ({
    filterType,
    config,
}) =>
    client
        .get(buildPath([FILTER_RESOURCE]), { ...config, params: { filterType } })
        .then((response: AxiosResponse) => response.data);

export const deleteSavedFilter: DeleteOne<{ id: number }> = async ({ id, config }) =>
    client.delete(buildPath([FILTER_RESOURCE, id]), config).then((response: AxiosResponse) => response.data);

export const updateSavedFilter: UpdateOne<{ id: number; data: SavedFilterUpdateDTO }, SavedFilterReadDTO> = async ({
    id,
    data,
    config,
}) =>
    client
        .put(buildPath([FILTER_RESOURCE, id]), { ...data, id: id }, config)
        .then((response: AxiosResponse) => response.data);

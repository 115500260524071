import { Calculate } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { DimrVersionGridActivityReadDTO, DimrVersionGridReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getDimrStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const DimrIcon = Calculate;

export const getDimrInformation = (
    dimr: DimrVersionGridReadDTO | DimrVersionGridActivityReadDTO,
    intl: IntlShape
): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.dimr.view, params: { id: dimr.dimrMaster.id, version: dimr.versionNumber } }),
        name: intl.formatMessage(
            { id: 'title.dimrView' },
            { masterId: dimr.dimrMaster.id, versionNumber: dimr.versionNumber }
        ),
        title: dimr.title,
        status: getDimrStatus(dimr, intl),
        creation: dimr,
        details: [
            {
                labelKey: 'document.field.activities',
                value: ('activitiesIds' in dimr
                    ? dimr.activitiesIds
                    : dimr.rpAssessments.map(({ activity: { id } }) => id)
                ).map((id) => ({
                    label: String(id),
                    url: getRoute({ path: ROUTES.activity.view, params: { id } }),
                })),
            },
            {
                labelKey: 'document.field.alara',
                value: dimr.alaraLevel ? getLovLabel(translateValue(dimr.alaraLevel, intl.locale)) : null,
                type: true,
            },
            // TODO WDP
            { labelKey: 'dimr.field.owner', value: dimr.owner?.searchLabel ?? '', italic: true },
            { labelKey: 'dimr.field.rpo', value: dimr.rpo?.searchLabel ?? '', italic: true },
            { labelKey: 'dimr.field.rsoExperimentRso', value: dimr.rso?.searchLabel ?? '', italic: true },
        ],
    };
};

import { Help } from '@mui/icons-material';
import { InputAdornment, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { IntlShape } from 'react-intl';
import { NumericProps, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

export const numberWithUnitSchemaFor = (
    {
        label,
        path,
        unit,
        recipe,
        disabled,
        ...rest
    }: {
        label: string;
        path: string;
        unit: string;
        recipe?: string;
        disabled?: boolean;
    } & NumericProps,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.NUMBER,
    label,
    path,
    disabled,
    typeProperties: {
        endAdornment: () => (
            <>
                <InputAdornment position="end">{unit}</InputAdornment>
                {!!recipe && (
                    <InputAdornment position="end">
                        <Tooltip
                            title={
                                <>
                                    <Typography variant="subtitle2">
                                        {intl.formatMessage({ id: 'dimr.recipe.title' })}
                                    </Typography>
                                    <Typography variant="caption">{recipe}</Typography>
                                </>
                            }
                        >
                            <Help sx={{ cursor: 'help' }} />
                        </Tooltip>
                    </InputAdornment>
                )}
            </>
        ),
        ...rest,
    },
});

import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import React, { ReactElement, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GetManyPaged } from '../api/types';
import { TFilterElement } from '../forms/types';
import { useDeviceType } from '../hooks/useDeviceType';
import { SearchFilter } from './searching/filters';
import { BaseData, SearchPage } from './searching/SearchPage';

interface SearchTableWidgetProps<TData extends BaseData> {
    columns: ColumnDef<TData, any>[];
    selectedNodes?: TData[];
    onSelect?: (node: TData[]) => void;
    title: string;
    disabled?: boolean;
    readOnly?: boolean;
    filterSchemas: TFilterElement[];
    initialFilters?: SearchFilter[];
    getRowId: (node: TData) => number;
    pagedApiCall: GetManyPaged<TData>;
    adornmentIcon: ReactNode;
}

export const SearchTableWidget = <TData extends BaseData>({
    columns,
    selectedNodes = [],
    onSelect,
    title,
    disabled = false,
    readOnly,
    filterSchemas,
    initialFilters,
    getRowId,
    pagedApiCall,
    adornmentIcon,
}: SearchTableWidgetProps<TData>): ReactElement | null => {
    const { isMobile } = useDeviceType();
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };

    return !readOnly ? (
        <>
            <Tooltip title={title}>
                <span>
                    <IconButton onClick={() => setOpen(true)} size="small" disabled={disabled} sx={{ my: -1, ml: 0.5 }}>
                        {adornmentIcon}
                    </IconButton>
                </span>
            </Tooltip>

            <Dialog
                open={open}
                onClose={onClose}
                fullScreen={isMobile}
                fullWidth
                maxWidth="lg"
                PaperProps={{ sx: { height: '100%' } }}
            >
                <DialogTitle>
                    {title}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ p: 0 }}>
                    <SearchPage
                        pageKey={title}
                        documentType={null}
                        title={title}
                        columns={columns}
                        filterSchemas={filterSchemas}
                        initialFilters={initialFilters}
                        pagedApiCall={pagedApiCall}
                        selectable={true}
                        embedded={true}
                        selectedRows={selectedNodes}
                        onSelect={onSelect}
                        getRowId={getRowId}
                        filterDialogTitle={title}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        <FormattedMessage id="common.confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : null;
};

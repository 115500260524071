import { Box } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DismissibleAlert } from '../../components/DismissibleAlert';
import { useDebounce } from '../../hooks/useDebounce';
import { AutocompleteElement, AutocompleteElementProps } from './AutocompleteElement';

export type AsyncAutocompleteFetchOptions<T> = (
    searchTerm: string,
    exactId: boolean | undefined,
    idField: string | undefined,
    values?: any[]
) => Promise<T[]>;

export interface AsyncAutocompleteElementProps<T, M extends boolean | undefined, D extends boolean | undefined>
    extends Omit<AutocompleteElementProps<T, M, D>, 'options'> {
    queryKey?: string[];
    fetchOptions: AsyncAutocompleteFetchOptions<any>;
    fetchUseCases: FetchUseCase[];
    watchPaths?: string[] | ((path: string) => string[]);
    useValueDirectly?: boolean;
    validatePastedCodes?: (codes: string[]) => Promise<{ valid: T[]; invalid: string[] | null }>;
}

export enum FetchUseCase {
    ON_LOAD,
    ON_OPEN,
    ON_KEYSTROKE,
}

export const AsyncAutocompleteElement = <T, M extends boolean | undefined, D extends boolean | undefined>({
    field,
    fieldState,
    queryKey = [],
    fetchUseCases,
    fetchOptions,
    autocompleteProps,
    matchId,
    endAdornment,
    idField,
    textFieldProps,
    watchPaths: watchPathsAny = [],
    useValueDirectly = false,
    validatePastedCodes,
    ...rest
}: AsyncAutocompleteElementProps<T, M, D>): React.ReactElement | null => {
    const intl = useIntl();
    const formContext = useFormContext();
    const watchPaths = typeof watchPathsAny === 'function' ? watchPathsAny(field.name) : watchPathsAny;
    const queryParams = formContext !== null ? formContext.watch(watchPaths) : watchPaths?.map(() => null);

    const [searchTerm, setSearchTerm] = useState(() =>
        !rest.multiple && field.value != null && rest.getOptionLabel ? rest.getOptionLabel(field.value, intl) : ''
    );
    const [open, setOpen] = useState(false);
    const [lastInputReason, setLastInputReason] = useState('initial-loading');
    const [isPasting, setIsPasting] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const baseQueryKey = ['autocomplete', ...queryKey];
    const [invalidCodes, setInvalidCodes] = useState<string[] | null>(null);
    let computedQueryKey = baseQueryKey;
    if (fetchUseCases.includes(FetchUseCase.ON_OPEN)) {
        computedQueryKey = [...computedQueryKey, `open:${open}`];
    }
    if (fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE)) {
        computedQueryKey = [...computedQueryKey, debouncedSearchTerm].filter(Boolean);
    }

    const searchExactId = matchId && lastInputReason === 'initial-loading';

    const results = useQueries({
        queries: [
            ...[]
                .concat(field.value)
                .filter(Boolean)
                .map((value) => ({
                    queryKey: [...baseQueryKey, 'exact', value],
                    queryFn: () => fetchOptions(value, true, idField, queryParams),
                    enabled: !!(searchExactId && !fetchUseCases.includes(FetchUseCase.ON_LOAD) && !isPasting),
                    keepPreviousData: true,
                })),
            {
                queryKey: computedQueryKey,
                queryFn: () => fetchOptions(debouncedSearchTerm, searchExactId, idField, queryParams),
                enabled:
                    (!isPasting && fetchUseCases.includes(FetchUseCase.ON_LOAD)) ||
                    (fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE) &&
                        lastInputReason === 'input' &&
                        !!debouncedSearchTerm) ||
                    (fetchUseCases.includes(FetchUseCase.ON_OPEN) && open),
                keepPreviousData: true,
            },
        ],
    });

    const options = results
        .map((e) => e.data)
        .flat()
        .filter(Boolean);
    const isFetching = results.some((e) => e.isFetching);
    const isError = results.some((e) => e.isError);
    const hasFetched = results.some((e) => !!e.data);

    // Handler for updating value with through external component
    // this is injected into the endAdornment prop
    const updateValue = (value: unknown) => {
        field.onChange(value);
        setSearchTerm('');
        setLastInputReason('initial-loading'); // needed in order to trigger the query for the new value when using matchId
    };

    // Workaround for bug where `setValue` would not update the value rendered by this component
    const actualValue = useValueDirectly ? field.value : formContext ? formContext.watch(field.name) : undefined;
    const actualField = useMemo(
        () => (formContext ? { ...field, value: actualValue } : field),
        [formContext, actualValue, field]
    );

    const handlePaste = async (event: React.ClipboardEvent) => {
        if (!validatePastedCodes || isPasting) {
            return;
        }
        setIsPasting(true);
        event.preventDefault();
        event.stopPropagation();
        const pastedData = event.clipboardData.getData('Text');
        const codes = pastedData
            .split(/[\n,\s;]+/)
            .map((item) => item.trim())
            .filter(Boolean);

        try {
            const { valid, invalid = null } = await validatePastedCodes(codes);
            const combinedValues = [...(actualValue || []), ...valid];
            const uniqueValues = idField
                ? Array.from(new Set(combinedValues.map((item) => item[idField]))).map((id) =>
                      combinedValues.find((item) => item[idField] === id)
                  )
                : Array.from(new Set(combinedValues));
            field.onChange(uniqueValues);
            setInvalidCodes(invalid);
        } catch (error) {
            console.error('Error validating pasted codes:', error);
        } finally {
            setIsPasting(false);
        }
    };

    return (
        <Box>
            <AutocompleteElement
                endAdornment={() =>
                    !!endAdornment
                        ? endAdornment({
                              field: actualField,
                              updateValue,
                              multiple: rest.multiple,
                              matchId,
                              disabled: isPasting || rest.disabled,
                              readOnly: rest.readOnly,
                          })
                        : null
                }
                idField={idField}
                field={actualField}
                fieldState={fieldState}
                options={options || []}
                loading={isFetching}
                matchId={matchId}
                required={textFieldProps?.InputProps?.required}
                {...rest}
                autocompleteProps={{
                    open,
                    onOpen: () => {
                        setOpen(true);
                    },
                    onClose: () => {
                        setOpen(false);
                    },
                    autoComplete: true,
                    ...(fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE) ? { filterOptions: (x) => x } : {}),
                    onInputChange: (event, newInputValue, reason) => {
                        const isResetByOnChangeOrKeystroke =
                            event !== null && (reason === 'reset' || reason === 'input' || reason === 'clear');
                        const isInitialLoadingWithValue = reason === 'reset' && newInputValue.length;
                        if ((isResetByOnChangeOrKeystroke || isInitialLoadingWithValue) && !isPasting) {
                            setLastInputReason(reason);
                            setSearchTerm(newInputValue);
                        }
                    },
                    inputValue: searchTerm,
                    noOptionsText:
                        !isFetching && !hasFetched
                            ? intl.formatMessage({ id: 'document.autocomplete.hint' })
                            : undefined,
                    onChange: (event, newValue, reason, details) => {
                        if (reason === 'clear' || (reason === 'removeOption' && !newValue.length)) {
                            setInvalidCodes(null);
                        }
                        autocompleteProps?.onChange?.(event, newValue, reason, details);
                    },
                    ...autocompleteProps,
                }}
                textFieldProps={{
                    onPaste: handlePaste,
                    ...textFieldProps,
                    ...(isError
                        ? {
                              error: true,
                              helperText: intl.formatMessage({ id: 'document.autocomplete.error' }),
                          }
                        : actualValue && actualValue.length > 10 // arbitrary limit just to avoi showing the helper text for a few items
                        ? {
                              helperText: intl.formatMessage(
                                  { id: 'document.autocomplete.totalSelected' },
                                  { total: actualValue.length }
                              ),
                          }
                        : undefined),
                }}
            />
            {invalidCodes && invalidCodes.length > 0 && (
                <DismissibleAlert sx={{ my: 1 }} severity="warning">
                    {intl.formatMessage(
                        { id: 'document.field.paste.invalidCodes' },
                        { codes: invalidCodes.join(', '), totalInvalid: invalidCodes.length }
                    )}
                </DismissibleAlert>
            )}
        </Box>
    );
};

import {
    ActivityReadDTO,
    ListOfValuesReadDTO,
    ParticipantRoleCode,
    VicGridReadDTO,
    VicReadDTO,
    VicUpdateDTO,
} from '../../../api/dto';

export const mapVicToUpdateDTO = (vic: any, isCreation: boolean = false): VicUpdateDTO & VicReadDTO => {
    const { activity, ...vicWithoutActivity } = vic as any;
    return isCreation ? vic : vicWithoutActivity;
};
export const makeVicDefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    riskTypes: ListOfValuesReadDTO[]
) => {
    return {
        activities: [linkedActivity],
        title: linkedActivity.title,
        facility: linkedActivity.facility,
        worksToBePerformed: linkedActivity.description,
        vicCernParticipants: linkedActivity.participants
            ?.filter((p) => p.role.code === ParticipantRoleCode.SUPERVISOR)
            .filter((p) => p.person != null)
            .map((p) => {
                return {
                    participant: p.person!,
                    participantRole: p.role,
                };
            }),
        vicCreationDate: new Date().toISOString(),
        vicRiskAssessments: riskTypes.map((r) => {
            return {
                vicRiskType: r,
                answer: false,
                mitigation: null,
            };
        }),
    } satisfies Partial<VicReadDTO>;
};

export const makeVicDefaultValuesFromClonedVic = (vicReadDTO: VicReadDTO) => {
    const { id, version, ...rest } = vicReadDTO;
    return {
        ...rest,
        clonedFrom: vicReadDTO as VicGridReadDTO,
    } satisfies Omit<VicReadDTO, 'id' | 'version'>;
};

import { Info } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DefaultAccordion } from '../DefaultAccordion';

// Perhaps we could move this information elsewhere

const URL_ENGLISH = 'https://edms.cern.ch/file/335802/LAST_RELEASED/IS37_E.pdf';
const URL_FRENCH = 'https://edms.cern.ch/file/335802/LAST_RELEASED/IS37_F.pdf';

export const Is37WarningPanel: React.FC = () => {
    const intl = useIntl();
    return (
        <Box sx={{ mt: 2 }}>
            <DefaultAccordion title="Information" icon={<Info color="action" />}>
                <Typography variant="h6">
                    <FormattedMessage id="is37.recommandations.important" />
                </Typography>
                <ul>
                    <li>
                        <FormattedMessage id="is37.recommandations.recommandation1" />
                    </li>
                    <li>
                        <FormattedMessage id="is37.recommandations.recommandation2" />
                    </li>
                    <li>
                        <FormattedMessage id="is37.recommandations.recommandation3" />
                    </li>
                    <li>
                        <FormattedMessage id="is37.recommandations.recommandation4" />
                    </li>
                    <li>
                        <FormattedMessage id="is37.recommandations.recommandation5" />
                    </li>
                </ul>
                <Typography variant="h6">
                    <FormattedMessage id="is37.recommandations.safetyInstruction" />
                </Typography>
                <FormattedMessage
                    id="common.labelValue"
                    values={{
                        label: intl.formatMessage({ id: 'is37.recommandations.safetyInstruction.reference' }),
                        value: (
                            <>
                                <Link href={URL_ENGLISH} target="_blank" rel="noopener">
                                    <FormattedMessage id="language.english" />
                                </Link>
                                {', '}
                                <Link href={URL_FRENCH} target="_blank" rel="noopener">
                                    <FormattedMessage id="language.french" />
                                </Link>
                            </>
                        ),
                    }}
                />
            </DefaultAccordion>
        </Box>
    );
};

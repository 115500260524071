import { Checkbox, Chip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { ImpactFormattedDate } from '../../ImpactFormattedDate';

export const DATATYPES = {
    BOOLEAN: 'BOOLEAN',
    INTEGER: 'INTEGER',
    DECIMAL: 'DECIMAL',
    STRING: 'STRING',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    ENUMERATION: 'ENUMERATION',
} as const;

export type Datatype = (typeof DATATYPES)[keyof typeof DATATYPES];

export const getImpactTableDefaultColumn = <TData,>(): Partial<ColumnDef<TData>> => ({
    cell: ({ column, getValue }) => {
        const value = getValue() as string;
        if (!value) return null;
        switch (column.columnDef?.meta?.dataType) {
            case DATATYPES.BOOLEAN:
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Checkbox
                            disabled
                            checked={(value ?? '').toLowerCase() === 'true'}
                            indeterminate={!value}
                            style={{
                                padding: 0,
                            }}
                            color="primary"
                        />
                    </div>
                );
            case DATATYPES.DATE:
                return (
                    <time dateTime={value}>
                        <ImpactFormattedDate date={new Date(value)} />
                    </time>
                );
            case DATATYPES.DATETIME:
                return (
                    <time dateTime={value}>
                        <ImpactFormattedDate date={new Date(value)} time />
                    </time>
                );
            case DATATYPES.ENUMERATION:
                return <Chip label={value} variant="outlined" size="small" />;
            default:
                return String(value) ?? '';
        }
    },
});

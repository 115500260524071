import { Global } from '@emotion/react';
import { Close, TaskSharp } from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    Divider,
    Drawer as MUIDrawer,
    Fab,
    Stack,
    styled,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaskDTO } from '../../api/dto';
import { useCloseDrawerOnResize } from '../../hooks/useCloseDrawerOnResize';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useDrawerHook, UseDrawerReturnType } from '../../hooks/useDrawerHook';
import { translateTaskType } from '../../lib/language';

export const DocumentDrawerContext = createContext<
    | ({
          width: number;
          setWidth: React.Dispatch<React.SetStateAction<number>>;
          taskSelected: TaskDTO | null;
          setTaskSelected: React.Dispatch<React.SetStateAction<TaskDTO | null>>;
          activeTasksCount: number;
          setActiveTasksCount: React.Dispatch<React.SetStateAction<number>>;
          actionButtonKey: number;
          triggerActionButtonAnimation: () => void;
      } & UseDrawerReturnType)
    | undefined
>(undefined);

export const rightDrawerWidth = 350;

const Puller = styled(Box)(() => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const drawerBleeding = 64;

interface DocumentDrawerStructureProps {
    Content?: React.ReactNode;
}

interface DocumentDrawerProviderProps {
    children: React.ReactNode;
}

export const DocumentDrawerProvider: React.FC<DocumentDrawerProviderProps> = ({ children }) => {
    const [taskSelected, setTaskSelected] = useState<TaskDTO | null>(null);
    const [activeTasksCount, setActiveTasksCount] = useState(0);
    const [width, setWidth] = useState(350);
    const [actionButtonKey, setActionButtonKey] = useState(0);
    const triggerActionButtonAnimation = useCallback(() => setActionButtonKey(Math.random()), [setActionButtonKey]);
    const { isMobile } = useDeviceType();
    const drawerHook = useDrawerHook(!isMobile);

    return (
        <DocumentDrawerContext.Provider
            value={{
                width,
                setWidth,
                taskSelected,
                setTaskSelected,
                activeTasksCount,
                setActiveTasksCount,
                actionButtonKey,
                triggerActionButtonAnimation,
                ...drawerHook,
            }}
        >
            {children}
        </DocumentDrawerContext.Provider>
    );
};

export const DocumentDrawerStructure = ({ Content }: DocumentDrawerStructureProps) => {
    const intl = useIntl();
    const { isMobile, mobileScreenBreakPoint } = useDeviceType();
    const { isDrawerOpen, toggleDrawer, closeDrawer, taskSelected, activeTasksCount } =
        useContext(DocumentDrawerContext)!;

    useCloseDrawerOnResize(mobileScreenBreakPoint, closeDrawer);

    return isMobile ? (
        <>
            <SwipeableDrawer
                anchor="bottom"
                open={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={toggleDrawer}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiDrawer-paperAnchorBottom': {
                            height: `calc(65% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                        },
                    }}
                />
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        boxShadow: '0px -5px 11px 0px rgb(0 0 0 / 11%)',
                    }}
                >
                    <Puller />

                    <Stack direction="row" alignItems="center" pl={2} spacing={1}>
                        <Badge badgeContent={activeTasksCount} color="error" overlap="circular">
                            <Avatar color="error" sx={{ width: 32, height: 32 }}>
                                <TaskSharp />
                            </Avatar>
                        </Badge>
                        <Typography sx={{ pt: 2, pb: 2 }} variant="h6">
                            <FormattedMessage id="document.tasks" />
                        </Typography>
                        {taskSelected !== null && (
                            <Chip
                                label={translateTaskType(taskSelected.type, intl.locale).typeName}
                                variant="outlined"
                                color="primary"
                                size="small"
                            />
                        )}
                    </Stack>
                </StyledBox>
                <Divider />
                <StyledBox
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    {Content}
                </StyledBox>
            </SwipeableDrawer>
        </>
    ) : (
        <>
            <MUIDrawer
                open={isDrawerOpen}
                sx={{
                    width: isMobile ? 'unset' : rightDrawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: isMobile ? 'unset' : rightDrawerWidth,
                        boxSizing: 'border-box',
                        height: isMobile ? '75%' : '100%',
                    },
                }}
                anchor={isMobile ? 'bottom' : 'right'}
                variant={isMobile ? 'temporary' : 'persistent'}
                onClose={closeDrawer}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {!isMobile && <Toolbar />}

                <Box sx={{ overflow: 'auto', pb: 7 }}>
                    <Stack direction="row" spacing={1} sx={{ px: 2, py: 1.6 }}>
                        <TaskSharp />
                        <Typography>
                            <FormattedMessage id="document.tasks" />
                        </Typography>
                    </Stack>
                    <Divider />
                    <Box>{Content}</Box>
                </Box>
            </MUIDrawer>
            <Fab
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 1200,
                }}
                aria-label={'Tasks'}
                color={isDrawerOpen ? 'default' : 'default'}
                variant="extended"
                onClick={toggleDrawer}
            >
                {isDrawerOpen ? (
                    <>
                        <Close sx={{ mr: 1 }} />
                        <FormattedMessage id="common.close" />
                    </>
                ) : (
                    <>
                        <Badge
                            color="primary"
                            badgeContent={taskSelected ? 1 : 0}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <Badge color="error" badgeContent={activeTasksCount}>
                                <TaskSharp />
                            </Badge>
                        </Badge>
                        <Box sx={{ ml: 1 }}>
                            <FormattedMessage id="document.tasks" />
                        </Box>
                    </>
                )}
            </Fab>
        </>
    );
};

import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { updateDocumentAttachments } from '../attachments';
import { backendClient as client } from '../clients';
import { WdpVersionContextReadDTO, WdpVersionGridReadDTO, WdpVersionReadDTO, WdpVersionUpdateDTO } from '../dto';
import { CreateOne, GetManyPaged, GetOne, UpdateOne } from '../types';

export const WDP_PATH = 'wdp';

export const getWdpVersion: GetOne<{ id: string; versionNumber: number }, WdpVersionReadDTO> = async ({
    id,
    versionNumber,
    config,
}) => client.get(buildPath([WDP_PATH, id, versionNumber]), config).then((response: AxiosResponse) => response.data);

export const getWdpVersionLatest: GetOne<{ id: string }, WdpVersionReadDTO> = async ({ id, config }) =>
    client.get(buildPath([WDP_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getWdpVersions: GetManyPaged<WdpVersionGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([WDP_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const updateWdpVersion: UpdateOne<
    { id: number; versionNumber: number; data: WdpVersionUpdateDTO; strict: boolean },
    WdpVersionReadDTO
> = async ({ id, versionNumber, data, strict, config }) =>
    client
        .put(buildPath([WDP_PATH, id, versionNumber]), data, { ...config, params: { strict } })
        .then((response: AxiosResponse) => response.data);

export const createWdpVersionAndMaster: CreateOne<{ data: WdpVersionUpdateDTO }, WdpVersionReadDTO> = async ({
    data,
    config,
}) => client.post(buildPath([WDP_PATH]), data, { ...config }).then((response: AxiosResponse) => response.data);

export const getWdpRules: GetOne<{ id?: number }, PreciseRulesDTO<WdpVersionContextReadDTO>> = async ({
    config,
    id,
} = {}) =>
    client
        .get(buildPath([WDP_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const wdpAttachmentsApi = updateDocumentAttachments(WDP_PATH);

export const getWdpPdf: GetOne<{ id: number; versionNumber: number; langCode: string }, Blob> = async ({
    id,
    versionNumber,
    langCode,
    config,
}) =>
    client
        .get(buildPath([WDP_PATH, id, versionNumber, 'pdf']), { ...config, params: { langCode }, responseType: 'blob' })
        .then((response: AxiosResponse) => response.data);

export const cancelWdp: GetOne<{ id: number; versionNumber: number; version: number }, WdpVersionReadDTO> = async ({
    id,
    versionNumber,
    version,
    config,
}) =>
    client
        .get(buildPath([WDP_PATH, id, versionNumber, 'cancelWdp']), { ...config, params: { version } })
        .then((response: AxiosResponse) => response.data);

export const reopenWdp: GetOne<{ id: number; versionNumber: number; version: number }, WdpVersionReadDTO> = async ({
    id,
    versionNumber,
    version,
    config,
}) =>
    client
        .get(buildPath([WDP_PATH, id, versionNumber, 'reopenWdp']), { ...config, params: { version } })
        .then((response: AxiosResponse) => response.data);

import { isValid } from 'date-fns';
import { IntlShape } from 'react-intl';
import {
    sfAnd,
    sfEqual,
    sfGe,
    sfGt,
    sfIsEmpty,
    sfIsNotEmpty,
    sfIsNotNull,
    sfLe,
    sfLike,
    sfLt,
    sfNot,
    sfNotEqual,
    sfOr,
} from 'spring-filter-query-builder';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { escapeSfqbStringOrNumber } from '../../lib/sfqb';

export interface SearchFilter {
    field: string;
    operator: FilterOperatorEnum;
    value: any;
}

export interface SearchFilterTyped extends SearchFilter {
    type: FilterTypeEnum;
}

export enum FilterTypeEnum {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date',
    DATETIME = 'dateTime',
    AUTOCOMPLETE = 'autocomplete',
    AUTOCOMPLETE_ASYNC = 'autocompleteAsync',
    HIERARCHICAL_AUTOCOMPLETE_ASYNC = 'hierarchicalAutocompleteAsync',
}

export enum FilterOperatorEnum {
    CONTAINS = 'contains',
    EQUALS = 'equals',
    STARTS_WITH = 'startsWith',
    ENDS_WITH = 'endsWith',
    IS_EMPTY = 'isEmpty',
    IS_NOT_EMPTY = 'isNotEmpty',
    IS_ANY_OF = 'isAnyOf',
    IS_NOT_ANY_OF = 'isNotAnyOf',
    EQUAL = '=',
    NOT_EQUAL = '!=',
    GT = '>',
    GE = '>=',
    LT = '<',
    LE = '<=',
    IS = 'is',
    IS_NOT = 'isNot',
    IS_THE_DATE = 'isDate',
    IS_NOT_THE_DATE = 'isNotTheDate',
    AFTER = 'after',
    ON_OR_AFTER = 'onOrAfter',
    BEFORE = 'before',
    ON_OR_BEFORE = 'onOrBefore',
    IS_NOT_STARTS_WITH = 'isNotStartsWith',
    IS_ANY_STARTS_WITH_OF = 'isAnyStartsWithOf',
    IS_NOT_ANY_STARTS_WITH_OF = 'isNotAnyStartsWithOf',
}

export enum BooleanFilterOption {
    ANY = 'any',
    TRUE = 'true',
    FALSE = 'false',
}

export type FilterOperatorDef = {
    id: FilterOperatorEnum;
    label: string;
    valueElement: UiSchemaType;
    valueElementProps?: object;
    valueElementRequired?: boolean;
};

export const filterOperatorDefs = ({
    formatMessage,
}: IntlShape): {
    [key in FilterTypeEnum]: FilterOperatorDef[];
} => ({
    [FilterTypeEnum.STRING]: [
        {
            id: FilterOperatorEnum.CONTAINS,
            label: formatMessage({ id: 'search.filters.operator.contains' }),
            valueElement: UiSchemaType.INPUT,
        },
        {
            id: FilterOperatorEnum.EQUALS,
            label: formatMessage({ id: 'search.filters.operator.equals' }),
            valueElement: UiSchemaType.INPUT,
        },
        {
            id: FilterOperatorEnum.STARTS_WITH,
            label: formatMessage({ id: 'search.filters.operator.startsWith' }),
            valueElement: UiSchemaType.INPUT,
        },
        {
            id: FilterOperatorEnum.ENDS_WITH,
            label: formatMessage({ id: 'search.filters.operator.endsWith' }),
            valueElement: UiSchemaType.INPUT,
        },
        {
            id: FilterOperatorEnum.IS_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        {
            id: FilterOperatorEnum.IS_NOT_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isNotEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        // Disabled because not working; see IMPACT-2478
        //{
        //    id: FilterOperatorEnum.IS_ANY_OF,
        //    label: formatMessage({ id: 'search.filters.operator.isAnyOf' }),
        //    valueElement: UiSchemaType.AUTOCOMPLETE,
        //    valueElementProps: {
        //        options: [],
        //        multiple: true,
        //        freeSolo: true,
        //    },
        //},
        //{
        //    id: FilterOperatorEnum.IS_NOT_ANY_OF,
        //    label: formatMessage({ id: 'search.filters.operator.isNotAnyOf' }),
        //    valueElement: UiSchemaType.AUTOCOMPLETE,
        //    valueElementProps: {
        //        options: [],
        //        multiple: true,
        //        freeSolo: true,
        //    },
        //},
    ],
    [FilterTypeEnum.NUMBER]: [
        {
            id: FilterOperatorEnum.EQUAL,
            label: '=',
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.NOT_EQUAL,
            label: '\u2260', // !=
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.GT,
            label: '>',
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.GE,
            label: '\u2265', // >=
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.LT,
            label: '<',
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.LE,
            label: '\u2264', // <=
            valueElement: UiSchemaType.NUMBER,
        },
        {
            id: FilterOperatorEnum.IS_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        {
            id: FilterOperatorEnum.IS_NOT_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isNotEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        // Disabled because not working; see IMPACT-2478
        //{
        //    id: FilterOperatorEnum.IS_ANY_OF,
        //    label: formatMessage({ id: 'search.filters.operator.isAnyOf' }),
        //    valueElement: UiSchemaType.AUTOCOMPLETE,
        //    valueElementProps: {
        //        options: [],
        //        multiple: true,
        //        freeSolo: true,
        //    },
        //},
        //{
        //    id: FilterOperatorEnum.IS_NOT_ANY_OF,
        //    label: formatMessage({ id: 'search.filters.operator.isNotAnyOf' }),
        //    valueElement: UiSchemaType.AUTOCOMPLETE,
        //    valueElementProps: {
        //        options: [],
        //        multiple: true,
        //        freeSolo: true,
        //    },
        //},
    ],
    [FilterTypeEnum.BOOLEAN]: [
        {
            id: FilterOperatorEnum.IS,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.AUTOCOMPLETE,
            valueElementProps: {
                options: [
                    { id: BooleanFilterOption.ANY, label: formatMessage({ id: 'search.filters.value.any' }) },
                    { id: BooleanFilterOption.TRUE, label: formatMessage({ id: 'search.filters.value.true' }) },
                    { id: BooleanFilterOption.FALSE, label: formatMessage({ id: 'search.filters.value.false' }) },
                ],
            },
        },
    ],
    [FilterTypeEnum.DATE]: [
        {
            id: FilterOperatorEnum.IS_THE_DATE,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.IS_NOT_THE_DATE,
            label: formatMessage({ id: 'search.filters.operator.isNot' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.AFTER,
            label: formatMessage({ id: 'search.filters.operator.isAfterDate' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.ON_OR_AFTER,
            label: formatMessage({ id: 'search.filters.operator.isOnDateOrAfter' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.BEFORE,
            label: formatMessage({ id: 'search.filters.operator.isBeforeDate' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.ON_OR_BEFORE,
            label: formatMessage({ id: 'search.filters.operator.isOnDateOrBefore' }),
            valueElement: UiSchemaType.DATE,
        },
        {
            id: FilterOperatorEnum.IS_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        {
            id: FilterOperatorEnum.IS_NOT_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isNotEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
    ],
    [FilterTypeEnum.DATETIME]: [
        {
            id: FilterOperatorEnum.IS,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.IS_NOT,
            label: formatMessage({ id: 'search.filters.operator.isNot' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.AFTER,
            label: formatMessage({ id: 'search.filters.operator.isAfterDate' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.ON_OR_AFTER,
            label: formatMessage({ id: 'search.filters.operator.isOnDateOrAfter' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.BEFORE,
            label: formatMessage({ id: 'search.filters.operator.isBeforeDate' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.ON_OR_BEFORE,
            label: formatMessage({ id: 'search.filters.operator.isOnDateOrBefore' }),
            valueElement: UiSchemaType.DATETIME,
        },
        {
            id: FilterOperatorEnum.IS_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
        {
            id: FilterOperatorEnum.IS_NOT_EMPTY,
            label: formatMessage({ id: 'search.filters.operator.isNotEmpty' }),
            valueElement: UiSchemaType.CUSTOM,
            valueElementRequired: false,
        },
    ],
    [FilterTypeEnum.AUTOCOMPLETE]: [
        {
            id: FilterOperatorEnum.IS,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.AUTOCOMPLETE,
        },
        {
            id: FilterOperatorEnum.IS_NOT,
            label: formatMessage({ id: 'search.filters.operator.isNot' }),
            valueElement: UiSchemaType.AUTOCOMPLETE,
        },
        {
            id: FilterOperatorEnum.IS_ANY_OF,
            label: formatMessage({ id: 'search.filters.operator.isAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE,
            valueElementProps: {
                multiple: true,
            },
        },
        {
            id: FilterOperatorEnum.IS_NOT_ANY_OF,
            label: formatMessage({ id: 'search.filters.operator.isNotAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE,
            valueElementProps: {
                multiple: true,
                freeSolo: true,
            },
        },
    ],
    [FilterTypeEnum.AUTOCOMPLETE_ASYNC]: [
        {
            id: FilterOperatorEnum.IS,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
        },
        {
            id: FilterOperatorEnum.IS_NOT,
            label: formatMessage({ id: 'search.filters.operator.isNot' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
        },
        {
            id: FilterOperatorEnum.IS_ANY_OF,
            label: formatMessage({ id: 'search.filters.operator.isAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
            valueElementProps: {
                multiple: true,
                freeSolo: true,
            },
        },
        {
            id: FilterOperatorEnum.IS_NOT_ANY_OF,
            label: formatMessage({ id: 'search.filters.operator.isNotAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
            valueElementProps: {
                multiple: true,
                freeSolo: true,
            },
        },
    ],
    [FilterTypeEnum.HIERARCHICAL_AUTOCOMPLETE_ASYNC]: [
        {
            id: FilterOperatorEnum.STARTS_WITH,
            label: formatMessage({ id: 'search.filters.operator.is' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
        },
        {
            id: FilterOperatorEnum.IS_NOT_STARTS_WITH,
            label: formatMessage({ id: 'search.filters.operator.isNot' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
        },
        {
            id: FilterOperatorEnum.IS_ANY_STARTS_WITH_OF,
            label: formatMessage({ id: 'search.filters.operator.isAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
            valueElementProps: {
                multiple: true,
                freeSolo: true,
            },
        },
        {
            id: FilterOperatorEnum.IS_NOT_ANY_STARTS_WITH_OF,
            label: formatMessage({ id: 'search.filters.operator.isNotAnyOf' }),
            valueElement: UiSchemaType.AUTOCOMPLETE_ASYNC,
            valueElementProps: {
                multiple: true,
                freeSolo: true,
            },
        },
    ],
});

const getDayDate = (value: string) => {
    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);
    return dateValue.toISOString();
};

const getNextDayDate = (value: string) => {
    const nextDay = new Date(value);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
    return nextDay.toISOString();
};

export const toSpringFilterQuery = (filters: SearchFilterTyped[]): string => {
    const strip = <T extends number | never>(str: string | T): string | T => {
        return typeof str == 'number' ? str : str.replace(/\*/, '');
    };
    const items = filters
        .map((filter) => {
            // Convert date and datetime to string ISO format
            const { value } = filter;
            if (value instanceof Date && isValid(value)) {
                return {
                    ...filter,
                    value: value.toISOString(),
                };
            }
            return filter;
        })
        .map(({ field, value, operator, type }) => {
            switch (operator) {
                case FilterOperatorEnum.CONTAINS:
                    return sfLike(field, '*' + strip(escapeSfqbStringOrNumber(value, true)) + '*');
                case FilterOperatorEnum.EQUAL:
                case FilterOperatorEnum.EQUALS:
                case FilterOperatorEnum.IS:
                    if (type === FilterTypeEnum.BOOLEAN) {
                        const rhs = {
                            [BooleanFilterOption.TRUE]: true,
                            [BooleanFilterOption.FALSE]: false,
                        }[value?.id as string];
                        return rhs !== undefined ? sfEqual(field, String(rhs)) : sfIsNotNull(field);
                    } else {
                        return sfEqual(field, escapeSfqbStringOrNumber(value));
                    }
                case FilterOperatorEnum.STARTS_WITH:
                    return sfLike(field, strip(escapeSfqbStringOrNumber(value, true)) + '*');
                case FilterOperatorEnum.IS_NOT_STARTS_WITH:
                    return sfNot(sfLike(field, strip(escapeSfqbStringOrNumber(value, true)) + '*'));
                case FilterOperatorEnum.ENDS_WITH:
                    return sfLike(field, '*' + strip(escapeSfqbStringOrNumber(value, true)));
                case FilterOperatorEnum.IS_EMPTY:
                    return sfIsEmpty(field);
                case FilterOperatorEnum.IS_NOT_EMPTY:
                    return sfIsNotEmpty(field);
                case FilterOperatorEnum.IS_ANY_OF:
                    // Workaround: Not using IN operator because the version with the fix (v0.3.1) was not release to NPM
                    // https://github.com/sisimomo/Spring-Filter-Query-Builder/issues/2
                    return sfOr(value.map((v: string | number) => sfEqual(field, escapeSfqbStringOrNumber(v))));
                case FilterOperatorEnum.IS_ANY_STARTS_WITH_OF:
                    return sfOr(
                        value.map((v: string | number) => sfEqual(field, strip(escapeSfqbStringOrNumber(v)) + '*'))
                    );
                case FilterOperatorEnum.IS_NOT_ANY_STARTS_WITH_OF:
                    return sfAnd(
                        value.map((v: string | number) => sfLike(field, strip(escapeSfqbStringOrNumber(v)) + '*'))
                    );
                case FilterOperatorEnum.IS_NOT_ANY_OF:
                    return sfAnd(value.map((v: string | number) => sfNotEqual(field, escapeSfqbStringOrNumber(v))));
                case FilterOperatorEnum.NOT_EQUAL:
                case FilterOperatorEnum.IS_NOT:
                    return sfNotEqual(field, escapeSfqbStringOrNumber(value));
                case FilterOperatorEnum.GT:
                case FilterOperatorEnum.AFTER:
                    return sfGt(field, escapeSfqbStringOrNumber(value));
                case FilterOperatorEnum.GE:
                case FilterOperatorEnum.ON_OR_AFTER:
                    return sfGe(field, escapeSfqbStringOrNumber(value));
                case FilterOperatorEnum.LT:
                case FilterOperatorEnum.BEFORE:
                    return sfLt(field, escapeSfqbStringOrNumber(value));
                case FilterOperatorEnum.LE:
                case FilterOperatorEnum.ON_OR_BEFORE:
                    return sfLe(field, escapeSfqbStringOrNumber(value));
                case FilterOperatorEnum.IS_THE_DATE:
                    return sfAnd([
                        sfGe(field, escapeSfqbStringOrNumber(getDayDate(value))),
                        sfLt(field, escapeSfqbStringOrNumber(getNextDayDate(value))),
                    ]);
                case FilterOperatorEnum.IS_NOT_THE_DATE:
                    return sfAnd([
                        sfLt(field, escapeSfqbStringOrNumber(getDayDate(value))),
                        sfGe(field, escapeSfqbStringOrNumber(getNextDayDate(value))),
                    ]);
            }
            throw new Error(operator);
        });
    if (items.length > 0) {
        // This combinator requires at least one item to work
        return sfAnd(items).toString();
    } else {
        return '';
    }
};

/* eslint-disable react/destructuring-assignment*/
import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { NdcIcon } from '../../../lib/information/noteDeCoupure';
import { getNdcStatus } from '../../../lib/status';
import { NdcPageData } from './NdcPageData';
import { NdcPageVariant } from './NoteDeCoupurePage';

interface NdcContentHeaderProps {
    data: NdcPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const NdcContentHeader: React.FC<NdcContentHeaderProps> = ({ data, ...rest }) => {
    const { variant } = data;
    const intl = useIntl();

    return (
        <DocumentHeader
            documentType={DocumentType.NDC}
            documentId={variant === NdcPageVariant.READ ? data.noteDeCoupureData.id : null}
            icon={<NdcIcon />}
            title={
                variant === NdcPageVariant.READ
                    ? intl.formatMessage({ id: 'ndc.titleExisting' }, { ndcNumber: data.noteDeCoupureData.ndcNumber })
                    : variant === NdcPageVariant.CREATE
                    ? intl.formatMessage({ id: 'ndc.titleNew' })
                    : intl.formatMessage({ id: 'ndc.titleNewCloning' }, { ndcNumber: data.noteDeCoupureData.ndcNumber })
            }
            status={variant === NdcPageVariant.READ ? getNdcStatus(data.noteDeCoupureData, intl) : undefined}
            creation={variant === NdcPageVariant.READ ? data.noteDeCoupureData : undefined}
            {...rest}
        />
    );
};

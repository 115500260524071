import { Skeleton } from '@mui/material';
import React, { ReactElement, RefObject } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState, MissingStateReasonEnum } from './EmptyState';

interface SearchCardListProps<TData> {
    isLoading: boolean;
    isError: boolean;
    parentRef: RefObject<HTMLDivElement>;
    items?: TData[];
    itemRender: (item: TData) => ReactElement;
    cardSkeletonHeight?: number;
}

export const SearchCardList = <TData,>({
    isLoading,
    isError,
    parentRef,
    items = [],
    itemRender,
    cardSkeletonHeight = 126,
}: SearchCardListProps<TData>): ReactElement => {
    const intl = useIntl();
    return (
        <>
            {isLoading ? (
                Array(parentRef?.current ? Math.floor(parentRef.current.offsetHeight / cardSkeletonHeight) : 6)
                    .fill(null)
                    .map((_, index) => <Skeleton key={index} height={cardSkeletonHeight} variant="rounded" />)
            ) : isError ? (
                <EmptyState
                    reason={MissingStateReasonEnum.ERROR}
                    message={intl.formatMessage({ id: 'search.error' })}
                />
            ) : items?.length ? (
                items.map(itemRender)
            ) : (
                <EmptyState />
            )}
        </>
    );
};

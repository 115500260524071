import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDateTime } from '../../lib/date';
import { getDocumentInformation } from '../../lib/information/document';
import { EntityData } from '../../lib/information/types/EntityData';
import { EntityInformation } from '../../lib/information/types/EntityInformation';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';
import { ImpactLink } from '../ImpactLink';

type DocumentDetailsProps = EntityData & {
    withType?: boolean;
};

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({ withType, ...props }) => {
    const intl = useIntl();
    const { environment } = useApplicationSettings();
    const document = useMemo(() => getDocumentInformation(props, environment, intl), [...Object.keys(props), intl]);
    const details = useMemo(
        () => [
            ...(document.creation
                ? [
                      {
                          custom: true,
                          labelKey: 'document.field.creator',
                          value: (
                              <FormattedMessage
                                  id="document.createdByShort"
                                  values={{
                                      person: (
                                          <span style={{ fontStyle: 'italic' }}>
                                              {document.creation.createdBy.searchLabel}
                                          </span>
                                      ),
                                      date: formatDateTime(document.creation.createdDate, intl),
                                  }}
                              />
                          ),
                      } satisfies EntityInformation['details'][number],
                  ]
                : []),
            ...document.details.filter((d) => withType || !d.type),
        ],
        [document.creation, document.details, withType]
    );
    return (
        <>
            {details.map((detail, i) => (
                <Typography key={i} variant="body2" color="textSecondary">
                    <FormattedMessage
                        id="common.labelValue"
                        values={{
                            label: intl.formatMessage({ id: detail.labelKey }),
                            value: (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    fontStyle={!detail.custom && detail.italic ? 'italic' : undefined}
                                >
                                    {detail.custom
                                        ? detail.value
                                        : detail.value !== null &&
                                          (typeof detail.value === 'string' ? (
                                              detail.value
                                          ) : !Array.isArray(detail.value) ? (
                                              <ImpactLink to={detail.value.url}>{detail.value.label}</ImpactLink>
                                          ) : (
                                              detail.value.map((item, j) => (
                                                  <span key={j}>
                                                      <ImpactLink to={item.url}>{item.label}</ImpactLink>
                                                      {j < (detail.value as unknown[]).length - 1 ? ', ' : undefined}
                                                  </span>
                                              ))
                                          ))}
                                </Typography>
                            ),
                        }}
                    />
                </Typography>
            ))}
        </>
    );
};

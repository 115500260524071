import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getActivities } from '../../api/activities';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { AccessControlSearchForm } from '../../forms/documents/AccessControlSearchForm';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

export const AccessControlPage: React.FunctionComponent = () => {
    const [activities, setActivities] = useState<any[]>([]);

    useAsyncEffect(async () => {
        try {
            const result = await getActivities();
            setActivities(result?.content);
        } catch (error) {
            toast.error('There was an error fetching activities');
        }
    });

    return (
        <DocumentPageStructure
            Header={
                <>
                    <Typography>Search access currently granted by IMPACT to ADaMS</Typography>
                    <AccessControlSearchForm />
                    <Stack spacing={1} direction="row" sx={{ marginTop: 1 }}>
                        <Button variant="contained" size="small">
                            Reset
                        </Button>
                        <Button variant="contained" size="small">
                            Search
                        </Button>
                    </Stack>
                </>
            }
            Body={<Box sx={{ margin: 2 }}>{/*<AccessGrid activities={activities} />*/}</Box>}
        />
    );
};

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { EgroupDTO } from './dto';
import { GetManyPaged } from './types';

const RESOURCE_PATH = 'egroups';

export const getEgroups: GetManyPaged<EgroupDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

import { Grading, Person, Replay } from '@mui/icons-material';
import {
    Checkbox,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
} from '@mui/material';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PersonReadDTO } from '../api/dto';
import { useDeviceType } from '../hooks/useDeviceType';
import { PersonTooltip } from './PersonTooltip';

interface SelectablePersonListProps {
    persons: PersonReadDTO[];
    selection: Set<number>;
    updateSelection: (newSelection: Set<number>) => void;
    disabled?: boolean;
    otherActions?: ReactNode;
}

export const SelectablePersonList: React.FC<SelectablePersonListProps> = ({
    persons,
    selection,
    updateSelection,
    disabled,
    otherActions,
}) => {
    const intl = useIntl();
    const { isMobile } = useDeviceType();

    const toggleSelectedAssignee = useCallback(
        (person: Pick<PersonReadDTO, 'id'>) =>
            updateSelection(
                new Set(
                    selection.has(person.id)
                        ? [...selection.values()].filter((id) => id !== person.id)
                        : [...selection.values(), person.id]
                )
            ),
        [selection, updateSelection]
    );
    const setAllSelectedAssignee = useCallback(
        () => updateSelection(new Set(persons.map(({ id }) => id))),
        [persons, updateSelection]
    );
    const setNoSelectedAssignee = useCallback(() => updateSelection(new Set()), [selection, updateSelection]);
    const sortedPersons = useMemo(
        () => persons.slice().sort((a, b) => a.searchLabel.localeCompare(b.searchLabel)),
        [persons]
    );

    return (
        <Stack direction="column" spacing={2}>
            <List dense disablePadding>
                {sortedPersons.map((person) => {
                    return (
                        <ListItem key={person.id} disablePadding>
                            <ListItemButton
                                selected={selection.has(person.id)}
                                onClick={() => toggleSelectedAssignee(person)}
                                disabled={disabled}
                            >
                                {!isMobile && (
                                    <ListItemIcon>
                                        <PersonTooltip person={person}>
                                            <Person color="action" sx={{ cursor: 'help' }} />
                                        </PersonTooltip>
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    primary={
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            sx={{ minHeight: 24 /* Height of the chip */ }}
                                        >
                                            <span>{person.searchLabel}</span>
                                            {!!person.orgUnit && !isMobile && (
                                                <Chip size="small" label={person.orgUnit.code} />
                                            )}
                                        </Stack>
                                    }
                                    sx={{ my: 0 }}
                                />
                                {!isMobile && (
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selection.has(person.id)}
                                            disableRipple
                                            sx={{
                                                py: 0,
                                                display: selection.size === 0 ? 'none' : undefined,
                                            }}
                                        />
                                    </ListItemIcon>
                                )}
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            {selection.size > 0 && (
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Stack direction="row">{otherActions}</Stack>
                    <Stack direction="row" justifyContent="flex-end">
                        <Tooltip title={intl.formatMessage({ id: 'common.selectAll' })}>
                            <span>
                                <IconButton
                                    disabled={selection.size === sortedPersons.length || disabled}
                                    onClick={setAllSelectedAssignee}
                                >
                                    <Grading />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={intl.formatMessage({ id: 'common.reset' })}>
                            <span>
                                <IconButton onClick={() => setNoSelectedAssignee()} disabled={disabled}>
                                    <Replay />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

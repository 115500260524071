import {
    Cell,
    CellStyle,
    CellTemplate,
    Compatible,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';

const cellStyle: CellStyle = {};

export interface BaseDropdownCell extends Cell {
    type: 'multiautocomplete' | 'autocomplete';
    values: unknown[];
    selectedValue: string | undefined;
    idKey: string;
    labelKey: string;
    fallbackLabelKey: string;
    setData: (data: unknown[]) => void;
}

export interface MultiDropdownCell extends BaseDropdownCell {
    type: 'multiautocomplete';
    valueList: unknown[];
}

export interface DropdownCell extends BaseDropdownCell {
    type: 'autocomplete';
    value: unknown;
}

export type DropdownsCell = MultiDropdownCell | DropdownCell;

export class MultiDropdownCellTemplate implements CellTemplate<MultiDropdownCell> {
    setOpen = (b: boolean) => {};

    setCell = (cell: Compatible<MultiDropdownCell>) => {};

    constructor(props: { setOpen: any; setCell: any }) {
        this.setOpen = props.setOpen;
        this.setCell = props.setCell;
    }

    getCompatibleCell(uncertainCell: Uncertain<MultiDropdownCell>): Compatible<MultiDropdownCell> {
        const { valueList, values, idKey, labelKey, fallbackLabelKey, setData, ...cell } = uncertainCell;
        const value = NaN;
        let text = '';
        if (valueList && values && valueList.length > 0 && values.length > 0) {
            text = valueList
                .map((v) => {
                    // @ts-ignore
                    const option = values.find((v2) => v2[idKey] === v[idKey]);
                    if (!option) {
                        return '';
                    }
                    // @ts-ignore
                    return option.name;
                })
                .filter(Boolean)
                .join(', ');
        }
        return {
            ...cell,
            text,
            value,
            valueList: valueList!,
            idKey: idKey!,
            labelKey: labelKey!,
            fallbackLabelKey: fallbackLabelKey!,
            values: values!,
            setData: setData!,
            selectedValue: undefined,
        };
    }

    isFocusable?(cell: Compatible<MultiDropdownCell>): boolean {
        return true;
    }

    update?(
        cell: Compatible<MultiDropdownCell>,
        cellToMerge: UncertainCompatible<MultiDropdownCell>
    ): Compatible<MultiDropdownCell> {
        if (cellToMerge.type === 'multiautocomplete' && cell.idKey === cellToMerge.idKey)
            return { ...cell, ...cellToMerge, nonEditable: cell.nonEditable };
        return cell;
    }

    openDialog(cell: Compatible<MultiDropdownCell>) {
        this.setOpen(true);
        this.setCell(cell);
    }

    handleKeyDown?(
        cell: Compatible<MultiDropdownCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<MultiDropdownCell>; enableEditMode: boolean } {
        if (keyCode === keyCodes.BACKSPACE || keyCode === keyCodes.DELETE) {
            return { cell: this.getCompatibleCell({ ...cell, valueList: [] }), enableEditMode: false };
        }
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: true };
        if (keyCode === keyCodes.F2) {
            this.openDialog(cell);
        }
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<MultiDropdownCell>,
        eventData: string
    ): { cell: Compatible<MultiDropdownCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented.');
    }

    getStyle?(cell: Compatible<MultiDropdownCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<MultiDropdownCell>, isInEditMode: boolean): string {
        return `${cell.className} multidropdown`.trim();
    }

    render(
        cell: Compatible<MultiDropdownCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<MultiDropdownCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div
                onDoubleClick={() => this.openDialog(cell)}
                style={{ width: '100%', height: '100%', marginTop: '6px' }}
            >
                <div>{cell.text}</div>
            </div>
        );
    }
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendClient } from '../../api/clients';
import { getOidcUserSessionKey } from '../../lib/auth';
import { ROUTES } from '../../routes/routes';

interface BackendInterceptorWrapperProps {
    children: React.ReactNode;
}
export const BackendInterceptorWrapper: React.FC<BackendInterceptorWrapperProps> = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const interceptor = backendClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 404) {
                    navigate(ROUTES.notFound, { replace: true });
                } else if (error.response.status === 401) {
                    // The app should in principle not reach this branch
                    window.sessionStorage.removeItem(getOidcUserSessionKey(true));
                    window.location.reload();
                }
                return Promise.reject(error.response);
            }
        );
        return () => backendClient.interceptors.response.eject(interceptor);
    }, []);

    return <>{children}</>;
};

import {
    ActivityReadDTO,
    FirePermitReadDTO,
    FirePermitUpdateDTO,
    ListOfValuesReadDTO,
    LocationDTO,
    PersonRichReadDTO,
} from '../../../api/dto';
import { isDescendantOfProvidedLocations } from '../../../api/locations';

export const mapFpToUpdateDTO = (
    firePermit: any,
    isCreation: boolean = false
): FirePermitUpdateDTO & FirePermitReadDTO => {
    const { activity, ...firePermitWithoutActivity } = firePermit as any;
    return isCreation ? firePermit : firePermitWithoutActivity;
};

export const makeFirePermitDefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    loggedUser: PersonRichReadDTO,
    defaultWorkerType: ListOfValuesReadDTO | undefined,
    linkedLocation: LocationDTO | undefined
) => {
    // check createFirePermitFromActivity in OIM for reference
    return {
        activity: linkedActivity,
        availableActions: {
            canClone: false,
            attachable: false,
        },
        workToBePerformed: linkedActivity.description,
        modusOperandi: linkedActivity.modusOperandi,
        location: linkedLocation
            ? linkedActivity.locations
                  ?.map(({ location }) => location)
                  .filter((location): location is LocationDTO => location !== null)
                  .find((location) => location?.id === linkedLocation.id)
            : null,
        responsible: loggedUser,
        firePermitCompanyEmails: [],
        firePermitActivityHazards: linkedActivity.activityHazards.map((activityHazard) => activityHazard.type),
        firePermitLocationHazards: linkedActivity.locationHazards.map((locationHazard) => ({
            hazard: locationHazard.hazard,
            location: locationHazard.location,
        })),
        firePermitWarningDevices: [], //TODO: IMPACT-1962
        firePermitWorkTypes: [],
        is37s: [],
        worker: defaultWorkerType,
    } satisfies Partial<FirePermitReadDTO>;
};

export const makeFirePermitDefaultValuesFromClonedFirePermit = (
    firePermitReadDTO: FirePermitReadDTO,
    loggedUser: PersonRichReadDTO
) => {
    const { id, version, ...rest } = firePermitReadDTO;
    return {
        ...rest,
        responsible: loggedUser,
        fireInspectionPremises: null,
        terminationDate: null,
        approversInstructions: null,
        is37s: [],
    } satisfies Omit<FirePermitReadDTO, 'id' | 'version'>;
};

export const isFirePermitLocationPartOfActivity = async ({
    activity,
    location,
}: Pick<FirePermitReadDTO, 'activity' | 'location'>): Promise<boolean> => {
    const activityLocations = activity.locations.map((l) => l.location);
    if (location == null || location.id == null || activityLocations.includes(location)) {
        return true;
    }
    return isDescendantOfProvidedLocations({ location, locationsList: activityLocations });
};

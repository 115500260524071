import { IntlShape } from 'react-intl';
import { ActivityReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchActivitiesById } from '../../lib/api';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { documentTypeProperties } from './document';

interface ActivityTypePropertiesParameters {
    fetchActivities?: (searchTerm: string) => Promise<ActivityReadDTO[]>;
}

const activityTypeProperties = (
    { fetchActivities }: ActivityTypePropertiesParameters,
    intl: IntlShape
): AsyncAutocompleteFormElement['typeProperties'] =>
    documentTypeProperties({
        fetchOptions: fetchActivities ?? fetchActivitiesById,
        getOptionLabel: ({ id }: ActivityReadDTO) => intl.formatMessage({ id: 'activity.titleExisting' }, { id }),
        label: intl.formatMessage({ id: 'activity.name' }),
    });

interface ActivitySchemaParameters
    extends ActivityTypePropertiesParameters,
        Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const activitySchema = (
    { fetchActivities, path, label, required }: ActivitySchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: activityTypeProperties({ fetchActivities }, intl),
});

interface ActivityFilterSchemaParameters
    extends ActivityTypePropertiesParameters,
        Pick<TFilterElement, 'id' | 'label'> {}

export const activityFilterSchema = (
    { fetchActivities, id, label }: ActivityFilterSchemaParameters,
    intl: IntlShape
): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: ActivityReadDTO) => value.id,
    typeProperties: activityTypeProperties({ fetchActivities }, intl),
});

import { sfAnd, sfEqual, sfGt, sfLike, sfOr } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

const SYSTEM_DEFAULT_FILTER = sfAnd([sfGt('bLevel', 1), sfEqual('visible', 'true')]);

export const makeSystemFilter = (searchParam: string): string => {
    return sfAnd([
        SYSTEM_DEFAULT_FILTER,
        sfOr([
            sfLike('name', `${escapeSfqbString(searchParam, true)}*`),
            sfLike('name', `*${escapeSfqbString(searchParam, true)}*`),
        ]),
    ]).toString();
};

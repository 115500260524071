import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getDocumentInformation } from '../../lib/information/document';
import { EntityData } from '../../lib/information/types/EntityData';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';
import { DocumentDetails } from '../documents/DocumentDetails';
import { ExpandCollapseButton } from '../ExpandCollapseButton';
import { StatusChip } from '../StatusChip';

interface DocumentResultCardProps {
    document: EntityData;
    isExpanded: boolean;
    onExpandToggle: () => void;
}

export const DocumentResultCard: React.FC<DocumentResultCardProps> = ({ document, isExpanded, onExpandToggle }) => {
    const intl = useIntl();
    const { environment } = useApplicationSettings();
    const { url, name, title, status, details } = useMemo(
        () => getDocumentInformation(document, environment, intl),
        [document, intl]
    );
    const types = useMemo(() => details.filter((d) => d.type), [details]);
    return (
        <Card>
            <CardActionArea component={Link} to={url ?? '#'}>
                <CardHeader
                    sx={{ width: '100%', height: '100%' }}
                    title={
                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Typography variant="body1">{name}</Typography>
                            {status !== null && <StatusChip label={status.label} color={status.color} size="small" />}
                        </Stack>
                    }
                    disableTypography
                    subheader={
                        !!title && (
                            <Stack spacing={1}>
                                <Typography variant="body2" color="textSecondary">
                                    {title}
                                </Typography>
                            </Stack>
                        )
                    }
                />
            </CardActionArea>
            <CardActions disableSpacing sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Stack direction="row" spacing={1}>
                    <Stack direction="row" spacing={0.5} alignSelf="center">
                        {types
                            .map((type) => type.value)
                            .filter((label): label is string => typeof label === 'string')
                            .map((label, i) => (
                                <Chip key={i} label={label} variant="outlined" color="default" size="small" />
                            ))}
                    </Stack>
                </Stack>
                <ExpandCollapseButton
                    size="small"
                    expand={isExpanded}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        onExpandToggle();
                    }}
                />
            </CardActions>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                    <DocumentDetails {...document} />
                </CardContent>
            </Collapse>
        </Card>
    );
};

import { Send } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { createChangeRequest } from '../../api/documents/is37s';
import {
    DocumentType,
    IS37ChangeRequestContextReadDTO,
    IS37ChangeRequestCreateDTO,
    IS37ReadDTO,
    IS37StatusCode,
} from '../../api/dto';
import { FieldsAttributesContextProvider } from '../../forms/FieldsAttributesContext';
import { FormGenerator } from '../../forms/FormGenerator';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { useFormRules } from '../../forms/rule/useFormRules';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { makeIS37ChangeRequestDefaultValues } from '../../lib/records/documents/is37';

export interface IS37ChangeRequestDialogProps {
    is37: IS37ReadDTO;
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    alertSlot?: ReactNode;
    refreshData: () => void;
    defaultValues: Partial<IS37ChangeRequestCreateDTO>;
    rules: PreciseRulesDTO<IS37ChangeRequestContextReadDTO>;
}

const uiSchemaIs37CrFor = (is37: IS37ReadDTO, intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_RESPONSIVE,
    typeProperties: {
        direction: {
            xs: 'column',
        },
    },
    elements: [
        {
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                        sm: 'row',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'is37.field.startDate' }),
                            path: 'newStartDate',
                            disabled: is37.status.code !== IS37StatusCode.APPROVED,
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'is37.field.endDate' }),
                            path: 'newEndDate',
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                ],
            },
        },
        {
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                        sm: 'row',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.TIME,
                            label: intl.formatMessage({ id: 'is37.field.recommissioningStart' }),
                            path: 'newRecommissioningStart',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.TIME,
                            label: intl.formatMessage({ id: 'is37.field.recommissioningEnd' }),
                            path: 'newRecommissioningEnd',
                        },
                    },
                ],
            },
        },
        {
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'is37.changeRequest.field.comment' }),
                path: 'requestorComments',
            },
        },
    ],
});
export const IS37ChangeRequestDialog = ({
    is37,
    open,
    onClose,
    onCancel,
    refreshData,
    defaultValues,
    rules,
}: IS37ChangeRequestDialogProps): ReactElement => {
    const intl = useIntl();

    const changeRequestContext: IS37ChangeRequestContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: null,
                      task: null,
                  }
                : null,
        [rules.context]
    );

    const { form, attributes } = useFormRules<IS37ChangeRequestCreateDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...makeIS37ChangeRequestDefaultValues(is37),
            },
        },
        rules,
        originalValues: defaultValues,
        context: changeRequestContext as any,
    });
    const { clearErrors, handleSubmit } = form;

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.IS37_CR);

    const { mutate: mutateCreateChangeRequest, isLoading: isCreateChangeRequestLoading } = useMutation(
        createChangeRequest,
        {
            onSuccess: async () => {
                onClose();
                refreshData();
            },
            onError: (response: AxiosResponse) => handleFormSaveError(response, { submit: true }),
        }
    );
    const { isMobile } = useDeviceType();

    const handleSave = handleSubmit((formData) => {
        mutateCreateChangeRequest({
            data: formData,
            is37id: String(is37.id),
        });
    });

    const formSchema = uiSchemaIs37CrFor(is37, intl);
    return (
        <Dialog
            fullScreen={isMobile}
            open={open}
            onClose={() => !isCreateChangeRequestLoading && onClose()}
            maxWidth="sm"
            fullWidth
            keepMounted
        >
            <DialogTitle>
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <Typography variant="h6" color="textSecondary">
                        <FormattedMessage id="is37.changeRequest.shortname" />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Box>
                    <FormProvider {...form}>
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator
                                form={form}
                                rootElement={formSchema}
                                disabled={isCreateChangeRequestLoading}
                            />
                        </FieldsAttributesContextProvider>
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onCancel();
                        clearErrors();
                    }}
                    startIcon={<CloseIcon />}
                    disabled={isCreateChangeRequestLoading}
                    sx={{ color: grey[600] }}
                >
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button onClick={handleSave} startIcon={<Send />} disabled={isCreateChangeRequestLoading}>
                    <FormattedMessage id="document.action.submit" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

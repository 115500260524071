export enum UiSchemaType {
    LAYOUT_VERTICAL,
    LAYOUT_HORIZONTAL,
    LAYOUT_GRID,
    LAYOUT_RESPONSIVE,
    PANEL,
    INPUT,
    AUTOCOMPLETE,
    AUTOCOMPLETE_ASYNC,
    CHECKBOX,
    DATE,
    DATETIME,
    TIME,
    FIELD_ARRAY,
    FIELD_ARRAY_TABS,
    REGION,
    RANGE,
    RICH_TEXT,
    CUSTOM,
    FILTER_GROUP,
    NUMBER,
}

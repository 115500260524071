import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { OrgUnitReadDTO } from './dto';
import { GetManyPaged, RequestMethod } from './types';

const RESOURCE_PATH = 'organic-units';

export const getOrgUnits: GetManyPaged<OrgUnitReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getOrgUnitRoots: RequestMethod<any, OrgUnitReadDTO[]> = async () =>
    client.get(buildPath([RESOURCE_PATH, 'roots'])).then((response: AxiosResponse) => response.data);

export const getOrgUnitChildren: RequestMethod<any, OrgUnitReadDTO[]> = async ({ code } = {}) =>
    client.get(buildPath([RESOURCE_PATH, code, 'children'])).then((response: AxiosResponse) => response.data);

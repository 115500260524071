import { useMutation } from '@tanstack/react-query';
import { Log } from 'oidc-client-ts';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { getUser, OIDC_CONFIG, stripCurrentUrl } from '../../lib/auth';
import { LoadingPage } from '../../pages/LoadingPage';

const DEBUG_AUTH = false;

if (DEBUG_AUTH) {
    const QA_TOKEN_EXPIRATION_TIME = 1200; // seconds
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
    OIDC_CONFIG.accessTokenExpiringNotificationTimeInSeconds = QA_TOKEN_EXPIRATION_TIME - 5;
}

interface AuthWallProps {
    children: ReactNode;
}

const AuthWall: React.FC<AuthWallProps> = ({ children }) => {
    const { isAuthenticated, isLoading, activeNavigator, signinSilent, signinRedirect } = useAuth();
    const { mutate: signin, isLoading: isSigninLoading } = useMutation(() =>
        signinSilent().catch(() => signinRedirect())
    );
    // Automatic sign-in, see: https://github.com/authts/react-oidc-context#automatic-sign-in
    useEffect(() => {
        if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading && !isSigninLoading) {
            signin();
        } else {
            // When the user refocuses the window, check if the token is still valid
            const focusHandler = () => {
                if (getUser()?.expired !== false && !isSigninLoading) {
                    window.location.replace(stripCurrentUrl());
                }
            };
            window.addEventListener('focus', focusHandler);
            return () => window.removeEventListener('focus', focusHandler);
        }
    }, [isAuthenticated, activeNavigator, isLoading, isSigninLoading, signinSilent, signinRedirect]);
    return isAuthenticated ? <>{children}</> : <LoadingPage />;
};

interface AuthWrapperProps {
    children: ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
    const navigate = useNavigate();
    const onSigninCallback = (): void => {
        // remove the payload from the URL upon successful login
        const url = new URL(stripCurrentUrl());
        const urlWithoutDomain = url.pathname + url.search + url.hash;
        navigate(urlWithoutDomain, { replace: true });
    };
    return (
        <AuthProvider {...OIDC_CONFIG} onSigninCallback={onSigninCallback}>
            <AuthWall>{children}</AuthWall>
        </AuthProvider>
    );
};

import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ApiErrorDTO, ApiValidationErrorItemDTO, DocumentType } from '../api/dto';
import { HttpCode } from '../api/types';
import { isErrorResponseWithItems } from '../lib/api';
import { translateApiError } from '../lib/language';
import { getDocumentTypeName } from '../lib/records/documents/document';

export const useDocumentSaveErrorCallback = <T extends FieldValues>(
    { clearErrors, setError }: Pick<UseFormReturn<T>, 'clearErrors' | 'setError'>,
    documentType: DocumentType
) => {
    const intl = useIntl();
    return useCallback(
        (response: AxiosResponse, { validate, submit }: { validate?: boolean; submit?: boolean } = {}) => {
            const document = getDocumentTypeName(documentType, intl);
            if (400 <= response.status && response.status < 500) {
                if (isErrorResponseWithItems(response) && response.data.errorItems.length > 0) {
                    toast.warning(
                        intl.formatMessage({ id: 'document.error.validationItems' }, { document, validate, submit })
                    );
                } else {
                    if (response.status === HttpCode.CONFLICT && !response.data?.messageEn) {
                        toast.warning(intl.formatMessage({ id: 'document.error.conflict' }));
                    } else if (response.data?.messageEn) {
                        const validationError = translateApiError(
                            response.data as ApiErrorDTO<ApiValidationErrorItemDTO>,
                            intl.locale
                        );
                        toast.error(validationError.message);
                    } else {
                        // Shouldn't happen...
                        toast.error(intl.formatMessage({ id: 'common.error' }));
                    }
                }
            } else {
                toast.error(intl.formatMessage({ id: 'document.error.save' }, { document, validate, submit }));
            }

            if (response?.data?.errorItems) {
                const validationError = translateApiError(
                    response.data as ApiErrorDTO<ApiValidationErrorItemDTO>,
                    intl.locale
                );
                clearErrors();
                validationError.errorItems.forEach(({ field, message }) => {
                    const fieldError = { type: 'server', message };
                    setError(field as any, fieldError);
                });
            }
        },
        [clearErrors, setError, documentType, intl]
    );
};

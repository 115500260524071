import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { Is37Icon } from '../../../lib/information/is37';
import { getIs37Status } from '../../../lib/status';
import { IS37PageData } from './IS37PageData';
import { IS37PageVariant } from './IS37PageVariant';

interface IS37ContentHeaderProps {
    data: IS37PageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const IS37ContentHeader: React.FC<IS37ContentHeaderProps> = ({ data, ...rest }) => {
    const { variant } = data;
    const intl = useIntl();

    return (
        <DocumentHeader
            documentType={DocumentType.IS37}
            documentId={variant === IS37PageVariant.READ ? data.is37Data.id : null}
            icon={<Is37Icon />}
            title={
                variant === IS37PageVariant.READ
                    ? intl.formatMessage({ id: 'is37.titleExisting' }, { is37Id: data.is37Data.id })
                    : variant === IS37PageVariant.CREATE
                    ? intl.formatMessage({ id: 'is37.titleNew' })
                    : intl.formatMessage({ id: 'is37.titleNewCloning' }, { is37Id: data.is37Data.id })
            }
            status={variant === IS37PageVariant.READ ? getIs37Status(data.is37Data, intl) : undefined}
            creation={variant === IS37PageVariant.READ ? data.is37Data : undefined}
            {...rest}
        />
    );
};

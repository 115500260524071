import { ExpandMore } from '@mui/icons-material';
import { IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';

interface ExpandCollapseButtonProps extends Omit<IconButtonProps, 'children'> {
    expand: boolean;
}

export const ExpandCollapseButton = styled((props: ExpandCollapseButtonProps) => {
    const { expand, ...other } = props;
    return (
        <IconButton {...other}>
            <ExpandMore />
        </IconButton>
    );
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

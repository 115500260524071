import { Star, StarBorder } from '@mui/icons-material';
import { CircularProgress, IconButton, Snackbar, Tooltip, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { createFavourite, deleteFavourite, existsFavourite } from '../api/users';
import { useAsyncEffect } from '../hooks/useAsyncEffect';

const messageDuration = 3000;
const addSuccessMessage = 'Added to favourites';
const removeSuccessMessage = 'Removed from favourites';

interface FavouriteButtonProps {
    activityId: number;
    defaultIsFavourite?: boolean;
}
export const FavouriteButton: React.FC<FavouriteButtonProps> = ({ activityId, defaultIsFavourite }) => {
    const [isFavourite, setIsFavourite] = useState(defaultIsFavourite || false);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [ongoingRequest, setOngoingRequest] = useState(false);
    const theme = useTheme();

    const handleFavouriteSwitch = async () => {
        const [request, successMessage] = isFavourite
            ? [deleteFavourite, removeSuccessMessage]
            : [createFavourite, addSuccessMessage];

        setOngoingRequest(true);
        const response: AxiosResponse = await request({ activity: activityId });
        if (response.status === 200) {
            setIsFavourite(!isFavourite);
            setMessage(successMessage);
            setOpen(true);
        }

        setOngoingRequest(false);
    };

    useAsyncEffect(async () => {
        if (defaultIsFavourite === undefined && activityId) {
            const response: AxiosResponse = await existsFavourite({ activity: activityId });
            setIsFavourite(response.data);
        }
    }, []);

    return (
        <>
            {ongoingRequest ? (
                <CircularProgress size="1.5em" sx={{ margin: 1 }} />
            ) : (
                <Tooltip title={isFavourite ? 'Remove from favourites' : 'Add to favourites'}>
                    <IconButton size={'small'} onClick={handleFavouriteSwitch}>
                        {isFavourite ? <Star sx={{ color: theme.palette.warning.main }} /> : <StarBorder />}
                    </IconButton>
                </Tooltip>
            )}
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                message={message}
                autoHideDuration={messageDuration}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    );
};

import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassicLayout } from './ClassicLayout';
import { HeaderlessLayout } from './HeaderlessLayout';

const LAYOUT_PARAM = 'layoutMode';
const LAYOUT_HEADERLESS = 'HEADERLESS';

interface LayoutProviderProps {
    children: React.ReactNode;
}
export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const layoutMode = queryParams.get(LAYOUT_PARAM);
    //constant state to track the layout mode on page load
    const [LAYOUT_ON_LOAD] = useState(queryParams.get(LAYOUT_PARAM));

    useEffect(() => {
        if (LAYOUT_ON_LOAD === LAYOUT_HEADERLESS) {
            const parsedQuery = queryString.parse(location.search);
            parsedQuery[LAYOUT_PARAM] = LAYOUT_HEADERLESS;
            navigate(`${location.pathname}?${queryString.stringify(parsedQuery)}`, {
                replace: true,
            });
        }
    }, [location.pathname]);

    switch (layoutMode) {
        case LAYOUT_HEADERLESS:
            return <HeaderlessLayout>{children}</HeaderlessLayout>;
        default:
            return <ClassicLayout>{children}</ClassicLayout>;
    }
};

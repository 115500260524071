import { Typography } from '@mui/material';
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getDimrAlaraLevels, getDimrStatuses, getDimrVersions } from '../../../api/documents/dimrs';
import { DimrVersionGridReadDTO, DocumentType, SavedFilterType } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { wdpFilterSchema } from '../../../forms/schemas/wdp';
import { TFilterElement } from '../../../forms/types';
import { Locale } from '../../../i18n/Locale';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { formatVersionedId } from '../../../lib/records/documents/versioned';
import { getDimrStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const CODE_ID = 'versionCode';
const TITLE = 'title';
const OWNER_ID = 'owner.searchLabel';
const ACTIVITY_ID = 'activity.id';
const CREATED_DATE = 'createdDate';
const STATUS_ID = 'status.code';
const MAIN_FACILITY_ID = 'mainFacility.code';
const ALARA_LEVEL_ID = 'alaraLevel.code';
const BYPASS_WORKFLOW = 'bypassWorkflow';
const RSSO_RPE_ID = 'rssoRpe.searchLabel';
const RSO_ID = 'rso.searchLabel';
const RPO_ID = 'rpo.searchLabel';
const WDP_ID = 'workDosePlanningVersions.id';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: CODE_ID,
        label: intl.formatMessage({ id: 'dimr.field.version' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: TITLE,
        label: intl.formatMessage({ id: 'document.field.title' }),
        type: FilterTypeEnum.STRING,
    },
    valueFilterSchema(
        { id: STATUS_ID, label: intl.formatMessage({ id: 'document.field.status' }), fetchOptions: getDimrStatuses },
        intl.locale
    ),
    valueFilterSchema(
        {
            id: ALARA_LEVEL_ID,
            label: intl.formatMessage({ id: 'document.field.alaraLevel' }),
            fetchOptions: getDimrAlaraLevels,
        },
        intl.locale
    ),
    wdpFilterSchema({ id: WDP_ID, label: intl.formatMessage({ id: 'wdp.name' }) }, intl),
    activityFilterSchema({ id: ACTIVITY_ID, label: intl.formatMessage({ id: 'activity.name' }) }, intl),
    personFilterSchema({ id: OWNER_ID, label: intl.formatMessage({ id: 'dimr.field.owner' }) }),
    personFilterSchema({ id: RPO_ID, label: intl.formatMessage({ id: 'dimr.field.rpo' }) }),
    personFilterSchema({ id: RSO_ID, label: intl.formatMessage({ id: 'dimr.field.rsoExperimentRso' }) }),
    {
        id: CREATED_DATE,
        label: intl.formatMessage({ id: 'document.field.createdDate' }),
        type: FilterTypeEnum.DATE,
    },
    valueFilterSchema(
        {
            id: MAIN_FACILITY_ID,
            label: intl.formatMessage({ id: 'dimr.field.mainFacility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    {
        id: BYPASS_WORKFLOW,
        label: intl.formatMessage({ id: 'dimr.field.bypassWorkflow' }),
        type: FilterTypeEnum.BOOLEAN,
    },
    personFilterSchema({ id: RSSO_RPE_ID, label: intl.formatMessage({ id: 'dimr.field.rssoRpe' }) }),
];

// Some columns appear in the WDP, reason why we expose them here

export interface DimrHelper<T> {
    helper: ColumnHelper<T>;
    accessor: (object: T) => DimrVersionGridReadDTO | null;
}

export interface DimrColumnData {
    id: string;
    header: string;
}

export const columnDimrId = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.id, {
        ...data,
        size: 105,
        cell: ({ row: { original } }) => {
            const row = accessor(original);
            return row !== null ? (
                <ImpactLink
                    to={getRoute({
                        path: ROUTES.dimr.view,
                        params: { id: row.dimrMaster.id, version: row.versionNumber },
                    })}
                >
                    {formatVersionedId(row.dimrMaster.id, row.versionNumber)}
                </ImpactLink>
            ) : null;
        },
    });

export const columnDimrTitle = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.title, {
        ...data,
        size: 240,
    });

export const columnDimrStatus = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>, intl: IntlShape) =>
    helper.accessor(
        (row: T) => (accessor(row)?.status ? getLovLabel(translateValue(accessor(row)?.status!, intl.locale)) : ''),
        {
            ...data,
            size: 180,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
            cell: ({ row: { original } }) => {
                const row = accessor(original);
                return row !== null ? <StatusChip {...getDimrStatus(row, intl)} size="small" /> : null;
            },
        }
    );

export const columnDimrAlaraLevel = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>, locale: Locale) =>
    helper.accessor(
        (row: T) => (accessor(row)?.alaraLevel ? getLovLabel(translateValue(accessor(row)?.alaraLevel!, locale)) : ''),
        {
            ...data,
            size: 90,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
        }
    );

export const columnDimrActivities = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.activitiesIds, {
        ...data,
        size: 200,
        enableSorting: false,
        cell: ({ row: { original } }) => {
            const row = accessor(original);
            return (
                !!row?.activitiesIds && (
                    <>
                        {row.activitiesIds.map((id, i) => (
                            <span key={id}>
                                <ImpactLink to={getRoute({ path: ROUTES.activity.view, params: { id } })}>
                                    {id}
                                </ImpactLink>
                                {i < row.activitiesIds.length - 1 ? ', ' : undefined}
                            </span>
                        ))}
                    </>
                )
            );
        },
    });

export const columnDimrOwner = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.owner?.searchLabel, {
        ...data,
        size: 200,
        cell: ({ row: { original }, getValue }) => {
            const row = accessor(original);
            return (
                !!row?.owner && (
                    <PersonTooltip person={row.owner}>
                        <Typography variant="inherit">{getValue()}</Typography>
                    </PersonTooltip>
                )
            );
        },
    });

export const columnDimrRpo = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.rpo?.searchLabel, {
        ...data,
        size: 200,
        cell: ({ row: { original }, getValue }) => {
            const row = accessor(original);
            return (
                !!row?.rpo && (
                    <PersonTooltip person={row.rpo}>
                        <Typography variant="inherit">{getValue()}</Typography>
                    </PersonTooltip>
                )
            );
        },
    });

export const columnDimrRso = <T,>(data: DimrColumnData, { helper, accessor }: DimrHelper<T>) =>
    helper.accessor((row: T) => accessor(row)?.rso?.searchLabel ?? '', {
        ...data,
        size: 200,
        cell: ({ row: { original }, getValue }) => {
            const row = accessor(original);
            return (
                !!row?.rso && (
                    <PersonTooltip person={row.rso}>
                        <Typography variant="inherit">{getValue()}</Typography>
                    </PersonTooltip>
                )
            );
        },
    });

const columnHelper = createColumnHelper<DimrVersionGridReadDTO>();

const helper: DimrHelper<DimrVersionGridReadDTO> = { helper: columnHelper, accessor: (row) => row };

const columns = (intl: IntlShape) => [
    columnDimrId(
        {
            id: 'dimrMasterId',
            header: intl.formatMessage({ id: 'dimr.dimrSlashVersion' }),
        },
        helper
    ),
    columnDimrTitle(
        {
            id: 'title',
            header: intl.formatMessage({ id: 'document.field.title' }),
        },
        helper
    ),
    columnDimrStatus(
        {
            id: 'status.label',
            header: intl.formatMessage({ id: 'document.field.status' }),
        },
        helper,
        intl
    ),
    columnDimrAlaraLevel(
        {
            id: 'alaraLevel.label',
            header: intl.formatMessage({ id: 'document.field.alara' }),
        },
        helper,
        intl.locale
    ),
    columnHelper.accessor((row) => row.workDosePlanningVersions, {
        id: 'workDosePlanningVersions',
        header: intl.formatMessage({ id: 'dimr.field.wdps' }),
        size: 200,
        enableSorting: false,
        cell: ({ row: { original } }) => (
            <>
                {original.workDosePlanningVersions.map((wdp, i) => (
                    <span key={`${wdp.wdpMasterId}:${wdp.versionNumber}`}>
                        <ImpactLink
                            to={getRoute({
                                path: ROUTES.wdp.view,
                                params: { id: wdp.wdpMasterId, version: wdp.versionNumber },
                            })}
                        >
                            {formatVersionedId(wdp.wdpMasterId, wdp.versionNumber)}
                        </ImpactLink>
                        {i < original.workDosePlanningVersions.length - 1 ? ', ' : undefined}
                    </span>
                ))}
            </>
        ),
    }),
    columnDimrActivities(
        {
            id: 'activities',
            header: intl.formatMessage({ id: 'document.field.activities' }),
        },
        helper
    ),
    columnDimrOwner(
        {
            id: 'owner.searchLabel',
            header: intl.formatMessage({ id: 'dimr.field.owner' }),
        },
        helper
    ),
    columnDimrRpo(
        {
            id: 'rpo.searchLabel',
            header: intl.formatMessage({ id: 'dimr.field.rpo' }),
        },
        helper
    ),
    columnDimrRso(
        {
            id: 'rso.searchLabel',
            header: intl.formatMessage({ id: 'dimr.field.rsoExperimentRso' }),
        },
        helper
    ),
    columnHelper.accessor((row) => row.createdDate ?? '', {
        id: 'createdDate',
        header: intl.formatMessage({ id: 'document.field.createdDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
];

export const DIMRSearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.dimrSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'dimr.name' })}
            columns={columns(intl)}
            pageKey="DIMRSearchPage"
            documentType={DocumentType.DIMR}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getDimrVersions}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Dimr, dimr: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.DIMR}
        />
    );
};

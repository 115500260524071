import { CheckBox, CheckBoxOutlineBlank, Grading, Replay, ViewColumn } from '@mui/icons-material';
import {
    Badge,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    ToggleButton,
} from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDeviceType } from '../../../hooks/useDeviceType';

interface ColumnVisibilityButtonProps<TData> {
    table: Table<TData>;
    hiddenColumnsIds: string[] | undefined;
}

export const ColumnVisibilityButton = <TData,>({
    table,
    hiddenColumnsIds,
    ...rest
}: ColumnVisibilityButtonProps<TData>): ReactElement => {
    const { isMobile } = useDeviceType();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const badge = (() => {
        const defaultVisibility = table
            .getAllLeafColumns()
            .filter((column) => !column.columnDef.meta?.initiallyHidden)
            .map(({ getIsVisible }) => getIsVisible());
        const hiddenColumnsVisibility = table
            .getAllLeafColumns()
            .filter((column) => column.columnDef.meta?.initiallyHidden)
            .map(({ getIsVisible }) => getIsVisible());
        const visibility = table.getAllLeafColumns().map(({ getIsVisible }) => getIsVisible());

        const identity = <T,>(v: T) => v;
        return defaultVisibility.every(identity) && !hiddenColumnsVisibility.some(identity)
            ? undefined
            : visibility.filter(identity).length;
    })();

    const restoreDefaultVisibility = () => {
        table.resetColumnVisibility();
        const hiddenColumnsObject =
            hiddenColumnsIds &&
            hiddenColumnsIds.reduce((acc: Record<string, boolean>, currentValue) => {
                acc[currentValue] = false;
                return acc;
            }, {});
        hiddenColumnsObject && table.setColumnVisibility(hiddenColumnsObject);
    };

    return (
        <>
            <ToggleButton value="columns" onClick={handleOpen} {...rest} selected={badge !== undefined}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Badge color="primary" badgeContent={badge} showZero>
                        <ViewColumn />
                    </Badge>
                    {!isMobile && (
                        <Box>
                            <FormattedMessage id="search.columns.button" />
                        </Box>
                    )}
                </Stack>
            </ToggleButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList dense>
                    <MenuItem onClick={() => table.toggleAllColumnsVisible(true)}>
                        <ListItemIcon>
                            <Grading />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="common.selectAll" />
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={restoreDefaultVisibility}>
                        <ListItemIcon>
                            <Replay />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="common.restoreDefaults" />
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    {table
                        .getAllLeafColumns()
                        .map(({ id, getIsVisible, toggleVisibility, columnDef }: Column<TData, unknown>) => (
                            <MenuItem key={id} selected={getIsVisible()} onClick={() => toggleVisibility()}>
                                <ListItemIcon>{getIsVisible() ? <CheckBox /> : <CheckBoxOutlineBlank />}</ListItemIcon>
                                <ListItemText>{columnDef.header as any}</ListItemText>
                            </MenuItem>
                        ))}
                </MenuList>
            </Menu>
        </>
    );
};

import {
    FormatListNumbered,
    FormatListNumberedRtl,
    Group,
    Link,
    Person,
    Place,
    StackedLineChart,
} from '@mui/icons-material';
import { AccountMultiplePlus } from 'mdi-material-ui';
import {
    DimrStatusCode,
    WdpParticipantReadDTO,
    WdpPerformedTaskReadDTO,
    WdpRpAssessmentReadDTO,
    WdpWorkingPositionReadDTO,
    WdpWorkingTeamReadDTO,
    WdpWorkStepReadDTO,
} from '../../../api/dto';
import { WdpTabMenuContextValue } from './tabs/menu/WdpTabMenuContext';
import { WdpFollowupTab } from './tabs/WdpFollowupTab';
import { WdpTab } from './tabs/WdpTab';
import { createEnum } from './tabs/WdpUtils';
import { fieldsMetadata, TableHeader, TreeNode } from './WdpInterfaces';
import { WdpTabV } from './WdpTabsRegion';

const participantsHeaders: TableHeader[] = [
    { name: 'unknown', labelTranslationKey: 'wdp.spreadsheet.column.unknown', width: 80 },
    {
        name: 'name',
        labelTranslationKey: 'wdp.spreadsheet.column.unknownParticipant',
        readonly: (row: { unknown: boolean }) => !row.unknown,
        hideReadonly: true,
        width: 180,
    },
    {
        name: 'person',
        labelTranslationKey: 'wdp.spreadsheet.column.activityParticipant',
        readonly: (row: { unknown: boolean }) => row.unknown,
        hideReadonly: true,
        width: 300,
        type: 'person',
        idKey: 'id',
    },
    {
        name: 'wdpWorkingTeamIdxs',
        labelTranslationKey: 'wdp.spreadsheet.column.teams',
        readonly: false,
        width: 300,
        customType: fieldsMetadata.teams,
        type: 'multiautocomplete',
    },
    {
        name: 'operationalDoseLast31Days',
        labelTranslationKey: 'wdp.spreadsheet.column.opDose31Days',
        unitTranslationKey: 'document.unit.microSievert',
        readonly: true,
        type: 'number',
        width: 120,
        redactedKey: 'doseHistoryVisible',
    },
    {
        name: 'operationalDoseLast12Months',
        labelTranslationKey: 'wdp.spreadsheet.column.opDose12Months',
        unitTranslationKey: 'document.unit.microSievert',
        readonly: true,
        type: 'number',
        width: 120,
        redactedKey: 'doseHistoryVisible',
    },
    {
        name: 'dosimetryStatus',
        labelTranslationKey: 'wdp.spreadsheet.column.dosimetryStatus',
        readonly: true,
        redactedKey: 'doseHistoryVisible',
    },
    {
        name: 'doseTaken',
        labelTranslationKey: 'wdp.spreadsheet.column.doseTaken',
        unitTranslationKey: 'document.unit.microSievert',
        readonly: true,
        type: 'number',
        redactedKey: 'doseTakenVisible',
    },
    {
        name: 'estimatedDose',
        labelTranslationKey: 'wdp.spreadsheet.column.estimatedDose',
        unitTranslationKey: 'document.unit.microSievert',
        readonly: true,
        type: 'number',
    },
    {
        name: 'estimationNext12Months',
        labelTranslationKey: 'wdp.spreadsheet.column.estimation12Months',
        unitTranslationKey: 'document.unit.microSievert',
        readonly: true,
        type: 'number',
    },
    {
        name: 'totalIndividualWorkingTime',
        labelTranslationKey: 'wdp.spreadsheet.column.totalIndividualWorkingTime',
        unitTranslationKey: 'document.unit.personHour',
        width: 120,
        readonly: true,
        type: 'number',
    },
];

const isLeaf = (row: WdpWorkStepReadDTO | WdpPerformedTaskReadDTO, tree: TreeNode[] | undefined): boolean => {
    if (tree === undefined) {
        throw new Error(); // Tree must be defined for steps and tasks
    }
    const rowId = 'workStepsIdx' in row ? row.workStepsIdx : row.performedTasksIdx;
    const node = tree.find((e) => e.id === rowId)!;
    return !node.hasChildren;
};
export const isNotLeaf = (...row: Parameters<typeof isLeaf>) => !isLeaf(...row);

const workStepsHeaders = (performed: boolean): TableHeader[] => [
    {
        name: 'step',
        labelTranslationKey: 'wdp.spreadsheet.column.step',
        width: 80,
        type: 'chevron',
        readonly: true,
    },
    {
        name: 'description',
        labelTranslationKey: 'wdp.spreadsheet.column.description',
        width: 300,
    },
    ...(performed
        ? []
        : [
              {
                  name: 'responsible',
                  labelTranslationKey: 'wdp.spreadsheet.column.responsible',
                  width: 250,
                  type: 'person',
                  idKey: 'id',
              } satisfies TableHeader,
          ]),
    {
        name: 'wdpWorkingTeamIdxs',
        labelTranslationKey: 'wdp.spreadsheet.column.workTeams',
        readonly: (row: WdpWorkStepReadDTO | WdpPerformedTaskReadDTO, tree) =>
            row.wdpParticipantIdxs.length > 0 || isNotLeaf(row, tree),
        hideReadonly: true,
        width: 200,
        customType: fieldsMetadata.teams,
        type: 'multiautocomplete',
    },
    {
        name: 'wdpParticipantIdxs',
        labelTranslationKey: 'wdp.spreadsheet.column.workers',
        readonly: (row: WdpWorkStepReadDTO | WdpPerformedTaskReadDTO, tree) =>
            row.wdpWorkingTeamIdxs.length > 0 || isNotLeaf(row, tree),
        hideReadonly: true,
        width: 200,
        customType: fieldsMetadata.participants,
        type: 'multiautocomplete',
    },
    {
        name: 'numberOfParticipants',
        labelTranslationKey: 'wdp.spreadsheet.column.numberOfParticipants',
        readonly: isNotLeaf,
        hideReadonly: true,
        width: 90,
        type: 'number',
    },
    {
        name: 'wdpWorkingPositionIdxs',
        labelTranslationKey: 'wdp.spreadsheet.column.workingPositions',
        readonly: isNotLeaf,
        hideReadonly: true,
        width: 200,
        customType: fieldsMetadata.positions,
        type: 'multiautocomplete',
    },
    ...(performed
        ? [
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.estimatedDoseRate',
                  unitTranslationKey: 'document.unit.microSievertPerHour',
                  name: 'estimatedDoseRate',
                  readonly: true,
                  type: 'number',
              } satisfies TableHeader,
          ]
        : [
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.doseRate',
                  unitTranslationKey: 'document.unit.microSievertPerHour',
                  name: 'doseRate',
                  readonly: true,
                  type: 'number',
              } satisfies TableHeader,
          ]),
    {
        name: 'coefficient',
        labelTranslationKey: 'wdp.spreadsheet.column.exposureTimeCoefficient',
        readonly: isNotLeaf,
        hideReadonly: !performed,
        type: 'number',
        width: 90,
    },
    ...(performed
        ? [
              {
                  name: 'exposureTime',
                  labelTranslationKey: 'wdp.spreadsheet.column.exposureTime',
                  unitTranslationKey: 'document.unit.minute',
                  readonly: isNotLeaf,
                  type: 'number',
                  width: 90,
              } satisfies TableHeader,
          ]
        : [
              {
                  name: 'estimatedExposureTime',
                  labelTranslationKey: 'wdp.spreadsheet.column.estimatedExposureTime',
                  unitTranslationKey: 'document.unit.minute',
                  readonly: isNotLeaf,
                  hideReadonly: true,
                  type: 'number',
                  width: 90,
              } satisfies TableHeader,
          ]),
    ...(performed
        ? []
        : [
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.estimatedTotalStepExposureTime',
                  unitTranslationKey: 'document.unit.personHour',
                  name: 'estimatedTotalExposureTime',
                  readonly: true,
                  width: 110,
                  type: 'number',
              } satisfies TableHeader,
          ]),
    {
        labelTranslationKey: 'wdp.spreadsheet.column.estimatedDosePersonal',
        unitTranslationKey: 'document.unit.personMicroSievertPerHour',
        name: 'estimatedDose',
        readonly: true,
        type: 'number',
        width: 120,
    },
    ...(performed
        ? ([
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.doseReceivedFromDosiserv',
                  unitTranslationKey: 'document.unit.microSievert',
                  name: 'doseReceivedFromDosiserv',
                  readonly: true,
                  type: 'number',
                  redactedKey: 'doseTakenVisible',
                  width: 120,
              },
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.doseReceivedManuallyEntered',
                  unitTranslationKey: 'document.unit.microSievert',
                  name: 'doseReceivedManuallyInput',
                  readonly: isNotLeaf,
                  type: 'number',
              },
          ] satisfies TableHeader[])
        : [
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.estimatedTotalStepDose',
                  unitTranslationKey: 'document.unit.microSievert',
                  name: 'estimatedTotalTaskDose',
                  readonly: true,
                  type: 'number',
              } satisfies TableHeader,
          ]),
    {
        name: 'expectedStartDate',
        labelTranslationKey: 'wdp.spreadsheet.column.expectedStartDate',
        type: 'date',
        width: 120,
    },
    {
        name: 'expectedEndDate',
        labelTranslationKey: 'wdp.spreadsheet.column.expectedEndDate',
        type: 'date',
        width: 120,
    },
    ...(performed
        ? ([
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.lastCheckpoint',
                  name: 'wdpCheckPoints',
                  readonly: isNotLeaf,
                  hideReadonly: true,
                  type: 'checkpoints',
                  width: 165,
              },
              {
                  labelTranslationKey: 'wdp.spreadsheet.column.comments',
                  name: 'comment',
                  type: 'longInput',
                  width: 400,
              },
          ] satisfies TableHeader[])
        : []),
];

export const WdpTabs = {
    TEAMS: {
        Component: WdpTab,
        Icon: Group,
        labelTranslationKey: 'wdp.spreadsheet.tab.teams',
        props: {
            menuEntries: [],
            headers: [
                { name: 'name', labelTranslationKey: 'wdp.spreadsheet.column.name', readonly: false, width: 150 },
                { name: 'description', labelTranslationKey: 'wdp.spreadsheet.column.description', width: 500 },
            ],
            newRowSupplier: () => ({} satisfies Partial<WdpWorkingTeamReadDTO>), // No extra fields needed for new row
            metadata: fieldsMetadata.teams,
        },
    },
    PARTICIPANTS: {
        Component: WdpTab,
        Icon: Person,
        labelTranslationKey: 'wdp.spreadsheet.tab.participants',
        props: {
            menuEntries: [
                {
                    label: (intl) => intl.formatMessage({ id: 'wdp.spreadsheet.action.importFromActivity' }),
                    Icon: AccountMultiplePlus,
                    onClick: ({ setOpenActivityImportDialog }: WdpTabMenuContextValue) =>
                        setOpenActivityImportDialog(true),
                },
            ],
            headers: participantsHeaders,
            newRowSupplier: (wdp) =>
                ({
                    unknown: false,
                    replacement: !!(wdp?.dimr && wdp?.dimr.status.code !== DimrStatusCode.DRAFT),
                    wdpWorkingTeamIdxs: [],
                } satisfies Partial<WdpParticipantReadDTO>),
            metadata: fieldsMetadata.participants,
        },
    },
    WORKING_POSITIONS: {
        Component: WdpTab,
        Icon: Place,
        labelTranslationKey: 'wdp.spreadsheet.tab.workingPositions',
        props: {
            menuEntries: [],
            headers: [
                { name: 'name', labelTranslationKey: 'wdp.spreadsheet.column.name', readonly: false },
                { name: 'description', labelTranslationKey: 'wdp.spreadsheet.column.description', width: 500 },
                {
                    name: 'doseRate',
                    labelTranslationKey: 'wdp.spreadsheet.column.doseRate',
                    unitTranslationKey: 'document.unit.microSievertPerHour',
                    type: 'number',
                },
                {
                    name: 'reductionFactor',
                    labelTranslationKey: 'wdp.spreadsheet.column.reductionFactor',
                    type: 'number',
                },
                {
                    name: 'finalDoseRate',
                    readonly: true,
                    labelTranslationKey: 'wdp.spreadsheet.column.doseRateCalculation',
                    unitTranslationKey: 'document.unit.microSievertPerHour',
                    type: 'number',
                    width: 120,
                },
            ],
            newRowSupplier: () => ({ reductionFactor: 1 } satisfies Partial<WdpWorkingPositionReadDTO>),
            metadata: fieldsMetadata.positions,
        },
    },
    WORK_STEPS: {
        Component: WdpTab,
        Icon: FormatListNumbered,
        labelTranslationKey: 'wdp.spreadsheet.tab.workSteps',
        props: {
            menuEntries: [],
            headers: workStepsHeaders(false),
            newRowSupplier: () =>
                ({
                    wdpParticipantIdxs: [],
                    wdpWorkingPositionIdxs: [],
                    wdpWorkingTeamIdxs: [],
                } satisfies Partial<WdpWorkStepReadDTO>),
            metadata: fieldsMetadata.workSteps,
        },
    },
    RP_ASSESSMENTS: {
        Component: WdpTab,
        Icon: Link,
        labelTranslationKey: 'wdp.spreadsheet.tab.rpAssessments',
        props: {
            menuEntries: [],
            headers: [
                {
                    name: 'rpAssessment.activity.id',
                    labelTranslationKey: 'wdp.spreadsheet.column.id',
                    readonly: true,
                    width: 90,
                    type: 'number',
                },
                {
                    name: 'rpAssessment.activity.title',
                    labelTranslationKey: 'document.field.activityTitle',
                    readonly: true,
                    width: 500,
                },
                {
                    name: 'rpAssessment.activity.scheduledStart',
                    labelTranslationKey: 'wdp.spreadsheet.column.scheduledStartDate',
                    width: 120,
                    readonly: true,
                    type: 'date',
                },
                {
                    name: 'rpAssessment.activity.scheduledEnd',
                    labelTranslationKey: 'wdp.spreadsheet.column.scheduledEndDate',
                    width: 120,
                    readonly: true,
                    type: 'date',
                },
                {
                    name: 'wdpWorkStepIdxs',
                    labelTranslationKey: 'wdp.spreadsheet.column.workSteps',
                    width: 250,
                    customType: fieldsMetadata.workSteps,
                    type: 'worksteps',
                },
                {
                    name: 'avgEstDoseRate',
                    labelTranslationKey: 'wdp.spreadsheet.column.averageEstimatedDoseRate',
                    unitTranslationKey: 'document.unit.microSievertPerHour',
                    readonly: true,
                    type: 'number',
                },
                {
                    name: 'totalCollectiveWorkTime',
                    labelTranslationKey: 'wdp.spreadsheet.column.totalCollectiveWorkingTime',
                    unitTranslationKey: 'document.unit.hour',
                    readonly: true,
                    type: 'number',
                },
                {
                    name: 'maxIndividualWorkTime',
                    labelTranslationKey: 'wdp.spreadsheet.column.maxIndividualWorkingTime',
                    unitTranslationKey: 'document.unit.hour',
                    readonly: true,
                    type: 'number',
                },
                {
                    name: 'estCollectiveDose',
                    labelTranslationKey: 'wdp.spreadsheet.column.estimatedCollectiveDose',
                    unitTranslationKey: 'document.unit.personMicroSievertPerHour',
                    readonly: true,
                    type: 'number',
                },
                {
                    name: 'maxEstIndividualDose',
                    width: 120,
                    labelTranslationKey: 'wdp.spreadsheet.column.maxEstimatedIndividualDose',
                    unitTranslationKey: 'document.unit.microSievert',
                    readonly: true,
                    type: 'number',
                },
                {
                    name: 'maxEstDoseRate',
                    labelTranslationKey: 'wdp.spreadsheet.column.maxEstimatedDoseRate',
                    unitTranslationKey: 'document.unit.microSievertPerHour',
                    readonly: true,
                    type: 'number',
                    width: 120,
                },
            ] satisfies TableHeader[],
            newRowSupplier: () => ({ wdpWorkStepIdxs: [] } satisfies Partial<WdpRpAssessmentReadDTO>),
            metadata: fieldsMetadata.rpAssessments,
            spreadsheetProps: {} as any,
        },
    },
    AS_PERFORMED: {
        Component: WdpTab,
        Icon: FormatListNumberedRtl,
        labelTranslationKey: 'wdp.spreadsheet.tab.asPerformed',
        props: {
            menuEntries: [],
            headers: workStepsHeaders(true),
            newRowSupplier: () =>
                ({
                    wdpParticipantIdxs: [],
                    wdpWorkingPositionIdxs: [],
                    wdpWorkingTeamIdxs: [],
                    wdpCheckPoints: [],
                    comment: null,
                } satisfies Partial<WdpPerformedTaskReadDTO>),
            metadata: fieldsMetadata.tasks,
        },
    },
    FOLLOWUP: {
        Component: WdpFollowupTab,
        Icon: StackedLineChart,
        labelTranslationKey: 'wdp.spreadsheet.tab.followup',
        props: undefined,
    },
} satisfies Record<string, WdpTabV>;

export type WdpTabKey = keyof typeof WdpTabs;
export const WdpTabKeys = createEnum<WdpTabKey>(Object.keys(WdpTabs) as WdpTabKey[]);

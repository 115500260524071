import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getActivity } from '../../../api/activities';
import { getNdcByIdOrNdcNumber, getNdcValidationRules } from '../../../api/documents/noteDeCoupures';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { NoteDeCoupureForm } from '../../../forms/documents/NoteDeCoupureForm';
import { getRoute, ROUTES } from '../../../routes/routes';

export enum NdcPageVariant {
    READ,
    CREATE,
    CLONE,
}

interface NoteDeCoupurePageProps {
    variant: NdcPageVariant;
}
export const NoteDeCoupurePage: React.FunctionComponent<NoteDeCoupurePageProps> = ({ variant }) => {
    const { formatMessage } = useIntl();
    const { id: documentIdOrNumber } = useParams();
    const {
        data: noteDeCoupureData,
        isLoading: isDataLoading,
        isError: ndcDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.NDC, documentIdOrNumber],
        () => getNdcByIdOrNdcNumber({ idOrNdcNumber: String(documentIdOrNumber) }),
        {
            enabled: documentIdOrNumber !== undefined,
        }
    );
    const refreshNoteDeCoupureData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const activityId =
        variant === NdcPageVariant.READ ? noteDeCoupureData?.activity.id : String(params.get('activity'));

    useEffect(() => {
        if (noteDeCoupureData && String(noteDeCoupureData.id) !== documentIdOrNumber) {
            let path: string;
            if (variant === NdcPageVariant.READ) {
                path = ROUTES.noteDeCoupure.view;
            } else if (variant === NdcPageVariant.CLONE) {
                path = ROUTES.noteDeCoupure.clone;
            } else {
                throw new Error();
            }
            navigate(
                getRoute({
                    path,
                    params: { id: String(noteDeCoupureData.id) },
                    queryParams: variant === NdcPageVariant.CLONE ? { activity: String(activityId) } : undefined,
                }),
                {
                    replace: true,
                }
            );
        }
    }, [noteDeCoupureData, documentIdOrNumber, navigate]);

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.NDC, documentIdOrNumber, 'rules'], () =>
        getNdcValidationRules({ idOrNdcNumber: documentIdOrNumber })
    );

    if (
        (isDataLoading && documentIdOrNumber !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        isRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.NDC} title={formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (ndcDataFailed || activityDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="ndc.error.document" />
                <ImpactHelmet documentType={DocumentType.NDC} title={formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === NdcPageVariant.CREATE && activityData && rulesData) ||
        ((variant === NdcPageVariant.READ || variant === NdcPageVariant.CLONE) &&
            noteDeCoupureData &&
            activityData &&
            rulesData)
    ) {
        return (
            <NoteDeCoupureForm
                data={
                    variant === NdcPageVariant.CREATE
                        ? {
                              variant,
                              activityData,
                          }
                        : {
                              variant,
                              noteDeCoupureData: noteDeCoupureData!, // Cast
                              activityData,
                              idOrNdcNumber: String(documentIdOrNumber),
                          }
                }
                rules={rulesData}
                refreshNoteDeCoupureData={refreshNoteDeCoupureData}
            />
        );
    } else {
        return <Fragment />;
    }
};

import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface DialogInputLayoutProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
    onCancel: () => void;
    onBlur?: () => void;
    children: ReactNode;
    title?: string;
    readOnly?: boolean;
    maxWidth?: Breakpoint;
}

export const DialogInputLayout: React.FC<DialogInputLayoutProps> = ({
    open,
    setOpen,
    onConfirm,
    onCancel,
    onBlur,
    children,
    title,
    readOnly,
    maxWidth,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onBlur ? onBlur : () => setOpen(false)}
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : 'md'}
        >
            <DialogTitle>
                {title || (
                    <FormattedMessage
                        id={!readOnly ? 'wdp.spreadsheet.setValue.title' : 'wdp.spreadsheet.readValue.title'}
                    />
                )}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} sx={{ color: grey[600] }}>
                    <FormattedMessage id={!readOnly ? 'common.cancel' : 'common.close'} />
                </Button>
                {!readOnly && (
                    <Button onClick={onConfirm}>
                        <FormattedMessage id="common.confirm" />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

import {
    DimrVersionGridActivityReadDTO,
    DimrVersionReadDTO,
    PersonRichReadDTO,
    WdpRpAssessmentReadDTO,
    WdpVersionReadDTO,
    WdpVersionUpdateDTO,
} from '../../../api/dto';

export const makeWdpDefaultValues = () =>
    ({
        availableActions: {
            canSave: true,
            canCancel: false,
            canReopen: false,
            showAsPerformedTab: false,
        },
        cancelled: false,
        wdpEditors: [],
        wdpFacilities: [],
        wdpParticipants: [],
        wdpPerformedTasks: [],
        wdpRpAssessments: [],
        wdpWorkSteps: [],
        wdpWorkingPositions: [],
        wdpWorkingTeams: [],
    } satisfies Partial<WdpVersionReadDTO>);

export const mapWdpToUpdateDTO = (wdp: WdpVersionReadDTO & WdpVersionUpdateDTO, isCreation: boolean) => {
    return wdp;
};

export const makeWdpRpAssessmentsValuesFromDimr = (
    linkedDimr: DimrVersionReadDTO | DimrVersionGridActivityReadDTO
): WdpRpAssessmentReadDTO[] =>
    linkedDimr.rpAssessments
        .map((rpa, i) => ({
            assessmentsIdx: i,
            avgEstDoseRate: rpa.avgEstDoseRate,
            estCollectiveDose: rpa.estCollectiveDose,
            maxEstDoseRate: rpa.maxEstDoseRate,
            maxEstIndividualDose: rpa.maxEstIndividualDose,
            maxIndividualWorkTime: rpa.maxIndividualWorkTime,
            totalCollectiveWorkTime: rpa.totalCollectiveWorkTime,
            rpAssessment: rpa,
            wdpWorkStepIdxs: rpa.wdpWorkSteps,
        }))
        .sort((a, b) => a.rpAssessment.activity.id - b.rpAssessment.activity.id);

export const makeWdpDefaultValuesFromLinkedDimr = (linkedDimr: DimrVersionReadDTO, loggedUser: PersonRichReadDTO) => {
    return {
        dimr: { ...linkedDimr },
        title: linkedDimr.title ? linkedDimr.title : '',
        wdpFacilities: linkedDimr.mainFacility ? [linkedDimr.mainFacility] : [],
        wdpRpAssessments: makeWdpRpAssessmentsValuesFromDimr(linkedDimr),
    } satisfies Partial<WdpVersionReadDTO>;
};

export const makeWdpDefaultValuesFromClonedWdp = (wdpData: WdpVersionReadDTO, loggedUser: PersonRichReadDTO) => {
    return {
        ...wdpData,
        availableActions: {
            canSave: true,
            canCancel: false,
            canReopen: false,
            showAsPerformedTab: false,
        },
        dimr: null,
        cancelled: false,
        wdpPerformedTasks: [],
        wdpRpAssessments: [],
    };
};

import { InterventionPeriodNimReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchInterventionPeriodNames, fetchInterventionPeriodsByFacility } from '../../lib/api';
import { AsyncAutocompleteFetchOptions, FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface InterventionPeriodTypePropertiesParameters {
    multiple?: boolean;
    fetchEndpoint?: AsyncAutocompleteFetchOptions<InterventionPeriodNimReadDTO>;
}

const interventionPeriodTypeProperties = ({
    multiple,
    fetchEndpoint,
}: InterventionPeriodTypePropertiesParameters): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchEndpoint ?? fetchInterventionPeriodsByFacility,
    watchPaths: ['facility'],
    fetchUseCases: [FetchUseCase.ON_OPEN],
    getOptionLabel: (value) => value.periodName,
    multiple,
});

interface InterventionPeriodSchemaParameters
    extends InterventionPeriodTypePropertiesParameters,
        Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const interventionPeriodSchema = ({
    path,
    label,
    multiple,
    required,
}: InterventionPeriodSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: interventionPeriodTypeProperties({ multiple }),
});

interface InterventionPeriodFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const interventionPeriodFilterSchema = ({
    id,
    label,
}: InterventionPeriodFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value) => value.periodName,
    typeProperties: {
        ...interventionPeriodTypeProperties({ fetchEndpoint: fetchInterventionPeriodNames }),
        fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
        autocompleteProps: {
            disableCloseOnSelect: false,
            forcePopupIcon: false,
        },
    },
});

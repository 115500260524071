import { SubdirectoryArrowRight } from '@mui/icons-material';
import {
    Stack,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, Fragment } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { LocationDTO } from '../api/dto';
import { translateValue } from '../lib/language';

const TableCell = styled(MuiTableCell)({
    borderBottom: 'none',
    color: 'white',
});

type InfoEntry = {
    labelKey: MessageDescriptor['id'];
    value: React.ReactNode;
    large?: boolean;
};

interface LocationInfoCardProps {
    location: LocationDTO;
}
export const LocationInfoCard: FC<LocationInfoCardProps> = ({ location: location }) => {
    const intl = useIntl();
    const data: InfoEntry[] = [
        {
            labelKey: 'document.location.info.alias',
            value: location.impactAlias,
        },
        {
            labelKey: 'document.location.info.type',
            value: location.locationType,
        },
        {
            labelKey: 'document.location.info.building',
            value: location.buildingNumber,
        },
        {
            labelKey: 'document.location.info.selectable',
            value: intl.formatMessage({ id: location.selectable ? 'common.yes' : 'common.no' }),
        },
        {
            labelKey: 'document.location.info.facility',
            value: location.facility != null ? translateValue(location.facility, intl.locale).label : '',
        },
        {
            labelKey: 'document.location.info.highestAreaClassification',
            value:
                location.highestAreaClassification != null
                    ? translateValue(location.highestAreaClassification, intl.locale).label
                    : '',
        },
        {
            labelKey: 'document.location.info.accessPoints',
            value:
                location.accessPoints && location.accessPoints?.length > 0 ? (
                    location.accessPoints.map((accessPoint) => (
                        <Typography variant="body2" sx={{ ml: 2 }} key={accessPoint.name}>
                            {accessPoint.name}
                        </Typography>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        <FormattedMessage id="document.location.noAccessPoints" />
                    </Typography>
                ),
            large: true,
        },
        {
            labelKey: 'document.location.info.path',
            value: (
                <Stack direction="row" spacing={0.5} sx={{ ml: 2 }}>
                    {location.node?.path?.map((parent, idx, paths) => {
                        const icon = idx < paths.length - 1 ? <SubdirectoryArrowRight fontSize="small" /> : null;
                        return (
                            <Fragment key={idx}>
                                <Typography variant="body2">{parent}</Typography>
                                {icon}
                            </Fragment>
                        );
                    })}
                </Stack>
            ),
            large: true,
        },
    ];
    return (
        <TableContainer sx={{ py: 1 }}>
            <Table size="small">
                <TableBody>
                    {data.map(({ labelKey, value, large }, i) => (
                        <TableRow key={i}>
                            <TableCell colSpan={large ? 2 : undefined} sx={{ py: 0 }}>
                                <Typography variant="subtitle2">
                                    <FormattedMessage
                                        id="common.labelValue"
                                        values={{ label: intl.formatMessage({ id: labelKey }), value: large && value }}
                                    />
                                </Typography>
                            </TableCell>
                            {!large && (
                                <TableCell sx={{ py: 0 }}>
                                    <Typography variant="body2">{value}</Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

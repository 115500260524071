import { Assessment } from '@mui/icons-material';
import React from 'react';
import { getRoute, ROUTES } from '../../routes/routes';
import { DocumentButtonsStack } from './DocumentButtonsStack';

interface DoseReportButtonProps {
    dimrId?: number;
    activityId?: number;
    activityClusterId?: number;
}

export const DoseReportButton: React.FC<DoseReportButtonProps> = (props) => {
    return (
        <DocumentButtonsStack.Action
            icon={<Assessment />}
            titleKey="dimr.action.doseReport"
            onClick={() => {
                window.open(
                    getRoute({
                        path: ROUTES.radiationDoses,
                        queryParams: { ...props },
                    })
                );
            }}
        />
    );
};

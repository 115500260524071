import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { updateDocumentAttachments } from '../attachments';
import { backendClient as client } from '../clients';
import {
    CommentUpdateDTO,
    ListOfValuesReadDTO,
    PersonReadDTO,
    VicContextReadDTO,
    VicGridReadDTO,
    VicReadDTO,
    VicUpdateDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const VIC_PATH = 'vic';

export const getVic: GetOne<{ id: string }, VicReadDTO> = async ({ id, config }) =>
    client.get(buildPath([VIC_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getVics: GetManyPaged<VicGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getVicStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getVicRiskTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'risk-types']), config).then((response: AxiosResponse) => response.data);

export const getVicApplicability: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'applicabilities']), config).then((response: AxiosResponse) => response.data);

export const getVicDistributionTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'distribution-types']), config).then((response: AxiosResponse) => response.data);

export const getVicSafetyDocs: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'safety-docs']), config).then((response: AxiosResponse) => response.data);

export const getVicParticipantCompanyTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'participant-company-types']), config)
        .then((response: AxiosResponse) => response.data);

export const getVicParticipantRoles: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'participant-roles']), config).then((response: AxiosResponse) => response.data);

export const getPersonsTso: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'tso']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getPersonsHse: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'hse']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getPersonsEros: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'eros']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getPersonsLR: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'location-responsible']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const vicAttachmentsApi = updateDocumentAttachments(VIC_PATH);

export const createVic: CreateOne<{ data: VicUpdateDTO }, VicReadDTO> = async ({ data, config }) =>
    client.post(buildPath([VIC_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateVic: UpdateOne<{ id: number; taskId?: string; data: VicUpdateDTO }, VicReadDTO> = async ({
    id,
    taskId,
    data,
    config,
}) =>
    client
        .put(buildPath([VIC_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitVic: UpdateOne<{ id: number; data: VicUpdateDTO }, VicReadDTO> = async ({ id, data, config }) =>
    client.put(buildPath([VIC_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitVic: UpdateOne<{ id: number; data: VicUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([VIC_PATH, id, 'validate']), data, config);

export const getVicValidationRules: GetOne<{ id: string | undefined }, PreciseRulesDTO<VicContextReadDTO>> = async ({
    id,
    config,
}) =>
    client
        .get(buildPath([VIC_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const cancelVic: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([VIC_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const getVicPdf: GetOne<{ id: number; langCode: string }, Blob> = async ({ id, langCode, config }) =>
    client
        .get(buildPath([VIC_PATH, id, 'pdf']), { ...config, params: { langCode }, responseType: 'blob' })
        .then((response: AxiosResponse) => response.data);

import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { updateDocumentAttachments } from '../attachments';
import { backendClient as client } from '../clients';
import {
    CommentUpdateDTO,
    DimrSuggestedValuesDTO,
    DimrVersionContextReadDTO,
    DimrVersionGridActivityReadDTO,
    DimrVersionGridReadDTO,
    DimrVersionReadDTO,
    DimrVersionUpdateDTO,
    ListOfValuesReadDTO,
    PersonReadDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const DIMR_PATH = 'dimr';
export const RPA_SUBPATH = 'rpa';

export const getDimrVersion: GetOne<{ id: string; versionNumber: number }, DimrVersionReadDTO> = async ({
    id,
    versionNumber,
    config,
}) => client.get(buildPath([DIMR_PATH, id, versionNumber]), config).then((response: AxiosResponse) => response.data);

export const getDimrByVersionId: GetOne<{ id: string }, DimrVersionReadDTO> = async ({ id, config }) =>
    client.get(buildPath([DIMR_PATH, 'version', id]), config).then((response: AxiosResponse) => response.data);

export const getDimrVersionLatest: GetOne<{ id: string }, DimrVersionReadDTO> = async ({ id, config }) =>
    client.get(buildPath([DIMR_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getDimrVersions: GetManyPaged<DimrVersionGridReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([DIMR_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getDimrVersionsWithActivities: GetManyPaged<DimrVersionGridActivityReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([DIMR_PATH, 'activities']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getDimrStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);
export const getGeneralJobCodes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'general-job-codes']), config).then((response: AxiosResponse) => response.data);
export const getEquipmentJobCodes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'equipment-job-codes']), config).then((response: AxiosResponse) => response.data);

export const getDimrAlaraLevels: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'alara-levels']), config).then((response: AxiosResponse) => response.data);

export const getDimrRequirementPeriods: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'requirement-periods']), config).then((response: AxiosResponse) => response.data);

export const getDimrAreaClassification: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'area-classification']), config).then((response: AxiosResponse) => response.data);

export const createDimr: CreateOne<{ data: DimrVersionUpdateDTO }, DimrVersionReadDTO> = async ({ data, config }) =>
    client.post(buildPath([DIMR_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateDimr: UpdateOne<
    { id: number; taskId: string; data: DimrVersionUpdateDTO },
    DimrVersionReadDTO
> = async ({ id, taskId, data, config }) =>
    client
        .put(buildPath([DIMR_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const getDimrContext: RequestMethod<
    { dimr: DimrVersionUpdateDTO & Pick<DimrVersionReadDTO, 'id'>; taskId: string },
    PreciseRulesDTO<DimrVersionContextReadDTO>
> = async ({ dimr, taskId, config }) =>
    client
        .post(buildPath([DIMR_PATH, 'context']), dimr, { ...config, params: { id: dimr.id, taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitDimr: UpdateOne<{ id: number; data: DimrVersionUpdateDTO }, DimrVersionReadDTO> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([DIMR_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitDimr: UpdateOne<{ id: number; data: DimrVersionUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([DIMR_PATH, id, 'validate']), data, config);
export const cancelDimr: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([DIMR_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const requestFeedback: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client
        .post(buildPath([DIMR_PATH, id, 'request-feedback']), config)
        .then((response: AxiosResponse) => response.data);

export const requestClosure: RequestMethod<{ id: number }, any> = async ({ id, config }) =>
    client.post(buildPath([DIMR_PATH, id, 'request-closure']), config).then((response: AxiosResponse) => response.data);
export const getDimrValidationRules: GetOne<
    { id: number | undefined },
    PreciseRulesDTO<DimrVersionContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([DIMR_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const createNewDimrVersion: UpdateOne<{ dimrVersionId: number }, DimrVersionReadDTO> = async ({
    dimrVersionId,
    config,
}) => client.post(buildPath([DIMR_PATH, dimrVersionId]), config).then((response: AxiosResponse) => response.data);

export const getPersonsRso: GetManyUnpaged<{ facilityCode: string; ownerId: number }, PersonReadDTO> = async ({
    facilityCode,
    ownerId,
    config,
}) =>
    client
        .get(buildPath([DIMR_PATH, 'rso', facilityCode, ownerId]), config)
        .then((response: AxiosResponse) => response.data);

export const getPersonsRpo: GetManyUnpaged<{}, PersonReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'rpo']), config).then((response: AxiosResponse) => response.data);

export const getPersonsRssoRpeByFacility: GetManyUnpaged<
    { facilityCode: string; ownerId: number },
    PersonReadDTO
> = async ({ facilityCode, ownerId, config }) =>
    client
        .get(buildPath([DIMR_PATH, 'rsso-rpe', facilityCode, ownerId]), config)
        .then((response: AxiosResponse) => response.data);

export const getPersonsRssoRpeByActivity: GetManyUnpaged<{ activityId: number }, PersonReadDTO> = async ({
    activityId,
    config,
}) =>
    client
        .get(buildPath([DIMR_PATH, 'rp-assessment', 'rsso-rpe', activityId]), config)
        .then((response: AxiosResponse) => response.data);

export const getSuggestedValues: GetOne<{ activityId: string }, DimrSuggestedValuesDTO> = async ({
    activityId,
    config,
}) =>
    client
        .get(buildPath([DIMR_PATH, 'suggested-values', activityId]), config)
        .then((response: AxiosResponse) => response.data);

export const dimrAttachmentsApi = updateDocumentAttachments(DIMR_PATH);

export const rpaAttachmentsApi = updateDocumentAttachments(DIMR_PATH, RPA_SUBPATH);

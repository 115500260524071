import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import React from 'react';
import { useDeviceType } from '../hooks/useDeviceType';

export interface ResponsiveReadOnlyDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    isLoading?: boolean;
    keepMounted?: boolean;
    ref?: React.RefObject<any>;
    children: React.ReactNode;
}

export const ResponsiveReadOnlyDialog: React.FC<ResponsiveReadOnlyDialogProps> = ({
    open,
    onClose,
    title,
    isLoading,
    keepMounted,
    ref,
    children,
}) => {
    const { isMobile } = useDeviceType();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            keepMounted={keepMounted}
            fullScreen={isMobile}
            fullWidth
            maxWidth="xl"
            PaperProps={{ sx: { height: !isMobile ? '80%' : undefined, overflow: 'hidden' } }}
        >
            <DialogTitle>
                {title}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    p: 0,
                    overflow: 'hidden',
                    mb: -0.2, // Otherwise there is a tiny white line at the bottom
                }}
            >
                <Box sx={{ height: '100%' }}>
                    {isLoading && <LinearProgress />}
                    <div
                        ref={ref}
                        style={{
                            height: '100%',
                            marginTop: isLoading ? -4 : 0 /*, opacity: isLoading ? 0 : undefined */,
                        }}
                    >
                        {children}
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

import { Box, Button, ButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

type DashedButtonProps = Omit<ButtonProps, 'variant'>;

export const DashedButton: React.FC<DashedButtonProps> = ({ sx, ...rest }) => {
    const addFieldBorderRadius = 1;
    const addFieldBorderStyle = {
        border: 1,
        borderColor: grey[400],
        borderRadius: addFieldBorderRadius,
        p: 2,
        borderStyle: 'dashed',
    };
    return (
        <Box
            sx={{
                ...addFieldBorderStyle,
                borderStyle: 'dashed',
                padding: 0,
            }}
        >
            <Button
                sx={{
                    width: '100%',
                    //makes sure that the shadow on hover stays inside the box dashed border
                    borderRadius: addFieldBorderRadius,
                    ...sx,
                }}
                {...rest}
            ></Button>
        </Box>
    );
};

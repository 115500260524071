import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { DataPointReadDTO, DocumentType, ListOfValuesWithCountReadDTO } from './dto';
import { GetManyUnpaged, RequestMethod } from './types';

const RESOURCE_PATH = 'statistics';

export const getDocumentsCreated: GetManyUnpaged<{}, DataPointReadDTO<DocumentType>> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'created']), { ...config }).then((response: AxiosResponse) => response.data);

export const getDocumentsStatuses: RequestMethod<
    {},
    Partial<Record<DocumentType, Record<string, ListOfValuesWithCountReadDTO>>>
> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'statuses']), { ...config }).then((response: AxiosResponse) => response.data);

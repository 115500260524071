import { Close, FileUpload } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListSubheader,
    Stack,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AttachmentDTO, AttachmentUploadDTO } from '../../api/dto';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useConfirmationDialog } from '../ConfirmationDialogContext';
import { DashedButton } from '../DashedButton';
import { AttachmentDetails } from './AttachmentDetails';
import { AttachmentListEntry } from './AttachmentListEntry';
import { AttachmentUploadWidget } from './AttachmentUploadWidget';

interface AttachmentsWidgetProps {
    open: boolean;
    onClose: () => void;
    initialAttachments: AttachmentDTO[];
    onLink?: (attachments: AttachmentDTO[]) => void;
    onUnlink?: (attachments: AttachmentDTO[]) => void;
    updateAttachment: (args: { attachmentId: number; data: AttachmentUploadDTO }) => Promise<AttachmentDTO>;
    readOnly?: boolean;
}
export const AttachmentsWidget: React.FC<AttachmentsWidgetProps> = ({
    open,
    onClose,
    initialAttachments,
    onLink = () => {},
    onUnlink = () => {},
    updateAttachment,
    readOnly = false,
}) => {
    const [attachments, setAttachments] = useState<AttachmentDTO[]>(initialAttachments);
    const [selectedAttachment, setSelectedAttachment] = useState<AttachmentDTO | null>(attachments[0] ?? null);
    const [uploadOpen, setUploadOpen] = useState(false);

    const { isMobile } = useDeviceType();
    const unlinkAttachmentConfirmation = useConfirmationDialog();
    const intl = useIntl();

    const handleAttachmentRemoval = (attachment: AttachmentDTO) => {
        unlinkAttachmentConfirmation({
            prompt: intl.formatMessage({ id: 'document.attachment.confirmation.delete' }),
            onConfirm: () => {
                setAttachments(attachments.filter((e) => e.id !== attachment.id));
                onUnlink([attachment]);
            },
        });
    };

    const handleAttachmentUpload = (newAttachment: AttachmentDTO) => {
        setAttachments([...attachments, newAttachment]);
        setSelectedAttachment(newAttachment);
        onLink([newAttachment]);
    };

    useEffect(() => {
        if (attachments.every((a) => a.id !== selectedAttachment?.id)) {
            setSelectedAttachment(attachments[0] ?? null);
        }
    }, [attachments, selectedAttachment]);

    return (
        <Dialog open={open} onClose={onClose} fullScreen={isMobile} fullWidth maxWidth="lg">
            <DialogTitle>
                <FormattedMessage id="document.attachment.title" />
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={attachments.length > 0 ? 7 : undefined}>
                        <Stack direction="column" spacing={1}>
                            {attachments.length > 0 ? (
                                <List
                                    subheader={
                                        <ListSubheader>
                                            <FormattedMessage id="document.attachment.files" />
                                        </ListSubheader>
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {attachments.map((attachment) => (
                                        <AttachmentListEntry
                                            key={attachment.id}
                                            attachment={attachment}
                                            onSelection={(newAttachment) => setSelectedAttachment(newAttachment)}
                                            onRemoval={handleAttachmentRemoval}
                                            onUpdate={(newValue) => {
                                                setAttachments(
                                                    attachments.map((obj) => (obj === attachment ? newValue : obj))
                                                );
                                                if (selectedAttachment === attachment) {
                                                    setSelectedAttachment(newValue);
                                                }
                                            }}
                                            updateAttachment={updateAttachment}
                                            selected={selectedAttachment?.id === attachment.id}
                                            readOnly={readOnly}
                                        />
                                    ))}
                                </List>
                            ) : (
                                <Stack direction="row" justifyContent="center">
                                    <Typography sx={{ my: 2 }}>
                                        <FormattedMessage id="document.attachment.empty" />
                                    </Typography>
                                </Stack>
                            )}
                            {!readOnly && (
                                <DashedButton onClick={() => setUploadOpen(true)} startIcon={<FileUpload />}>
                                    <FormattedMessage id="document.attachment.action.upload" />
                                </DashedButton>
                            )}
                        </Stack>
                    </Grid>
                    {attachments.length > 0 && (
                        <Grid item xs={0} sm={5}>
                            <AttachmentDetails attachment={selectedAttachment} />
                        </Grid>
                    )}
                </Grid>

                <AttachmentUploadWidget
                    open={uploadOpen}
                    onClose={() => setUploadOpen(false)}
                    onSubmit={handleAttachmentUpload}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} startIcon={<Close />} sx={{ color: grey[600] }}>
                    <FormattedMessage id="common.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

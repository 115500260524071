import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { getActivity } from '../../../api/activities';
import { getLockout, getLockoutType, getLockoutValidationRules } from '../../../api/documents/lockouts';
import { DocumentType, LockoutTypeCode } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { LockoutForm } from '../../../forms/documents/LockoutForm';
import { LockoutPageVariant } from './LockoutPageVariant';

interface LockoutPageProps {
    variant: LockoutPageVariant;
}

export const LockoutPage: React.FC<LockoutPageProps> = ({ variant }) => {
    const intl = useIntl();

    const { id: lockoutId } = useParams();
    const [params] = useSearchParams();

    const {
        data: lockoutData,
        isLoading: lockoutDataLoading,
        isError: lockoutDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.LOK, lockoutId], () => getLockout({ id: String(lockoutId) }), {
        enabled: lockoutId !== undefined,
    });

    const activityId =
        variant === LockoutPageVariant.READ || variant === LockoutPageVariant.CLONE
            ? lockoutData?.activity.id
            : String(params.get('activity'));

    const lockoutTypeCode =
        variant === LockoutPageVariant.READ || variant === LockoutPageVariant.CLONE
            ? lockoutData?.lockoutType.code
            : (String(params.get('type')) as LockoutTypeCode);

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: lockoutTypeData,
        isLoading: isLockoutTypeLoading,
        isError: lockoutTypeDataFailed,
    } = useQuery(['lockoutType', lockoutTypeCode], () => getLockoutType({ lockoutTypeCode: String(lockoutTypeCode) }), {
        enabled: lockoutTypeCode !== undefined,
    });

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.LOK, lockoutId, 'rules'], () =>
        getLockoutValidationRules({ id: variant === LockoutPageVariant.CLONE ? undefined : lockoutId })
    );

    if (
        (lockoutDataLoading && lockoutId !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        (isLockoutTypeLoading && lockoutTypeCode !== undefined) ||
        isRulesDataLoading
    ) {
        return <LinearProgress />;
    } else if (lockoutDataFailed || activityDataFailed || lockoutTypeDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="lockout.error.document" />
                <ImpactHelmet documentType={DocumentType.LOK} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === LockoutPageVariant.CREATE && activityData && lockoutTypeData) ||
        ((variant === LockoutPageVariant.READ || variant === LockoutPageVariant.CLONE) && lockoutData && activityData)
    ) {
        return (
            <LockoutForm
                data={
                    variant === LockoutPageVariant.CREATE
                        ? { variant, activityData: activityData!, lockoutType: lockoutTypeData! }
                        : { variant, lockoutData: lockoutData!, activityData }
                }
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};

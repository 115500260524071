import { Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { useIntl } from 'react-intl';
import { FilterOperatorDef, filterOperatorDefs, FilterTypeEnum } from '../../components/searching/filters';
import { FormGenerator } from '../FormGenerator';
import { FilterGroupElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { injectCurrentPath } from '../utils';
import { AutocompleteDefaultOption } from './AutocompleteElement';

interface FilterGroupProps {
    form: UseFormReturn;
    element: FilterGroupElement;
}
export const FilterGroup: React.FC<FilterGroupProps> = ({ form, element }) => {
    const intl = useIntl();
    const { elements, path } = element;

    const watchFieldElement = form.watch(`${path}.field`);
    const watchOperatorElement = form.watch(`${path}.operator`);
    const watchValueElement = form.watch(`${path}.value`);

    const fieldSelected = useMemo(
        () => elements.find((el: AutocompleteDefaultOption) => el.id === watchFieldElement),
        [watchFieldElement]
    );

    const localizedFilterOperatorDefs = useMemo(() => filterOperatorDefs(intl), [intl]);

    const operatorDefs = fieldSelected
        ? localizedFilterOperatorDefs[fieldSelected.type as FilterTypeEnum]
        : ([] as FilterOperatorDef[]);

    const operatorSelected: FilterOperatorDef | null = useMemo(
        () => (operatorDefs ? operatorDefs.find((el) => el.id === watchOperatorElement) ?? null : null),
        [operatorDefs, watchOperatorElement]
    );

    const fieldElement: TFormElement | null = !watchFieldElement
        ? {
              type: UiSchemaType.AUTOCOMPLETE,
              label: intl.formatMessage({ id: 'search.filters.filterBy' }),
              path: 'field',
              typeProperties: {
                  options: elements.map((el: AutocompleteDefaultOption) => ({ id: el.id, label: el.label })),
                  matchId: true,
                  autocompleteProps: {
                      openOnFocus: true,
                  },
                  textFieldProps: {
                      placeholder: intl.formatMessage({ id: 'search.filters.selectFieldPlaceholder' }),
                  },
              },
          }
        : null;

    useEffect(() => {
        if (watchFieldElement && !watchOperatorElement) {
            form.setFocus(`${path}.operator`);
        }
    }, [watchFieldElement, watchOperatorElement, path]);

    const operatorElement: TFormElement | null =
        fieldSelected && operatorDefs && watchFieldElement && !watchOperatorElement
            ? {
                  type: UiSchemaType.AUTOCOMPLETE,
                  path: 'operator',
                  label: fieldSelected?.label,
                  typeProperties: {
                      options: operatorDefs,
                      matchId: true,
                      autocompleteProps: {
                          openOnFocus: true,
                      },
                      textFieldProps: {
                          placeholder: intl.formatMessage({ id: 'search.filters.selectOperatorPlaceholder' }),
                      },
                  },
              }
            : null;

    useEffect(() => {
        if (watchOperatorElement && !watchValueElement && operatorSelected?.valueElementRequired !== false) {
            form.setFocus(`${path}.value`);
        }
    }, [watchOperatorElement, watchValueElement, path, operatorSelected]);

    const valueElement =
        fieldSelected && watchOperatorElement && operatorSelected !== null
            ? {
                  type: operatorSelected.valueElement,
                  path: 'value',
                  label: `${fieldSelected?.label} ${operatorSelected?.label}`,
                  ...(operatorSelected.valueElement === UiSchemaType.CUSTOM
                      ? {
                            Component: () => (
                                <Typography>{`${fieldSelected.label} ${operatorSelected.label}`}</Typography>
                            ),
                        }
                      : {
                            typeProperties: {
                                ...fieldSelected.typeProperties,
                                ...operatorSelected.valueElementProps,
                            },
                        }),
              }
            : null;
    const activeElement = fieldElement || operatorElement || valueElement;
    return (
        <Stack direction="column" justifyContent="center" sx={{ height: '100%' }}>
            <FormGenerator
                key={activeElement?.path}
                form={form}
                rootElement={injectCurrentPath(element)(activeElement as TFormElement)}
            />
        </Stack>
    );
};

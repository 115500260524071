import { LockoutStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const LockoutStatusColorMapping: Record<LockoutStatusCode, StatusColor> = {
    [LockoutStatusCode.NEW]: StatusColor.PRIMARY,
    [LockoutStatusCode.SAVED]: StatusColor.PRIMARY,
    [LockoutStatusCode.SUBMITTED]: StatusColor.SUCCESS,
    [LockoutStatusCode.WAITING_LOCKOUT_APPROVAL]: StatusColor.WARNING,
    [LockoutStatusCode.LOCKOUT_APPROVED]: StatusColor.SUCCESS,
    [LockoutStatusCode.WAITING_UNLOCK_APPROVAL]: StatusColor.WARNING,
    [LockoutStatusCode.FINISHED]: StatusColor.NORMAL,
    [LockoutStatusCode.REJECTED]: StatusColor.ERROR,
    [LockoutStatusCode.CANCELLED]: StatusColor.ERROR,
    [LockoutStatusCode.WAITING_FOR_APPROVAL]: StatusColor.WARNING,
    [LockoutStatusCode.LOCKED]: StatusColor.SUCCESS,
};

import { Info } from '@mui/icons-material';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export enum MissingStateReasonEnum {
    EMPTY = 'EMPTY',
    ERROR = 'ERROR',
}

const defaultMessageKeys: Record<MissingStateReasonEnum, MessageDescriptor['id']> = {
    [MissingStateReasonEnum.EMPTY]: 'search.empty',
    [MissingStateReasonEnum.ERROR]: 'search.error',
};
const defaultColors: Record<MissingStateReasonEnum, 'disabled' | 'error'> = {
    [MissingStateReasonEnum.EMPTY]: 'disabled',
    [MissingStateReasonEnum.ERROR]: 'error',
};

interface EmptyStateProps {
    sx?: SxProps<Theme>;
    reason?: MissingStateReasonEnum;
    message?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ sx, reason = MissingStateReasonEnum.EMPTY, message }) => {
    const defaultMessageKey = defaultMessageKeys[reason];
    return (
        <Stack
            spacing={1}
            sx={{
                display: 'flex',
                flex: 1,
                height: '100%',
                width: '100%',
                alignItems: 'center',
                ...sx,
            }}
        >
            <Info color={defaultColors[reason]} />
            <Typography color="textSecondary" variant="caption">
                {message || <FormattedMessage id={defaultMessageKey} />}
            </Typography>
        </Stack>
    );
};

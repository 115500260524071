import { NdcStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const NdcStatusColorMapping: Record<NdcStatusCode, StatusColor> = {
    [NdcStatusCode.SAVED]: StatusColor.PRIMARY,
    [NdcStatusCode.WAITING_FOR_APPROVAL]: StatusColor.WARNING,
    [NdcStatusCode.APPROVED]: StatusColor.SUCCESS,
    [NdcStatusCode.DISTRIBUTED]: StatusColor.SUCCESS,
    [NdcStatusCode.IN_PROGRESS]: StatusColor.SUCCESS,
    [NdcStatusCode.REJECTED]: StatusColor.ERROR,
    [NdcStatusCode.CANCELLED]: StatusColor.ERROR,
    [NdcStatusCode.WAITING_NOTIFY_CANCEL]: StatusColor.ERROR,
    [NdcStatusCode.FINISHED]: StatusColor.NORMAL,
    [NdcStatusCode.WAITING_NOTIFY_FINISH]: StatusColor.NORMAL,
};

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, LinearProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListOfValuesReadDTO } from '../../api/dto';
import { getClasses, getValuesByClass } from '../../api/values';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { StripedTableRow } from '../../components/StripedTableRow';
import { FetchUseCase } from '../../forms/fields/AsyncAutocompleteElement';
import { FormGenerator } from '../../forms/FormGenerator';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { Locale } from '../../i18n/Locale';
import { translateValue } from '../../lib/language';

interface FormValues {
    className: string | undefined;
}

export const LocalizationValuePage: React.FunctionComponent = () => {
    const intl = useIntl();
    const theme = useTheme();
    const schema: TFormElement = useMemo(
        () => ({
            type: UiSchemaType.AUTOCOMPLETE_ASYNC,
            path: 'className' satisfies keyof FormValues,
            label: intl.formatMessage({ id: 'document.field.type' }),
            required: true,
            typeProperties: {
                idField: 'code' satisfies keyof ListOfValuesReadDTO,
                fetchOptions: () => getClasses(),
                fetchUseCases: [FetchUseCase.ON_LOAD],
                getOptionLabel: (value) => value,
            },
        }),
        [intl]
    );
    const form = useForm<FormValues>();
    const className = form.watch('className');
    const { isLoading, data } = useQuery(['classes', className], () => getValuesByClass({ className: className! }), {
        enabled: className !== undefined,
    });
    const locales: Locale[] = ['en', 'fr'];
    return (
        <Stack direction="column">
            <ImpactHelmet title={intl.formatMessage({ id: 'meta.localization.values.title' })} />
            <Box p={2}>
                <FormGenerator rootElement={schema} form={form} />
            </Box>
            {className === undefined || data ? (
                data ? (
                    <Table stickyHeader>
                        <TableHead
                            sx={{
                                position: 'sticky',
                                top: 0,
                            }}
                        >
                            <TableRow>
                                <TableCell align="center" colSpan={3}></TableCell>
                                <TableCell align="center" colSpan={2}>
                                    <FormattedMessage id="language.english" />
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    <FormattedMessage id="language.french" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">
                                    <FormattedMessage id="meta.localization.values.code" />
                                </TableCell>
                                <TableCell align="center">
                                    <FormattedMessage id="meta.localization.values.visibility" />
                                </TableCell>
                                {new Array(2).fill(null).map((v, i) => (
                                    <Fragment key={i}>
                                        <TableCell align="center">
                                            <FormattedMessage id="meta.localization.values.label" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormattedMessage id="document.field.description" />
                                        </TableCell>
                                    </Fragment>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((v) => (
                                <StripedTableRow key={v.code}>
                                    <TableCell align="center">{v.orderInType}</TableCell>
                                    <TableCell align="center">
                                        <code>{v.code}</code>
                                    </TableCell>
                                    <TableCell align="center">
                                        {v.allowSelect === 'Y' ? (
                                            <Visibility color="action" />
                                        ) : (
                                            <VisibilityOff color="error" />
                                        )}
                                    </TableCell>
                                    {locales.map((locale) => {
                                        const { label, description } = translateValue(v, locale);
                                        const descriptionIndex = description.indexOf(label);
                                        const parts =
                                            descriptionIndex !== -1
                                                ? [
                                                      description.slice(0, descriptionIndex),
                                                      label,
                                                      description.slice(
                                                          descriptionIndex + label.length,
                                                          description.length
                                                      ),
                                                  ]
                                                : [description];
                                        const lightColor = theme.palette.grey.A400;
                                        return (
                                            <Fragment key={locale}>
                                                <TableCell align="center">{label}</TableCell>
                                                <TableCell align="center">
                                                    {parts.map((part, i) => (
                                                        <span
                                                            key={i}
                                                            style={{ color: i === 1 ? lightColor : undefined }}
                                                        >
                                                            {part}
                                                        </span>
                                                    ))}
                                                </TableCell>
                                            </Fragment>
                                        );
                                    })}
                                </StripedTableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : null
            ) : isLoading ? (
                <LinearProgress />
            ) : (
                <FormattedMessage id="common.error" />
            )}
        </Stack>
    );
};

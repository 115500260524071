import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { DocumentVersionReferenceDTO } from '../../api/dto';
import { DocumentIdentifier } from '../../lib/records/documents/document';

export type PageContextState =
    | {}
    | (Pick<DocumentIdentifier, 'documentType'> &
          ({ documentId?: undefined } | { documentId: number; documentVersion?: DocumentVersionReferenceDTO }));

type Id = number;

export interface PageContextValue {
    context: PageContextState;
    register: (context: PageContextState) => Id;
    unregister: (id: Id) => void;
}

export const PageContext = createContext<PageContextValue | undefined>(undefined);

export const usePageContext = () => {
    const value = useContext(PageContext);
    if (value === undefined) {
        throw new Error(); // Out of context
    }
    return value;
};

const DEFAULT_CONTEXT: PageContextState = {};

interface PageContextProviderProps {
    children: React.ReactNode;
}

export const PageContextProvider: React.FC<PageContextProviderProps> = ({ children }) => {
    const counter = useRef<number>(0);
    const [contexts, setContextsState] = useState<{ id: number; context: PageContextState }[]>([]);
    const contextsRef = useRef(contexts);

    const setContexts = useCallback(
        (newContexts: typeof contexts) => {
            contextsRef.current = contexts;
            setContextsState(newContexts);
        },
        [contextsRef, setContextsState]
    );
    const register = useCallback(
        (context: PageContextState) => {
            const id = counter.current++;
            setContexts([{ id, context }, ...contextsRef.current]);
            return id;
        },
        [counter, setContexts, contextsRef]
    );
    const unregister = useCallback(
        (id: number) => setContexts(contextsRef.current.filter(({ id: otherId }) => id !== otherId)),
        [setContexts, contextsRef]
    );
    const context = useMemo(() => contexts[0]?.context ?? DEFAULT_CONTEXT, [contexts]);
    const value: PageContextValue = useMemo(
        () => ({
            context,
            register,
            unregister,
        }),
        [context, register, unregister]
    );

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

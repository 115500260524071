import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { getActivity } from '../../../api/activities';
import { getVic, getVicRiskTypes, getVicValidationRules } from '../../../api/documents/vics';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { VicForm } from '../../../forms/documents/VicForm';
import { VicPageVariant } from './VicPageVariant';

interface VicPageProps {
    variant: VicPageVariant;
}

export const VicPage: React.FC<VicPageProps> = ({ variant }) => {
    const { id: vicId } = useParams();
    const [params] = useSearchParams();
    const intl = useIntl();

    const {
        data: vicData,
        isLoading: vicDataLoading,
        isError: vicDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.VIC, vicId], () => getVic({ id: String(vicId) }), {
        enabled: vicId !== undefined,
    });

    const {
        data: riskTypes,
        isLoading: riskTypesLoading,
        isError: riskTypesFailed,
    } = useQuery(['riskTypes'], () => getVicRiskTypes());

    const activityId = variant === VicPageVariant.CREATE ? String(params.get('activity')) : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: variant === VicPageVariant.CREATE && activityId !== undefined,
    });

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.VIC, vicId, 'rules'], () => getVicValidationRules({ id: vicId }));

    if (
        (vicDataLoading && vicId !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        isRulesDataLoading ||
        riskTypesLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.VIC} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (vicDataFailed || activityDataFailed || rulesDataFailed || riskTypesFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="vic.error.document" />
                <ImpactHelmet documentType={DocumentType.VIC} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === VicPageVariant.CREATE && activityData) ||
        (variant === VicPageVariant.READ && vicData) ||
        (variant === VicPageVariant.CLONE && vicData)
    ) {
        return (
            <VicForm
                data={
                    variant === VicPageVariant.CREATE
                        ? { variant, activityData: activityData! }
                        : { variant, vicData: vicData! }
                }
                rules={rulesData}
                refreshData={refreshData}
                riskTypes={riskTypes}
            />
        );
    } else {
        return <Fragment />;
    }
};

import CERNMap, { MapOptions } from 'cernmap';
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { LocationDTO } from '../api/dto';
import { MapDialog } from './MapDialog';

interface MapDialogContextValue {
    openDialog: (location: LocationDTO) => void;
}

const MapDialogContext = createContext<MapDialogContextValue | null>(null);

export const useMapDialogContext = (): MapDialogContextValue => {
    const context = useContext(MapDialogContext);
    if (context === null) {
        throw new Error();
    }
    return context;
};

const getMapOptions = (location: LocationDTO | null): MapOptions => ({
    location: location?.code,
    showLocationGraphic: true,
});

interface MapDialogContextProviderProps {
    children: React.ReactNode;
}

export const MapDialogContextProvider: React.FC<MapDialogContextProviderProps> = ({ children }) => {
    const [open, setOpen] = useState<boolean | null>(null); // null means the component was never mounted
    const [location, setLocation] = useState<LocationDTO | null>(null);
    const [isLoading, setLoading] = useState(false);
    const mapRef = useRef<CERNMap | null>();
    // Memoization for performance
    const value = useMemo<MapDialogContextValue>(
        () => ({
            openDialog: (newLocation: LocationDTO) => {
                setOpen(true);
                setLocation(newLocation);
                setLoading(true);
                mapRef.current?.display(getMapOptions(newLocation));
            },
        }),
        [setOpen, setLocation, mapRef]
    );
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const handleSetMap = useCallback((map: CERNMap | null) => (mapRef.current = map), [mapRef]);
    const handleLoaded = useCallback(() => setLoading(false), [setLoading]);
    const options = useMemo(() => getMapOptions(location), [location]);
    return (
        <MapDialogContext.Provider value={value}>
            {children}
            <MapDialog
                open={!!open}
                onClose={handleClose}
                setMap={handleSetMap}
                keepMounted={open !== null}
                isLoading={isLoading}
                onLoaded={handleLoaded}
                options={options}
                title={location?.impactName}
            />
        </MapDialogContext.Provider>
    );
};

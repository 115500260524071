import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AttachmentDTO } from '../../api/dto';
import { formatDateTime } from '../../lib/date';

interface AttachmentDetailsProps {
    attachment: AttachmentDTO | null;
}
export const AttachmentDetails: React.FC<AttachmentDetailsProps> = ({ attachment }) => {
    const intl = useIntl();
    if (attachment === null) return null;
    return (
        <>
            <Typography variant="h6">
                <FormattedMessage id="document.attachment.file.field.description" />
            </Typography>
            <Typography>{attachment.description}</Typography>
            <br />
            <Typography variant="h6">
                <FormattedMessage id="document.attachment.information.title" />
            </Typography>
            <Typography>
                <FormattedMessage
                    id="document.attachment.information.fileName"
                    values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        value: attachment.originalFileName,
                    }}
                />
            </Typography>
            <Typography>
                <FormattedMessage
                    id="document.attachment.information.fileSize"
                    values={{ b: (chunks) => <strong>{chunks}</strong>, value: attachment.fileSize }}
                />
            </Typography>
            <Typography>
                <FormattedMessage
                    id="document.attachment.information.mimeType"
                    values={{ b: (chunks) => <strong>{chunks}</strong>, value: attachment.mimeType }}
                />
            </Typography>
            <Typography>
                <FormattedMessage
                    id="document.attachment.information.uploadedBy"
                    values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        value: attachment.createdBy?.searchLabel,
                    }}
                />
            </Typography>
            <Typography>
                <FormattedMessage
                    id="document.attachment.information.uploadedDate"
                    values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                        value: attachment.createdDate ? formatDateTime(new Date(attachment.createdDate), intl) : null,
                    }}
                />
            </Typography>
        </>
    );
};

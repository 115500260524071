import { useMutation } from '@tanstack/react-query';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { toast } from 'react-toastify';
import { impersonateUser } from '../api/keycloak';
import { getOidcUserSessionKey } from '../lib/auth';
import { useAdminRole } from './useAdminRole';

export const useImpersonation = () => {
    const canImpersonateUsers = useAdminRole();
    const isCurrentlyImpersonating = window.sessionStorage.getItem(getOidcUserSessionKey(true)) !== null;
    const clearImpersonation = () => {
        window.sessionStorage.setItem(
            getOidcUserSessionKey(false),
            window.sessionStorage.getItem(getOidcUserSessionKey(true)) ?? ''
        );
        window.sessionStorage.removeItem(getOidcUserSessionKey(true));
    };
    const reloadApplication = () => {
        return window.location.reload();
    };
    const clearImpersonationAndReload = () => {
        clearImpersonation();
        reloadApplication();
    };

    const { user: unsafeUser } = useAuth();
    const user = unsafeUser!;
    const { mutate: impersonate, isLoading: isImpersonateLoading } = useMutation(
        ({ impersonatingUsername }: { impersonatingUsername: string | null; callback?: () => void }) => {
            const originalUserValue = window.sessionStorage.getItem(getOidcUserSessionKey(true));
            return impersonateUser({
                subjectToken:
                    (originalUserValue ? User.fromStorageString(originalUserValue).access_token : undefined) ??
                    user.access_token ??
                    '',
                requestedSubject: impersonatingUsername ?? '',
            });
        },
        {
            onSuccess: async (data, { callback }) => {
                const profile = JSON.parse(atob(data.access_token.split('.')[1]));
                const fullData = { ...data, expires_at: profile.exp, profile };
                // If the person is already impersonating someone, then keep it
                if (!window.sessionStorage.getItem(getOidcUserSessionKey(true))) {
                    window.sessionStorage.setItem(
                        getOidcUserSessionKey(true),
                        window.sessionStorage.getItem(getOidcUserSessionKey(false)) ?? ''
                    );
                }
                window.sessionStorage.setItem(getOidcUserSessionKey(false), JSON.stringify(fullData));
                if (callback) {
                    callback(); // <- not really useful since we're refreshing the page anyway
                }
                return reloadApplication();
            },
            onError: () => {
                toast.error('An error occurred while setting the impersonation');
            },
        }
    );

    return {
        canImpersonateUsers,
        isCurrentlyImpersonating,
        clearImpersonation,
        clearImpersonationAndReload,
        impersonate,
        isImpersonateLoading,
    };
};

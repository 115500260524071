import { ActivityNimReadDTO } from '../../../api/dto';

export const makeDefaultValuesFromClonedActivity = (originalActivity: ActivityNimReadDTO) => {
    const { id, version, ...rest } = originalActivity;
    return {
        ...rest,
        activityChangeRequests: [],
        activityTags: [],
        adamsAccessLogs: [],
        createdBy: null as any,
        createdDate: null as any,
        firePermits: [],
        is37s: [],
        rpAssessments: [],
        scheduledStart: null,
        scheduledEnd: null,
        accessStart: null,
        accessEnd: null,
        baselineStart: null,
        baselineEnd: null,
        interventionPeriod: null,
        workingTimeGrtdStart: null,
        workingTimeGrtdEnd: null,
        workingTimeGtd: null,
        workingTimeGrtdTxt: null,
        whenComments: null,
    } satisfies Omit<ActivityNimReadDTO, 'id' | 'version'>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import {
    CommentUpdateDTO,
    CryoEquipmentLocalReadDTO,
    ListOfValuesReadDTO,
    LockoutContextReadDTO,
    LockoutGridReadDTO,
    LockoutReadDTO,
    LockoutUpdateDTO,
    TaskPayloadCommentUpdateDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const LOCKOUT_PATH = 'lockout';

export const getLockout: GetOne<{ id: string }, LockoutReadDTO> = async ({ id, config }) =>
    client.get(buildPath([LOCKOUT_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getLockouts: GetManyPaged<LockoutGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([LOCKOUT_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getLockoutStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([LOCKOUT_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getLockoutTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([LOCKOUT_PATH, 'types']), config).then((response: AxiosResponse) => response.data);

export const getLockoutType: GetOne<{ lockoutTypeCode: string }, ListOfValuesReadDTO> = async ({
    lockoutTypeCode,
    config,
}) =>
    client
        .get(buildPath([LOCKOUT_PATH, 'types', lockoutTypeCode]), config)
        .then((response: AxiosResponse) => response.data);

export const createLockout: CreateOne<{ data: LockoutUpdateDTO }, LockoutReadDTO> = async ({ data, config }) =>
    client.post(buildPath([LOCKOUT_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateLockout: UpdateOne<{ id: number; taskId: string; data: LockoutUpdateDTO }, LockoutReadDTO> = async ({
    id,
    taskId,
    data,
    config,
}) =>
    client
        .put(buildPath([LOCKOUT_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitLockout: UpdateOne<{ id: number; data: LockoutUpdateDTO }, LockoutReadDTO> = async ({
    id,
    data,
    config,
}) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const getLockoutValidationRules: GetOne<
    { id: string | undefined },
    PreciseRulesDTO<LockoutContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([LOCKOUT_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const validateSubmitLockout: UpdateOne<{ id: number; data: LockoutUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'validate']), data, config);

export const cancelLockout: RequestMethod<{ id: number; comment: CommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client
        .post(buildPath([LOCKOUT_PATH, id, 'cancel']), comment, config)
        .then((response: AxiosResponse) => response.data);

export const lockLockoutTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'lock']), data, config).then((response: AxiosResponse) => response.data);

export const validateLock: UpdateOne<{ id: number; data: LockoutUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'lock', 'validate']), data, config);

export const unlockLockoutTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'unlock']), data, config).then((response: AxiosResponse) => response.data);

export const validateUnlock: UpdateOne<{ id: number; data: LockoutUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([LOCKOUT_PATH, id, 'unlock', 'validate']), data, config);

export const completeWorkTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([LOCKOUT_PATH, id, 'complete-work']), data, config)
        .then((response: AxiosResponse) => response.data);

export const provideYellowPaperTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([LOCKOUT_PATH, id, 'provide-yellow-paper']), data, config)
        .then((response: AxiosResponse) => response.data);

export const receiveYellowPaperTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([LOCKOUT_PATH, id, 'receive-yellow-paper']), data, config)
        .then((response: AxiosResponse) => response.data);

export const returnYellowPaperTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([LOCKOUT_PATH, id, 'return-yellow-paper']), data, config)
        .then((response: AxiosResponse) => response.data);

export const confirmReceptionOfYellowPaperTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<LockoutUpdateDTO> },
    LockoutReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([LOCKOUT_PATH, id, 'confirm-reception-yellow-paper']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getCryoEquipment: GetManyPaged<CryoEquipmentLocalReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([LOCKOUT_PATH, 'equipment', 'cryo']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

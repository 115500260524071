import React from 'react';
import { useIntl } from 'react-intl';
import { formatDate, formatDateTime } from '../lib/date';

interface ImpactFormattedDateProps {
    date: Date;
    time?: boolean;
}

export const ImpactFormattedDate: React.FC<ImpactFormattedDateProps> = ({ date, time }) => {
    const intl = useIntl();
    const formatted = time ? formatDateTime(date, intl) : formatDate(date, intl);
    return <>{formatted}</>;
};

import { Alert, Stack, Typography, useTheme } from '@mui/material';
import { axisClasses, ChartsAxisContentProps, ChartsAxisTooltipContent, LineChart } from '@mui/x-charts';
import { DatasetType } from '@mui/x-charts/models/seriesType/config';
import React, { useCallback } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import {
    WdpFollowupLastVarianceReadDTO,
    WdpFollowupTimeSeriesPointReadDTO,
    WdpVersionReadDTO,
} from '../../../../api/dto';
import { formatDate } from '../../../../lib/date';

const UNIT_KEY = 'document.unit.personMicroSievert' satisfies MessageDescriptor['id'];

interface CustomChartsAxisContentProps extends ChartsAxisContentProps {
    dataset: (Omit<WdpFollowupTimeSeriesPointReadDTO, 'date'> & { date: Date })[];
}

// Adds the "variance" data to the tooltip
const CustomChartsAxisContent: React.FC<CustomChartsAxisContentProps> = ({ dataset, ...props }) => {
    const intl = useIntl();
    type AdditionalValueKey = keyof Pick<WdpFollowupTimeSeriesPointReadDTO, 'scheduleVariance' | 'costVariance'>;
    const keyToLabel: Record<AdditionalValueKey, MessageDescriptor['id']> = {
        scheduleVariance: 'wdp.followup.series.workVariance',
        costVariance: 'wdp.followup.series.doseVariance',
    };
    const color = 'black';
    const additionalSeries: ChartsAxisContentProps['series'] = (Object.keys(keyToLabel) as AdditionalValueKey[]).map(
        (key) =>
            ({
                type: 'line',
                id: key,
                dataKey: key,
                data: dataset.map((o) => o[key]),
                color,
                getColor: () => color,
                label: intl.formatMessage(
                    { id: 'common.labelUnit' },
                    { label: intl.formatMessage({ id: keyToLabel[key] }), unit: intl.formatMessage({ id: UNIT_KEY }) }
                ),
                valueFormatter: (value) => (value == null ? '' : value.toLocaleString()),
            } satisfies ChartsAxisContentProps['series'][number])
    );
    return <ChartsAxisTooltipContent {...props} contentProps={{ series: [...props.series, ...additionalSeries] }} />;
};

interface WdpFollowupTabProps {
    wdp: WdpVersionReadDTO;
}

export const WdpFollowupTab: React.FC<WdpFollowupTabProps> = ({ wdp }) => {
    const intl = useIntl();
    const theme = useTheme();
    type TimeKey = keyof Pick<WdpFollowupTimeSeriesPointReadDTO, 'date'>;
    type ValueKey = keyof Pick<
        Omit<WdpFollowupTimeSeriesPointReadDTO, TimeKey>,
        'estimatedTotalCollectiveDose' | 'totalCollectiveDose' | 'earnedValue'
    >;
    const keyToLabel: Record<ValueKey, MessageDescriptor['id']> = {
        estimatedTotalCollectiveDose: 'wdp.followup.series.plannedWorked',
        totalCollectiveDose: 'wdp.followup.series.actualDose',
        earnedValue: 'wdp.followup.series.earnedWork',
    };
    const keyToDetailedLabel: Partial<Record<ValueKey, MessageDescriptor['id']>> = {
        estimatedTotalCollectiveDose: 'wdp.followup.series.plannedWorkedDetails',
        earnedValue: 'wdp.followup.series.earnedWorkDetails',
    };
    const colors: Record<ValueKey, string> = {
        estimatedTotalCollectiveDose: theme.palette.primary.main,
        totalCollectiveDose: theme.palette.error.main,
        earnedValue: theme.palette.success.main,
    };
    const customize: Omit<Parameters<typeof LineChart>[0], 'xAxis' | 'series' | 'dataset'> = {
        height: 600,
        margin: { top: 5 },
        sx: {
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        },
    };
    const data = (wdp?.wdpFollowup?.data ?? []).map(({ date, ...rest }) => ({
        date: new Date(date),
        ...rest,
    })) satisfies DatasetType;
    const axisContent = useCallback(
        (props: ChartsAxisContentProps) => <CustomChartsAxisContent {...props} dataset={data} />,
        [data]
    );
    const formatLabel = (
        labelKey: MessageDescriptor['id'],
        detailedLabelKey: MessageDescriptor['id'] | undefined,
        unitKey: MessageDescriptor['id']
    ) => {
        const label = intl.formatMessage({ id: labelKey }),
            unit = intl.formatMessage({ id: unitKey });
        const fullLabel =
            detailedLabelKey !== undefined ? `${label} (${intl.formatMessage({ id: detailedLabelKey })})` : label;
        return intl.formatMessage({ id: 'common.labelUnit' }, { label: fullLabel, unit: unit });
    };
    return (
        <Stack direction="column" spacing={0} sx={{ p: 2 }}>
            <LineChart
                xAxis={[
                    {
                        dataKey: 'date' satisfies TimeKey,
                        valueFormatter: (value: Date) => formatDate(value, intl),
                        scaleType: 'point',
                    },
                ]}
                yAxis={[
                    {
                        label: `[${intl.formatMessage({ id: UNIT_KEY })}]`,
                    },
                ]}
                series={(Object.keys(keyToLabel) as ValueKey[]).map((key) => ({
                    dataKey: key,
                    label: formatLabel(keyToLabel[key], keyToDetailedLabel[key], UNIT_KEY),
                    color: colors[key],
                    curve: key === 'totalCollectiveDose' ? 'stepAfter' : 'linear',
                    showMark: true,
                }))}
                grid={{ horizontal: true }}
                dataset={data}
                slots={{
                    axisContent,
                }}
                slotProps={{
                    noDataOverlay: {
                        // Maybe some day they will implement proper localization, for now this is the only way
                        message: intl.formatMessage({ id: 'wdp.followup.noData' }),
                    },
                }}
                {...customize}
            />
            <Stack direction="column" spacing={2}>
                <Stack
                    direction={{ sx: 'column', md: 'row' }}
                    spacing={{ md: 4 }}
                    justifyContent="center"
                    textAlign="center"
                >
                    {(
                        [
                            {
                                labelKey: 'wdp.followup.series.workVariance',
                                variance: wdp?.wdpFollowup?.lastScheduleVariance,
                            },
                            {
                                labelKey: 'wdp.followup.series.doseVariance',
                                variance: wdp?.wdpFollowup?.lastCostVariance,
                            },
                        ] satisfies {
                            labelKey: MessageDescriptor['id'];
                            variance: WdpFollowupLastVarianceReadDTO | null | undefined;
                        }[]
                    ).map(({ labelKey, variance }, i) => (
                        <Typography key={i}>
                            <FormattedMessage
                                id="common.labelValue"
                                values={{
                                    label: (
                                        <Typography component="span" fontWeight="bold">
                                            <FormattedMessage id={labelKey} />
                                        </Typography>
                                    ),
                                    value: variance
                                        ? `${variance.variance} ${intl.formatMessage({ id: UNIT_KEY })} (${formatDate(
                                              variance.date,
                                              intl
                                          )})`
                                        : '-',
                                }}
                            />
                        </Typography>
                    ))}
                </Stack>
                {!wdp?.wdpFollowup?.totalCollectiveDoseVisible && (
                    <Alert severity="warning">
                        <FormattedMessage id="wdp.followup.warningHidden" />
                    </Alert>
                )}
            </Stack>
        </Stack>
    );
};

import {
    AccessTime,
    Build,
    ContentCopy,
    ContentPaste,
    ContentPasteGo,
    Help,
    People,
    Send,
    Settings,
} from '@mui/icons-material';
import { Alert, Box, Button, Divider, Grid, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import {
    getVicApplicability,
    getVicDistributionTypes,
    getVicParticipantCompanyTypes,
    getVicParticipantRoles,
    getVicSafetyDocs,
} from '../../../api/documents/vics';
import {
    LinkedDocumentCode,
    ParticipantRoleCode,
    PersonReadDTO,
    VicCernParticipantReadDTO,
    VicCompanyParticipantReadDTO,
    VicDistributionReadDTO,
    VicDistributionTypeCode,
    VicReadDTO,
    VicRiskAssessmentReadDTO,
} from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import {
    fetchErosByName,
    fetchHseByName,
    fetchLocationResponsibleByName,
    fetchPeopleByName,
    fetchTsoByName,
} from '../../../lib/api';
import { translateValue } from '../../../lib/language';
import { DocumentRedirect } from '../../fields/specific/DocumentRedirect';
import { FormElementCommon, LayoutGridItem, LayoutResponsiveItem, TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { personSchema } from '../person';
import { supplierSchema } from '../supplier';
import { valueSchema } from '../value';

const SyncWithParticipants: React.FC<FormElementCommon> = ({ disabled }) => {
    const { watch, setValue } = useFormContext<VicReadDTO>();
    const vicCernParticipants: VicCernParticipantReadDTO[] = watch('vicCernParticipants') ?? [];
    const vicCompanyParticipants: VicCompanyParticipantReadDTO[] = watch('vicCompanyParticipants') ?? [];
    const vicDistributions: VicDistributionReadDTO[] = watch('vicDistributions') ?? [];

    const {
        data: distributionTypes = [],
        isLoading,
        isError,
    } = useQuery(['vicDistributionTypes'], () => getVicDistributionTypes());

    const mergedParticipants = [...vicCernParticipants, ...vicCompanyParticipants];

    const handleClick = () => {
        const updatedDistributions = [...vicDistributions];
        mergedParticipants.forEach((participant) => {
            const recordPresent = vicDistributions.find((dist) => dist.person?.id === participant.participant?.id);
            if (!recordPresent && participant.participant) {
                updatedDistributions.push({
                    person: participant.participant,
                    distributionType: distributionTypes.find(
                        (distributionType) => distributionType.code === VicDistributionTypeCode.FOR_APPROVAL
                    )!,
                });
            }
        });
        setValue('vicDistributions', updatedDistributions);
    };

    return (
        <Button
            variant="outlined"
            disabled={disabled || isLoading || isError}
            onClick={handleClick}
            startIcon={<ContentCopy />}
            fullWidth={true}
            size={'small'}
        >
            <FormattedMessage id="vic.prefillDistributionList" />
        </Button>
    );
};

const RiskAssessmentTypeName: React.FC<FormElementCommon> = (props) => {
    const intl = useIntl();
    const { watch } = useFormContext();
    const path: string | undefined = (props as any).path;
    const value: VicRiskAssessmentReadDTO | undefined = watch(path ?? '_'); // Dummy property that never gets resolved to satisfy the hook
    const display =
        path !== undefined && value !== undefined ? translateValue(value.vicRiskType, intl.locale) : undefined;
    return display !== undefined ? (
        <Box>
            <FormattedMessage
                id="common.dashValue"
                values={{
                    label: (
                        <Typography component="strong" fontWeight="bold">
                            {display.label}
                        </Typography>
                    ),
                    value: (
                        <Typography component="em" fontStyle="italic">
                            {display.description}
                        </Typography>
                    ),
                }}
            />
        </Box>
    ) : null;
};

const HazardsNotPresent: React.FC<FormElementCommon> = () => {
    const intl = useIntl();
    const { watch } = useFormContext<VicReadDTO>();
    const path = 'vicRiskAssessments';
    const riskAssessmentsList: VicRiskAssessmentReadDTO[] = watch(path);
    const hazardsNotPresent = riskAssessmentsList
        .filter((riskAssessment) => !riskAssessment.answer)
        .map((hazard) => translateValue(hazard.vicRiskType, intl.locale));

    return (
        <Box>
            <Typography sx={{ mb: 1 }}>
                <FormattedMessage id="vic.conclusion.identifiedNonHazards" />
            </Typography>
            <Grid container spacing={1} sx={{ mx: 3 }}>
                {hazardsNotPresent.map((hazard) => (
                    <Grid item xs={12} sm={6} md={6} lg={3} key={hazard.code}>
                        <Typography component="strong" fontWeight="bold">
                            {hazard.label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const IdentifiedHazards: React.FC<FormElementCommon> = () => {
    const intl = useIntl();
    const { watch } = useFormContext<VicReadDTO>();
    const path = 'vicRiskAssessments';
    const riskAssessmentsList: VicRiskAssessmentReadDTO[] = watch(path);
    const identifiedHazards = riskAssessmentsList
        .filter((riskAssessment) => riskAssessment.answer)
        .map((hazard) => ({
            ...translateValue(hazard.vicRiskType, intl.locale),
            mitigation: hazard.mitigation,
        }));
    return (
        <Box>
            <Typography sx={{ mb: 1 }}>
                <FormattedMessage id="vic.conclusion.identifiedHazards" />
            </Typography>
            <Box sx={{ mx: 5 }}>
                <Stack divider={<Divider />} spacing={2}>
                    {identifiedHazards.map((hazard) => (
                        <React.Fragment key={hazard.code}>
                            <Box>
                                <FormattedMessage
                                    id="common.labelValue"
                                    values={{
                                        label: (
                                            <Typography component="strong" fontWeight="bold">
                                                {hazard.label}
                                            </Typography>
                                        ),
                                        value: (
                                            <Box sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                                <Typography component="em" fontStyle="italic">
                                                    {hazard.mitigation}
                                                </Typography>
                                            </Box>
                                        ),
                                    }}
                                />
                            </Box>
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

const riskAssessmentSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 4,
            element: {
                type: UiSchemaType.CUSTOM,
                Component: RiskAssessmentTypeName,
            },
        },
        {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 3,
            element: {
                type: UiSchemaType.CHECKBOX,
                path: 'answer',
                label: intl.formatMessage({ id: 'vic.field.applicable' }),
            },
        },
        {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 5,
            element: {
                type: UiSchemaType.INPUT,
                path: 'mitigation',
                label: intl.formatMessage({ id: 'vic.field.mitigation' }),
            },
        },
    ],
});

const cernParticipantSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            sm: 6,
            element: valueSchema(
                {
                    path: 'participantRole',
                    label: intl.formatMessage({ id: 'vic.field.participantRole' }),
                    fetchOptions: getVicParticipantRoles,
                },
                intl.locale
            ),
        },
        {
            xs: 12,
            sm: 6,
            element: personSchema({
                path: 'participant',
                label: intl.formatMessage({ id: 'document.field.person' }),
                watchPaths: (path: string) => [`${path.split('.').slice(0, 2).join('.')}.participantRole`],
                fetchEndpoint: async (searchTerm, exactId, idField, values) => {
                    const fetchByRole: Record<
                        string,
                        (arg1: string, arg2: boolean | undefined) => Promise<PersonReadDTO[]>
                    > = {
                        [ParticipantRoleCode.TSO]: fetchTsoByName,
                        [ParticipantRoleCode.HSE_ADVISOR]: fetchHseByName,
                        [ParticipantRoleCode.EROS_ADVISOR]: fetchErosByName,
                        [ParticipantRoleCode.LOCATION_RESPONSIBLE]: fetchLocationResponsibleByName,
                    };

                    const selectedRoleCode = values?.[0]?.code;
                    const fetchFunction = fetchByRole[selectedRoleCode] || fetchPeopleByName;
                    return fetchFunction(searchTerm, exactId);
                },
                fetchOnOpen: true,
            }),
        },
    ],
});

const distributionSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            sm: 6,
            element: personSchema({
                path: 'person',
                label: intl.formatMessage({ id: 'document.field.person' }),
            }),
        },
        {
            xs: 12,
            sm: 6,
            element: valueSchema(
                {
                    path: 'distributionType',
                    label: intl.formatMessage({ id: 'document.field.type' }),
                    fetchOptions: getVicDistributionTypes,
                },
                intl.locale
            ),
        },
    ],
});

const companyParticipantSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            md: 4,
            element: supplierSchema({
                path: 'company',
                label: intl.formatMessage({ id: 'document.field.company' }),
            }),
        },
        {
            xs: 12,
            sm: 6,
            md: 4,
            element: personSchema({
                path: 'participant',
                label: intl.formatMessage({ id: 'document.field.person' }),
            }),
        },
        {
            xs: 12,
            sm: 6,
            md: 4,
            element: valueSchema(
                {
                    path: 'participantCompanyType',
                    label: intl.formatMessage({ id: 'document.field.type' }),
                    fetchOptions: getVicParticipantCompanyTypes,
                },
                intl.locale
            ),
        },
    ],
});

export const vicFormSchema = (intl: IntlShape, distrPrefillButtonHidden: boolean, isV1: boolean): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'title',
                            label: intl.formatMessage({ id: 'document.field.title' }),
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATETIME,
                            path: 'vicCreationDate',
                            label: intl.formatMessage({ id: 'vic.field.visitDate' }),
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'document.field.facility' }),
                                path: 'facility',
                                fetchOptions: getFacilities,
                                multiple: false,
                            },
                            intl.locale
                        ),
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'vic.panel.schedule' }),
            typeProperties: {
                icon: <AccessTime color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            path: 'scheduledStartDate',
                            label: intl.formatMessage({ id: 'document.field.startDate' }),
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            path: 'scheduledEndDate',
                            label: intl.formatMessage({ id: 'document.field.endDate' }),
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'vic.panel.participants' }),
            typeProperties: {
                icon: <People color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'vicCernParticipants',
                        element: cernParticipantSchema(intl),
                        appendLabel: intl.formatMessage({ id: 'vic.field.cernParticipants.add' }),
                        removeLabel: intl.formatMessage({ id: 'vic.field.cernParticipants.remove' }),
                        initialValue: {},
                    },
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'vicCompanyParticipants',
                        element: companyParticipantSchema(intl),
                        appendLabel: intl.formatMessage({ id: 'vic.field.companyParticipants.add' }),
                        removeLabel: intl.formatMessage({ id: 'vic.field.companyParticipants.remove' }),
                        initialValue: {},
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'vic.panel.how' }),
            typeProperties: {
                icon: <Build color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.INPUT,
                        path: 'documents',
                        label: intl.formatMessage({ id: 'vic.field.documents' }),
                    },
                    {
                        type: UiSchemaType.RICH_TEXT,
                        path: 'worksToBePerformed',
                        label: intl.formatMessage({ id: 'vic.field.worksToBePerformed' }),
                        placeholder: intl.formatMessage({ id: 'vic.placeholder.worksToBePerformed' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkAccessConditions',
                        label: intl.formatMessage({ id: 'vic.field.checkAccessConditions' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkRescueOrganization',
                        label: intl.formatMessage({ id: 'vic.field.checkRescueOrganization' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkWorkingZone',
                        label: intl.formatMessage({ id: 'vic.field.checkWorkingZone' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkPowerSupply',
                        label: intl.formatMessage({ id: 'vic.field.checkPowerSupply' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkEquipment',
                        label: intl.formatMessage({ id: 'vic.field.checkEquipment' }),
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkToilets',
                        label: intl.formatMessage({ id: 'vic.field.checkToilets' }),
                    },
                ],
            },
        },
        ...(!isV1
            ? [
                  {
                      type: UiSchemaType.PANEL,
                      label: intl.formatMessage({ id: 'vic.panel.pointsRevised' }),
                      typeProperties: {
                          icon: <ContentPaste color="action" />,
                      },
                      element: {
                          type: UiSchemaType.LAYOUT_GRID,
                          elements: [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: () => (
                                          <Alert severity="info">
                                              <Stack direction="column">
                                                  <Box>
                                                      <FormattedMessage id="vic.riskAssessment.procedure.question" />
                                                  </Box>
                                                  <Typography variant="caption" fontWeight="light">
                                                      <FormattedMessage id="vic.riskAssessment.procedure.asterisk1" />
                                                  </Typography>
                                                  <Typography variant="caption" fontWeight="light">
                                                      <FormattedMessage id="vic.riskAssessment.procedure.asterisk2" />
                                                  </Typography>
                                              </Stack>
                                          </Alert>
                                      ),
                                  },
                              },
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.LAYOUT_GRID,
                                      elements: [
                                          {
                                              xs: 12,
                                              sm: 12,
                                              md: 6,
                                              lg: 4,
                                              element: {
                                                  type: UiSchemaType.CHECKBOX,
                                                  path: 'riskAssessmentAttachment',
                                                  label: intl.formatMessage({
                                                      id: 'vic.field.riskAssessmentAttachment',
                                                  }),
                                              },
                                          },
                                          {
                                              xs: 12,
                                              sm: 12,
                                              md: 6,
                                              lg: 4,
                                              element: {
                                                  type: UiSchemaType.CHECKBOX,
                                                  path: 'riskAssessmentEdms',
                                                  label: intl.formatMessage({ id: 'vic.field.riskAssessmentEdms' }),
                                              },
                                          },
                                          {
                                              xs: 12,
                                              sm: 12,
                                              md: 12,
                                              lg: 4,
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  path: 'edmsNumber',
                                                  label: intl.formatMessage({ id: 'vic.field.edmsNumber' }),
                                                  typeProperties: {
                                                      multiline: false,
                                                      endAdornment: ({ field }) => {
                                                          const { value: edmsNumber } = field as {
                                                              value: string | undefined;
                                                          };
                                                          return (
                                                              <DocumentRedirect
                                                                  value={edmsNumber}
                                                                  documentType={LinkedDocumentCode.EDMS_PROCEDURE}
                                                              />
                                                          );
                                                      },
                                                  },
                                              },
                                          },
                                      ],
                                  },
                              },
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: () => (
                                          <Alert severity="info">
                                              <Stack direction="column">
                                                  <Box>
                                                      <FormattedMessage id="vic.riskAssessment.measures.question" />
                                                  </Box>
                                                  <Typography variant="caption" fontWeight="light">
                                                      <FormattedMessage id="vic.riskAssessment.measures.details" />
                                                  </Typography>
                                              </Stack>
                                          </Alert>
                                      ),
                                  },
                              },

                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.FIELD_ARRAY,
                                      path: 'vicRiskAssessments',
                                      element: riskAssessmentSchema(intl),
                                      appendLabel: intl.formatMessage({ id: 'vic.field.riskAssessments' }),
                                      removeLabel: '', // (no need, because it is `shallowReadOnly`)
                                      initialValue: {},
                                      // Cannot add or remove items, but can edit the items themselves
                                      shallowReadOnly: true,
                                  },
                              },
                          ],
                      },
                  } satisfies TFormElement,
              ]
            : []),
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'vic.panel.conclusion' }),
            typeProperties: {
                icon: <ContentPasteGo color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    ...(!isV1
                        ? [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: HazardsNotPresent,
                                  },
                              } satisfies LayoutGridItem,
                          ]
                        : []),
                    ...(!isV1
                        ? [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: IdentifiedHazards,
                                  },
                              } satisfies LayoutGridItem,
                          ]
                        : []),
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                path: 'vicApplicability',
                                label: intl.formatMessage({ id: 'vic.field.vicApplicability' }),
                                fetchOptions: getVicApplicability,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                path: 'vicSafetyDocs',
                                label: intl.formatMessage({ id: 'vic.field.vicSafetyDocs' }),
                                fetchOptions: getVicSafetyDocs,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.RICH_TEXT,
                            path: 'interventionPerimeter',
                            label: intl.formatMessage({ id: 'vic.field.interventionPerimeter' }),
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.RICH_TEXT,
                            path: 'remarks',
                            label: intl.formatMessage({ id: 'vic.field.remarks' }),
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.LAYOUT_GRID,
                            elements: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalFirePermit',
                                        label: intl.formatMessage({ id: 'firePermit.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalIs37',
                                        label: intl.formatMessage({ id: 'is37.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalDimr',
                                        label: intl.formatMessage({ id: 'dimr.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalWdp',
                                        label: intl.formatMessage({ id: 'wdp.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalCryogenicLockout',
                                        label: intl.formatMessage({ id: 'lockout.cryogenic.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalElectricalLockout',
                                        label: intl.formatMessage({ id: 'lockout.electrical.name' }),
                                    },
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    lg: 3,
                                    element: {
                                        type: UiSchemaType.CHECKBOX,
                                        path: 'additionalAuthorization',
                                        label: intl.formatMessage({ id: 'vic.field.additionalAuthorization' }),
                                    },
                                },
                            ],
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'serviceNeeds',
                            label: intl.formatMessage({ id: 'vic.field.serviceNeeds' }),
                            typeProperties: {
                                multiline: true,
                                endAdornment: () => {
                                    return (
                                        <>
                                            {
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Typography variant="caption">
                                                                <FormattedMessage id="vic.tooltip.serviceNeeds" />
                                                            </Typography>
                                                        }
                                                    >
                                                        <Help sx={{ cursor: 'help' }} />
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                        </>
                                    );
                                },
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'emergencyInfo',
                            label: intl.formatMessage({ id: 'vic.field.emergencyInfo' }),
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'workProcedure',
                            label: intl.formatMessage({ id: 'vic.field.workProcedure' }),
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'otherPoints',
                            label: intl.formatMessage({ id: 'vic.field.otherPoints' }),
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.distribution' }),
            typeProperties: {
                icon: <Send color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                elements: [
                    ...(distrPrefillButtonHidden
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: SyncWithParticipants,
                                  },
                              } satisfies LayoutResponsiveItem,
                          ]
                        : []),
                    {
                        element: {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'vicDistributions',
                            element: distributionSchema(intl),
                            appendLabel: intl.formatMessage({ id: 'vic.field.vicDistributions.add' }),
                            removeLabel: intl.formatMessage({ id: 'vic.field.vicDistributions.remove' }),
                            initialValue: {},
                        },
                    },
                ],
            },
        },
    ],
});

import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { LockoutIcon } from '../../../lib/information/lockout';
import { getLockoutStatus } from '../../../lib/status';
import { LockoutPageData } from './LockoutPageData';
import { LockoutPageVariant } from './LockoutPageVariant';

interface LockoutContentHeaderProps {
    data: LockoutPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const LockoutContentHeader: React.FC<LockoutContentHeaderProps> = ({ data, ...rest }) => {
    const intl = useIntl();
    const { variant } = data;
    return (
        <DocumentHeader
            documentType={DocumentType.LOK}
            documentId={variant === LockoutPageVariant.READ ? data.lockoutData.id : null}
            icon={<LockoutIcon />}
            title={
                variant === LockoutPageVariant.READ
                    ? intl.formatMessage({ id: 'lockout.titleExisting' }, { id: data.lockoutData.id })
                    : variant === LockoutPageVariant.CLONE
                    ? intl.formatMessage({ id: 'lockout.titleNewCloning' }, { id: data.lockoutData.id })
                    : intl.formatMessage({ id: 'lockout.titleNew' })
            }
            status={variant === LockoutPageVariant.READ ? getLockoutStatus(data.lockoutData, intl) : undefined}
            creation={variant === LockoutPageVariant.READ ? data.lockoutData : undefined}
            {...rest}
        />
    );
};

import { IntlShape } from 'react-intl';
import { getIs37s } from '../../api/documents/is37s';
import { IS37ReadDTO } from '../../api/dto';
import { makeIs37Filter } from '../../lib/filters/is37';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { documentTypeProperties } from './document';

interface Is37SchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
}

export const is37Schema = (
    { path, label, required, multiple }: Is37SchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: documentTypeProperties({
        fetchOptions: async (query) => {
            const result = await getIs37s({ filter: makeIs37Filter(query) });
            return result.content;
        },
        getOptionLabel: (is37: IS37ReadDTO) => intl.formatMessage({ id: 'is37.titleExisting' }, { is37Id: is37.id }),
        label: intl.formatMessage({ id: 'is37.name' }),
        multiple,
        autocompletePropsOverrides: {
            disableCloseOnSelect: true,
        },
    }),
});

import {
    CellChange,
    CellLocation,
    Compatible,
    Highlight,
    Id,
    MenuOption,
    ReactGrid,
    ReactGridProps,
    Row,
} from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import React, { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { WdpVersionReadDTO } from '../../api/dto';
import { useWdpTabMenuContext, WdpTabMenuContextValue } from '../../pages/documents/wdp/tabs/menu/WdpTabMenuContext';
import { accessValue, formatHeaderLabel, tableHeaderStyle } from '../../pages/documents/wdp/tabs/WdpUtils';
import { CellTypes, MenuButton, TableHeader, TreeNode } from '../../pages/documents/wdp/WdpInterfaces';
import { CheckpointsCell, CheckpointsCellTemplate } from './CheckpointsCellTemplate';
import { CustomDateCell, CustomDateCellTemplate } from './CustomDateCellTemplate';
import { DialogCheckpointsInput } from './DialogCheckpointsInput';
import { DialogCustomDate } from './DialogCustomDate';
import { DialogLongInput } from './DialogLongInput';
import { DialogMultiDropdownInput } from './DialogMultiDropdownInput';
import { DialogPersonInput } from './DialogPersonInput';
import { LongInputCell, LongInputCellTemplate } from './LongInputCellTemplate';
import { BaseDropdownCell, MultiDropdownCell, MultiDropdownCellTemplate } from './MultiDropdownCellTemplate';
import { PersonAutocompleteTemplate, PersonCell } from './PersonAutocompleteTemplate';
import './reactgridstyles.css';
import { useColumnWidths } from './useColumnWidths';
import { WorkStepsCell, WorkStepsCellTemplate } from './WorkStepsCellTemplate';

export type Range = Parameters<Exclude<ReactGridProps['onSelectionChanging'], undefined>>[0][number];

export type SpreadsheetProps = {
    headers: TableHeader[];
    data: Record<string, any>[];
    setData: (
        callbackOrData: ((prevData: Record<string, any>[]) => Record<string, any>[]) | Record<string, any>[]
    ) => void;
    keyField: string;
    disabled: boolean;
    wdp: Pick<WdpVersionReadDTO, 'wdpMasterId' | 'versionNumber'>;
    focusLocation?: CellLocation | undefined;
    onFocus?: (cellLocation: CellLocation | undefined) => void;
    onSelection?: (ranges: Range[]) => void;
    menuEntries?: MenuButton[];
    onCellsChanged?: (spreadsheetHistory: CellChange[]) => void;
    headerHeight?: number;
    rowHeight?: number;
    customTypes?: WdpVersionReadDTO | undefined;
    addIdxHeader?: boolean;
    highlights?: Highlight[];
    stickyLeftColumns?: number;
};

export const NEW_ENTITY_FIELD = '__newEntity';
const HEADER_ID = '__HEADER__';
const IDX_HEADER_NAME = '__IDX__';

const idxHeader: TableHeader = {
    name: IDX_HEADER_NAME,
    labelTranslationKey: null,
    width: 40,
    readonly: true,
    type: 'number',
};

const transformHeaders = (headers: TableHeader[], headerHeight: number, intl: IntlShape): Row<CellTypes> => ({
    rowId: HEADER_ID,
    cells: headers.map((h) => ({
        type: 'header',
        text: formatHeaderLabel(h, intl),
        style: tableHeaderStyle,
    })),
    height: headerHeight,
});

const transformRowCell = (
    rowData: any,
    header: TableHeader,
    customTypes: WdpVersionReadDTO | undefined,
    keyField: string,
    setData: {
        (callbackOrData: ((prevData: Record<string, any>[]) => Record<string, any>[]) | Record<string, any>[]): void;
    },
    tree: TreeNode[] | undefined,
    disabled: boolean
): CellTypes => {
    const val = accessValue(rowData, header.name);
    const metadata = header.customType;
    const setDataRow = (newValue: unknown) => {
        return setData((prevData: any[]) =>
            prevData.map((row) =>
                row[keyField] === rowData[keyField] ? { ...row, ...{ [header.name]: newValue } } : row
            )
        );
    };

    const isRedacted = header.redactedKey !== undefined ? rowData[header.redactedKey] === false : false;

    const isCellNonEditable = typeof header.readonly === 'function' ? header.readonly(rowData, tree) : header.readonly;
    const nonEditable = isCellNonEditable || disabled;
    const showValue = (!isCellNonEditable || !header.hideReadonly) && !isRedacted;

    const idAttribute = rowData[keyField];
    const node = tree?.find((treeNode: TreeNode) => treeNode.id === idAttribute);
    const parent = tree?.find((treeNode: TreeNode) => treeNode.id === node?.parent);

    const commonProps = (h: TableHeader) => {
        let className = isRedacted ? 'rg-cell-customDose' : '';

        if (tree !== undefined && parent === undefined) {
            className = `${className} rg-cell-tree-parent`.trim();
        }

        return {
            className,
            columnId: h.name,
        };
    };

    if (header.type === 'chevron') {
        return {
            type: header.type,
            hasChildren: false, // this is only for rendering chevron
            text: node && showValue ? node.treeIndex : '',
            isExpanded: true,
            parentId: parent ? parent.rowId : undefined,
            nonEditable: true,
            ...commonProps(header),
        };
    }

    if (header.type && (header.type === 'multiautocomplete' || header.type === 'worksteps')) {
        const values = !!customTypes
            ? metadata
                ? (customTypes[metadata.dataKey as keyof WdpVersionReadDTO] as any[])?.map((opt) => {
                      return {
                          [metadata.idKey]: opt[metadata.idKey],
                          name:
                              !!opt[metadata.labelKey] && metadata.labelNestedKey && metadata.labelNestedKey.length > 0
                                  ? opt[metadata.labelKey][metadata.labelNestedKey]
                                  : opt[metadata.labelKey] ??
                                    (metadata.fallbackLabelKey ? opt[metadata.fallbackLabelKey] : ''),
                          isRoot: opt.parent === null,
                      };
                  })
                : []
            : [];

        const dropdownCell = {
            values,
            selectedValue: val,
            idKey: metadata?.idKey ?? header.idKey ?? 'value',
            labelKey: 'name',
            fallbackLabelKey: 'name', // ???
            nonEditable,
            setData: setDataRow,
            ...commonProps(header),
        } satisfies Partial<BaseDropdownCell>;

        return {
            ...dropdownCell,
            type: header.type,
            valueList: val,
            ...commonProps(header),
        } satisfies MultiDropdownCell | WorkStepsCell;
    }
    if (header.type === 'person') {
        return {
            type: 'person',
            person: showValue ? val : undefined,
            nonEditable,
            setData: setDataRow,
            ...commonProps(header),
        };
    }
    if (header.type === 'number') {
        return {
            type: 'number',
            value: showValue ? val : undefined,
            format: Intl.NumberFormat('en-EN', { useGrouping: false }),
            nonEditable,
            ...commonProps(header),
        };
    }
    if (header.type === 'date') {
        return {
            type: 'date',
            date: showValue && val ? new Date(val) : undefined,
            setData: setDataRow,
            nonEditable,
            ...commonProps(header),
        };
    }
    if (header.type === 'checkpoints') {
        return {
            type: 'checkpoints',
            wdpCheckPoints: showValue ? val : undefined,
            setData: setDataRow,
            nonEditable,
            ...commonProps(header),
        };
    }
    if (header.type === 'longInput') {
        return {
            type: 'longInput',
            text: showValue ? val ?? '' : '',
            setData: setDataRow,
            nonEditable,
            ...commonProps(header),
        };
    }
    return {
        type: header.type ?? typeof val === 'boolean' ? 'checkbox' : 'text',
        hasChildren: !!rowData.children?.length,
        text: showValue ? val ?? '' : '',
        value: val,
        checked: val,
        nonEditable,
        ...commonProps(header),
    };
};

const assignIndexes = (tree: TreeNode[]) => {
    let currentRootIndex = 1;

    const indexMap: Record<number, string> = {};

    for (let i = 0; i < tree.length; i++) {
        const node: TreeNode = tree[i];
        if (node.parent === undefined || node.parent === null) {
            node.treeIndex = `${currentRootIndex}`;
            currentRootIndex = currentRootIndex + 1;
        } else {
            const parentIndex: string = indexMap[node.parent] || '';
            node.treeIndex = `${parentIndex}.${tree.filter((n) => n.parent === node.parent).indexOf(node) + 1}`;
        }
        indexMap[node.id] = node.treeIndex;
    }
    return tree;
};

const buildTree = (rows: any[], keyField: string): TreeNode[] => {
    const tree = rows.map((row) => {
        const id = row[keyField];
        const hasChildren = rows.some((r) => {
            return r.parent?.[keyField] === id;
        });

        return {
            id,
            parent: row.parent?.[keyField],
            rowId: row[keyField],
            hasChildren,
            treeIndex: '',
        };
    });
    return assignIndexes(tree);
};

const transformRows = (
    headers: TableHeader[],
    data: any[],
    keyField: string,
    customTypes: WdpVersionReadDTO | undefined,
    rowHeight: number,
    setData: {
        (callbackOrData: ((prevData: Record<string, any>[]) => Record<string, any>[]) | Record<string, any>[]): void;
    },
    disabled: boolean
): Row<CellTypes>[] => {
    let tree: TreeNode[] | undefined = undefined;
    if (data.every((row) => row.hasOwnProperty('workStepsIdx') || row.hasOwnProperty('performedTasksIdx'))) {
        tree = buildTree(data, keyField);
    }

    return data.map((rowData) => ({
        rowId: rowData[keyField],
        height: rowHeight,
        cells: headers.map((header) =>
            transformRowCell(rowData, header, customTypes, keyField, setData, tree, disabled)
        ),
    }));
};

const getMenuEntries = (
    menuEntries: MenuButton[],
    selectedRowIds: Id[],
    context: WdpTabMenuContextValue,
    intl: IntlShape
): MenuOption[] => {
    const getLabel = (menuEntry: MenuButton) =>
        typeof menuEntry.label === 'string' ? menuEntry.label : menuEntry.label(intl);
    return selectedRowIds.includes(HEADER_ID)
        ? []
        : menuEntries
              .filter((menuEntry) => !menuEntry.disabled)
              .map((menuEntry) => ({
                  id: getLabel(menuEntry), // ???
                  label: getLabel(menuEntry),
                  handler: () => menuEntry.onClick(context),
              }));
};

const getValueField = (cell: any, type: CellTypes['type']) => {
    const accessField =
        type === 'multiautocomplete' || type === 'worksteps'
            ? 'valueList'
            : type === 'checkbox'
            ? 'checked'
            : type === 'number'
            ? 'value'
            : type === 'date'
            ? 'date'
            : type === 'time'
            ? 'time'
            : type === 'dropdown'
            ? 'selectedValue'
            : type === 'person'
            ? 'value'
            : type === 'chevron'
            ? 'value'
            : 'text';
    return cell[accessField];
};

export const Spreadsheet = ({
    headers,
    data,
    setData,
    keyField,
    disabled,
    wdp,
    focusLocation,
    onFocus,
    onSelection,
    menuEntries = [],
    headerHeight = 45,
    rowHeight = 40,
    customTypes = undefined,
    addIdxHeader,
    highlights,
    stickyLeftColumns = 1,
}: SpreadsheetProps) => {
    const intl = useIntl();
    const enhancedHeaders = addIdxHeader ? [idxHeader, ...headers] : headers;
    const contextValue = useWdpTabMenuContext();
    const [personDialogOpen, setPersonDialogOpen] = useState(false);
    const [multiDropdownOpen, setMultiDropdownOpen] = useState(false);
    const [checkpointDialogOpen, setCheckpointDialogOpen] = useState(false);
    const [longInputDialogOpen, setLongInputDialogOpen] = useState(false);
    const [dateCellOpen, setDateCellOpen] = useState(false);
    const [cell, setCell] = useState<
        | Compatible<PersonCell>
        | Compatible<MultiDropdownCell>
        | Compatible<CheckpointsCell>
        | Compatible<CustomDateCell>
        | undefined
    >(undefined);

    const rows = React.useMemo(
        () => [
            transformHeaders(enhancedHeaders, headerHeight, intl),
            ...transformRows(
                enhancedHeaders,
                data.map((row: any, index) => ({ ...row, [IDX_HEADER_NAME]: index + 1 })),
                keyField,
                customTypes,
                rowHeight,
                setData,
                disabled
            ),
        ],
        [setData, data, disabled]
    );

    const { columns, handleColumnResize } = useColumnWidths(enhancedHeaders, keyField);

    const focusedCellData = data.find((row: any) => row[keyField] === focusLocation?.rowId);

    return (
        <>
            <ReactGrid
                rows={rows}
                columns={columns}
                enableRowSelection
                enableRangeSelection
                focusLocation={focusLocation}
                onColumnResized={(columnId, newWidth) => handleColumnResize(columnId, newWidth)}
                onFocusLocationChanging={(newCellLocation) => {
                    if (newCellLocation.rowId === HEADER_ID) return false;
                    if (onFocus) {
                        onFocus(newCellLocation);
                    }
                    return true;
                }}
                onSelectionChanging={(newSelectionRanges) => {
                    if (onSelection) {
                        onSelection(newSelectionRanges);
                    }
                    return true;
                }}
                minColumnWidth={1}
                stickyTopRows={1}
                stickyLeftColumns={stickyLeftColumns}
                onContextMenu={(selectedRowIds) =>
                    menuEntries ? getMenuEntries(menuEntries, selectedRowIds, contextValue, intl) : []
                }
                canReorderRows={(targetRowId: Id) => targetRowId !== 'header'}
                onRowsReordered={() => 1}
                onCellsChanged={(args: CellChange<CellTypes>[]) => {
                    const updatedRow: Record<string, any> = args.reduce(
                        (acc: Record<string, any>, { columnId, rowId, type, newCell }) => {
                            const currentRow = acc[rowId] || {};
                            currentRow[columnId] = getValueField(newCell, type);
                            acc[rowId] = currentRow;
                            return acc;
                        },
                        {}
                    );
                    setData(data.map((row: Record<string, any>) => ({ ...row, ...(updatedRow[row[keyField]] ?? {}) })));
                }}
                customCellTemplates={{
                    date: new CustomDateCellTemplate({ intl, setOpen: setDateCellOpen, setCell }),
                    multiautocomplete: new MultiDropdownCellTemplate({ setOpen: setMultiDropdownOpen, setCell }),
                    person: new PersonAutocompleteTemplate({
                        // @ts-ignore
                        canOpen: !focusedCellData?.unknown,
                        setOpen: setPersonDialogOpen,
                        setCell,
                    }),
                    checkpoints: new CheckpointsCellTemplate({ intl, setCell, setOpen: setCheckpointDialogOpen }),
                    worksteps: new WorkStepsCellTemplate({ wdp, setOpen: setMultiDropdownOpen, setCell }),
                    longInput: new LongInputCellTemplate({ setCell, setOpen: setLongInputDialogOpen }),
                }}
                highlights={highlights}
                disableVirtualScrolling // This allows us to focus a cell (see `customWdpErrorResolver` in `WdpForm`)
            />
            <DialogPersonInput
                open={personDialogOpen}
                cell={cell as Compatible<PersonCell> | undefined}
                setOpen={setPersonDialogOpen}
            />
            <DialogMultiDropdownInput
                open={multiDropdownOpen}
                cell={cell as Compatible<MultiDropdownCell> | undefined}
                setOpen={setMultiDropdownOpen}
            />
            <DialogCustomDate
                open={dateCellOpen}
                setOpen={setDateCellOpen}
                cell={cell as Compatible<CustomDateCell> | undefined}
            />
            <DialogCheckpointsInput
                open={checkpointDialogOpen}
                cell={cell as Compatible<CheckpointsCell> | undefined}
                setOpen={setCheckpointDialogOpen}
            />
            <DialogLongInput
                open={longInputDialogOpen}
                cell={cell as Compatible<LongInputCell> | undefined}
                setOpen={setLongInputDialogOpen}
            />
        </>
    );
};

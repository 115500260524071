/* eslint-disable @typescript-eslint/no-use-before-define */
import { AccessTime, AccountTree, Article, AttachFile, CallSplit, MenuBook, PictureAsPdf } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createActivity, submitActivity, updateActivity } from '../../api/activities-nim';
import { ActivityContextReadDTO, ActivityNimReadDTO, DocumentType, DurationTypeCode } from '../../api/dto';
import { ButtonSet } from '../../components/ButtonSet';
import { CommentList } from '../../components/comments/CommentList';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { FavouriteButton } from '../../components/FavouriteActivityButton';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import { mapActivityToUpdateDTO } from '../../lib/records/activity';
import { makeDefaultValuesFromClonedActivity } from '../../lib/records/documents/activity';
import { ActivityContentHeader } from '../../pages/activities/ActivityContentHeader';
import { ActivityPageData } from '../../pages/activities/ActivityPageData';
import { ActivityPageVariant } from '../../pages/activities/ActivityPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { activityFormSchema } from '../schemas/documents/activity';

interface ActivityFormProps {
    data: ActivityPageData;
    refreshData: () => void;
    rules: PreciseRulesDTO<ActivityContextReadDTO>;
}

export const ActivityForm: React.FC<ActivityFormProps> = ({ data: pageData, refreshData, rules }) => {
    const { withDispatchTaskEvent } = useTaskContext();

    const intl = useIntl();
    const { variant } = pageData;
    const defaultValues = useMemo(
        () =>
            pageData.variant === ActivityPageVariant.READ
                ? pageData.activityData
                : pageData.variant === ActivityPageVariant.CLONE
                ? makeDefaultValuesFromClonedActivity(pageData.activityData)
                : {},
        [pageData]
    );
    const tasks = useTasks(
        pageData.variant === ActivityPageVariant.READ
            ? {
                  documentTypes: [DocumentType.ACTIVITY],
                  documentId: pageData.activityData.id,
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );
    const context: ActivityContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: null,
                      task: tasks.currentTask?.type?.type ?? null,
                  }
                : null,
        [rules.context, tasks.currentTask]
    );
    const { form, attributes } = useFormRules<ActivityNimReadDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues,
        },
        rules,
        originalValues: defaultValues,
        context: context as any,
    });
    const { handleSubmit, clearErrors, formState } = form;
    const copyButtonDisabled = variant !== ActivityPageVariant.READ; // TODO This should be changed
    const isSavable = true;
    const canClone = true;
    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== ActivityPageVariant.READ;

    const navigate = useNavigate();

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.ACTIVITY);

    const copyProposedDate = useCallback(() => {
        const { proposedDate, duration, durationType } = form.getValues();

        if (proposedDate != null && duration && durationType) {
            const parsedDuration = typeof duration === 'string' ? parseInt(duration, 10) : duration;
            let endDate = new Date(proposedDate);
            if (!isNaN(endDate.getTime()) && !isNaN(parsedDuration)) {
                form.setValue('accessStart', proposedDate);
                if (durationType.code === DurationTypeCode.DAY && parsedDuration > 0) {
                    endDate.setDate(endDate.getDate() + (parsedDuration - 1));
                }
                form.setValue('accessEnd', endDate.toISOString());
            }
        }
    }, [form.getValues, form.setValue]);

    const activityLocations =
        form
            .getValues()
            .activityLocations?.map((activityLocation) => activityLocation?.location)
            .filter((location) => !!location) ?? [];

    const uiSchemaActivity = useMemo(
        () => activityFormSchema(intl, copyProposedDate, copyButtonDisabled, activityLocations),
        [intl, activityLocations]
    );

    const { mutateAsync: saveActivity, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === ActivityPageVariant.READ) {
                const updateOrSubmit = submit ? submitActivity : updateActivity;
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateOrSubmit({
                    id: pageData.activityData.id,
                    taskId: tasks.currentTask.id,
                    data: mapActivityToUpdateDTO(formData),
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                return createActivity({ data: mapActivityToUpdateDTO(formData) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === ActivityPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.activity.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );

    const isLoading = isSaveLoading; // TODO add `|| tasks.disabled`

    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && formIsDirty,
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const informationButtons = [
        <Button disabled={true} startIcon={<CallSplit />} size="small">
            Split
        </Button>,
        <Button disabled={true} startIcon={<MenuBook />} size="small">
            Approvals
        </Button>,
        <Button disabled={true} startIcon={<Article />} size="small">
            Change Requests
        </Button>,
        <Button disabled={true} startIcon={<AccountTree />} size="small">
            See Workflow
        </Button>,
        <Button disabled={true} startIcon={<AttachFile />} size="small">
            Attachments
        </Button>,
        <Button disabled={true} startIcon={<AccessTime />} size="small">
            View History
        </Button>,
        <Button disabled={true} startIcon={<PictureAsPdf />} size="small">
            Download PDF
        </Button>,
    ];

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <ActivityContentHeader
                        data={pageData}
                        editable={isSavable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === ActivityPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null}
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        isDirty={formIsDirty}
                                        context={context} //TODO
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === ActivityPageVariant.CREATE ||
                                        variant === ActivityPageVariant.CLONE
                                            ? COMMON_DOCUMENT_BUTTONS.CREATE
                                            : COMMON_DOCUMENT_BUTTONS.SAVE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveActivity({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === ActivityPageVariant.READ && canClone && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                        onClick={() => {
                                            window.open(
                                                getRoute({
                                                    path: ROUTES.activity.clone,
                                                    params: { id: String(pageData.activityData.id) },
                                                })
                                            );
                                        }}
                                        disabled={formIsDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
                                    />
                                )}
                                {/* TODO */}
                                {variant === ActivityPageVariant.READ && (
                                    <FavouriteButton activityId={pageData.activityData.id} />
                                )}
                                <ButtonSet buttons={informationButtons} color={'grey'} />
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <Box m={2}>
                            {variant === ActivityPageVariant.READ && (
                                <LinkedDocumentsPanel type={EntityType.ActivityNim} activity={pageData.activityData} />
                            )}
                            <ValidationErrorsPanel schema={uiSchemaActivity} />
                            <FieldsAttributesContextProvider value={attributes}>
                                <FormGenerator rootElement={uiSchemaActivity} form={form} disabled={isLoading} />
                                {variant === ActivityPageVariant.READ && (
                                    <WorkflowHistory
                                        documentType={DocumentType.ACTIVITY}
                                        documentId={pageData.activityData.id}
                                    />
                                )}
                                {variant === ActivityPageVariant.READ && (
                                    <CommentList
                                        documentTypes={[DocumentType.ACTIVITY]}
                                        documentId={pageData.activityData.id}
                                        currentTask={tasks.currentTask}
                                        disabled={isLoading}
                                    />
                                )}
                            </FieldsAttributesContextProvider>
                        </Box>
                    </>
                }
                Drawer={
                    variant === ActivityPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};

import { IntlShape } from 'react-intl';
import {
    ActivityNimReadDTO,
    ActivityReadDTO,
    DimrVersionGridReadDTO,
    FirePermitReadDTO,
    IS37ChangeRequestReadDTO,
    IS37ReadDTO,
    ListOfValuesReadDTO,
    LockoutGridReadDTO,
    NdcReadDTO,
    TaskDTO,
    VicGridReadDTO,
    WdpVersionReadDTO,
} from '../api/dto';
import { ActivityStatusColorMapping } from './colors/ActivityStatusColorMapping';
import { DIMRStatusColorMapping } from './colors/DIMRStatusColorMapping';
import { FirePermitStatusColorMapping } from './colors/FirePermitStatusColorMapping';
import { Is37ChangeRequestStatusColorMapping } from './colors/Is37ChangeRequestStatusColorMapping';
import { Is37StatusColorMapping } from './colors/Is37StatusColorMapping';
import { LockoutStatusColorMapping } from './colors/LockoutStatusColorMapping';
import { NdcStatusColorMapping } from './colors/NdcStatusColorMapping';
import { StatusColor } from './colors/StatusColor';
import { VicStatusColorMapping } from './colors/VicStatusColorMapping';
import { translateValue } from './language';

export interface DocumentStatus {
    label: string;
    color: StatusColor;
}

export const fromDocumentStatus = <Code extends string>(
    status: ListOfValuesReadDTO<Code>,
    colors: Record<Code, StatusColor>,
    intl: IntlShape
): DocumentStatus => ({
    label: translateValue(status, intl.locale).label,
    color: colors[status.code],
});

export const getNdcStatus = (activity: Pick<NdcReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(activity.status, NdcStatusColorMapping, intl);

export const getActivityStatus = (
    activity: Pick<ActivityReadDTO, 'status'> | Pick<ActivityNimReadDTO, 'status'>,
    intl: IntlShape
): DocumentStatus => fromDocumentStatus(activity.status, ActivityStatusColorMapping, intl);

export const getIs37Status = (is37: Pick<IS37ReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(is37.status, Is37StatusColorMapping, intl);

export const getIs37ChangeRequestStatus = (
    is37ChangeRequest: Pick<IS37ChangeRequestReadDTO, 'status'>,
    intl: IntlShape
): DocumentStatus => fromDocumentStatus(is37ChangeRequest.status, Is37ChangeRequestStatusColorMapping, intl);

export const getFirePermitStatus = (firePermit: Pick<FirePermitReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(firePermit.status, FirePermitStatusColorMapping, intl);

export const getDimrStatus = (dimr: Pick<DimrVersionGridReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(dimr.status, DIMRStatusColorMapping, intl);

export const getWdpStatus = (
    wdp: Pick<WdpVersionReadDTO, 'cancelled'>,
    intl: Pick<IntlShape, 'formatMessage'>
): DocumentStatus =>
    !wdp.cancelled
        ? { label: intl.formatMessage({ id: 'wdp.status.active' }), color: StatusColor.PRIMARY }
        : { label: intl.formatMessage({ id: 'wdp.status.cancelled' }), color: StatusColor.ERROR };

export const getVicStatus = (vic: Pick<VicGridReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(vic.status, VicStatusColorMapping, intl);

export const getLockoutStatus = (lockout: Pick<LockoutGridReadDTO, 'status'>, intl: IntlShape): DocumentStatus =>
    fromDocumentStatus(lockout.status, LockoutStatusColorMapping, intl);

export const getTaskStatus = (task: TaskDTO, { formatMessage }: Pick<IntlShape, 'formatMessage'>): DocumentStatus =>
    task.taskActive
        ? { label: formatMessage({ id: 'task.status.open' }), color: StatusColor.SUCCESS }
        : task.taskCompletionCode !== null
        ? { label: formatMessage({ id: 'task.status.completed' }), color: StatusColor.NORMAL }
        : { label: formatMessage({ id: 'task.status.cancelled' }), color: StatusColor.ERROR };

import { PowerOff } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { NdcReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDateTime } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getNdcStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const NdcIcon = PowerOff;

export const getNoteDeCoupureInformation = (noteDeCoupure: NdcReadDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getRoute({
            path: ROUTES.noteDeCoupure.view,
            params: { id: `${noteDeCoupure.id}` },
        }),
        name: intl.formatMessage({ id: 'ndc.titleExisting' }, { ndcNumber: noteDeCoupure.ndcNumber }),
        title: noteDeCoupure.title,
        status: getNdcStatus(noteDeCoupure, intl),
        creation: noteDeCoupure,
        details: [
            {
                labelKey: 'activity.name',
                value: {
                    label: String(noteDeCoupure.activity.id),
                    url: getRoute({
                        path: ROUTES.activity.view,
                        params: { id: String(noteDeCoupure.activity.id) },
                    }),
                },
            },
            ...(noteDeCoupure.interventionType
                ? [
                      {
                          labelKey: 'ndc.field.interventionType' as const,
                          value: getLovLabel(translateValue(noteDeCoupure.interventionType, intl.locale)),
                          type: true,
                      },
                  ]
                : []),
            {
                labelKey: 'document.field.startDate',
                value: noteDeCoupure.startDate ? formatDateTime(noteDeCoupure.startDate, intl) : '',
            },
            {
                labelKey: 'document.field.endDate',
                value: noteDeCoupure.endDate ? formatDateTime(noteDeCoupure.endDate, intl) : '',
            },
            {
                labelKey: 'ndc.field.cutExecutor',
                value: noteDeCoupure.cutExecutor?.searchLabel ?? '',
                italic: true,
            },
            {
                labelKey: 'ndc.field.requestedBy',
                value: noteDeCoupure.requestedBy?.searchLabel ?? '',
                italic: true,
            },
            {
                labelKey: 'ndc.field.type',
                value: noteDeCoupure.severity ? getLovLabel(translateValue(noteDeCoupure.severity, intl.locale)) : '',
            },
        ],
    };
};

import { Warning } from '@mui/icons-material';
import { Tooltip, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';

interface InactiveActivityWarningProps {}

export const InactiveActivityWarning: React.FC<InactiveActivityWarningProps> = () => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Typography key="warning" sx={{ display: 'flex' }}>
            <Tooltip title={intl.formatMessage({ id: 'document.warning.activityNotActive' })}>
                <Warning sx={{ color: theme.palette.error.main, marginRight: 1 }} />
            </Tooltip>
        </Typography>
    );
};

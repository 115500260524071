import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getActivity, getActivityValidationRules } from '../../api/activities-nim';
import { DocumentType } from '../../api/dto';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { ActivityForm } from '../../forms/documents/ActivityForm';
import { ActivityPageVariant } from './ActivityPageVariant';

interface ActivityPageProps {
    variant: ActivityPageVariant;
}

export const ActivityPage: React.FC<ActivityPageProps> = ({ variant }) => {
    const { id: activityId } = useParams();
    const intl = useIntl();

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.ACTIVITY, activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.ACTIVITY, activityId, 'rules'], () => getActivityValidationRules({ id: activityId }));

    if ((isActivityDataLoading && activityId !== undefined) || isRulesDataLoading) {
        return <LinearProgress />;
    } else if (activityDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="activity.error" />
                <ImpactHelmet title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        variant === ActivityPageVariant.CREATE ||
        ((variant === ActivityPageVariant.READ || variant === ActivityPageVariant.CLONE) && activityData)
    ) {
        return (
            <ActivityForm
                data={variant === ActivityPageVariant.CREATE ? { variant } : { variant, activityData: activityData! }}
                refreshData={refreshData}
                rules={rulesData}
            />
        );
    } else {
        return <Fragment />;
    }
};

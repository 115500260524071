import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { I18nMessageDTO } from './dto';
import { GetOne } from './types';

const RESOURCE_PATH = 'meta';

export const getLocalizations: GetOne<{}, Record<string, I18nMessageDTO>> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'localization']), config).then((response: AxiosResponse) => response.data);

import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ActivityReadDTO } from './dto';
import { GetManyPaged, GetOne } from './types';

export const ACTIVITY_PATH = 'activities';

export const getActivity: GetOne<{ id: string }, ActivityReadDTO> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id]), config).then((response) => response.data);

export const getActivities: GetManyPaged<ActivityReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);

import { Box, Breakpoint, Container } from '@mui/material';
import React from 'react';

interface DocumentPageContainerProps {
    maxWidth?: Breakpoint | false;
    noTopPadding?: boolean;
    noBottomPadding?: boolean;
    drawer: boolean;
    children: React.ReactNode;
}

export const DocumentPageContainer: React.FC<DocumentPageContainerProps> = ({
    maxWidth = 'lg',
    noTopPadding,
    noBottomPadding,
    drawer,
    children,
}) => {
    return (
        <Container maxWidth={maxWidth} disableGutters>
            <Box
                sx={{
                    padding: 2,
                    pt: noTopPadding ? 0 : undefined,
                    pb: drawer ? 10 : noBottomPadding ? 0 : undefined,
                }}
            >
                {children}
            </Box>
        </Container>
    );
};

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { SystemBsSrcReadDTO } from './dto';
import { GetManyPaged, RequestMethod } from './types';

const RESOURCE_PATH = 'systems';

export const getSystems: GetManyPaged<SystemBsSrcReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getSystemRoots: RequestMethod<any, SystemBsSrcReadDTO[]> = async () =>
    client.get(buildPath([RESOURCE_PATH, 'roots'])).then((response: AxiosResponse) => response.data);

export const getSystemChildren: RequestMethod<any, SystemBsSrcReadDTO[]> = async ({ code } = {}) =>
    client.get(buildPath([RESOURCE_PATH, code, 'children'])).then((response: AxiosResponse) => response.data);

import { Delete, Expand, FilterList, TableRows, VerticalAlignCenter, ViewStream } from '@mui/icons-material';
import { Badge, Box, IconButton, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SortingState, Table } from '@tanstack/react-table';
import React, { MouseEvent, ReactElement } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDeviceType } from '../../hooks/useDeviceType';
import { ColumnVisibilityButton } from '../tables/table/ColumnVisibilityButton';
import { SortingSettingsButton } from '../tables/table/SortingSettingsButton';
import { SearchFilter } from './filters';
import { QuickFiltersButtons } from './QuickFiltersButtons';
import { SearchView } from './SearchView';

export interface SortableField {
    id: string;
    label: string;
}

interface SearchPageHeaderProps<TData> {
    onFiltersClick: (event: MouseEvent<HTMLElement>) => void;
    onPredefinedFilterClick: (predefinedFilter: SearchFilter[]) => void;
    onViewChange: (event: MouseEvent<HTMLElement>, nextView: SearchView | null) => void;
    onExpandAllToggle: (event: MouseEvent<HTMLElement>) => void;
    activeFiltersCount: number;
    view: SearchView;
    isExpandAll: boolean;
    table: Table<TData>;
    sortableFields: SortableField[];
    sorting: SortingState;
    onSortingChange: (arg: SortingState) => void;
    hasViewChoice: boolean;
    selectedCount?: number;
    onClearSelection?: () => void;
    predefinedFilters?: {
        filters: SearchFilter[];
        active: boolean;
        labelKey: MessageDescriptor['id'];
    }[];
    hiddenColumnsIds: string[] | undefined;
}

export const SearchPageHeader = <TData,>({
    onFiltersClick,
    onPredefinedFilterClick,
    onViewChange,
    onExpandAllToggle,
    activeFiltersCount,
    view,
    isExpandAll,
    table,
    sortableFields,
    sorting,
    onSortingChange,
    hasViewChoice,
    selectedCount,
    onClearSelection,
    predefinedFilters,
    hiddenColumnsIds,
}: SearchPageHeaderProps<TData>): ReactElement => {
    const { isMobile } = useDeviceType();

    const sortedFieldId: string | null = sorting[0]?.id || null;
    const isSortingDesc = sorting[0]?.desc || false;

    const handleSortingFieldChange = (field: SortableField | null) => {
        if (field !== null) {
            onSortingChange([{ id: field.id ?? '', desc: isSortingDesc }]);
        } else {
            onSortingChange([]);
        }
    };
    const handleSortingOrderChange = (isOrderDesc: boolean) => {
        if (sortedFieldId !== null) {
            onSortingChange([{ id: sortedFieldId, desc: isOrderDesc }]);
        }
    };

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
            }}
        >
            <Stack direction="row" spacing={1}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" flex={1}>
                    <Stack
                        direction="row"
                        spacing={1}
                        minHeight={34} /* Minimum height corresponds to number of selected elements label */
                    >
                        <ToggleButtonGroup color="primary" size={isMobile ? undefined : 'small'}>
                            <ToggleButton value="filters" onClick={onFiltersClick} selected={activeFiltersCount > 0}>
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <Badge color="primary" badgeContent={activeFiltersCount}>
                                        <FilterList />
                                    </Badge>
                                    {!isMobile && (
                                        <Box>
                                            <FormattedMessage id="search.filters" />
                                        </Box>
                                    )}
                                </Stack>
                            </ToggleButton>

                            {view === SearchView.LIST ? (
                                <SortingSettingsButton
                                    sortableFields={sortableFields}
                                    sortedField={sortableFields.find((field) => field.id === sortedFieldId) || null}
                                    onFieldSelection={handleSortingFieldChange}
                                    isSortingDesc={isSortingDesc}
                                    setIsSortingDesc={handleSortingOrderChange}
                                />
                            ) : null}

                            {view === SearchView.TABLE ? (
                                <ColumnVisibilityButton hiddenColumnsIds={hiddenColumnsIds} table={table} />
                            ) : null}
                            {view === SearchView.LIST ? (
                                <ToggleButton onClick={onExpandAllToggle} value="expand">
                                    {isExpandAll ? <VerticalAlignCenter /> : <Expand />}
                                </ToggleButton>
                            ) : null}
                        </ToggleButtonGroup>

                        <QuickFiltersButtons
                            predefinedFilters={predefinedFilters}
                            onPredefinedFilterClick={onPredefinedFilterClick}
                        />
                    </Stack>

                    {hasViewChoice && (
                        <ToggleButtonGroup
                            value={view}
                            exclusive
                            onChange={onViewChange}
                            color="primary"
                            size={isMobile ? undefined : 'small'}
                        >
                            <ToggleButton value={SearchView.LIST}>
                                <ViewStream />
                            </ToggleButton>
                            <ToggleButton value={SearchView.TABLE}>
                                <TableRows />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    {selectedCount !== undefined && selectedCount > 0 && (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box>{selectedCount} elements selected</Box>
                            {onClearSelection !== undefined && (
                                <IconButton size="small" onClick={onClearSelection}>
                                    <Delete />
                                </IconButton>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

import { isValid } from 'date-fns';
import { TFilterElement } from '../../forms/types';
import { Nullable } from '../../lib/types';
import { FilterOperatorEnum, SearchFilter, SearchFilterTyped } from './filters';

export const sanitizeFiltersBySchema = (
    filters: Nullable<SearchFilter>[] | undefined | null,
    filterSchemas: TFilterElement[]
) =>
    (filters ?? [])
        .filter((filter): filter is SearchFilter => {
            return (
                filter.field !== null &&
                filter.operator !== null &&
                (!!filter.value ||
                    typeof filter.value === 'boolean' ||
                    [FilterOperatorEnum.IS_EMPTY, FilterOperatorEnum.IS_NOT_EMPTY].includes(filter.operator)) &&
                !(filter.value instanceof Date && !isValid(filter.value))
            );
        })
        .flatMap((filter): SearchFilterTyped[] => {
            const schema = filterSchemas.find((f) => f.id === filter.field);
            if (schema !== undefined) {
                const valueGetter = schema.valueGetter ?? ((original: any) => original);
                const newValue = Array.isArray(filter.value)
                    ? (filter.value as any[]).map((v) => valueGetter(v))
                    : valueGetter(filter.value);
                if (Array.isArray(newValue) && newValue.length === 0) {
                    return [];
                }
                const newFilter = { ...filter, value: newValue, type: schema.type };
                return [newFilter];
            } else {
                // Could happen with saved filter if we remove a feature
                return [];
            }
        });

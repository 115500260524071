import { FieldValues, Resolver } from 'react-hook-form';
import { ResolverResult } from 'react-hook-form/dist/types/resolvers';
import { FieldAttributes, PreciseRulesDTO, rulesValidator } from './deserializer';

export const formRulesResolver =
    <TFieldValues extends FieldValues = FieldValues, TState extends FieldValues = FieldValues, TContext = any>(
        originalValues: TFieldValues,
        rules: PreciseRulesDTO<TState>,
        context: TState,
        ignoredFields: Set<string>,
        attributesCallback: (attributes: Record<string, FieldAttributes>) => void,
        setValue: (name: string, value: any) => void
    ): Resolver<TFieldValues, TContext> =>
    (values, _, options): ResolverResult<TFieldValues> => {
        if (options.shouldUseNativeValidation) {
            throw new Error(); // Unsupported
        }
        const newAttributes = rulesValidator(originalValues, values, rules, context, setValue, ignoredFields);
        attributesCallback(newAttributes);
        // Errors are always provided from the backend, we don't compute them here
        return {
            errors: {},
            values: values, // TODO currently modified in place; do we need a deep copy?
        };
    };

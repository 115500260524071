import { useEffect } from 'react';

export const useCloseDrawerOnResize = (mobileScreenBreakPoint: number, closeDrawer: () => void) => {
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < mobileScreenBreakPoint) {
                closeDrawer();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [mobileScreenBreakPoint, closeDrawer]);
};

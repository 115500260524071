import { FileCopy } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getContracts } from '../../api/activities-nim';
import { ContractNimReadDTO, SupplierReadDTO } from '../../api/dto';
import { PersonTooltip } from '../../components/PersonTooltip';
import { FilterTypeEnum } from '../../components/searching/filters';
import { SearchTableWidget } from '../../components/SearchTableWidget';
import { fetchContractsByString, fetchSupplierByCode } from '../../lib/api';
import { getContractLabel, getSupplierCodeLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import {
    AdornableElement,
    AsyncAutocompleteFormElement,
    AutocompleteFormElement,
    TFilterElement,
    TFormElement,
} from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { personFilterSchema } from './person';
import { supplierFilterSchema } from './supplier';

const getContractId = (contract: ContractNimReadDTO) => contract.id;
const columnHelper = createColumnHelper<ContractNimReadDTO>();
const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.contractNumber ?? '', {
        id: 'contractNumber',
        header: intl.formatMessage({ id: 'activity.field.contract.contractNumber' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.employer?.code ?? '', {
        id: 'employer.code',
        header: intl.formatMessage({ id: 'activity.field.contract.companyCode' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.employer?.name ?? '', {
        id: 'employer.name',
        header: intl.formatMessage({ id: 'activity.field.contract.companyName' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.cernContractResponsible?.searchLabel ?? '', {
        id: 'cernContractResponsible',
        header: intl.formatMessage({ id: 'activity.field.contract.responsible' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.cernContractResponsible && (
                <PersonTooltip person={original.cernContractResponsible}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
];
const filterSchemas = ({ formatMessage }: IntlShape): TFilterElement[] => [
    {
        id: 'contractNumber',
        label: formatMessage({ id: 'activity.field.contract.contractNumber' }),
        type: FilterTypeEnum.STRING,
    },
    supplierFilterSchema({
        id: 'employer.code',
        label: formatMessage({ id: 'activity.field.contract.companyCode' }),
        fetchOptions: fetchSupplierByCode,
        getLabel: getSupplierCodeLabel,
    }),
    supplierFilterSchema({
        id: 'employer.name',
        label: formatMessage({ id: 'activity.field.contract.companyName' }),
        valueGetter: (value: SupplierReadDTO) => value.name,
    }),
    personFilterSchema({
        id: 'cernContractResponsible',
        label: formatMessage({ id: 'activity.field.contract.responsible' }),
    }),
];

type ContractsSearchTableDialogProps = Parameters<NonNullable<AdornableElement['endAdornment']>>[0];

const ContractsSearchTableDialog: React.FC<ContractsSearchTableDialogProps> = ({
    field,
    updateValue,
    matchId,
    disabled,
    readOnly,
}) => {
    const intl = useIntl();
    const { value } = field;
    const columnsMemo = useMemo(() => columns(intl), [intl]);
    return (
        <SearchTableWidget
            title={intl.formatMessage({ id: 'activity.contract.title' })}
            disabled={disabled}
            readOnly={readOnly}
            selectedNodes={value ? [value] : []}
            onSelect={(nodes: ContractNimReadDTO[]) => {
                if (!nodes) return;
                updateValue && updateValue(matchId ? getContractId(nodes[nodes.length - 1]) : nodes[nodes.length - 1]);
            }}
            columns={columnsMemo}
            filterSchemas={filterSchemas(intl)}
            getRowId={getContractId}
            pagedApiCall={getContracts}
            adornmentIcon={<FileCopy />}
        />
    );
};

const contractTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchContractsByString,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getContractLabel,
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
    multiple: false,
    endAdornment: ContractsSearchTableDialog,
});
const contractTypePropertiesWithoutAdornment = (): Omit<
    AsyncAutocompleteFormElement['typeProperties'],
    'endAdornment'
> => {
    const { endAdornment, ...rest } = contractTypeProperties();
    return rest;
};
interface ContractsSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const contractsSchema = ({ path, label, required }: ContractsSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: contractTypeProperties(),
});

interface ContractFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const contractsFilterSchema = ({ id, label }: ContractFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: ContractNimReadDTO) => value.id,
    typeProperties: contractTypePropertiesWithoutAdornment(),
});

import {
    ApiErrorDTO,
    ApiValidationErrorItemDTO,
    LanguageCode,
    ListOfValuesReadDTO,
    PersonReadDTO,
    TaskDTO,
    TaskTypeDTO,
    WorkflowHistoryEntryDTO,
} from '../api/dto';
import { Locale } from '../i18n/Locale';

export const getPreferredLanguageValue = (
    person: PersonReadDTO,
    availableLanguages: ListOfValuesReadDTO[]
): ListOfValuesReadDTO | undefined => {
    const LANGUAGE_CODES: Record<PersonReadDTO['preferredLanguage'], ListOfValuesReadDTO['code']> = {
        EN: LanguageCode.ENGLISH,
        FR: LanguageCode.FRENCH,
    };
    const preferredLanguageCode = LANGUAGE_CODES[person.preferredLanguage];
    return availableLanguages.filter(({ code }) => code === preferredLanguageCode)[0];
};

export const translateValue = (
    { labelEn, labelFr, descriptionEn, descriptionFr, ...rest }: ListOfValuesReadDTO,
    locale: Locale
): Omit<ListOfValuesReadDTO, 'labelEn' | 'labelFr' | 'descriptionEn' | 'descriptionFr'> => ({
    ...rest,
    label: { en: labelEn, fr: labelFr }[locale],
    description: { en: descriptionEn, fr: descriptionFr }[locale],
});

export const translateInfoline = (
    { messageEn, messageFr, ...rest }: WorkflowHistoryEntryDTO,
    locale: Locale
): Omit<WorkflowHistoryEntryDTO, 'messageEn' | 'messageFr'> & { message: string } => ({
    ...rest,
    message: { en: messageEn, fr: messageFr }[locale],
});

const translateApiErrorItem = (
    { messageEn, messageFr, ...rest }: ApiValidationErrorItemDTO,
    locale: Locale
): Omit<ApiValidationErrorItemDTO, 'messageEn' | 'messageFr'> & { message: string } => ({
    ...rest,
    message: { en: messageEn, fr: messageFr }[locale],
});

export const translateApiError = (
    { messageEn, messageFr, errorItems, ...rest }: ApiErrorDTO<ApiValidationErrorItemDTO>,
    locale: Locale
): Omit<ApiErrorDTO<ReturnType<typeof translateApiErrorItem>>, 'messageEn' | 'messageFr'> & { message: string } => ({
    ...rest,
    message: { en: messageEn, fr: messageFr }[locale],
    errorItems: errorItems.map((item) => translateApiErrorItem(item, locale)),
});

export const translateTaskType = (
    { typeNameEn, typeNameFr, ...rest }: TaskTypeDTO,
    locale: Locale
): Omit<TaskTypeDTO, 'typeNameEn' | 'typeNameFr'> & { typeName: string } => ({
    ...rest,
    typeName: { en: typeNameEn, fr: typeNameFr }[locale],
});

export const translateTask = (
    { assignmentLabelEn, assignmentLabelFr, ...rest }: TaskDTO,
    locale: Locale
): Omit<TaskDTO, 'assignmentLabelEn' | 'assignmentLabelFr'> & { assignmentLabel: string } => ({
    ...rest,
    assignmentLabel: { en: assignmentLabelEn, fr: assignmentLabelFr }[locale],
});

import { TextField } from '@mui/material';
import React, { HTMLAttributes, useCallback } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { AdornableElement } from '../types';

type InputProps = {
    field: ControllerRenderProps<FieldValues, string>;
    label: string;
    helperText?: string;
    error: FieldError | undefined;
    multiline?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    inputTypeProps?: { inputMode?: HTMLAttributes<unknown>['inputMode']; pattern?: string; type?: string };
} & AdornableElement;

export const Input: React.FC<InputProps> = ({
    field,
    label,
    helperText,
    error,
    multiline = false,
    disabled = false,
    readOnly = false,
    required = false,
    endAdornment = () => null,
    inputTypeProps = { type: 'text' },
}) => {
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (inputTypeProps.pattern && !new RegExp(inputTypeProps.pattern).test(e.target.value)) {
                e.stopPropagation();
                return;
            }
            if (!multiline) {
                e.target.value = e.target.value?.replace(/[\n\r]/g, '');
            }
            return field.onChange(e);
        },
        [multiline, field.onChange]
    );

    return (
        <TextField
            {...field}
            onChange={onChange}
            inputRef={field.ref}
            inputProps={{
                ...inputTypeProps,
            }}
            ref={null}
            value={field.value ?? ''}
            label={label}
            size="small"
            error={!!error}
            multiline // IMPACT-2333
            helperText={error?.message || helperText}
            disabled={disabled || readOnly}
            required={required}
            fullWidth
            sx={{
                flexGrow: 1,
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: endAdornment({ field, disabled, readOnly }),
            }}
        />
    );
};

import { Badge } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { AttachmentDTO, AttachmentsIdsDTO, AttachmentUploadDTO } from '../../../api/dto';
import { AttachmentsWidget } from '../../../components/attachments/AttachmentsWidget';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../../components/documents/DocumentButtonsStack';

interface AttachmentsActionProps {
    attachments: AttachmentDTO[];
    linkAttachments: (attachments: AttachmentsIdsDTO) => Promise<number[]>;
    unlinkAttachments: (attachments: AttachmentsIdsDTO) => Promise<number[]>;
    updateAttachment: (args: { attachmentId: number; data: AttachmentUploadDTO }) => Promise<AttachmentDTO>;
    onAttachmentsLinks?: () => void;
    readOnly?: boolean;
}

export const AttachmentsAction: React.FC<AttachmentsActionProps> = ({
    attachments: initialAttachments,
    linkAttachments: linkAttachmentsCallback,
    unlinkAttachments: unlinkAttachmentsCallback,
    updateAttachment,
    onAttachmentsLinks = () => {},
    readOnly,
}) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [attachmentsCount, setAttachmentsCount] = useState(initialAttachments.length);

    const { mutate: unlinkAttachments } = useMutation(unlinkAttachmentsCallback, {
        onSuccess: (data) => {
            onAttachmentsLinks();
            setAttachmentsCount(attachmentsCount - data.length);
        },
        onError: () => toast.error(intl.formatMessage({ id: 'document.attachment.errorLink' })),
    });

    const { mutate: linkAttachments } = useMutation(linkAttachmentsCallback, {
        onSuccess: (data) => {
            onAttachmentsLinks();
            setAttachmentsCount(attachmentsCount + data.length);
        },
        onError: () => toast.error(intl.formatMessage({ id: 'document.attachment.errorLink' })),
    });

    const onLinkAttachments = (attachments: AttachmentDTO[]) =>
        linkAttachments({ attachmentsIds: attachments.map((e) => e.id) });

    const onUnlinkAttachments = (attachments: AttachmentDTO[]) =>
        unlinkAttachments({ attachmentsIds: attachments.map((e) => e.id) });

    return (
        <>
            <DocumentButtonsStack.Action
                {...COMMON_DOCUMENT_BUTTONS.ATTACHMENTS}
                icon={
                    attachmentsCount > 0 ? (
                        <Badge
                            color="primary"
                            badgeContent={attachmentsCount}
                            sx={{
                                svg: {
                                    fontSize: {
                                        md: '18px' /* Prone to breakage, but is enough to address the discrepancy */,
                                    },
                                },
                            }}
                        >
                            {COMMON_DOCUMENT_BUTTONS.ATTACHMENTS.icon}
                        </Badge>
                    ) : (
                        COMMON_DOCUMENT_BUTTONS.ATTACHMENTS.icon
                    )
                }
                onClick={() => setOpen(true)}
            />
            <AttachmentsWidget
                open={open}
                onClose={() => setOpen(false)}
                initialAttachments={initialAttachments}
                onLink={onLinkAttachments}
                onUnlink={onUnlinkAttachments}
                updateAttachment={updateAttachment}
                readOnly={readOnly}
            />
        </>
    );
};

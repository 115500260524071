import { Alert, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const VicWarningPanel: React.FC = () => {
    return (
        <>
            <Stack direction="column" spacing={2}>
                <Alert severity="info">
                    <FormattedMessage id="vic.warning.title" />
                </Alert>
                <Box>
                    <Typography>
                        <FormattedMessage id="vic.warning.subtitle" />
                    </Typography>
                </Box>
            </Stack>
        </>
    );
};

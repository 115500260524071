import { AddBox } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDeviceType } from '../hooks/useDeviceType';
import { ROUTES } from '../routes/routes';
import { ResponsiveButton } from './ResponsiveButton';

interface CreateDropdownProps {
    closeMenu: () => void;
}

export const CreateDropdown: React.FC<CreateDropdownProps> = ({ closeMenu }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const isMobile: boolean = useDeviceType().isMobile;

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };
    const handleClose = () => {
        setAnchorEl(null);
        if (isMobile) {
            closeMenu();
        }
    };

    const options = [
        {
            label: <FormattedMessage id="sidebar.action.createActivity" />,
            onOptionClick: () => {
                navigate(ROUTES.activity.create);
                handleClose();
            },
            disabled: false,
        },
        {
            label: <FormattedMessage id="sidebar.action.createUrgentIntervention" />,
            onOptionClick: () => {
                handleClose();
            },
            disabled: true, //TODO look at OIM: file UserMenuPresenter.java line 605
        },
        {
            label: <FormattedMessage id="sidebar.action.createActivityCluster" />,
            onOptionClick: () => {
                handleClose();
            },
            disabled: true, //TODO look at OIM: function canUserCreateActivityCluster in ActivityClusterUtils.groovy
        },
        {
            label: <FormattedMessage id="sidebar.action.createWDP" />,
            onOptionClick: () => {
                navigate(ROUTES.wdp.create);
                handleClose();
            },
            disabled: false,
        },
    ];

    return (
        <>
            <ResponsiveButton
                icon={<AddBox />}
                buttonVariant={'contained'}
                onClick={() => {
                    if (isMobile) {
                        closeMenu();
                    }
                    navigate(ROUTES.activity.create);
                }}
                onClickExpand={handleOpen}
                tooltipText={''}
                dropdown={true}
                large={true}
                split={true}
            >
                <FormattedMessage id="sidebar.action.createActivity" />
            </ResponsiveButton>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {options.map(({ label, onOptionClick, disabled }, i) => (
                    <MenuItem key={i} onClick={onOptionClick} disabled={disabled}>
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LanguageCode } from '../../api/dto';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';
import { PdfDialog } from '../PdfDialog';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from './DocumentButtonsStack';

const PDF_HASH = '#pdf';

interface LocalizedDocumentPdfButtonProps {
    queryKey: (string | number)[];
    fetchPdf: (language: LanguageCode) => Promise<Blob>;
    isPdfPreview: boolean;
    canGenerateInBothLanguages: boolean;
    dirty?: boolean;
}

export const LocalizedDocumentPdfButton: React.FC<LocalizedDocumentPdfButtonProps> = ({
    queryKey,
    fetchPdf,
    isPdfPreview,
    canGenerateInBothLanguages,
    dirty,
}) => {
    const intl = useIntl();
    const { preferredLanguage } = useApplicationSettings();
    const [open, setOpen] = useState(false);
    const preferredLanguageCode = preferredLanguage?.code ?? LanguageCode.ENGLISH;
    const [selectedLanguage, setSelectedLanguage] = useState(preferredLanguageCode);

    const handleOpen = (language: LanguageCode) => {
        setSelectedLanguage(language);
        setOpen(true);
    };

    useEffect(() => {
        if (window.location.hash === PDF_HASH) {
            handleOpen(preferredLanguageCode);
        }
    }, []);

    const getLanguageOptions = () =>
        (
            [
                { labelKey: 'language.english', value: LanguageCode.ENGLISH },
                { labelKey: 'language.french', value: LanguageCode.FRENCH },
            ] as const
        ).map(({ labelKey, value }) => ({
            label: intl.formatMessage({ id: labelKey }),
            onClick: () => handleOpen(value),
        }));

    const handleClose = () => {
        if (window.location.hash === PDF_HASH) {
            history.replaceState('', document.title, window.location.pathname + window.location.search);
        }
        setOpen(false);
    };

    return (
        <>
            <DocumentButtonsStack.Action
                {...(isPdfPreview ? COMMON_DOCUMENT_BUTTONS.PREVIEW : COMMON_DOCUMENT_BUTTONS.PRINT)}
                split
                onClick={() => handleOpen(preferredLanguageCode)}
                options={canGenerateInBothLanguages ? getLanguageOptions() : undefined}
                disabled={dirty}
                disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
            />
            <PdfDialog
                queryKey={queryKey}
                fetchPdf={() => fetchPdf(selectedLanguage)}
                open={open}
                onClose={handleClose}
            />
        </>
    );
};

import { Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DefaultAccordion } from '../components/DefaultAccordion';
import { AsyncAutocompleteElement } from './fields/AsyncAutocompleteElement';
import { AutocompleteElement } from './fields/AutocompleteElement';
import { FieldArray } from './fields/FieldArray';
import { FieldArrayTabs } from './fields/FieldArrayTabs';
import { FilterGroup } from './fields/FilterGroup';
import { ImpactDatePicker } from './fields/ImpactDatePicker';
import { ImpactDateTimePicker } from './fields/ImpactDateTimePicker';
import { ImpactTimePicker } from './fields/ImpactTimePicker';
import { Input } from './fields/Input';
import { RichTextEditor } from './fields/RichTextEditor';
import { useFieldsAttributesContext } from './FieldsAttributesContext';
import { FormGroupErrorContextProviderConsumer } from './FormGroupErrorContext';
import { FieldAttributes } from './rule/deserializer';
import { InputFormElement, LayoutHorizontalFormElement, TFormElement } from './types';
import { UiSchemaType } from './UiSchemaType';
import { injectCurrentPath, joinFieldPath } from './utils';

type Paths =
    | { path: undefined; children: undefined }
    | { path: string; children: undefined }
    | { path: undefined; children: (string | undefined)[][] };

const flatten = (paths: Paths): (string | undefined)[] =>
    paths.path !== undefined ? [paths.path] : paths.children !== undefined ? paths.children.flat() : [undefined];

const getPaths = (e: TFormElement, currentPath: string, shouldPrependPath: boolean = false): Paths => {
    switch (e.type) {
        case UiSchemaType.INPUT:
        case UiSchemaType.NUMBER:
        case UiSchemaType.AUTOCOMPLETE:
        case UiSchemaType.AUTOCOMPLETE_ASYNC:
        case UiSchemaType.CHECKBOX:
        case UiSchemaType.DATE:
        case UiSchemaType.DATETIME:
        case UiSchemaType.TIME:
        case UiSchemaType.FIELD_ARRAY:
        case UiSchemaType.FIELD_ARRAY_TABS:
        case UiSchemaType.RICH_TEXT:
            return {
                path: shouldPrependPath && !!currentPath ? joinFieldPath([currentPath, e.path]) : e.path,
                children: undefined,
            };
        case UiSchemaType.LAYOUT_VERTICAL:
        case UiSchemaType.LAYOUT_HORIZONTAL:
        case UiSchemaType.REGION:
            return {
                path: undefined,
                children: e.elements.map((child) => flatten(getPaths(child, currentPath, true))),
            };
        case UiSchemaType.LAYOUT_GRID:
        case UiSchemaType.LAYOUT_RESPONSIVE:
            return {
                path: undefined,
                children: e.elements.map((child) => flatten(getPaths(child.element, currentPath, true))),
            };
        case UiSchemaType.PANEL:
            return { path: undefined, children: e.element ? [flatten(getPaths(e.element, currentPath, true))] : [] };
        default:
            return { path: undefined, children: undefined };
    }
};

const isVisible = (attributes: FieldAttributes): boolean => attributes.editable || attributes.visible;

interface FormGeneratorProps {
    rootElement: TFormElement;
    form: UseFormReturn<any, object>;
    currentPath?: string;
    disabled?: boolean;
    readOnly?: boolean;
}

type FormElementProps = {
    form: UseFormReturn;
    element: TFormElement;
    currentPath: string;
};

const FormElement: React.FC<FormElementProps> = ({ form, element, currentPath }) => {
    const { control, register } = form;
    const { disabled, readOnly: elementReadOnly } = element;

    const elementPaths = useMemo(() => getPaths(element, currentPath, false), [element, currentPath]);

    const optionalPath = elementPaths.path;
    const optionalChildrenPaths = useMemo(() => elementPaths.children ?? [], [elementPaths.children]);

    const optionalPathHoisted = useMemo(() => [[optionalPath]], [optionalPath]);
    const [[attributes]] = useFieldsAttributesContext(optionalPathHoisted);
    const childrenAttributes = useFieldsAttributesContext(optionalChildrenPaths);
    if (!isVisible(attributes)) {
        return null;
    }

    const readOnly = elementReadOnly || !attributes.editable;

    switch (element.type) {
        case UiSchemaType.FIELD_ARRAY: {
            return (
                <FieldArray
                    key={element.path}
                    form={form}
                    element={element}
                    disabled={disabled || readOnly}
                    required={attributes.mandatory}
                />
            );
        }
        case UiSchemaType.FIELD_ARRAY_TABS: {
            return <FieldArrayTabs key={element.path} form={form} element={element} />;
        }
        case UiSchemaType.FILTER_GROUP: {
            return <FilterGroup key={element.path} form={form} element={element} />;
        }
        case UiSchemaType.LAYOUT_VERTICAL: {
            const { elements } = element;
            return (
                <Stack direction="column" spacing={2} flexGrow={1}>
                    {elements.map((el, index) => (
                        <FormElement
                            key={index}
                            form={form}
                            element={injectCurrentPath(element)(el)}
                            currentPath={currentPath}
                        />
                    ))}
                </Stack>
            );
        }
        case UiSchemaType.LAYOUT_HORIZONTAL: {
            const { elements } = element;
            return (
                <Stack direction="row" spacing={2} flexGrow={1}>
                    {elements.map((el, index) => (
                        <FormElement
                            key={index}
                            form={form}
                            element={injectCurrentPath(element)(el)}
                            currentPath={currentPath}
                        />
                    ))}
                </Stack>
            );
        }
        case UiSchemaType.LAYOUT_RESPONSIVE: {
            const { elements, typeProperties } = element;
            return childrenAttributes.flat().some((a) => isVisible(a)) ? (
                <Stack direction={typeProperties?.direction} spacing={2} flexGrow={1}>
                    {elements.map((item, index) =>
                        childrenAttributes[index].some((childAttributes) => isVisible(childAttributes)) ? (
                            <Box key={index} flex={item.flex} sx={{ width: '100%' }}>
                                <FormElement
                                    form={form}
                                    element={injectCurrentPath(element)(item.element)}
                                    currentPath={currentPath}
                                />
                            </Box>
                        ) : null
                    )}
                </Stack>
            ) : null;
        }
        case UiSchemaType.LAYOUT_GRID: {
            const { elements, typeProperties } = element;
            const { columns = 12 } = typeProperties ?? {};
            return (
                <Box>
                    <Grid container columns={columns} spacing={2}>
                        {elements.map(({ xs, sm, md, lg, xl, element: el }, idx) => {
                            return (
                                <Grid item key={idx} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                    <FormElement
                                        form={form}
                                        element={injectCurrentPath(element)(el)}
                                        currentPath={currentPath}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            );
        }
        case UiSchemaType.PANEL: {
            const { label, element: child, typeProperties, additionalText } = element;
            return (
                <FormGroupErrorContextProviderConsumer>
                    {(isError) => (
                        <DefaultAccordion
                            title={label}
                            additionalText={additionalText}
                            error={isError}
                            icon={typeProperties?.icon}
                            disabled={disabled}
                            disableGutters={typeProperties?.disableGutters}
                            collapsed={typeProperties?.collapsed}
                        >
                            {child && (
                                <FormElement
                                    form={form}
                                    element={injectCurrentPath(element)(child)}
                                    currentPath={currentPath}
                                />
                            )}
                        </DefaultAccordion>
                    )}
                </FormGroupErrorContextProviderConsumer>
            );
        }
        case UiSchemaType.REGION: {
            const { elements } = element;
            return (
                <Box m={1} p={1} sx={{ display: 'flex', flexDirection: 'row', flex: 1, border: '1px dashed #ddd' }}>
                    {elements.map((el, index) => (
                        <FormElement
                            key={index}
                            form={form}
                            element={injectCurrentPath(element)(el)}
                            currentPath={currentPath}
                        />
                    ))}
                </Box>
            );
        }
        case UiSchemaType.INPUT:
        case UiSchemaType.NUMBER: {
            const { path, label, required, typeProperties } = element;
            const { ref, ...registered } = register(path);
            const pattern = (() => {
                const p = element.typeProperties ?? {};
                if ('pattern' in p) {
                    return p.pattern;
                } else {
                    const integer = 'integer' in p && p.integer,
                        signed = 'signed' in p && !!p.signed;
                    return `^${signed ? '-?' : ''}[0-9]*${integer ? '' : '\\.?[0-9]*'}$`;
                }
            })();
            return (
                <Controller
                    key={path}
                    control={control}
                    {...registered}
                    render={({ field, fieldState }) => (
                        <Input
                            field={field}
                            label={label}
                            error={fieldState.error}
                            disabled={disabled}
                            readOnly={readOnly}
                            required={required || attributes.mandatory}
                            inputTypeProps={
                                element.type === UiSchemaType.NUMBER
                                    ? {
                                          inputMode: 'numeric',
                                          pattern,
                                          type: 'text',
                                      }
                                    : { type: 'text' }
                            }
                            {...typeProperties}
                        />
                    )}
                />
            );
        }
        case UiSchemaType.RICH_TEXT: {
            const { path, label, required, placeholder } = element;
            const { ref, ...registered } = register(path);
            const { setValue } = form;

            return (
                <Controller
                    key={path}
                    control={control}
                    {...registered}
                    render={({ field, fieldState }) => (
                        <RichTextEditor
                            label={label}
                            name={field.name}
                            value={field.value}
                            setValue={setValue}
                            disabled={disabled || readOnly}
                            required={required || attributes.mandatory}
                            error={fieldState.error}
                            placeholder={placeholder}
                        />
                    )}
                />
            );
        }
        case UiSchemaType.AUTOCOMPLETE: {
            const { path, label, required, typeProperties } = element;
            const { ref, ...registered } = register(path);
            return (
                <Controller
                    key={path}
                    control={control}
                    {...registered}
                    render={({ field, fieldState }) => (
                        <AutocompleteElement
                            field={field}
                            fieldState={fieldState}
                            disabled={disabled}
                            readOnly={readOnly}
                            label={label}
                            {...typeProperties}
                            autocompleteProps={{ fullWidth: true, size: 'small', ...typeProperties.autocompleteProps }}
                            textFieldProps={{
                                InputLabelProps: { shrink: true, required: required || attributes.mandatory },
                                ...typeProperties.textFieldProps,
                            }}
                        />
                    )}
                />
            );
        }
        case UiSchemaType.AUTOCOMPLETE_ASYNC: {
            const { path, label, required, typeProperties } = element;
            const { ref, ...registered } = register(path);

            return (
                <Controller
                    key={path}
                    control={control}
                    {...registered}
                    render={({ field, fieldState }) => (
                        <AsyncAutocompleteElement
                            key={path}
                            queryKey={[path]}
                            field={field}
                            fieldState={fieldState}
                            disabled={disabled}
                            readOnly={readOnly}
                            label={label}
                            {...typeProperties}
                            autocompleteProps={{ fullWidth: true, size: 'small', ...typeProperties.autocompleteProps }}
                            textFieldProps={{
                                InputLabelProps: { shrink: true },
                                InputProps: { required: required || attributes.mandatory },
                                ...typeProperties.textFieldProps,
                            }}
                        />
                    )}
                />
            );
        }
        case UiSchemaType.CHECKBOX: {
            const { path, label, typeProperties = {} } = element;
            const { endAdornment = () => null } = typeProperties;
            const { ref, ...registered } = register(path);
            return (
                <Controller
                    key={path}
                    control={control}
                    {...registered}
                    render={({ field: { value, ...field }, fieldState }) => (
                        <FormGroup>
                            <Stack direction="row" alignItems="center">
                                <FormControlLabel
                                    inputRef={ref}
                                    control={
                                        <Checkbox
                                            checked={value}
                                            disabled={disabled || readOnly}
                                            {...field}
                                            // See https://github.com/mui/material-ui/issues/17043
                                            onChange={!readOnly ? field.onChange : undefined}
                                            sx={{
                                                ml: 1,
                                                color: fieldState.error ? 'error.main' : undefined,
                                            }}
                                        />
                                    }
                                    label={label}
                                />
                                {endAdornment({ field: { value, ...field }, disabled, readOnly })}
                            </Stack>
                            {fieldState.error?.message && (
                                <FormHelperText error sx={{ ml: 1 }}>
                                    {fieldState.error.message}
                                </FormHelperText>
                            )}
                        </FormGroup>
                    )}
                />
            );
        }
        case UiSchemaType.DATE: {
            const { path, label, required, typeProperties } = element;
            return (
                <ImpactDatePicker
                    key={path}
                    label={label}
                    registerReturn={register(path)}
                    control={control}
                    disabled={disabled || readOnly}
                    required={required || attributes.mandatory}
                    {...typeProperties}
                />
            );
        }
        case UiSchemaType.DATETIME: {
            const { path, label, required, typeProperties } = element;
            return (
                <ImpactDateTimePicker
                    key={path}
                    label={label}
                    registerReturn={register(path)}
                    control={control}
                    disabled={disabled || readOnly}
                    required={required || attributes.mandatory}
                    {...typeProperties}
                />
            );
        }
        case UiSchemaType.TIME: {
            const { path, label, required, typeProperties } = element;
            return (
                <ImpactTimePicker
                    key={path}
                    label={label}
                    registerReturn={register(path)}
                    control={control}
                    disabled={disabled || readOnly}
                    required={required || attributes.mandatory}
                    minutesStep={typeProperties?.minutesStep}
                />
            );
        }
        case UiSchemaType.RANGE: {
            const { label, typeProperties } = element;
            const { fromPath, toPath, fromLabel = '', toLabel = '', minValue = 0, maxValue = 100 } = typeProperties;
            const inputProps = {
                type: 'number',
                inputProps: {
                    min: minValue,
                    max: maxValue,
                },
                InputProps: {
                    endAdornment: <></>,
                },
                disabled: disabled || readOnly,
            };
            const rangeSchema = {
                type: UiSchemaType.LAYOUT_HORIZONTAL,
                elements: [
                    {
                        type: UiSchemaType.INPUT,
                        path: fromPath,
                        label: fromLabel,
                        typeProperties: inputProps,
                    } as InputFormElement,
                    {
                        type: UiSchemaType.INPUT,
                        path: toPath,
                        label: toLabel,
                        typeProperties: inputProps,
                    } as InputFormElement,
                ],
            } as LayoutHorizontalFormElement;

            return (
                <>
                    <Typography>{label}:</Typography>
                    <FormElement
                        form={form}
                        element={injectCurrentPath(element)(rangeSchema)}
                        currentPath={currentPath}
                    />
                </>
            );
        }
        case UiSchemaType.CUSTOM: {
            const { Component, type, ...props } = element;
            return <Component {...props} />;
        }
    }
};

export const FormGenerator: React.FC<FormGeneratorProps> = ({ rootElement, form, currentPath, disabled, readOnly }) => {
    return (
        <FormElement
            form={form}
            element={{
                ...rootElement,
                disabled: rootElement.disabled || disabled,
                readOnly: rootElement.readOnly || readOnly,
            }}
            currentPath={currentPath ?? ''}
        />
    );
};

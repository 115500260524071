import { LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalizations } from '../../api/meta';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { LocalizationTable } from '../../components/LocalizationTable';

export const LocalizationBackendPage: React.FC = () => {
    const intl = useIntl();
    const { isLoading, data } = useQuery(['backend-localizations'], () => getLocalizations());
    return data ? (
        <>
            <ImpactHelmet title={intl.formatMessage({ id: 'meta.localization.backend.title' })} />
            <LocalizationTable data={data} />
        </>
    ) : isLoading ? (
        <LinearProgress />
    ) : (
        <FormattedMessage id="common.error" />
    );
};

import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getVics, getVicStatuses } from '../../../api/documents/vics';
import { DocumentType, SavedFilterType, VicGridReadDTO } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { interventionPeriodFilterSchema } from '../../../forms/schemas/interventionPeriod';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { getVicStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: 'id',
        label: intl.formatMessage({ id: 'vic.field.vicNumber' }),
        type: FilterTypeEnum.NUMBER,
    },
    valueFilterSchema(
        {
            id: 'status.code',
            label: intl.formatMessage({ id: 'document.field.status' }),
            fetchOptions: getVicStatuses,
        },
        intl.locale
    ),
    personFilterSchema({ id: 'createdBy.searchLabel', label: intl.formatMessage({ id: 'document.field.creator' }) }),
    {
        id: 'title',
        label: intl.formatMessage({ id: 'document.field.title' }),
        type: FilterTypeEnum.STRING,
    },
    interventionPeriodFilterSchema({
        id: 'activities.interventionPeriod.periodName',
        label: intl.formatMessage({ id: 'activity.field.interventionPeriod' }),
    }),
    activityFilterSchema({ id: 'activities.id', label: intl.formatMessage({ id: 'activity.name' }) }, intl),
    {
        id: 'scheduledStartDate',
        label: intl.formatMessage({ id: 'document.field.startDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'scheduledEndDate',
        label: intl.formatMessage({ id: 'document.field.endDate' }),
        type: FilterTypeEnum.DATE,
    },
    valueFilterSchema(
        {
            id: 'facility.code',
            label: intl.formatMessage({ id: 'document.field.facility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    // TODO intervention period
];

const columnHelper = createColumnHelper<VicGridReadDTO>();

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: intl.formatMessage({ id: 'vic.field.vicNumber' }),
        size: 70,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.vic.view,
                    params: { id: original.id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    // TODO inconsistencies
    columnHelper.accessor((row) => getLovLabel(translateValue(row.status, intl.locale)), {
        id: 'status.label',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 140,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
        cell: ({ row }) => <StatusChip {...getVicStatus(row.original, intl)} size="small" />,
    }),
    columnHelper.accessor((row) => row.createdBy?.searchLabel ?? '', {
        id: 'createdBy.searchLabel',
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.createdBy && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.title ?? '', {
        id: 'title',
        header: intl.formatMessage({ id: 'document.field.title' }),
        size: 240,
    }),
    columnHelper.accessor((row) => row.scheduledStartDate ?? '', {
        id: 'scheduledStartDate',
        header: intl.formatMessage({ id: 'document.field.startDate' }),
        size: 60,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.scheduledEndDate ?? '', {
        id: 'scheduledEndDate',
        header: intl.formatMessage({ id: 'document.field.endDate' }),
        size: 60,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
];

export const VicSearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.vicSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'vic.name' })}
            columns={columns(intl)}
            pageKey="VicSearchPage"
            documentType={DocumentType.VIC}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getVics}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Vic, vic: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.VIC}
        />
    );
};

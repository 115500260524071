import SensorsIcon from '@mui/icons-material/Sensors';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { getIs37Sensors } from '../../api/documents/is37s';
import { CsamSensorReadDTO, IS37ReadDTO } from '../../api/dto';
import { FilterOperatorEnum, FilterTypeEnum, SearchFilter } from '../../components/searching/filters';
import { SearchTableWidget } from '../../components/SearchTableWidget';
import { DATATYPES } from '../../components/tables/table/utils';
import { fetchSensorsByName } from '../../lib/api';
import { getCsamSensorLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import {
    AdornableElement,
    AsyncAutocompleteFormElement,
    AutocompleteFormElement,
    TFilterElement,
    TFormElement,
} from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { is37AlarmTypeFilterSchema } from './is37AlarmType';

const ALARM_LOCATION_BUILDING = 'building';
const ALARM_LOCATION_FLOOR = 'floor';
const ALARM_LOCATION_ROOM = 'room';
const CSAM_ALARM_CENTRAL = 'cernSafetyEquipmentName';
const CSAM_ALARM_TYPE_ID = 'csamAlarmType';

const getCsamSensorId = (sensor: CsamSensorReadDTO) => sensor.id;

const columnHelper = createColumnHelper<CsamSensorReadDTO>();
const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.sensorName ?? '', {
        id: 'sensorName',
        header: intl.formatMessage({ id: 'document.field.code' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.fireBrigadeDescription ?? '', {
        id: 'fireBrigadeDescription',
        header: intl.formatMessage({ id: 'document.field.description' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.csamAlarmType ?? '', {
        id: CSAM_ALARM_TYPE_ID,
        header: intl.formatMessage({ id: 'is37.field.alarmType' }),
        size: 40,
    }),
    columnHelper.accessor((row) => row.alarmType ?? '', {
        id: 'alarmType',
        header: intl.formatMessage({ id: 'document.field.type' }),
        size: 40,
    }),
    columnHelper.accessor((row) => row.cernSafetyEquipmentName ?? '', {
        id: CSAM_ALARM_CENTRAL,
        header: intl.formatMessage({ id: 'is37.sensors.centralName' }),
        size: 80,
    }),
    columnHelper.accessor((row) => row.zone ?? '', {
        id: 'zone',
        header: intl.formatMessage({ id: 'is37.sensors.zone' }),
        size: 40,
        meta: {
            dataType: DATATYPES.INTEGER,
        },
    }),
    columnHelper.accessor((row) => row.building ?? '', {
        id: ALARM_LOCATION_BUILDING,
        header: intl.formatMessage({ id: 'document.location.building' }),
        size: 40,
        meta: {
            dataType: DATATYPES.INTEGER,
        },
    }),
    columnHelper.accessor((row) => row.floor ?? '', {
        id: ALARM_LOCATION_FLOOR,
        header: intl.formatMessage({ id: 'document.location.floor' }),
        size: 40,
    }),
    columnHelper.accessor((row) => row.room ?? '', {
        id: ALARM_LOCATION_ROOM,
        header: intl.formatMessage({ id: 'document.location.room' }),
        size: 40,
    }),
];
const filterSchemas = ({ formatMessage, locale }: IntlShape): TFilterElement[] => [
    {
        id: 'sensorName',
        label: formatMessage({ id: 'document.field.code' }),
        type: FilterTypeEnum.STRING,
    },
    is37AlarmTypeFilterSchema(
        {
            id: CSAM_ALARM_TYPE_ID,
            label: formatMessage({ id: 'is37.field.alarmType' }),
        },
        locale
    ),
    {
        id: CSAM_ALARM_CENTRAL,
        label: formatMessage({ id: 'is37.sensors.centralName' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: ALARM_LOCATION_BUILDING,
        label: formatMessage({ id: 'document.location.building' }),
        type: FilterTypeEnum.NUMBER,
    },
    {
        id: ALARM_LOCATION_FLOOR,
        label: formatMessage({ id: 'document.location.floor' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: ALARM_LOCATION_ROOM,
        label: formatMessage({ id: 'document.location.room' }),
        type: FilterTypeEnum.STRING,
    },
];

type SensorsSearchTableDialogProps = Parameters<NonNullable<AdornableElement['endAdornment']>>[0];

const SensorsSearchTableDialog: React.FC<SensorsSearchTableDialogProps> = ({
    field,
    updateValue,
    multiple,
    matchId,
    disabled,
    readOnly,
}) => {
    const intl = useIntl();
    const { value } = field;
    const { watch } = useFormContext<IS37ReadDTO>();
    const columnsMemo = useMemo(() => columns(intl), [intl]);
    const alarmType = watch('alarmType');
    const locations = watch('locations') ?? [];
    const commonValueOrNull = (values: (string | null)[]): string | null => {
        const distinctNonEmpty = [...new Set(values.filter((v): v is string => !!v))];
        return distinctNonEmpty.length === 1 ? distinctNonEmpty[0] : null;
    };
    const locationParts = locations.map((l) => {
        const parts1 = (l.impactAlias ?? l.code).split(' ')[0].split('/');
        let floor: string | null = null,
            room: string | null = null;
        if (parts1.length === 2) {
            const parts2 = parts1[1].split('-');
            floor = parts2[0];
            if (parts2.length === 2) {
                room = parts2[1];
            }
        }
        return {
            building: l.buildingNumber,
            floor,
            room,
        };
    });
    const commonBuilding = commonValueOrNull(locationParts.map((l) => l.building));
    const commonFloor = commonValueOrNull(locationParts.map((l) => l.floor));
    const commonRoom = commonValueOrNull(locationParts.map((l) => l.room));
    const filterIfValue = (filter: SearchFilter & { value: any | null }): SearchFilter[] =>
        !!filter.value ? [filter] : [];
    const initialFilters: SearchFilter[] = [
        ...filterIfValue({
            field: CSAM_ALARM_TYPE_ID,
            operator: FilterOperatorEnum.IS,
            value: alarmType,
        }),
        ...filterIfValue({
            field: ALARM_LOCATION_BUILDING,
            operator: FilterOperatorEnum.EQUAL,
            value: commonBuilding,
        }),
        ...filterIfValue({
            field: ALARM_LOCATION_FLOOR,
            operator: FilterOperatorEnum.EQUALS,
            value: commonFloor,
        }),
        ...filterIfValue({
            field: ALARM_LOCATION_ROOM,
            operator: FilterOperatorEnum.EQUALS,
            value: commonRoom,
        }),
    ];
    return (
        <SearchTableWidget
            title={intl.formatMessage({ id: 'is37.sensors.title' })}
            disabled={disabled}
            readOnly={readOnly}
            selectedNodes={value ? (multiple ? value : [value]) : []}
            onSelect={(nodes: CsamSensorReadDTO[]) => {
                if (!nodes) return;
                if (nodes.length === 1 && !multiple) {
                    updateValue && updateValue(matchId ? getCsamSensorId(nodes[0]) : nodes[0]);
                } else {
                    updateValue && updateValue(matchId ? nodes.map((e) => getCsamSensorId(e)) : nodes);
                }
            }}
            columns={columnsMemo}
            filterSchemas={filterSchemas(intl)}
            initialFilters={initialFilters}
            getRowId={getCsamSensorId}
            pagedApiCall={getIs37Sensors}
            adornmentIcon={<SensorsIcon />}
        />
    );
};

const sensorTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchSensorsByName,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getCsamSensorLabel,
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
    multiple: true,
    endAdornment: SensorsSearchTableDialog,
});

interface SensorsSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const sensorsSchema = ({ path, label, required }: SensorsSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: sensorTypeProperties(),
});

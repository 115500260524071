// Inspired by: https://mui.com/material-ui/react-avatar/#letter-avatars

import { PersonRichReadDTO } from '../api/dto';

const stringToColor = (string: string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

export const stringAvatar = (person: PersonRichReadDTO) => ({
    sx: {
        bgcolor: stringToColor(person.searchLabel),
    },
    children: [person.firstName, person.lastName]
        .map((s) => s[0])
        .filter((s) => s !== undefined)
        .join(''),
});

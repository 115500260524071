import { Error as ErrorIcon, ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    CircularProgress,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { EXTERNAL_ROUTES } from '../routes/externalRoutes';

const useResolvedError = (error: Error) => {
    const resolveError = () => import('stacktrace-js').then((StackTrace) => StackTrace.fromError(error));
    const [isResolvingError, setResolvingError] = useState(true);
    const [resolvedError, setResolvedError] = useState<StackTrace.StackFrame[] | null>(null);

    useEffect(() => {
        Sentry.captureException(error);

        resolveError()
            .then((data) => {
                setResolvedError(data);
            })
            .catch(() => {})
            .finally(() => {
                setResolvingError(false);
            });
    }, []);

    return {
        isResolvingError,
        resolvedError,
    };
};

export const CrashPage: React.FC = () => {
    const error = useRouteError() as Error;
    const { resolvedError, isResolvingError } = useResolvedError(error);

    const formatResolvedError = (resolvedErrorNonNull: StackTrace.StackFrame[]) =>
        error.name +
        ': ' +
        error.message +
        '\n' +
        resolvedErrorNonNull.map((frame) => '    at ' + frame.toString()).join('\n');

    return (
        <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{ minHeight: '100vh' }}
        >
            <ErrorIcon fontSize="large" color="error" sx={{ pt: 2, px: 2 }} />
            <Box sx={{ px: 2 }}>The IMPACT application has crashed.</Box>
            <Box sx={{ pb: 2, px: 2 }}>
                If the error persists, please{' '}
                <Link href={EXTERNAL_ROUTES.support.incidentReport()}>contact the support</Link>.
            </Box>
            {!isResolvingError || resolvedError ? (
                <Box sx={{ px: 1, pb: 1, textAlign: 'left' }}>
                    <Accordion elevation={3}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Crash report</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: grey[700] }}>
                            {!resolvedError && (
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    You are seeing the raw crash report.
                                </Alert>
                            )}
                            <Box sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                                {resolvedError ? formatResolvedError(resolvedError) : error.stack}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ) : (
                <CircularProgress sx={{ py: 1 }} />
            )}
        </Stack>
    );
};

import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldAttributes, PreciseRulesDTO } from './deserializer';
import { formRulesResolver } from './resolver';

interface UseRulesOptions {
    // A workaround that is helpful due to the current limitations of the validation framework
    // (e.g. disabling the fields inside a nested field but allowing adding new values)
    ignoredFields?: string[];
}

interface UseFormRulesProps<TFieldValues extends FieldValues, TState extends FieldValues, TContext> {
    formProps?: Omit<UseFormProps<TFieldValues, TContext>, 'resolver'>;
    rules: PreciseRulesDTO<TState> | undefined;
    originalValues: any;
    context: TState | undefined;
    options?: UseRulesOptions;
}

interface UseFormRulesReturn<TFieldValues extends FieldValues, TContext> {
    form: UseFormReturn<TFieldValues, TContext>;
    attributes: Record<string, FieldAttributes>;
}

export const useFormRules = <
    TFieldValues extends FieldValues = FieldValues,
    TState extends FieldValues = FieldValues,
    TContext = any
>({
    formProps: formProps,
    rules,
    originalValues,
    context,
    options = {},
}: UseFormRulesProps<TFieldValues, TState, TContext>): UseFormRulesReturn<TFieldValues, TContext> => {
    const ignoredFields = useMemo(() => new Set(options.ignoredFields ?? []), [JSON.stringify(options?.ignoredFields)]);
    const [attributes, setAttributes] = useState<Record<string, FieldAttributes>>({});

    const setValueRef = useRef<UseFormSetValue<TFieldValues>>();

    const resolver = useMemo(
        () =>
            rules !== undefined && context !== undefined
                ? formRulesResolver<TFieldValues, TState, TContext>(
                      originalValues,
                      rules,
                      context,
                      ignoredFields,
                      setAttributes,
                      (name, value) => setValueRef.current!(name as any, value)
                  )
                : (values: TFieldValues) => ({ errors: {}, values }),
        [setValueRef, originalValues, rules, context, ignoredFields]
    );
    const form = useForm({ ...formProps, resolver, mode: 'all' });

    useLayoutEffect(() => {
        setValueRef.current = form.setValue;
    }, [form.setValue]);

    useLayoutEffect(() => {
        resolver(form.getValues(), undefined, {
            fields: {},
            shouldUseNativeValidation: false,
        });
    }, [resolver, form.getValues]);

    return useMemo(() => ({ form, attributes }), [form, attributes]);
};

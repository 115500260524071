import { Box, Drawer, styled, Toolbar } from '@mui/material';
import * as React from 'react';
import { useCloseDrawerOnResize } from '../../hooks/useCloseDrawerOnResize';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useDrawerHook } from '../../hooks/useDrawerHook';
import { drawerWidth, Header } from './elements/Header';
import { NotificationHeader } from './elements/NotificationHeader';
import { SideMenu } from './elements/SideMenu';

interface IClassicLayout {
    children: React.ReactNode;
}

interface IMain {
    open?: boolean;
}
const Body = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<IMain>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: useDeviceType().isMobile ? 0 : `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    height: `100%`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}));

export const ClassicLayout: React.FunctionComponent<IClassicLayout> = ({ children }) => {
    const { isMobile, mobileScreenBreakPoint } = useDeviceType();
    // The menu is initially open on desktop, but closed on mobile devices
    const { isDrawerOpen, openDrawer, closeDrawer } = useDrawerHook(!isMobile);

    useCloseDrawerOnResize(mobileScreenBreakPoint, closeDrawer);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Drawer
                open={isDrawerOpen}
                sx={{
                    height: '100%',
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                anchor="left"
                variant={isMobile ? 'temporary' : 'persistent'}
                onClose={closeDrawer}
            >
                <SideMenu closeMenu={closeDrawer} />
            </Drawer>
            <Body open={isDrawerOpen}>
                <Header open={isDrawerOpen} handleDrawerOpen={openDrawer} handleDrawerClose={closeDrawer} />
                <Toolbar />
                <Box
                    sx={{
                        marginBottom: 0,
                        padding: 0,
                        height: `100%`,
                        width: '100%',
                        overflow: 'auto',
                        flexGrow: 1,
                    }}
                >
                    <NotificationHeader />
                    {children}
                </Box>
            </Body>
        </Box>
    );
};

import {
    Assignment,
    Comment,
    EditCalendar,
    HealthAndSafety,
    Info,
    NotListedLocation,
    PersonPin,
    Settings,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { ContentCopy, RadioactiveCircle } from 'mdi-material-ui';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, IntlShape } from 'react-intl';
import { getActivityPriorities, getActivityTypes } from '../../../api/activities-nim';
import { LinkedDocumentCode, ListOfValuesReadDTO, LocationDTO } from '../../../api/dto';
import {
    getActivityHazardTypes,
    getDurationTypes,
    getFacilities,
    getProcedureDocuments,
    getRadiographyDeviceTypes,
    getWorkingTimes,
} from '../../../api/values';
import { fetchCompensatoryMeasureTypeByLabel, fetchEgroups } from '../../../lib/api';
import { getLocationLabel } from '../../../lib/label';
import { FetchUseCase } from '../../fields/AsyncAutocompleteElement';
import { DocumentRedirect } from '../../fields/specific/DocumentRedirect';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { joinFieldPath, splitFieldPath } from '../../utils';
import { locationAccessPointSchema } from '../accessPoints';
import { activityParticipantRoleSchema } from '../activityParticipantRoles';
import { contractsSchema } from '../contracts';
import { interventionPeriodSchema } from '../interventionPeriod';
import { locationSchema } from '../location';
import { personSchema } from '../person';
import { systemSchema } from '../system';
import { numberWithUnitSchemaFor } from '../unit';
import { valueSchema } from '../value';

const uiSchemaParticipant = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: personSchema({
                label: intl.formatMessage({ id: 'activity.field.participant' }),
                path: 'person',
            }),
        },
        {
            xs: 6,
            element: activityParticipantRoleSchema(
                {
                    label: intl.formatMessage({ id: 'activity.field.role' }),
                    path: 'role',
                },
                intl.locale
            ),
        },
        {
            xs: 6,
            element: {
                type: UiSchemaType.AUTOCOMPLETE_ASYNC,
                label: 'E-group',
                path: 'egroup',
                typeProperties: {
                    fetchOptions: (searchTerm: string) => fetchEgroups(searchTerm),
                    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
                    getOptionLabel: (value: { name: string }) => value.name,
                    multiple: false,
                    autocompleteProps: {
                        isOptionEqualToValue: (option: { name: string }, value: { name: string }) => {
                            return option.name === value.name;
                        },
                    },
                },
            },
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: 'Unknown',
                path: 'unknown',
                typeProperties: {
                    multiline: true,
                },
            },
        },
        {
            xs: 12,
            element: contractsSchema({
                path: 'companyContract',
                label: 'Contract',
            }),
        },
    ],
});

const uiSchemaWhereLocation = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 8,
            element: locationSchema({
                label: intl.formatMessage({ id: 'document.field.location' }),
                path: 'location',
                allowAnyLocation: true,
            }),
        },
        {
            xs: 4,
            element: locationAccessPointSchema({
                label: intl.formatMessage({ id: 'document.location.info.accessPoints' }),
                path: 'accessPoints',
            }),
        },
        {
            xs: 12,
            sm: 6,
            element: numberWithUnitSchemaFor(
                {
                    label: 'Coordinates (Swisstopo - MN95)',
                    path: 'coordinateMn95east',
                    unit: 'East',
                    integer: false,
                    signed: false,
                },
                intl
            ),
        },
        {
            xs: 12,
            sm: 6,
            element: numberWithUnitSchemaFor(
                {
                    label: 'Coordinates (Swisstopo - MN95)',
                    path: 'coordinateMn95north',
                    unit: 'North',
                    integer: false,
                    signed: false,
                },
                intl
            ),
        },
    ],
});

const uiSchemaActivityHazard = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: valueSchema(
                {
                    label: intl.formatMessage({ id: 'document.field.type' }),
                    path: 'type',
                    fetchOptions: getActivityHazardTypes,
                    multiple: false,
                },
                intl.locale
            ),
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'document.panel.comments' }),
                path: 'commentHazardType',
                typeProperties: {
                    multiline: true,
                },
            },
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.FIELD_ARRAY,
                path: 'compensatoryMeasures',
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.AUTOCOMPLETE_ASYNC,
                                label: intl.formatMessage({ id: 'document.field.type' }),
                                path: 'type',
                                typeProperties: {
                                    idField: 'code',
                                    fetchOptions: (searchTerm: string) =>
                                        fetchCompensatoryMeasureTypeByLabel(searchTerm),
                                    fetchUseCases: [FetchUseCase.ON_KEYSTROKE, FetchUseCase.ON_LOAD],
                                    getOptionLabel: (value: { label: string }) => value.label,
                                    multiple: false,
                                    autocompleteProps: {
                                        isOptionEqualToValue: (option: { code: string }, value: { code: string }) => {
                                            return option.code === value.code;
                                        },
                                    },
                                },
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.panel.comments' }),
                                path: 'commentHazardType',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                    ],
                },
                appendLabel: intl.formatMessage({
                    id: 'activity.field.activityHazards.addNewCompensatoryMeasure',
                }),
                removeLabel: intl.formatMessage({
                    id: 'activity.field.activityHazards.removeCompensatoryMeasure',
                }),
            },
        },
    ],
});

const uiSchemaLocationHazards = (intl: IntlShape, activityLocations: LocationDTO[]): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 6,
            element: {
                type: UiSchemaType.AUTOCOMPLETE,
                label: intl.formatMessage({ id: 'document.field.location' }),
                path: 'location',
                typeProperties: {
                    options: activityLocations,
                    getOptionLabel: getLocationLabel,
                    matchId: true,
                    strongValidateOptions: true,
                },
            },
        },
        {
            xs: 6,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'document.field.type' }),
                path: 'hazard',
            },
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'activity.field.compensatoryMeasure' }),
                path: 'compensatoryMeasure',
                typeProperties: {
                    multiline: true,
                },
            },
        },
    ],
});

const uiSchemaCoactivityHazard = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'document.field.type' }),
                path: 'type',
            },
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'activity.field.compensatoryMeasure' }),
                path: 'compensatoryMeasure',
                typeProperties: {
                    multiline: true,
                },
            },
        },
    ],
});

const uiSchemaProcedureDocuments = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 6,
            element: valueSchema(
                {
                    label: intl.formatMessage({ id: 'document.field.type' }),
                    path: 'documentType',
                    fetchOptions: getProcedureDocuments,
                    multiple: false,
                },
                intl.locale
            ),
        },
        {
            xs: 6,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'activity.field.documentNumber' }),
                path: 'documentNumber',
                typeProperties: {
                    endAdornment: ({ field }) => {
                        const { value: edmsNumber } = field as {
                            value: string | undefined;
                        };
                        const { watch } = useFormContext();
                        const path = joinFieldPath([...splitFieldPath(field.name).slice(0, 2), 'documentType']);
                        const documentType: ListOfValuesReadDTO<LinkedDocumentCode> | undefined = watch(path);
                        return <DocumentRedirect value={edmsNumber} documentType={documentType?.code} />;
                    },
                },
            },
        },
    ],
});

export const activityFormSchema = (
    intl: IntlShape,
    copyProposedDate: React.Dispatch<any>,
    copyButtonDisabled: boolean,
    activityLocations: LocationDTO[]
): TFormElement => {
    return {
        type: UiSchemaType.LAYOUT_VERTICAL,
        elements: [
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'document.panel.general' }),
                typeProperties: {
                    icon: <Settings color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.field.title' }),
                                path: 'title',
                            },
                        },
                        {
                            xs: 6,
                            element: personSchema({
                                label: intl.formatMessage({ id: 'document.field.responsible' }),
                                path: 'activityResponsible',
                            }),
                        },
                        {
                            xs: 6,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'document.field.facility' }),
                                    path: 'facility',
                                    fetchOptions: getFacilities,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 6,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.activityTypes' }),
                                    path: 'type',
                                    fetchOptions: getActivityTypes,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 6,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.priority' }),
                                    path: 'priority',
                                    fetchOptions: getActivityPriorities,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'activity.field.notRequiresSafetyApproval' }),
                                path: 'notRequiresSafetyApproval',
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.what' }),
                typeProperties: {
                    icon: <Comment color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_RESPONSIVE,
                    typeProperties: {
                        direction: {
                            xs: 'column',
                        },
                    },
                    elements: [
                        {
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.field.description' }),
                                path: 'description',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                        {
                            element: systemSchema({
                                label: intl.formatMessage({ id: 'activity.field.system' }),
                                path: 'system',
                            }),
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.where' }),
                typeProperties: {
                    icon: <NotListedLocation color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'activityLocations',
                                element: uiSchemaWhereLocation(intl),
                                appendLabel: intl.formatMessage({ id: 'activity.field.location.addNewLocation' }),
                                removeLabel: intl.formatMessage({ id: 'activity.field.location.removeLocation' }),
                                initialValue: {
                                    location: null,
                                    accessPoints: null,
                                },
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.panel.comments' }),
                                path: 'whereComments',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({
                                        id: 'activity.field.impactedFacilities',
                                    }),
                                    path: 'impactedFacilities',
                                    fetchOptions: getFacilities,
                                    multiple: true,
                                },
                                intl.locale
                            ),
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.when.proposed' }),
                typeProperties: {
                    icon: <EditCalendar color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.proposedDate' }),
                                path: 'proposedDate',
                            },
                        },
                        {
                            xs: 6,
                            element: interventionPeriodSchema({
                                label: intl.formatMessage({ id: 'activity.field.proposedInterventionPeriod' }),
                                path: 'proposedInterventionPeriod',
                                multiple: false,
                            }),
                        },
                        {
                            xs: 6,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.workingTimeAvail' }),
                                    path: 'workingTimeAvail',
                                    fetchOptions: getWorkingTimes,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 3,
                            element: {
                                type: UiSchemaType.NUMBER,
                                label: intl.formatMessage({ id: 'activity.field.duration' }),
                                path: 'duration',
                                typeProperties: {
                                    integer: true,
                                    signed: false,
                                },
                            },
                        },
                        {
                            xs: 3,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.durationType' }),
                                    path: 'durationType',
                                    fetchOptions: getDurationTypes,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.TIME,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeAvailStart' }),
                                path: 'workingTimeAvailStart',
                                typeProperties: {
                                    minutesStep: 30,
                                },
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.TIME,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeAvailEnd' }),
                                path: 'workingTimeAvailEnd',
                                typeProperties: {
                                    minutesStep: 30,
                                },
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeAvailTxt' }),
                                path: 'workingTimeAvailTxt',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.earliestStart' }),
                                path: 'earliestStart',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.latestEnd' }),
                                path: 'latestEnd',
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.when.scheduled' }),
                typeProperties: {
                    icon: <EditCalendar color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: () => (
                                    <Button
                                        disabled={copyButtonDisabled}
                                        variant="outlined"
                                        startIcon={<ContentCopy />}
                                        onClick={copyProposedDate}
                                        fullWidth={true}
                                        size={'small'}
                                    >
                                        <FormattedMessage id="activity.field.copyProposed" />
                                    </Button>
                                ),
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.whenComments' }),
                                path: 'whenComments',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.accessStart' }),
                                path: 'accessStart',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.accessEnd' }),
                                path: 'accessEnd',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.scheduledStart' }),
                                path: 'scheduledStart',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.scheduledEnd' }),
                                path: 'scheduledEnd',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.baselineStart' }),
                                path: 'baselineStart',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.DATE,
                                label: intl.formatMessage({ id: 'activity.field.baselineEnd' }),
                                path: 'baselineEnd',
                            },
                        },
                        {
                            xs: 6,
                            element: interventionPeriodSchema({
                                label: intl.formatMessage({ id: 'activity.field.interventionPeriod' }),
                                path: 'interventionPeriod',
                                multiple: false,
                            }),
                        },
                        {
                            xs: 6,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.workingTimeGtd' }),
                                    path: 'workingTimeGtd',
                                    fetchOptions: getWorkingTimes,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeGrtdStart' }),
                                path: 'workingTimeGrtdStart',
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeGrtdEnd' }),
                                path: 'workingTimeGrtdEnd',
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.workingTimeGrtdTxt' }),
                                path: 'workingTimeGrtdTxt',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.who' }),
                typeProperties: {
                    icon: <PersonPin color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'activityParticipants',
                                element: uiSchemaParticipant(intl),
                                appendLabel: intl.formatMessage({
                                    id: 'activity.field.participant.addNewParticipant',
                                }),
                                removeLabel: intl.formatMessage({
                                    id: 'activity.field.participant.removeParticipant',
                                }),
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.NUMBER,
                                label: intl.formatMessage({ id: 'activity.field.maxNumberOfParticipants' }),
                                path: 'maxNbParticipants',
                                typeProperties: {
                                    integer: true,
                                    signed: false,
                                },
                            },
                        },
                        {
                            xs: 6,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.contactPhone' }),
                                path: 'contactPhone',
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'activity.field.shortTermWorkers' }),
                                path: 'shortTermWorkers',
                            },
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'document.panel.comments' }),
                                path: 'whoComments',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.how' }),
                typeProperties: {
                    icon: <Info color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_VERTICAL,
                    elements: [
                        {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.modusOperandi' }),
                            path: 'modusOperandi',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                        {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'procedureDocuments',
                            element: uiSchemaProcedureDocuments(intl),
                            appendLabel: intl.formatMessage({
                                id: 'activity.field.activityHazards.addProcedureDocument',
                            }),
                            removeLabel: intl.formatMessage({
                                id: 'activity.field.activityHazards.removeProcedureDocument',
                            }),
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'activity.field.materialResource' }),
                            path: 'materialResource',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'activity.field.constraints' }),
                            path: 'constraints',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                        {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'activity.field.waste' }),
                            path: 'waste',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.safety' }),
                typeProperties: {
                    icon: <HealthAndSafety color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_VERTICAL,
                    elements: [
                        //TODO panel with links?
                        {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'locationHazards',
                            element: uiSchemaLocationHazards(intl, activityLocations),
                            appendLabel: intl.formatMessage({ id: 'document.field.locationHazards.add' }),
                            removeLabel: intl.formatMessage({ id: 'document.field.locationHazards.remove' }),
                        },
                        {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'activityHazards',
                            element: uiSchemaActivityHazard(intl),
                            appendLabel: intl.formatMessage({
                                id: 'activity.field.activityHazards.addNewActivityHazard',
                            }),
                            removeLabel: intl.formatMessage({
                                id: 'activity.field.activityHazards.removeActivityHazard',
                            }),
                        },
                        {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'coactivityHazards',
                            element: uiSchemaCoactivityHazard(intl),
                            appendLabel: intl.formatMessage({
                                id: 'activity.field.coactivityHazard.addNewCoactivityHazard',
                            }),
                            removeLabel: intl.formatMessage({
                                id: 'activity.field.coactivityHazard.removeCoactivityHazard',
                            }),
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.radiography' }), //TODO
                typeProperties: {
                    icon: <RadioactiveCircle color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'activity.field.radiographyDeviceType' }),
                                    path: 'radiographyDeviceType',
                                    fetchOptions: getRadiographyDeviceTypes,
                                    multiple: false,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.INPUT,
                                label: intl.formatMessage({ id: 'activity.field.radiographyXraySource' }),
                                path: 'radiographyXraySource',
                                typeProperties: {
                                    multiline: true,
                                },
                            },
                        },
                    ],
                },
            },
            {
                type: UiSchemaType.PANEL,
                label: intl.formatMessage({ id: 'activity.panel.rpassessment' }),
                typeProperties: {
                    icon: <Assignment color="action" />,
                },
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.totalCollectiveWorkTime' }),
                                    path: 'totalCollectiveWorkingTime',
                                    unit: intl.formatMessage({ id: 'document.unit.personHour' }),
                                    integer: false,
                                    signed: false,
                                },
                                intl
                            ),
                        },
                        {
                            xs: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxIndividualWorkTime' }),
                                    path: 'maxIndividualWorkingTime',
                                    unit: intl.formatMessage({ id: 'document.unit.hour' }),
                                    integer: false,
                                    signed: false,
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.contaminatingWorks' }),
                                path: 'contaminatingWorks',
                            },
                        },
                    ],
                },
            },
            //TODO

            // {
            //     type: UiSchemaType.PANEL,
            //     label: intl.formatMessage({ id: 'activity.panel.tests' }),
            // },
            // {
            //     type: UiSchemaType.PANEL,
            //     label: intl.formatMessage({ id: 'activity.panel.workorders' }),
            // },
            // {
            //     type: UiSchemaType.PANEL,
            //     label: intl.formatMessage({ id: 'activity.panel.logbook' }),
            // },
        ],
    };
};

import { User, UserProfile } from 'oidc-client-ts';
import queryString from 'query-string';
import { AuthProviderProps } from 'react-oidc-context';

export const stripCurrentUrl = () => queryString.exclude(window.location.href, ['state', 'session_state', 'code']);

export const OIDC_CONFIG: AuthProviderProps = {
    authority: `${import.meta.env.VITE_KEYCLOAK_URL ?? ''}/realms/${import.meta.env.VITE_KEYCLOAK_REALM}`,
    client_id: import.meta.env.VITE_KEYCLOAK_CLIENTID ?? '',
    redirect_uri: window.location.href,
    redirectMethod: 'replace',
};

export const getUser = () => {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`);
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage) satisfies User as User & { user_profile: UserProfile };
};

export const getOidcUserSessionKey = (originalUserIfImpersonation: boolean): string =>
    `oidc.${originalUserIfImpersonation ? 'originalUser' : 'user'}:${import.meta.env.VITE_KEYCLOAK_URL}/realms/${
        import.meta.env.VITE_KEYCLOAK_REALM
    }:${import.meta.env.VITE_KEYCLOAK_CLIENTID}`;

import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { getActivity } from '../../../api/activities';
import { getChangeRequestValidationRules, getIs37, getIs37ValidationRules } from '../../../api/documents/is37s';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { Is37Form } from '../../../forms/documents/Is37Form';
import { IS37PageVariant } from './IS37PageVariant';

interface IS37PageProps {
    variant: IS37PageVariant;
}

export const IS37Page: React.FC<IS37PageProps> = ({ variant }) => {
    const { id: is37Id } = useParams();
    const [params] = useSearchParams();

    const intl = useIntl();

    const {
        data: is37Data,
        isLoading: is37DataLoading,
        isError: is37DataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.IS37, is37Id], () => getIs37({ id: String(is37Id) }), {
        enabled: is37Id !== undefined,
    });

    const activityId =
        variant === IS37PageVariant.READ || variant === IS37PageVariant.CLONE
            ? is37Data?.activity.id
            : String(params.get('activity'));

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.IS37, 'rules'], () =>
        getIs37ValidationRules({ id: variant === IS37PageVariant.CLONE ? undefined : is37Id })
    );

    const {
        data: changeRequestRulesData,
        isLoading: isChangeRequestRulesDataLoading,
        isError: changeRequestRulesDataFailed,
    } = useQuery([DocumentType.IS37, 'cr-rules'], () =>
        getChangeRequestValidationRules({ id: variant === IS37PageVariant.CLONE ? undefined : is37Id })
    );

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (is37DataLoading && is37Id !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        isRulesDataLoading ||
        isChangeRequestRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.IS37} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (is37DataFailed || activityDataFailed || rulesDataFailed || changeRequestRulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="is37.error.document" />
                <ImpactHelmet documentType={DocumentType.IS37} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === IS37PageVariant.CREATE && activityData) ||
        ((variant === IS37PageVariant.READ || variant === IS37PageVariant.CLONE) &&
            is37Data &&
            activityData &&
            changeRequestRulesData)
    ) {
        return (
            <Is37Form
                data={
                    variant === IS37PageVariant.CREATE
                        ? { variant, activityData }
                        : { variant, is37Data: is37Data!, activityData }
                }
                rules={rulesData}
                changeRequestRules={changeRequestRulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};

import React, { useEffect, useRef, useState } from 'react';
import { FieldPath, UseFormSetFocus } from 'react-hook-form';
import { DimrVersionReadDTO } from '../../api/dto';

interface DynamicValidationFormWrapperProps {
    children: React.ReactNode;
    setFocus: UseFormSetFocus<DimrVersionReadDTO>;
    depsContextRefresh: any;
    refetchContext: () => void;
    isContextLoading: boolean;
}

export const DynamicValidationFormWrapper = ({
    children,
    setFocus,
    depsContextRefresh,
    refetchContext,
    isContextLoading,
}: DynamicValidationFormWrapperProps) => {
    const prevDepsContextRefreshRef = useRef(depsContextRefresh);
    const [focusedField, setFocusedField] = useState<FieldPath<DimrVersionReadDTO> | null>(null);

    useEffect(() => {
        if (focusedField !== null) {
            setFocus(focusedField);
        }
    }, [isContextLoading, focusedField, setFocus]);

    const handleFocus = (e: React.BaseSyntheticEvent) => {
        setFocusedField(e.target.name);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const relatedTarget = e.relatedTarget as HTMLElement | null;

        if (relatedTarget?.dataset?.ignoreBlur === `${true}`) {
            return;
        }

        if (JSON.stringify(depsContextRefresh) !== JSON.stringify(prevDepsContextRefreshRef.current)) {
            refetchContext();
            prevDepsContextRefreshRef.current = depsContextRefresh;
            setFocusedField(null);
        }
    };

    return (
        <div onBlur={handleBlur} onFocus={handleFocus}>
            {children}
        </div>
    );
};

import { Id } from '@silevis/reactgrid';
import { useCallback, useMemo, useState } from 'react';
import { TableHeader } from '../../pages/documents/wdp/WdpInterfaces';

const LOCAL_STORAGE_KEY = 'spreadsheetColumnWidths';
const MIN_COLUMN_WIDTH = 30;

const getColumns = (headers: TableHeader[], columnWidths: { [key: string]: number }) =>
    headers.map(({ name }) => ({
        columnId: name,
        width: columnWidths[name],
        resizable: true,
    }));

export const useColumnWidths = (enhancedHeaders: TableHeader[], keyField: string) => {
    const loadColumnWidths = useCallback((): Record<string, number> => {
        const storedWidthsString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        const storedWidths = storedWidthsString !== null ? JSON.parse(storedWidthsString) : {};
        const widthsPerTab = storedWidths[keyField] ?? {};
        const initialWidths: Record<string, number> = {};

        enhancedHeaders.forEach((header) => {
            initialWidths[header.name] = widthsPerTab[header.name] ?? header.width ?? 100;
        });

        return initialWidths;
    }, [LOCAL_STORAGE_KEY, keyField, enhancedHeaders]);

    const [columnWidths, setColumnWidths] = useState<Record<string, number>>(loadColumnWidths);

    const columns = useMemo(() => {
        return getColumns(enhancedHeaders, columnWidths);
    }, [columnWidths, enhancedHeaders]);

    const saveColumnWidths = useCallback(
        (newColumnWidths: Record<string, number>) => {
            const storedWidthsString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
            const storedWidths = storedWidthsString !== null ? JSON.parse(storedWidthsString) : {};
            const newValue = { ...storedWidths, [keyField]: newColumnWidths };
            window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue));
        },
        [LOCAL_STORAGE_KEY, keyField]
    );

    const handleColumnResize = useCallback(
        (columnId: Id, newWidth: number) => {
            setColumnWidths((prevColumnWidths) => {
                const adjustedWidth = Math.max(newWidth, MIN_COLUMN_WIDTH);
                const newColumnWidths = {
                    ...prevColumnWidths,
                    [columnId]: adjustedWidth,
                };
                saveColumnWidths(newColumnWidths);
                return newColumnWidths;
            });
        },
        [MIN_COLUMN_WIDTH, saveColumnWidths]
    );

    return {
        columns,
        handleColumnResize,
    };
};

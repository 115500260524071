import { VicStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const VicStatusColorMapping: Record<VicStatusCode, StatusColor> = {
    [VicStatusCode.NEW]: StatusColor.PRIMARY,
    [VicStatusCode.SAVE]: StatusColor.PRIMARY,
    [VicStatusCode.WAITING_APPROVAL]: StatusColor.WARNING,
    [VicStatusCode.APPROVED]: StatusColor.NORMAL,
    [VicStatusCode.REJECTED]: StatusColor.ERROR,
    [VicStatusCode.CANCELLED]: StatusColor.ERROR,
};

import { Assignment, Done, Sensors, Settings } from '@mui/icons-material';
import React from 'react';
import { IntlShape } from 'react-intl';
import {
    getFirePermitFaxLanguages,
    getFirePermitHazardTypes,
    getFirePermitProtectionMeansExecutants,
    getFirePermitWarningDevices,
    getFirePermitWorkerTypes,
    getFirePermitWorkTypes,
} from '../../../api/documents/firePermits';
import { FirePermitCompanyWarning, FirePermitLocationWarning } from '../../fields/specific/FirePermitWarning';
import { LayoutGridItem, LayoutResponsiveItem, TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { fpCompanySchema } from '../fpCompany';
import { fpFireDetectionSchema } from '../fpFireDetection';
import { is37Schema } from '../is37Schema';
import { locationSchema } from '../location';
import { personSchema } from '../person';
import { valueSchema } from '../value';

const locationHazardsSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_RESPONSIVE,
    typeProperties: {
        direction: {
            xs: 'column',
            sm: 'row',
        },
    },
    elements: [
        {
            element: locationSchema({
                label: intl.formatMessage({ id: 'document.field.location' }),
                path: 'location',
            }),
        },
        {
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'document.field.type' }),
                path: 'hazard',
            },
        },
    ],
});

export const firePermitFormSchema = (
    intl: IntlShape,
    showCloseoutPanel: boolean,
    companyWarning: boolean,
    showLocationWarning: boolean
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'firePermit.panel.work' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'document.field.responsible' }),
                            path: 'responsible',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'document.field.startDate' }),
                            path: 'startDate',
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'document.field.endDate' }),
                            path: 'endDate',
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                    ...(showLocationWarning
                        ? [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: FirePermitLocationWarning,
                                  },
                              } satisfies LayoutGridItem,
                          ]
                        : []),
                    {
                        xs: 12,
                        sm: 6,
                        element: locationSchema({
                            label: intl.formatMessage({ id: 'document.field.location' }),
                            path: 'location',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.complementaryDetails' }),
                            path: 'locationInformation',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.workToBePerformed' }),
                            path: 'workToBePerformed',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.modusOperandi' }),
                            path: 'modusOperandi',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: valueSchema(
                                        {
                                            label: intl.formatMessage({ id: 'firePermit.field.workTypes' }),
                                            path: 'firePermitWorkTypes',
                                            fetchOptions: getFirePermitWorkTypes,
                                            multiple: true,
                                        },
                                        intl.locale
                                    ),
                                },
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: intl.formatMessage({ id: 'firePermit.field.otherWorkTypes' }),
                                        path: 'otherWorkType',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            elements: [
                                ...(companyWarning
                                    ? [
                                          {
                                              element: {
                                                  type: UiSchemaType.CUSTOM,
                                                  Component: FirePermitCompanyWarning,
                                              },
                                          } satisfies LayoutResponsiveItem,
                                      ]
                                    : []),
                                {
                                    element: {
                                        type: UiSchemaType.LAYOUT_RESPONSIVE,
                                        typeProperties: {
                                            direction: {
                                                xs: 'column',
                                                sm: 'row',
                                            },
                                        },
                                        elements: [
                                            {
                                                element: valueSchema(
                                                    {
                                                        label: intl.formatMessage({
                                                            id: 'firePermit.field.workerType',
                                                        }),
                                                        path: 'worker',
                                                        fetchOptions: getFirePermitWorkerTypes,
                                                    },
                                                    intl.locale
                                                ),
                                            },
                                            {
                                                element: personSchema({
                                                    label: intl.formatMessage({ id: 'firePermit.field.cernEmployee' }),
                                                    path: 'cernEmployee',
                                                }),
                                            },
                                            {
                                                element: fpCompanySchema({
                                                    label: intl.formatMessage({ id: 'document.field.company' }),
                                                    path: 'company',
                                                }),
                                            },
                                            {
                                                element: valueSchema(
                                                    {
                                                        label: intl.formatMessage({ id: 'document.field.language' }),
                                                        path: 'faxLanguage',
                                                        fetchOptions: getFirePermitFaxLanguages,
                                                    },
                                                    intl.locale
                                                ),
                                            },
                                        ],
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.FIELD_ARRAY,
                                        path: 'firePermitCompanyEmails',
                                        inline: true,
                                        element: {
                                            type: UiSchemaType.INPUT,
                                            label: intl.formatMessage({ id: 'firePermit.field.companyEmail' }),
                                            path: 'companyEmail',
                                        },
                                        appendLabel: intl.formatMessage({ id: 'firePermit.field.companyEmail.add' }),
                                        removeLabel: intl.formatMessage({ id: 'firePermit.field.companyEmail.remove' }),
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'firePermit.panel.instructionsAndHazards' }),
            typeProperties: {
                icon: <Assignment color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.protectionMeans' }),
                            path: 'protectionMeans',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'firePermit.field.protectionMeansExecutant' }),
                                path: 'protectionMeansExecutant',
                                fetchOptions: getFirePermitProtectionMeansExecutants,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.specialInstructions' }),
                            path: 'specialInstructions',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.approversInstructions' }),
                            path: 'approversInstructions',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'firePermit.field.activityHazards' }),
                                path: 'firePermitActivityHazards',
                                fetchOptions: getFirePermitHazardTypes,
                                multiple: true,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'firePermitLocationHazards',
                            element: locationHazardsSchema(intl),
                            appendLabel: intl.formatMessage({ id: 'document.field.locationHazards.add' }),
                            removeLabel: intl.formatMessage({ id: 'document.field.locationHazards.remove' }),
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'firePermit.panel.fireDetection' }),
            typeProperties: {
                icon: <Sensors color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: is37Schema(
                            {
                                label: intl.formatMessage({ id: 'firePermit.field.is37s' }),
                                path: 'is37s',
                                multiple: true,
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        element: fpFireDetectionSchema(
                            {
                                label: intl.formatMessage({ id: 'firePermit.field.fireDetection' }),
                                path: 'fireDetection',
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: valueSchema(
                                        {
                                            label: intl.formatMessage({ id: 'firePermit.field.warningDevices' }),
                                            path: 'firePermitWarningDevices',
                                            fetchOptions: getFirePermitWarningDevices,
                                            multiple: true,
                                        },
                                        intl.locale
                                    ),
                                },
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: intl.formatMessage({ id: 'firePermit.field.otherWarningDevice' }),
                                        path: 'otherWarningDevice',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'firePermit.field.fireFighterDevices' }),
                            path: 'fireFighterDevices',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        ...(showCloseoutPanel
            ? [
                  {
                      type: UiSchemaType.PANEL,
                      label: intl.formatMessage({ id: 'firePermit.panel.closeout' }),
                      typeProperties: {
                          icon: <Done color="action" />,
                      },
                      element: {
                          type: UiSchemaType.LAYOUT_GRID,
                          elements: [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.INPUT,
                                      label: intl.formatMessage({ id: 'firePermit.field.fireInspectionPremises' }),
                                      path: 'fireInspectionPremises',
                                      typeProperties: {
                                          multiline: true,
                                      },
                                  },
                              },
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.DATETIME,
                                      label: intl.formatMessage({ id: 'firePermit.field.terminationDate' }),
                                      path: 'terminationDate',
                                  },
                              },
                          ],
                      },
                  } satisfies TFormElement,
              ]
            : []),
    ],
});

import {
    ArrowCircleDown,
    ArrowCircleUp,
    ArrowDownward,
    ArrowUpward,
    RadioButtonUnchecked,
    Replay,
    Sort,
} from '@mui/icons-material';
import {
    Badge,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    ToggleButton,
} from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { SortableField } from '../../searching/SearchPageHeader';

interface SortingSettingsButtonProps {
    sortableFields: SortableField[];
    sortedField: SortableField | null;
    onFieldSelection: (field: SortableField | null) => void;
    isSortingDesc: boolean | null;
    setIsSortingDesc: (isOrderDesc: boolean) => void;
}
export const SortingSettingsButton: React.FC<SortingSettingsButtonProps> = ({
    sortableFields,
    sortedField,
    onFieldSelection,
    isSortingDesc,
    setIsSortingDesc,
    ...rest
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { isMobile } = useDeviceType();

    const handleReset = () => {
        // Note: `setIsSortingDesc` must be called before `onFieldSelection`
        setIsSortingDesc(false);
        onFieldSelection(null);
    };

    // A three state automaton
    const handleFieldSelection = (field: SortableField) => {
        const isAlreadySelected = field.id === sortedField?.id;
        if (isAlreadySelected) {
            if (isSortingDesc) {
                handleReset();
            } else {
                setIsSortingDesc(true);
            }
        } else {
            onFieldSelection(field);
        }
    };

    return (
        <>
            <ToggleButton
                value="sorting"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                {...rest}
                selected={sortedField !== null}
            >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Badge
                        color="primary"
                        badgeContent={
                            isSortingDesc ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />
                        }
                        invisible={sortedField === null}
                        sx={{
                            '.MuiBadge-badge': {
                                padding: 0,
                            },
                        }}
                    >
                        <Sort />
                    </Badge>
                    {!isMobile && (
                        <Box>
                            <FormattedMessage id="search.sort.button" />
                        </Box>
                    )}
                </Stack>
            </ToggleButton>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuList dense>
                    <MenuItem onClick={handleReset}>
                        <ListItemIcon>
                            <Replay />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="common.restoreDefaults" />
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    {sortableFields.map((field) => (
                        <MenuItem
                            key={field.id}
                            selected={field.id === sortedField?.id}
                            onClick={() => handleFieldSelection(field)}
                        >
                            <ListItemIcon>
                                {field.id === sortedField?.id ? (
                                    isSortingDesc ? (
                                        <ArrowCircleDown />
                                    ) : (
                                        <ArrowCircleUp />
                                    )
                                ) : (
                                    <RadioButtonUnchecked />
                                )}
                            </ListItemIcon>
                            <ListItemText>{field.label}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
};

import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../api/dto';
import { DocumentHeader } from '../../components/documents/DocumentHeader';
import { ActivityIcon } from '../../lib/information/activityNim';
import { getActivityStatus } from '../../lib/status';
import { ActivityPageData } from './ActivityPageData';
import { ActivityPageVariant } from './ActivityPageVariant';

interface ActivityContentHeaderProps {
    data: ActivityPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const ActivityContentHeader: React.FC<ActivityContentHeaderProps> = ({ data, ...rest }) => {
    const intl = useIntl();
    const { variant } = data;
    return (
        <DocumentHeader
            documentType={DocumentType.ACTIVITY}
            documentId={variant === ActivityPageVariant.READ ? data.activityData.id : null}
            icon={<ActivityIcon />}
            title={
                variant === ActivityPageVariant.READ
                    ? intl.formatMessage({ id: 'activity.titleExisting' }, { id: data.activityData.id })
                    : variant === ActivityPageVariant.CREATE
                    ? intl.formatMessage({ id: 'activity.titleNew' })
                    : intl.formatMessage({ id: 'activity.titleNewCloning' }, { activityNumber: data.activityData.id })
            }
            status={variant === ActivityPageVariant.READ ? getActivityStatus(data.activityData, intl) : undefined}
            creation={variant === ActivityPageVariant.READ ? data.activityData : undefined}
            {...rest}
        />
    );
};

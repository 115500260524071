import { IntlShape } from 'react-intl';
import { EnvironmentDTO } from '../../api/dto';
import { getActivityInformation } from './activity';
import { getActivityNimInformation } from './activityNim';
import { getDimrInformation } from './dimr';
import { getFirePermitInformation } from './firePermit';
import { getIs37ChangeRequestInformation, getIs37Information } from './is37';
import { getLockoutInformation } from './lockout';
import { getNoteDeCoupureInformation } from './noteDeCoupure';
import { getTaskInformation } from './task';
import { EntityData } from './types/EntityData';
import { EntityInformation } from './types/EntityInformation';
import { EntityType } from './types/EntityType';
import { getVicInformation } from './vic';
import { getWdpInformation } from './wdp';
import { getWorkOrderInformation } from './workOrder';

export const getDocumentInformation = (
    entity: EntityData,
    environment: EnvironmentDTO,
    intl: IntlShape
): EntityInformation => {
    switch (entity.type) {
        case EntityType.Activity:
            return getActivityInformation(entity.activity, intl);
        case EntityType.ActivityNim:
            return getActivityNimInformation(entity.activity, intl);
        case EntityType.NoteDeCoupure:
            return getNoteDeCoupureInformation(entity.noteDeCoupure, intl);
        case EntityType.Is37:
            return getIs37Information(entity.is37, intl);
        case EntityType.Is37ChangeRequest:
            return getIs37ChangeRequestInformation(entity.is37ChangeRequest, intl);
        case EntityType.FirePermit:
            return getFirePermitInformation(entity.firePermit, intl);
        case EntityType.WorkOrder:
            return getWorkOrderInformation(entity.workOrder, environment, intl);
        case EntityType.Wdp:
            return getWdpInformation(entity.wdp, intl);
        case EntityType.Dimr:
            return getDimrInformation(entity.dimr, intl);
        case EntityType.Vic:
            return getVicInformation(entity.vic, intl);
        case EntityType.Lockout:
            return getLockoutInformation(entity.lockout, intl);
        case EntityType.Task:
            return getTaskInformation(entity.task, intl);
    }
};

import { DriveFileMove } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { LinkedDocumentCode } from '../../../api/dto';
import { getEdhUrl, getEdmsUrl, getJmtUrl } from '../../../routes/externalRoutes';

interface DocumentRedirectProps {
    value: string | undefined;
    documentType: LinkedDocumentCode | undefined;
}

interface DocumentTypeHandler {
    getUrl: (documentId: string) => string;
    tooltip: MessageDescriptor['id'];
}

const documentTypeHandlers: Record<LinkedDocumentCode, DocumentTypeHandler> = {
    [LinkedDocumentCode.EDMS_PROCEDURE]: {
        getUrl: getEdmsUrl,
        tooltip: 'document.tooltip.openDocumentInEdms',
    },
    [LinkedDocumentCode.JMT_DOCUMENT]: {
        getUrl: getJmtUrl,
        tooltip: 'document.tooltip.openDocumentInJmt',
    },
    [LinkedDocumentCode.TRANSPORT_REQUEST]: {
        getUrl: getEdhUrl,
        tooltip: 'document.tooltip.openDocumentInEdh',
    },
};

export const DocumentRedirect: React.FC<DocumentRedirectProps> = ({ value, documentType }) => {
    const intl = useIntl();
    if (value && documentType) {
        const config = documentTypeHandlers[documentType];
        if (config) {
            // Just in case
            const { getUrl, tooltip } = config;
            return (
                <>
                    {!!value && (
                        <Tooltip
                            title={intl.formatMessage({
                                id: tooltip,
                            })}
                        >
                            <IconButton size="small" onClick={() => window.open(getUrl(value))} sx={{ my: -1, mr: -1 }}>
                                <DriveFileMove />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

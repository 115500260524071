import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { VicIcon } from '../../../lib/information/vic';
import { getVicStatus } from '../../../lib/status';
import { VicPageData } from './VicPageData';
import { VicPageVariant } from './VicPageVariant';

interface VicContentHeaderProps {
    data: VicPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const VicContentHeader: React.FC<VicContentHeaderProps> = ({ data, ...rest }) => {
    const intl = useIntl();
    const { variant } = data;
    return (
        <DocumentHeader
            documentType={DocumentType.VIC}
            documentId={variant === VicPageVariant.READ ? data.vicData.id : null}
            icon={<VicIcon />}
            title={
                variant === VicPageVariant.READ
                    ? intl.formatMessage({ id: 'vic.titleExisting' }, { id: data.vicData.id })
                    : variant === VicPageVariant.CREATE
                    ? intl.formatMessage({ id: 'vic.titleNew' })
                    : intl.formatMessage({ id: 'vic.titleNewCloning' }, { id: data.vicData.id })
            }
            status={variant === VicPageVariant.READ ? getVicStatus(data.vicData, intl) : undefined}
            creation={variant === VicPageVariant.READ ? data.vicData : undefined}
            {...rest}
        />
    );
};

import { Table, TableBody, TableCell as MuiTableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { SystemBsSrcReadDTO } from '../api/dto';

const TableCell = styled(MuiTableCell)({
    borderBottom: 'none',
    color: 'white',
});

type InfoEntry = {
    labelKey: MessageDescriptor['id'];
    value: React.ReactNode;
};

interface SystemInfoCardProps {
    system: SystemBsSrcReadDTO;
}
export const SystemInfoCard: React.FC<SystemInfoCardProps> = ({ system }) => {
    const intl = useIntl();
    const data: InfoEntry[] = [
        { labelKey: 'document.system.name', value: system.name },
        { labelKey: 'document.system.fullname', value: system.fullname },
    ];
    return (
        <TableContainer sx={{ py: 1 }}>
            <Table size="small">
                <TableBody>
                    {data.map(({ labelKey, value }, i) => (
                        <TableRow key={i}>
                            <TableCell sx={{ py: 0 }}>
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="common.labelValue"
                                        values={{ label: intl.formatMessage({ id: labelKey }), value: value }}
                                    />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

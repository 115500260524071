import { Alert, LinearProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getLockouts, getLockoutStatuses, getLockoutTypes } from '../../../api/documents/lockouts';
import {
    DocumentType,
    ListOfValuesReadDTO,
    LockoutGridReadDTO,
    LockoutStatusCode,
    LockoutTypeCode,
    SavedFilterType,
} from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterOperatorEnum, FilterTypeEnum, SearchFilter } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { interventionPeriodFilterSchema } from '../../../forms/schemas/interventionPeriod';
import { locationFilterSchema } from '../../../forms/schemas/location';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { getLockoutStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const STATUSES_PENDING_ACTION: Set<string> = new Set([
    LockoutStatusCode.WAITING_FOR_APPROVAL,
    LockoutStatusCode.WAITING_LOCKOUT_APPROVAL,
    LockoutStatusCode.WAITING_UNLOCK_APPROVAL,
]);

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: 'id',
        label: intl.formatMessage({ id: 'lockout.id' }),
        type: FilterTypeEnum.NUMBER,
    },
    valueFilterSchema(
        {
            id: 'lockoutType.code',
            label: intl.formatMessage({ id: 'lockout.field.type' }),
            fetchOptions: getLockoutTypes,
        },
        intl.locale
    ),
    activityFilterSchema(
        {
            id: 'activity.id',
            label: intl.formatMessage({ id: 'activity.name' }),
        },
        intl
    ),
    valueFilterSchema(
        {
            id: 'status.code',
            label: intl.formatMessage({ id: 'document.field.status' }),
            fetchOptions: getLockoutStatuses,
        },
        intl.locale
    ),
    valueFilterSchema(
        {
            id: 'activity.facility.code',
            label: intl.formatMessage({ id: 'document.field.activityFacility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    interventionPeriodFilterSchema({
        id: 'activity.interventionPeriod.periodName',
        label: intl.formatMessage({ id: 'activity.field.interventionPeriod' }),
    }),
    interventionPeriodFilterSchema({
        id: 'activity.proposedInterventionPeriod.periodName',
        label: intl.formatMessage({ id: 'activity.field.proposedInterventionPeriod' }),
    }),
    personFilterSchema({
        id: 'createdBy.searchLabel',
        label: intl.formatMessage({ id: 'document.field.creator' }),
    }),
    {
        id: 'activity.title',
        label: intl.formatMessage({ id: 'document.field.activityTitle' }),
        type: FilterTypeEnum.STRING,
    },
    locationFilterSchema({ id: 'lockoutLocations.id', label: 'Location' }),
    {
        id: 'requestStartDate',
        label: intl.formatMessage({ id: 'lockout.field.requestStart' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'requestEndDate',
        label: intl.formatMessage({ id: 'lockout.field.requestEnd' }),
        type: FilterTypeEnum.DATE,
    },
];

const columnHelper = createColumnHelper<LockoutGridReadDTO>();

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: intl.formatMessage({ id: 'lockout.id' }),
        size: 110,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.lockout.view,
                    params: { id: original.id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    // TODO inconsistencies
    columnHelper.accessor((row) => getLovLabel(translateValue(row.lockoutType, intl.locale)), {
        id: 'lockoutType.label',
        header: intl.formatMessage({ id: 'lockout.field.type' }),
        size: 80,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
    }),
    columnHelper.accessor((row) => row.activity.id, {
        id: 'activity.id',
        header: intl.formatMessage({ id: 'activity.name' }),
        size: 80,
        cell: ({ getValue }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activity.view,
                    params: { id: String(getValue()) },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => getLovLabel(translateValue(row.status, intl.locale)), {
        id: 'status.label',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 140,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
        cell: ({ row }) => <StatusChip {...getLockoutStatus(row.original, intl)} size="small" />,
    }),
    columnHelper.accessor(
        (row) => (row.activity.facility ? getLovLabel(translateValue(row.activity.facility, intl.locale)) : ''),
        {
            id: 'activity.facility.label',
            header: intl.formatMessage({ id: 'document.field.activityFacility' }),
            size: 130,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
        }
    ),
    columnHelper.accessor((row) => row.createdBy?.searchLabel ?? '', {
        id: 'createdBy.searchLabel',
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.createdBy && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.activity.title ?? '', {
        id: 'activity.title',
        header: intl.formatMessage({ id: 'document.field.activityTitle' }),
        size: 240,
    }),
    columnHelper.accessor((row) => row.lockoutLocations, {
        id: 'lockoutLocations',
        header: intl.formatMessage({ id: 'document.field.locations' }),
        size: 200,
        enableSorting: false,
        cell: ({ getValue }) =>
            getValue()
                .map((location) => location.impactName)
                .join(', '),
    }),
    columnHelper.accessor((row) => row.requestStartDate ?? '', {
        id: 'requestStartDate',
        header: intl.formatMessage({ id: 'lockout.field.requestStart' }),
        size: 110,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.requestEndDate ?? '', {
        id: 'requestEndDate',
        header: intl.formatMessage({ id: 'lockout.field.requestEnd' }),
        size: 110,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
];

const getLockoutsByType = (allTypes: ListOfValuesReadDTO[], type: string): SearchFilter[] => [
    {
        field: 'lockoutType.code',
        operator: FilterOperatorEnum.IS,
        value: allTypes.find((item) => item.code === type),
    },
];

const getActiveCryoLockouts = (types: ListOfValuesReadDTO[], statuses: ListOfValuesReadDTO[]): SearchFilter[] => [
    ...getLockoutsByType(types, LockoutTypeCode.CRYO),
    {
        field: 'status.code',
        operator: FilterOperatorEnum.IS,
        value: statuses.find((item) => item.code === LockoutStatusCode.LOCKED),
    },
];

const getPendingCryoLockouts = (types: ListOfValuesReadDTO[], statuses: ListOfValuesReadDTO[]): SearchFilter[] => [
    ...getLockoutsByType(types, LockoutTypeCode.CRYO),
    {
        field: 'status.code',
        operator: FilterOperatorEnum.IS_ANY_OF,
        value: statuses.filter((item) => STATUSES_PENDING_ACTION.has(item.code)),
    },
];

export const LockoutSearchPage = () => {
    const intl = useIntl();
    const {
        isError: isErrorTypes,
        isLoading: isLoadingTypes,
        data: lockoutTypes,
    } = useQuery(
        ['lockoutTypes'],
        () => {
            return getLockoutTypes();
        },
        { cacheTime: Number.MAX_SAFE_INTEGER }
    );
    const {
        isError: isStatusesError,
        isLoading: isLoadingStatuses,
        data: lockoutStatuses,
    } = useQuery(
        ['lockoutStatuses'],
        () => {
            return getLockoutStatuses();
        },
        { cacheTime: Number.MAX_SAFE_INTEGER }
    );

    if (isLoadingTypes || isLoadingStatuses) {
        return <LinearProgress />;
    } else if (isErrorTypes || isStatusesError) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="common.error" />
            </Alert>
        );
    }

    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.lockoutSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'lockout.name' })}
            columns={columns(intl)}
            pageKey="LockoutSearchPage"
            documentType={DocumentType.LOK}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getLockouts}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Lockout, lockout: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.LOK}
            predefinedFilters={[
                {
                    filters: getLockoutsByType(lockoutTypes, LockoutTypeCode.CRYO),
                    labelKey: 'search.filters.lockout.cryo',
                },
                {
                    filters: getLockoutsByType(lockoutTypes, LockoutTypeCode.ELECTRICAL),
                    labelKey: 'search.filters.lockout.electrical',
                },
                {
                    filters: getActiveCryoLockouts(lockoutTypes, lockoutStatuses),
                    labelKey: 'search.filters.lockout.cryo.active',
                },
                {
                    filters: getPendingCryoLockouts(lockoutTypes, lockoutStatuses),
                    labelKey: 'search.filters.lockout.cryo.pending',
                },
            ]}
        />
    );
};

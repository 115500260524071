import { TFormElement } from './types';

const FIELD_PATH_SEPARATOR = '.';

export const splitFieldPath = (path: string): string[] => path.split(FIELD_PATH_SEPARATOR);
export const joinFieldPath = (path: (string | number)[]): string => path.join(FIELD_PATH_SEPARATOR);
export const fieldPathStartsWith = (path: string[], prefix: string[]): boolean => {
    if (prefix.length > path.length) {
        return false;
    }
    for (let i = 0; i < prefix.length; i++) {
        if (path[i] !== prefix[i]) {
            return false;
        }
    }
    return true;
};
export const fieldPathsEqual = (path1: string[], path2: string[]): boolean =>
    path1.length === path2.length && fieldPathStartsWith(path1, path2);

const getCurrentPath = (paths: (string | undefined)[]) => paths.filter(Boolean).join(FIELD_PATH_SEPARATOR);

export const injectCurrentPath =
    (parentEl: TFormElement & { path?: string; disabled?: boolean; readOnly?: boolean }) =>
    (childEl: TFormElement & { path?: string }, index?: number) => {
        return {
            ...childEl,
            path: getCurrentPath([parentEl.path, ...(index !== undefined ? [index.toString()] : []), childEl.path]),
            disabled: parentEl.disabled || childEl.disabled,
            readOnly: parentEl.readOnly || childEl.readOnly,
        };
    };

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { buildImpactLocalstorageKey } from '../lib/localStorage';

const LOCAL_STORAGE_GRID_KEY = 'grid';

export interface GridTableLocalStorageState {
    hiddenColumns?: string[];
    savedFilterId?: number;
}

// https://github.com/jquense/yup/issues/1367
const localStorageStateSchema = yup
    .object({
        hiddenColumns: yup
            .array()
            .of(yup.string().required() as StringSchema<string>)
            .required()
            .default([]),
        savedFilterId: yup.number().default(undefined),
    })
    .required();

const defaultLocalStorageState = localStorageStateSchema.cast({});

const castLocalStorageState = (value: any, defaultValue: GridTableLocalStorageState): GridTableLocalStorageState => {
    try {
        return localStorageStateSchema.cast(value);
    } catch (validationError) {
        return defaultValue;
    }
};

const deserializeLocalStorageState = (
    value: string | null,
    defaultValue: GridTableLocalStorageState
): GridTableLocalStorageState => {
    try {
        return value !== null ? castLocalStorageState(JSON.parse(value), defaultValue) : defaultValue;
    } catch (syntaxError) {
        return defaultValue;
    }
};

const serializeLocalStorageState = (initialState: GridTableLocalStorageState): string => JSON.stringify(initialState);

const makeFullDefaultValue = (defaultValue: GridTableLocalStorageState): GridTableLocalStorageState => {
    return {
        hiddenColumns: defaultValue.hiddenColumns ?? defaultLocalStorageState.hiddenColumns,
        savedFilterId: defaultValue.savedFilterId ?? defaultLocalStorageState.savedFilterId,
    };
};

// Hook

export const useGridTableLocalStorageState = (
    gridKey: string,
    defaultValue: GridTableLocalStorageState = defaultLocalStorageState
): [GridTableLocalStorageState, (newLocalStorageState: GridTableLocalStorageState) => void] => {
    const key = useMemo(() => buildImpactLocalstorageKey(LOCAL_STORAGE_GRID_KEY, gridKey), [gridKey]);
    const fullDefaultValue = makeFullDefaultValue(defaultValue);
    const [localStorageState, setLocalStorageState] = useState(
        deserializeLocalStorageState(window.localStorage.getItem(key), fullDefaultValue)
    );

    useEffect(() => {
        setLocalStorageState(deserializeLocalStorageState(window.localStorage.getItem(key), fullDefaultValue));
    }, [key]);

    const setLocalStorageStateCallback = useCallback(
        (newInitialState: GridTableLocalStorageState) => {
            window.localStorage.setItem(key, serializeLocalStorageState(newInitialState));
            setLocalStorageState(deserializeLocalStorageState(window.localStorage.getItem(key), fullDefaultValue));
        },
        [key]
    );

    return [localStorageState, setLocalStorageStateCallback];
};

import { Settings } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getFavourites } from '../../api/users';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { FavouriteButton } from '../../components/FavouriteActivityButton';
import { ImpactLink } from '../../components/ImpactLink';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { getRoute, ROUTES } from '../../routes/routes';

interface FavouriteCardProps {
    activity: object;
}
const FavouriteCard: React.FC<FavouriteCardProps> = ({ activity }) => {
    const readableActivity: any = activity;
    const activityId = readableActivity.id;

    return (
        <Card sx={{ margin: 1 }} raised>
            <CardHeader
                title={
                    <Typography noWrap sx={{ minWidth: '0px' }}>
                        {readableActivity.title}
                    </Typography>
                }
                subheader={
                    <ImpactLink to={getRoute({ path: ROUTES.activity.view, params: { id: activityId } })}>
                        {readableActivity.id}
                    </ImpactLink>
                }
                sx={{
                    [`& .MuiCardHeader-content`]: { minWidth: '0px' },
                }}
            />
            <Divider />

            <CardContent>
                <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
                    <Box>
                        <Typography>Status:</Typography>
                        <Typography>Responsible:</Typography>
                        <Typography>Description:</Typography>
                        <Typography>Facility:</Typography>
                        <Typography>Type:</Typography>
                        <Typography>Priority:</Typography>
                        <Typography>Locations:</Typography>
                    </Box>

                    <Box sx={{ marginLeft: 2, minWidth: '0px' }}>
                        <Typography noWrap>{readableActivity.status?.label}</Typography>
                        <Typography noWrap>{readableActivity.who?.person?.id}</Typography>
                        <Typography noWrap>{readableActivity.description}</Typography>
                        <Typography noWrap>{readableActivity.facility?.description}</Typography>
                        <Typography noWrap>{readableActivity.type?.description}</Typography>
                        <Typography noWrap>{readableActivity.priority?.description}</Typography>
                        <Typography noWrap>{readableActivity.locations}</Typography>
                    </Box>
                </Box>
            </CardContent>

            <CardActions>
                <FavouriteButton defaultIsFavourite={true} activityId={activityId} />

                <IconButton>
                    <Settings />
                </IconButton>
            </CardActions>
        </Card>
    );
};

interface FavouritesPageProps {}
export const FavouritesPage: React.FC<FavouritesPageProps> = () => {
    const [favourites, setFavourites] = useState<any[]>([]);

    useAsyncEffect(async () => {
        try {
            const result = await getFavourites();
            setFavourites(result || []);
        } catch (error) {
            toast.error('There was an error fetching activities');
        }
    }, []);

    return (
        <DocumentPageStructure
            Header={<Typography>My Favourite Activities</Typography>}
            Body={
                <>
                    <Grid container>
                        {favourites.map((activity, idx) => (
                            <Grid key={idx} item xs={12} md={6} xl={3}>
                                <FavouriteCard activity={activity} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        />
    );
};

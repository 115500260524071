import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { CommentDTO, CommentLegacyType } from '../../api/dto';
import { stringAvatar } from '../../lib/avatar';
import { formatDateTime } from '../../lib/date';
import { translateTask, translateTaskType } from '../../lib/language';
import { sanitizeRichText } from '../../lib/sanitizeHtml';

const LEGACY_LABEL: Record<CommentLegacyType, MessageDescriptor['id']> = {
    [CommentLegacyType.DIMR_COMMENT]: 'document.comment.legacyType.dimr',
    [CommentLegacyType.DIMR_FEEDBACK]: 'document.comment.legacyType.dimrFeedback',
    [CommentLegacyType.DIMR_CLOSURE]: 'document.comment.legacyType.dimrClosure',
    [CommentLegacyType.VIC_COMMENT]: 'document.comment.legacyType.vic',
};

const RichTextBox = styled(Box)(() => ({
    '& p': {
        margin: 0,
    },
}));

type CommentListItemProps = Parameters<typeof ListItem>[0] & {
    comment: CommentDTO;
};

export const CommentListItem: React.FC<CommentListItemProps> = ({ comment, ...rest }) => {
    const intl = useIntl();
    const commentContext = useMemo(
        () =>
            comment.task
                ? intl.formatMessage(
                      { id: 'document.comment.task' },
                      { task: translateTaskType(comment.task.type, intl.locale).typeName }
                  )
                : comment.legacyType !== null
                ? intl.formatMessage({ id: LEGACY_LABEL[comment.legacyType] })
                : null,
        [intl, comment]
    );
    return (
        <ListItem {...rest} sx={{ p: 0, ...rest.sx }}>
            <ListItemAvatar>
                <Avatar {...stringAvatar(comment.createdBy)} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent="space-between">
                        <Stack>
                            <Typography>{comment.createdBy.searchLabel}</Typography>
                            {!!comment.task && (
                                <Typography variant="caption">
                                    {translateTask(comment.task, intl.locale).assignmentLabel}
                                </Typography>
                            )}
                        </Stack>
                        <Typography variant="caption">
                            {commentContext !== null ? commentContext + ' · ' : null}
                            {intl.formatMessage(
                                { id: 'document.comment.postedOn' },
                                { date: formatDateTime(comment.createdDate, intl) }
                            )}
                        </Typography>
                    </Stack>
                }
                secondary={
                    comment.richText ? (
                        <RichTextBox
                            component="span"
                            dangerouslySetInnerHTML={{ __html: sanitizeRichText(comment.text) }}
                        />
                    ) : (
                        <Box component="span" style={{ whiteSpace: 'pre-line' }}>
                            {comment.text.trim()}
                        </Box>
                    )
                }
                sx={{ my: 0 }}
            />
        </ListItem>
    );
};

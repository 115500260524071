import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

// Taken from: https://mui.com/material-ui/react-table/#customization

export const StripedTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

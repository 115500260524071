import { SignalCellularConnectedNoInternet4Bar } from '@mui/icons-material';
import {
    Box,
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Stack,
    ThemeOptions,
    ThemeProvider,
} from '@mui/material';
import { blue, green, orange, red } from '@mui/material/colors';
import { CernUserProfile } from 'oidc-client-ts';
import React, { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { EnvironmentDTO } from '../../api/dto';
import { LoadingPage } from '../../pages/LoadingPage';
import { useAsyncApplicationSettings } from './ApplicationSettingsProvider';

const makeEnvironmentTheme = (environment: EnvironmentDTO | undefined): ThemeOptions => {
    let mainColor,
        textColor = 'white';
    switch (environment) {
        case 'PRODUCTION':
            mainColor = blue[500];
            break;
        case 'DEVELOPMENT':
            mainColor = red[500];
            break;
        case 'TEST':
            mainColor = green[600];
            break;
        case 'LOCAL':
            mainColor = orange[800];
            break;
        default:
            return {};
    }
    return {
        components: {
            MuiToolbar: {
                defaultProps: {
                    sx: {
                        backgroundColor: mainColor,
                        color: textColor,
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    disableInteractive: true,
                },
            },
        },
    };
};

interface EnvironmentThemeProviderProps {
    children: React.ReactNode;
}
export const EnvironmentThemeProvider: React.FC<EnvironmentThemeProviderProps> = ({ children }) => {
    const { data: settings, isLoading, error } = useAsyncApplicationSettings();
    const theme = useMemo(() => createTheme(settings ? makeEnvironmentTheme(settings.environment) : {}), [settings]);
    const isForbidden = (error as any)?.data?.status === 403;
    const { signoutRedirect: logout, user } = useAuth();

    return (
        <ThemeProvider theme={theme}>
            {settings ? (
                children
            ) : isLoading ? (
                <LoadingPage />
            ) : isForbidden ? (
                <Dialog open>
                    <DialogTitle>Account is not allowed to access IMPACT</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The account <strong>{(user!.profile as CernUserProfile).cern_upn ?? '?'}</strong> is not
                            allowed to access IMPACT. Please note that service accounts cannot use the IMPACT
                            application because they are not linked to physical persons.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => logout()}>Log out</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Stack
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <SignalCellularConnectedNoInternet4Bar fontSize="large" color="error" sx={{ pt: 2, px: 2 }} />
                    <Box sx={{ px: 2 }}>The IMPACT application could not communicate with the server.</Box>
                    <Box sx={{ pb: 2, px: 2 }}>
                        We recommend checking your internet connection and{' '}
                        <Link href={window.location.href}>refreshing the page</Link>. If the error persists, please
                        contact the support.
                    </Box>
                </Stack>
            )}
        </ThemeProvider>
    );
};

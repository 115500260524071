import { getLocationAccessPoints } from '../../api/access-points';
import { AccessPointReadDTO, LocationDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchAccessPointsByName } from '../../lib/api';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const accessPointTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'accessPointName',
    fetchOptions: (searchTerm) => fetchAccessPointsByName(searchTerm),
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: (value) => value.name,
    multiple: false,
});
interface AccessPointFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const accessPointFilterSchema = ({ id, label }: AccessPointFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: AccessPointReadDTO) => value.name,
    typeProperties: {
        ...accessPointTypeProperties(),
        autocompleteProps: {
            openOnFocus: true,
            disableCloseOnSelect: false,
        },
    },
});

const locationAccessPointTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'id',
    fetchOptions: async (searchTerm, exactId, idField, values) =>
        getLocationAccessPoints({ locationId: (values?.[0] as LocationDTO | null | undefined)?.id ?? null }),
    watchPaths: (path: string) => [`${path.split('.').slice(0, 2).join('.')}.location`],
    fetchUseCases: [FetchUseCase.ON_OPEN],
    getOptionLabel: (value: AccessPointReadDTO) => value.name,
    multiple: true,
});
interface LocationAccessPointSchemaParameters
    extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const locationAccessPointSchema = ({
    path,
    label,
    required,
}: LocationAccessPointSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: locationAccessPointTypeProperties(),
});

import { Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ImpactHelmet } from '../../components/ImpactHelmet';
import { DocumentsCreatedChart } from './charts/DocumentsCreatedChart';
import { DocumentsStatusesChart } from './charts/DocumentsStatusesChart';

export const Statistics: React.FC = () => {
    const intl = useIntl();
    return (
        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
            <ImpactHelmet title={intl.formatMessage({ id: 'meta.statistics.title' })} />
            <DocumentsCreatedChart />
            <DocumentsStatusesChart />
        </Stack>
    );
};

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ListOfValuesReadDTO } from './dto';
import { GetManyUnpaged } from './types';

const RESOURCE_PATH = 'values';

export const getClasses: GetManyUnpaged<{}, string> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'meta', 'classes']), config).then((response: AxiosResponse) => response.data);

export const getValuesByClass: GetManyUnpaged<{ className: string }, ListOfValuesReadDTO> = async ({
    config,
    className,
}) =>
    client
        .get(buildPath([RESOURCE_PATH, 'meta', 'classes', className]), config)
        .then((response: AxiosResponse) => response.data);

export const getLanguages: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'languages']), config).then((response: AxiosResponse) => response.data);

export const getFacilities: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'facilities']), config).then((response: AxiosResponse) => response.data);

export const getWorkingTimes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'working-times']), config).then((response: AxiosResponse) => response.data);

export const getDurationTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'duration-types']), config).then((response: AxiosResponse) => response.data);

export const getActivityHazardTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'hazard-types']), config).then((response: AxiosResponse) => response.data);

export const getRadiographyDeviceTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH, 'radiography-device-types']), config)
        .then((response: AxiosResponse) => response.data);

export const getCryoLockTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'cryo-lock-types']), config).then((response: AxiosResponse) => response.data);

export const getProcedureDocuments: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'procedure-docs']), config).then((response: AxiosResponse) => response.data);

import { Compatible } from '@silevis/reactgrid';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { WdpPerformedTaskReadDTO } from '../../api/dto';
import { FormGenerator } from '../../forms/FormGenerator';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { CheckpointsCell } from './CheckpointsCellTemplate';
import { DialogInputLayout } from './DialogInputLayout';

type CheckpointsData = Pick<WdpPerformedTaskReadDTO, 'wdpCheckPoints'>;

const schema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.FIELD_ARRAY,
            path: 'wdpCheckPoints',
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                        sm: 'row',
                    },
                },
                elements: [
                    {
                        element: {
                            type: UiSchemaType.DATE,
                            path: 'checkPointDate',
                            label: intl.formatMessage({ id: 'document.field.date' }),
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.NUMBER,
                            path: 'percentageOfCompletion',
                            label: intl.formatMessage({ id: 'wdp.field.completionPercentage' }),
                            typeProperties: {
                                integer: true,
                                signed: false,
                            },
                        },
                    },
                ],
            },
            appendLabel: intl.formatMessage({ id: 'wdp.field.checkpoints.addNewCheckpoint' }),
            removeLabel: intl.formatMessage({ id: 'wdp.field.checkpoints.removeCheckpoint' }),
            initialValue: {
                checkPointDate: new Date(),
            },
        },
    ],
});

interface DialogCheckpointsInputProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    cell: Compatible<CheckpointsCell> | undefined;
}

export const DialogCheckpointsInput: React.FC<DialogCheckpointsInputProps> = ({ open, cell, setOpen }) => {
    const intl = useIntl();
    const form = useForm<CheckpointsData>({
        defaultValues: { wdpCheckPoints: [] },
    });
    const formSchema = useMemo(() => schema(intl), [intl]);
    const handleConfirm = () => {
        cell?.setData(form.getValues('wdpCheckPoints'));
        setOpen(false);
    };
    const handleCancel = () => setOpen(false);
    useEffect(() => {
        if (open) {
            form.setValue('wdpCheckPoints', cell?.wdpCheckPoints ?? []);
        }
    }, [open, form.setValue]);
    return (
        <DialogInputLayout
            open={open}
            setOpen={setOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            onBlur={handleConfirm}
            title={intl.formatMessage({ id: 'wdp.spreadsheet.checkpoints.title' })}
            readOnly={cell?.nonEditable}
        >
            <FormGenerator rootElement={formSchema} form={form} disabled={cell?.nonEditable} />
        </DialogInputLayout>
    );
};

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ActivityReadDTO, EgroupDTO, PersonReadDTO } from '../../../../../api/dto';
import { SelectablePersonList } from '../../../../../components/SelectablePersonList';
import { FormGenerator } from '../../../../../forms/FormGenerator';
import { TFormElement } from '../../../../../forms/types';
import { UiSchemaType } from '../../../../../forms/UiSchemaType';
import { useWdpTabMenuContext } from '../menu/WdpTabMenuContext';

const activityImportForm = (intl: IntlShape, activities: ActivityReadDTO[]): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.AUTOCOMPLETE,
            path: 'activity',
            label: intl.formatMessage({ id: 'activity.name' }),
            typeProperties: {
                options: activities,
                getOptionLabel: (activity: ActivityReadDTO) => activity.title,
                autocompleteProps: {
                    openOnFocus: true,
                },
            },
        },
    ],
});

interface ActivityImportFormValues {
    activity: ActivityReadDTO | undefined;
    egroup: EgroupDTO | undefined;
    participants: PersonReadDTO[];
}

interface WdpActivityImportDialogProps {
    activities: ActivityReadDTO[];
    onImport: (participants: PersonReadDTO[]) => void;
}

export const WdpActivityImportDialog: React.FC<WdpActivityImportDialogProps> = ({ activities, onImport }) => {
    const intl = useIntl();
    const { isOpenActivityImportDialog: open, setOpenActivityImportDialog: setOpen } = useWdpTabMenuContext();
    const setClosed = useCallback(() => setOpen(false), [setOpen]);
    const form = useForm<ActivityImportFormValues>({
        defaultValues: { participants: [], activity: activities.length === 1 ? activities[0] : undefined },
    });
    const selectedActivity = form.watch('activity');
    const availableParticipants = (selectedActivity?.participants ?? [])
        .map(({ person }) => person)
        .filter((person): person is PersonReadDTO => person != null);
    const selectedParticipants = form.watch('participants');
    const selectedParticipantsSet = new Set(selectedParticipants.map(({ id }) => id));
    const uiActivityImportForm = useMemo(() => activityImportForm(intl, activities), [intl]);
    useEffect(() => form.setValue('participants', []), [selectedActivity, form.setValue]);
    useEffect(() => {
        if (open) {
            form.reset();
        }
    }, [open, form.reset]);
    const handleImport = () => {
        onImport(selectedParticipants);
        setClosed();
    };
    return (
        <Dialog open={open} onClose={setClosed}>
            <DialogTitle>
                <FormattedMessage id="wdp.spreadsheet.action.importFromActivity" />
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
                    <FormGenerator rootElement={uiActivityImportForm} form={form} />
                    {availableParticipants.length > 0 && (
                        <SelectablePersonList
                            persons={availableParticipants}
                            selection={selectedParticipantsSet}
                            updateSelection={(newSelection) =>
                                form.setValue(
                                    'participants',
                                    availableParticipants.filter(({ id }) => newSelection.has(id))
                                )
                            }
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={setClosed} sx={{ color: grey[600] }}>
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button type="submit" disabled={selectedParticipants.length === 0} onClick={handleImport}>
                    <FormattedMessage id="wdp.spreadsheet.import.importSelected" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import React, { useEffect } from 'react';

export const useAsyncEffect = (
    asyncFun: (aborter: AbortController) => void,
    dependencies: React.DependencyList = []
) => {
    const aborter = new AbortController();

    useEffect(() => {
        asyncFun(aborter);

        return () => {
            aborter.abort();
        };
    }, dependencies);
};

import { ListOfValuesReadDTO } from '../../api/dto';
import { GetManyUnpaged } from '../../api/types';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface PrefilledLovSchemaPropertiesParameters {
    fetchOptions: GetManyUnpaged<{}, ListOfValuesReadDTO>;
    multiple?: boolean;
}

const prefilledLovTypeProperties = ({
    fetchOptions,
    multiple,
}: PrefilledLovSchemaPropertiesParameters): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'code',
    fetchOptions: () => fetchOptions(),
    fetchUseCases: [FetchUseCase.ON_LOAD],
    multiple,
});

interface PrefilledLovSchemaParameters
    extends PrefilledLovSchemaPropertiesParameters,
        Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const prefilledLovSchema = ({
    path,
    label,
    fetchOptions,
    multiple,
    required,
}: PrefilledLovSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: prefilledLovTypeProperties({ fetchOptions, multiple }),
});

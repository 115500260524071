import { Compatible } from '@silevis/reactgrid';
import React from 'react';
import { AsyncAutocompleteElement } from '../../forms/fields/AsyncAutocompleteElement';
import { personTypeProperties } from '../../forms/schemas/person';
import { DialogInputLayout } from './DialogInputLayout';
import { PersonCell } from './PersonAutocompleteTemplate';

interface ConfirmationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    cell: Compatible<PersonCell> | undefined;
}

export const DialogPersonInput: React.FC<ConfirmationDialogProps> = ({ open, cell, setOpen }) => {
    const handleCancel = () => {
        if (!!cell) cell.setData(cell.person);
        setOpen(false);
    };

    const field = {
        name: '',
        onBlur: () => 1,
        onChange: cell ? cell.setData : () => {},
        value: cell ? cell.person : '',
        ref: () => 1,
    };

    return (
        <DialogInputLayout
            open={open}
            setOpen={setOpen}
            onConfirm={() => setOpen(false)}
            onCancel={handleCancel}
            readOnly={cell?.nonEditable}
        >
            <AsyncAutocompleteElement
                field={field}
                useValueDirectly={true}
                strongValidateOptions={true}
                fieldState={{
                    invalid: false,
                    isTouched: false,
                    isDirty: false,
                }}
                {...personTypeProperties({ multiple: false })}
                idField={'id'}
                labelField={'name'}
                matchId={false}
                disabled={cell?.nonEditable}
                autocompleteProps={{
                    fullWidth: true,
                    size: 'small',
                    disableCloseOnSelect: false,
                    forcePopupIcon: false,
                    isOptionEqualToValue: (option, value) => {
                        return option.id === value.id;
                    },
                }}
            />
        </DialogInputLayout>
    );
};

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

export const useCopyToClipboard = () => {
    const intl = useIntl();
    return useCallback(
        (value: string) => {
            navigator.clipboard.writeText(value);
            toast.info(intl.formatMessage({ id: 'common.copiedToClipboard' }));
        },
        [intl]
    );
};

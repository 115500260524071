import { IntlShape } from 'react-intl';
import { getFirePermits } from '../../api/documents/firePermits';
import { makeFirePermitFilter } from '../../lib/filters/firePermit';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
import { documentTypeProperties } from './document';

interface FirePermitSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
}

export const firePermitSchema = (
    { path, label, required, multiple }: FirePermitSchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: documentTypeProperties({
        fetchOptions: async (query) => {
            const result = await getFirePermits({ filter: makeFirePermitFilter(query) });
            return result.content;
        },
        getOptionLabel: (firePermit) =>
            intl.formatMessage({ id: 'firePermit.titleExisting' }, { firePermitId: firePermit.id }), // TODO add activity title?,
        label: intl.formatMessage({ id: 'firePermit.name' }),
        multiple,
    }),
});

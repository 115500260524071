import { TextField } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import * as React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import { MuiPropsToOmit } from './dateAndTimeUtils';

export const dateFormat = 'dd-MMM-yyyy';

interface ImpactDatePickerProps extends Omit<DatePickerProps<any>, MuiPropsToOmit> {
    registerReturn: UseFormRegisterReturn;
    control: Control;
    label: string;
    disabled?: boolean;
    required?: boolean;
}
export const ImpactDatePicker: React.FC<ImpactDatePickerProps> = (props) => {
    const { registerReturn, control, required } = props;
    const { ref, ...registered } = registerReturn;

    return (
        <Controller
            control={control}
            {...registered}
            render={({ field: { ref: _, ...field }, fieldState }) => {
                return (
                    <DatePicker
                        //for MUI props
                        {...props}
                        {...field}
                        //otherwise optional empty fields will display as errors because
                        //they get passed new Date(undefined) -> Invalid Date
                        value={field.value ? new Date(field.value) : null}
                        format={dateFormat}
                        slots={{
                            textField: TextField,
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                InputLabelProps: {
                                    shrink: true,
                                },
                                error: !!fieldState.error,
                                helperText: fieldState.error?.message || undefined,
                                fullWidth: true,
                                required,
                                name: field.name,
                            },
                            field: {
                                clearable: true,
                            },
                        }}
                    />
                );
            }}
        />
    );
};

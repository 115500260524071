import { sfEqual, sfLike, sfOr } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

export const makeOrgUnitFilter = (searchParam: string): string => {
    const escapedSearchParam = escapeSfqbString(searchParam.replaceAll(/(\s|[-_\/])+/g, '-').trim(), true);
    return sfOr([sfLike('code', `${escapedSearchParam}*`), sfLike('code', `*-${escapedSearchParam}*`)]).toString();
};

export const makeOrgUnitIdFilter = (searchParam: string, idField: string = 'id'): string =>
    sfEqual(idField, escapeSfqbString(searchParam)).toString();

import { sfAnd, sfEqual, sfOr } from 'spring-filter-query-builder';
import { DocumentType, PersonRichReadDTO } from '../../api/dto';
import { escapeSfqbString } from '../sfqb';

export const makeDocumentTaskFilter = ({
    documentId,
    documentTypes,
    assignee,
}: {
    documentId: number;
    documentTypes: DocumentType[];
    assignee?: PersonRichReadDTO;
}): string => {
    return sfAnd([
        sfEqual('documentId', escapeSfqbString(String(documentId), false)),
        sfOr(
            documentTypes.map((documentType) => sfEqual('documentType', escapeSfqbString(String(documentType), false)))
        ),
        ...(assignee ? [sfEqual('assignees.person.searchLabel', escapeSfqbString(assignee.searchLabel, false))] : []),
    ]).toString();
};

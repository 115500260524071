import { Box, Stack } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { getDocumentsStatuses } from '../../../api/statistics';
import { FormGenerator } from '../../../forms/FormGenerator';
import { documentTypeSchema } from '../../../forms/schemas/documentType';
import { TFormElement } from '../../../forms/types';
import { UiSchemaType } from '../../../forms/UiSchemaType';
import { DocumentColorMapping } from '../../../lib/colors/DocumentColorMapping';
import { StatusColor } from '../../../lib/colors/StatusColor';
import { translateValue } from '../../../lib/language';

interface OptionsForm {
    documentType: { type: DocumentType } | null | undefined;
    onlyActive: boolean;
}

const formSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: documentTypeSchema({ path: 'documentType' }, intl),
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.CHECKBOX,
                path: 'onlyActive',
                label: intl.formatMessage({ id: 'meta.statistics.field.onlyActiveStatuses' }),
            },
        },
    ],
});

export const DocumentsStatusesChart: React.FC = () => {
    const intl = useIntl();
    const { data } = useQuery(['documents-statuses'], () => getDocumentsStatuses());
    const uiSchema = useMemo(() => formSchema(intl), []);
    const form = useForm<OptionsForm>({
        defaultValues: {
            documentType: null,
            onlyActive: false,
        },
    });
    const { documentType: documentTypeObject, onlyActive } = form.watch();
    const documentType = documentTypeObject?.type;

    const dataset = useMemo(() => {
        if (data === undefined || documentType == null) {
            return undefined;
        }
        const colors = DocumentColorMapping[documentType];
        if (colors === undefined) {
            return undefined;
        }
        const dataForDocumentType = data[documentType];
        if (dataForDocumentType === undefined) {
            return undefined;
        }
        return Object.entries(dataForDocumentType)
            .filter(
                ([status]) =>
                    !onlyActive ||
                    [StatusColor.PRIMARY, StatusColor.SUCCESS, StatusColor.WARNING].includes(colors[status])
            )
            .sort(([, a], [, b]) => b.count - a.count)
            .map(([k, v]) => ({ id: k, label: translateValue(v.value, intl.locale).label, value: v.count }));
    }, [data, documentType, onlyActive, intl]);

    return data !== undefined ? (
        <Stack direction="column" spacing={2} alignItems="center">
            <Box sx={{ width: '100%' }}>
                <FormGenerator rootElement={uiSchema} form={form} />
            </Box>
            {dataset !== undefined && documentType != null ? (
                <PieChart
                    series={[
                        {
                            data: dataset.map((o) => ({
                                ...o,
                                color: DocumentColorMapping[documentType]![o.id],
                            })),
                            innerRadius: 150,
                        },
                    ]}
                    height={500}
                />
            ) : null}
        </Stack>
    ) : null;
};

import { styled } from '@mui/material';
import React from 'react';
import { NotificationHeader } from './elements/NotificationHeader';

interface IMain {}
const Body = styled('main')<IMain>(() => ({
    flexGrow: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}));

interface HeaderlessLayoutProps {
    children: React.ReactNode;
}
export const HeaderlessLayout: React.FC<HeaderlessLayoutProps> = ({ children }) => {
    return (
        <Body>
            <NotificationHeader />
            {children}
        </Body>
    );
};

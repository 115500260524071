import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getDimrAlaraLevels, getDimrStatuses } from '../../../api/documents/dimrs';
import { getWdpVersions } from '../../../api/documents/wdps';
import { DocumentType, SavedFilterType, WdpVersionGridReadDTO } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { activityFilterSchema } from '../../../forms/schemas/activity';
import { dimrFilterSchema } from '../../../forms/schemas/dimr';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { EntityType } from '../../../lib/information/types/EntityType';
import { translateValue } from '../../../lib/language';
import { formatVersionedId } from '../../../lib/records/documents/versioned';
import { getWdpStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';
import {
    columnDimrActivities,
    columnDimrAlaraLevel,
    columnDimrId,
    columnDimrOwner,
    columnDimrRpo,
    columnDimrRso,
    columnDimrStatus,
    columnDimrTitle,
    DimrHelper,
} from '../dimr/DIMRSearchPage';

const CODE_ID = 'versionCode';
const TITLE = 'title';
const CREATOR = 'createdBy.searchLabel';
const FACILITIES = 'wdpFacilities.code';
const ACTIVITY_ID = 'activity.id';
const DIMR_VERSION_ID = 'dimr.id';
const DIMR_STATUS_ID = 'dimr.status.code';
const DIMR_ALARA_LEVEL_ID = 'dimr.alaraLevel.code';
const DIMR_TITLE = 'dimr.title';
const DIMR_OWNER = 'dimr.owner.searchLabel';
const CANCELLED = 'cancelled';
const DIMR_RPO = 'dimr.rpo.searchLabel';
const DIMR_RSO = 'dimr.rso.searchLabel';
const START_DATE = 'dimr.workStartDate';
const END_DATE = 'dimr.workEndDate';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: CODE_ID,
        label: intl.formatMessage({ id: 'wdp.field.version' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: TITLE,
        label: intl.formatMessage({ id: 'document.field.title' }),
        type: FilterTypeEnum.STRING,
    },
    personFilterSchema({
        id: CREATOR,
        label: intl.formatMessage({ id: 'document.field.creator' }),
    }),
    {
        id: CANCELLED,
        label: intl.formatMessage({ id: 'wdp.field.cancelled' }),
        type: FilterTypeEnum.BOOLEAN,
    },
    // TODO intervention period
    dimrFilterSchema({ id: DIMR_VERSION_ID, label: intl.formatMessage({ id: 'dimr.name' }) }, intl),
    // TODO linked to DIMR
    {
        id: DIMR_TITLE,
        label: intl.formatMessage({ id: 'wdp.field.dimrTitle' }),
        type: FilterTypeEnum.STRING,
    },
    valueFilterSchema(
        {
            id: DIMR_STATUS_ID,
            label: intl.formatMessage({ id: 'wdp.field.dimrStatus' }),
            fetchOptions: getDimrStatuses,
        },
        intl.locale
    ),
    valueFilterSchema(
        {
            id: DIMR_ALARA_LEVEL_ID,
            label: intl.formatMessage({ id: 'wdp.field.dimrAlaraLevel' }),
            fetchOptions: getDimrAlaraLevels,
        },
        intl.locale
    ),
    personFilterSchema({
        id: DIMR_OWNER,
        label: intl.formatMessage({ id: 'wdp.field.dimrOwner' }),
    }),
    personFilterSchema({ id: DIMR_RPO, label: intl.formatMessage({ id: 'wdp.field.dimrRpo' }) }),
    personFilterSchema({ id: DIMR_RSO, label: intl.formatMessage({ id: 'wdp.field.dimrRso' }) }),
    {
        id: START_DATE,
        label: intl.formatMessage({ id: 'wdp.field.dimrStartDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: END_DATE,
        label: intl.formatMessage({ id: 'wdp.field.dimrEndDate' }),
        type: FilterTypeEnum.DATE,
    },
    activityFilterSchema({ id: ACTIVITY_ID, label: intl.formatMessage({ id: 'activity.name' }) }, intl),
    valueFilterSchema(
        {
            id: FACILITIES,
            label: intl.formatMessage({ id: 'document.field.facilities' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
];

const columnHelper = createColumnHelper<WdpVersionGridReadDTO>();

const helper: DimrHelper<WdpVersionGridReadDTO> = { helper: columnHelper, accessor: (row) => row.dimr };

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.wdpMasterId, {
        id: 'wdpMasterId',
        header: intl.formatMessage({ id: 'wdp.field.wdpVersion' }),
        size: 100,
        cell: ({ row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.wdp.view,
                    params: { id: original.wdpMasterId, version: original.versionNumber },
                })}
            >
                {formatVersionedId(original.wdpMasterId, original.versionNumber)}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => row.title ?? '', {
        id: TITLE,
        header: intl.formatMessage({ id: 'document.field.title' }),
        size: 240,
    }),
    columnHelper.accessor((row) => row.createdBy?.searchLabel ?? '', {
        id: CREATOR,
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 240,
        cell: ({ row: { original }, getValue }) =>
            !!original.createdBy && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.cancelled, {
        id: 'cancelled',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 110,
        cell: ({ row: { original } }) => <StatusChip {...getWdpStatus(original, intl)} size="small" />,
    }),
    columnDimrId(
        {
            id: 'dimr.dimrMasterId',
            header: intl.formatMessage({ id: 'dimr.name' }),
        },
        helper
    ),
    columnDimrTitle(
        {
            id: DIMR_TITLE,
            header: intl.formatMessage({ id: 'wdp.field.dimrTitle' }),
        },
        helper
    ),
    columnDimrStatus(
        {
            id: DIMR_STATUS_ID,
            header: intl.formatMessage({ id: 'wdp.field.dimrStatus' }),
        },
        helper,
        intl
    ),
    columnDimrAlaraLevel(
        {
            id: DIMR_ALARA_LEVEL_ID,
            header: intl.formatMessage({ id: 'wdp.field.dimrAlara' }),
        },
        helper,
        intl.locale
    ),
    columnDimrOwner(
        {
            id: DIMR_OWNER,
            header: intl.formatMessage({ id: 'wdp.field.dimrOwner' }),
        },
        helper
    ),
    columnDimrRpo(
        {
            id: 'dimr.rpo.searchLabel',
            header: intl.formatMessage({ id: 'wdp.field.dimrRpo' }),
        },
        helper
    ),
    columnDimrRso(
        {
            id: 'dimr.rso.searchLabel',
            header: intl.formatMessage({ id: 'wdp.field.dimrRso' }),
        },
        helper
    ),
    columnHelper.accessor((row) => row.dimr?.workStartDate, {
        id: 'dimr.workStartDate',
        header: intl.formatMessage({ id: 'wdp.field.dimrStartDate' }),
        size: 155,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.dimr?.workEndDate, {
        id: 'dimr.workEndDate',
        header: intl.formatMessage({ id: 'wdp.field.dimrEndDate' }),
        size: 135,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnDimrActivities(
        {
            id: 'dimr.activities',
            header: intl.formatMessage({ id: 'wdp.field.dimrActivities' }),
        },
        helper
    ),
    columnHelper.accessor((row) => row.wdpFacilities.map((f) => translateValue(f, intl.locale).label).join(', '), {
        id: FACILITIES,
        header: intl.formatMessage({ id: 'document.field.facilities' }),
        size: 120,
        enableSorting: false,
    }),
];

export const WdpSearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.wdpSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'wdp.name' })}
            columns={columns(intl)}
            pageKey="WDPSearchPage"
            documentType={DocumentType.WDP}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getWdpVersions}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Wdp, wdp: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.WDP}
        />
    );
};

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { EquipmentReadDTO, EquipmentValidationReadDTO } from './dto';
import { GetManyList } from './types';

const RESOURCE_PATH = 'equipment';
export const getEquipments: GetManyList<EquipmentReadDTO> = async ({ searchTerm, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { searchTerm, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getActiveEquipments: GetManyList<EquipmentReadDTO> = async ({
    searchTerm,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([RESOURCE_PATH, 'active']), { ...config, params: { searchTerm, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getBulkValidation = async (codes: string[]): Promise<EquipmentValidationReadDTO> =>
    client
        .post(buildPath([RESOURCE_PATH, 'bulk-validation']), codes)
        .then((response: AxiosResponse<EquipmentValidationReadDTO>) => response.data);

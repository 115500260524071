import { Is37ChangeRequestStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const Is37ChangeRequestStatusColorMapping: Record<Is37ChangeRequestStatusCode, StatusColor> = {
    [Is37ChangeRequestStatusCode.REQUESTED]: StatusColor.PRIMARY,
    [Is37ChangeRequestStatusCode.ACCEPTED]: StatusColor.SUCCESS,
    [Is37ChangeRequestStatusCode.REJECTED]: StatusColor.WARNING,
    [Is37ChangeRequestStatusCode.CANCELLED]: StatusColor.NORMAL,
    [Is37ChangeRequestStatusCode.NEW]: StatusColor.PRIMARY,
};

import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { getNdcDistributionInfoAfter, getNdcDistributionInfoBefore } from '../../../api/documents/noteDeCoupures';
import { mapNdcToUpdateDTO } from '../../../lib/records/documents/noteDeCoupure';

interface DistributionListProps {
    done?: boolean;
}

export const DistributionList: React.FunctionComponent<DistributionListProps> = ({ done }) => {
    const { watch, getValues } = useFormContext();

    const watching = watch([
        'facilityCoordinatorsAffected',
        'bldgOccupantsAffected',
        'bldgLevelsOccupantsAffected',
        'bldgTsosAndDtsosAffected',
        'distributionTargets',
        'affectedNetworksAndEquipment',
    ]);

    const formDataMemoized = useMemo(() => getValues(), watching);
    const { isFetching, isError, data } = useQuery(['distributionInfo', formDataMemoized], () => {
        if (done) {
            return getNdcDistributionInfoAfter({ id: formDataMemoized.id });
        } else {
            const data2 = mapNdcToUpdateDTO(formDataMemoized);
            return getNdcDistributionInfoBefore({ data: data2 });
        }
    });

    if (done) {
        return isFetching ? (
            <Alert severity="success">
                <FormattedMessage id="ndc.distribution.fetch.loading" />
            </Alert>
        ) : isError || !data ? (
            <Alert severity="error">
                <FormattedMessage id="ndc.distribution.fetch.error" />
            </Alert>
        ) : (
            <Alert severity="success">
                <FormattedMessage
                    id="ndc.distribution.fetch.data"
                    values={{ people: data.peopleCount, emails: data.emailCount }}
                />
            </Alert>
        );
    } else {
        return isFetching ? (
            <Alert severity="info">
                <FormattedMessage id="ndc.distribution.compute.loading" />
            </Alert>
        ) : isError || !data ? (
            <Alert severity="error">
                <FormattedMessage id="ndc.distribution.compute.error" />
            </Alert>
        ) : (
            <Alert severity="info">
                <FormattedMessage
                    id="ndc.distribution.compute.data"
                    values={{ people: data.peopleCount, emails: data.emailCount }}
                />
            </Alert>
        );
    }
};

import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { InterventionPeriodNimReadDTO } from './dto';
import { GetManyPaged, GetManyUnpaged } from './types';
export const INTERVENTION_PERIODS_PATH = 'intervention-periods';

export const getFacilityInterventionPeriods: GetManyUnpaged<
    { facilityCode: string | null },
    InterventionPeriodNimReadDTO
> = async ({ facilityCode, config }) => {
    if (facilityCode == null) {
        return [];
    }
    return client
        .get(buildPath([INTERVENTION_PERIODS_PATH, facilityCode]), config)
        .then((response: AxiosResponse) => response.data);
};

export const getInterventionPeriodNames: GetManyPaged<InterventionPeriodNimReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([INTERVENTION_PERIODS_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

import { Typography } from '@mui/material';
import { Cell, CellStyle, CellTemplate, Compatible, Uncertain, UncertainCompatible } from '@silevis/reactgrid';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { WdpCheckPointReadDTO } from '../../api/dto';
import { formatDate } from '../../lib/date';

const cellStyle: CellStyle = {};

export interface CheckpointsCell extends Cell {
    type: 'checkpoints';
    wdpCheckPoints: WdpCheckPointReadDTO[];
    setData: (data: WdpCheckPointReadDTO[]) => void;
}

export class CheckpointsCellTemplate implements CellTemplate<CheckpointsCell> {
    intl: IntlShape;

    setOpen: (open: boolean) => void;

    setCell = (cell: Compatible<CheckpointsCell>) => {};

    constructor(props: { intl: IntlShape; setOpen: (open: boolean) => void; setCell: any }) {
        this.intl = props.intl;
        this.setOpen = props.setOpen;
        this.setCell = props.setCell;
    }

    getClassName(cell: Compatible<CheckpointsCell>, isInEditMode: boolean): string {
        return '';
    }

    getCompatibleCell(uncertainCell: Uncertain<CheckpointsCell>): Compatible<CheckpointsCell> {
        return {
            ...uncertainCell,
            text: '',
            value: Number.NaN,
            wdpCheckPoints: uncertainCell.wdpCheckPoints as any,
            setData: uncertainCell.setData!,
        };
    }

    getStyle(cell: Compatible<CheckpointsCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    handleCompositionEnd(
        cell: Compatible<CheckpointsCell>,
        eventData: string
    ): {
        cell: Compatible<CheckpointsCell>;
        enableEditMode: boolean;
    } {
        throw new Error('Method not implemented.');
    }

    handleKeyDown(
        cell: Compatible<CheckpointsCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string
    ): {
        cell: Compatible<CheckpointsCell>;
        enableEditMode: boolean;
    } {
        return { cell, enableEditMode: false };
    }

    isFocusable(cell: Compatible<CheckpointsCell>): boolean {
        return true;
    }

    render(
        cell: Compatible<CheckpointsCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<CheckpointsCell>, commit: boolean) => void
    ): React.ReactNode {
        const checkpoints = cell.wdpCheckPoints?.length ?? 0;
        const orderedValidCheckpoints = (cell.wdpCheckPoints ?? [])
            .slice()
            .filter(
                ({ checkPointDate, percentageOfCompletion }) =>
                    (typeof checkPointDate === 'string' ||
                        ((checkPointDate as any) instanceof Date && !isNaN(checkPointDate))) &&
                    percentageOfCompletion != null
            )
            .sort(
                (a, b) =>
                    new Date(a.checkPointDate).getTime() - new Date(b.checkPointDate).getTime() ||
                    a.percentageOfCompletion - b.percentageOfCompletion
            );
        const lastCheckpoint =
            orderedValidCheckpoints.length > 0 ? orderedValidCheckpoints[orderedValidCheckpoints.length - 1] : null;
        const handleClick = () => {
            if (cell.wdpCheckPoints) {
                this.setOpen(true);
                this.setCell(cell);
            }
        };
        return (
            !!cell.wdpCheckPoints && (
                <div
                    style={{ height: '100%', width: '100%', marginTop: '6px', cursor: 'pointer' }}
                    onClick={handleClick}
                >
                    <Typography sx={{ textDecoration: 'underline', fontSize: 'inherit' }}>
                        {lastCheckpoint !== null ? (
                            `${formatDate(lastCheckpoint.checkPointDate, this.intl)} ${
                                lastCheckpoint.percentageOfCompletion
                            }%`
                        ) : (
                            <FormattedMessage id="wdp.spreadsheet.checkpoints.nCheckpoints" values={{ checkpoints }} />
                        )}
                    </Typography>
                </div>
            )
        );
    }

    update(
        cell: Compatible<CheckpointsCell>,
        cellToMerge: UncertainCompatible<CheckpointsCell>
    ): Compatible<CheckpointsCell> {
        return cell;
    }
}

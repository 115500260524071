import { IntlShape } from 'react-intl';
import { DocumentType } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { getDocumentTypeName } from '../../lib/records/documents/document';
import { AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const DOCUMENT_TYPES_WITHOUT_WORKFLOW: Set<DocumentType> = new Set([DocumentType.RPA, DocumentType.WDP]);

interface DocumentTypeOption {
    type: DocumentType;
    label: string;
}

interface DocumentTypeTypePropertiesParameters {
    multiple?: boolean;
    onlyWithWorkflow?: boolean;
}

const documentTypeTypeProperties = (
    { multiple, onlyWithWorkflow }: DocumentTypeTypePropertiesParameters,
    intl: IntlShape
): AutocompleteFormElement['typeProperties'] => ({
    idField: 'type',
    multiple,
    options: Object.keys(DocumentType)
        .map((type) => type as DocumentType)
        .filter((type) => !!onlyWithWorkflow || !DOCUMENT_TYPES_WITHOUT_WORKFLOW.has(type))
        .map((type) => ({
            type: DocumentType[type],
            label: getDocumentTypeName(DocumentType[type], intl),
        })) satisfies DocumentTypeOption[],
    getOptionLabel: (option: DocumentTypeOption) => option.label,
});

interface DocumentTypeSchemaParameters
    extends DocumentTypeTypePropertiesParameters,
        Pick<AutocompleteFormElement, 'path' | 'required'> {}

export const documentTypeSchema = (
    { path, required, multiple }: DocumentTypeSchemaParameters,
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE,
    label: intl.formatMessage({ id: 'task.field.documentType' }),
    path,
    required,
    typeProperties: documentTypeTypeProperties({ multiple }, intl),
});

interface DocumentTypeFilterSchemaParameters extends DocumentTypeTypePropertiesParameters, Pick<TFilterElement, 'id'> {}

export const documentTypeFilterSchema = (
    { id }: DocumentTypeFilterSchemaParameters,
    intl: IntlShape
): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE,
    label: intl.formatMessage({ id: 'task.field.documentType' }),
    id,
    valueGetter: (value: DocumentTypeOption) => value.type,
    typeProperties: documentTypeTypeProperties({}, intl),
});

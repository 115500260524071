import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { getTasks } from '../api/task';
import { getFilterSchemaForTasks, getMyPendingTasksFilter } from '../pages/documents/task/TaskSearchPage';
import { useApplicationSettings } from './application/ApplicationSettingsProvider';
import { toSpringFilterQuery } from './searching/filters';
import { sanitizeFiltersBySchema } from './searching/utils';

interface TaskContextValue {
    taskQueryResults: UseQueryResult<Awaited<ReturnType<typeof getTasks>>>;
    withDispatchTaskEvent: <A extends any[], B>(f: (...a: A) => Promise<B>) => (...a: A) => Promise<B>;
}

const TaskContext = createContext<TaskContextValue | null>(null);

export const useTaskContext = () => {
    const context = useContext(TaskContext);
    if (context === null) {
        throw new Error();
    }
    return context;
};

interface TaskContextProviderProps {
    children: React.ReactNode;
}

const POLLING_INTERVAL_MS = 2000;
const REQUEST_RETRIES = 3;

export const TaskContextProvider: React.FC<TaskContextProviderProps> = ({ children }) => {
    const [repeatQueryCounter, setRepeatQueryCounter] = useState<number>(0);
    const intl = useIntl();
    const { currentUser, taskTypes } = useApplicationSettings();
    const taskFilterSchema = getFilterSchemaForTasks(intl);
    const sanitizedFilters = sanitizeFiltersBySchema(
        getMyPendingTasksFilter(currentUser, taskTypes, intl),
        taskFilterSchema
    );

    const taskQueryResults = useQuery(
        ['tasksCount'],
        () => getTasks({ filter: toSpringFilterQuery(sanitizedFilters) }),
        {
            enabled: repeatQueryCounter < REQUEST_RETRIES,
            refetchInterval: POLLING_INTERVAL_MS,
            onSuccess: () => {
                setRepeatQueryCounter(repeatQueryCounter + 1);
            },
        }
    );

    const withDispatchTaskEvent: TaskContextValue['withDispatchTaskEvent'] =
        (f) =>
        async (...args) => {
            const result = await f(...args);
            setRepeatQueryCounter(0);
            return result;
        };

    return <TaskContext.Provider value={{ taskQueryResults, withDispatchTaskEvent }}>{children}</TaskContext.Provider>;
};

import { Alert } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const FirePermitCompanyWarning: React.FC = () => (
    <Alert severity="info">
        <FormattedMessage id="firePermit.warning.company" />
    </Alert>
);
export const FirePermitLocationWarning: React.FC = () => (
    <Alert severity="warning">
        <FormattedMessage id="firePermit.warning.location" />
    </Alert>
);

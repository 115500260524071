import {
    Cell,
    CellStyle,
    CellTemplate,
    Compatible,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';

const cellStyle: CellStyle = {};
export interface LongInputCell extends Cell {
    type: 'longInput';
    setData: (value: any) => void;
    text: string;
}

export class LongInputCellTemplate implements CellTemplate<LongInputCell> {
    setOpen = (b: boolean) => {};

    setCell = (cell: Compatible<LongInputCell>) => {};

    constructor(props: { setOpen: any; setCell: any }) {
        this.setOpen = props.setOpen;
        this.setCell = props.setCell;
    }

    getCompatibleCell(uncertainCell: Uncertain<LongInputCell>): Compatible<LongInputCell> {
        const text = uncertainCell.text ?? '';
        return { ...uncertainCell, text, value: NaN, setData: uncertainCell.setData! };
    }

    isFocusable?(cell: Compatible<LongInputCell>): boolean {
        return true;
    }

    update?(
        cell: Compatible<LongInputCell>,
        cellToMerge: UncertainCompatible<LongInputCell>
    ): Compatible<LongInputCell> {
        if (cellToMerge.type === 'longInput') return this.getCompatibleCell({ ...cell, ...cellToMerge });
        return cell;
    }

    openDialog(cell: Compatible<LongInputCell>) {
        this.setOpen(true);
        this.setCell(cell);
    }

    handleKeyDown?(
        cell: Compatible<LongInputCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<LongInputCell>; enableEditMode: boolean } {
        if (keyCode === keyCodes.BACKSPACE || keyCode === keyCodes.DELETE) {
            return { cell: this.getCompatibleCell({ ...cell, text: undefined }), enableEditMode: false };
        }
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: true };
        if (keyCode === keyCodes.F2) {
            this.openDialog(cell);
        }
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<LongInputCell>,
        eventData: string
    ): { cell: Compatible<LongInputCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented.');
    }

    getStyle?(cell: Compatible<LongInputCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<LongInputCell>, isInEditMode: boolean): string {
        return `${cell.className} person`.trim();
    }

    // eslint-disable-next-line class-methods-use-this
    render(
        cell: Compatible<LongInputCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<LongInputCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div
                onDoubleClick={() => this.openDialog(cell)}
                style={{ height: '100%', width: '100%', marginTop: '6px' }}
            >
                <div>{cell.text}</div>
            </div>
        );
    }
}

import {
    AdminPanelSettings,
    BarChart,
    Close,
    ErrorOutline,
    ExpandLess,
    ExpandMore,
    FormatListBulleted,
    FormatListNumbered,
    HelpOutline,
    Info,
    KeyboardDoubleArrowLeft,
    Language,
    Login,
    Logout,
    Menu as MenuIcon,
    MoreVert,
    Notifications,
    Settings,
    SupportAgent,
} from '@mui/icons-material';
import {
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Badge,
    Box,
    Button,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link as MuiLink,
    List,
    ListItemIcon,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextBoxSearch } from 'mdi-material-ui';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { Link, useNavigate } from 'react-router-dom';
import packagejson from '../../../../package.json';
import { PersonReadDTO } from '../../../api/dto';
import { FetchUseCase } from '../../../forms/fields/AsyncAutocompleteElement';
import { FormGenerator } from '../../../forms/FormGenerator';
import { TFormElement } from '../../../forms/types';
import { UiSchemaType } from '../../../forms/UiSchemaType';
import { useAdminRole } from '../../../hooks/useAdminRole';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { useImpersonation } from '../../../hooks/useImpersonation';
import { fetchPeopleByName } from '../../../lib/api';
import { getPersonLabel } from '../../../lib/label';
import { EXTERNAL_ROUTES } from '../../../routes/externalRoutes';
import { ROUTES } from '../../../routes/routes';
import { ENVIRONMENT_SLUGS, useApplicationSettings } from '../../application/ApplicationSettingsProvider';
import { usePageContext } from '../../application/PageContextProvider';
import { ImpactLink } from '../../ImpactLink';
import { SearchBar } from './SearchBar';
interface IHeaderProps {
    open: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

interface IUserPreferenceDialogProps {
    open: boolean;
    close: () => void;
}

export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
}));

const UserPreferencesDialog: React.FunctionComponent<IUserPreferenceDialogProps> = ({ open, close }) => {
    return (
        <Dialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                User Preferences
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>Body to be set with the structured way later maybe?</DialogContent>
            <DialogActions>
                <Button onClick={() => {}}>View User Roles</Button>
                <Button onClick={close}>Cancel</Button>
                <Button onClick={() => {}}>Save and Close</Button>
            </DialogActions>
        </Dialog>
    );
};

interface ImpersonationDialogProps {
    open: boolean;
    onClose: () => void;
}

const ImpersonationDialog: React.FC<ImpersonationDialogProps> = ({ open, onClose }) => {
    const { impersonate, isImpersonateLoading } = useImpersonation();
    const form = useForm<{ impersonatingUser: PersonReadDTO | null }>({
        mode: 'onSubmit',
        defaultValues: { impersonatingUser: null },
    });
    const formRootElement: TFormElement = useMemo(
        () => ({
            type: UiSchemaType.AUTOCOMPLETE_ASYNC,
            label: 'User to impersonate',
            path: 'impersonatingUser',
            typeProperties: {
                fetchOptions: fetchPeopleByName,
                fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
                getOptionLabel: getPersonLabel,
            },
        }),
        []
    );
    const save = form.handleSubmit(({ impersonatingUser }) =>
        impersonate({ impersonatingUsername: impersonatingUser?.username ?? null, callback: onClose })
    );

    return (
        <Dialog onClose={() => !isImpersonateLoading && onClose()} open={open}>
            <DialogTitle>
                User impersonation
                <IconButton
                    onClick={() => !isImpersonateLoading && onClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography paragraph>Admin users can impersonate other users.</Typography>
                <FormGenerator rootElement={formRootElement} form={form} disabled={isImpersonateLoading} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => save()} disabled={isImpersonateLoading}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface AboutDialogProps {
    open: boolean;
    onClose: () => void;
}

const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose }) => {
    const intl = useIntl();
    const { environment, backendVersion } = useApplicationSettings();

    const frontendVersion = packagejson.version;
    const data: { labelKey: MessageDescriptor['id']; value: string }[] = [
        { labelKey: 'menu.about.environment', value: ENVIRONMENT_SLUGS[environment] },
        { labelKey: 'menu.about.backend', value: backendVersion },
        { labelKey: 'menu.about.frontend', value: frontendVersion },
    ];

    return (
        <Dialog onClose={() => onClose()} open={open}>
            <DialogTitle>
                <FormattedMessage id="menu.about.title" />
                <IconButton
                    onClick={() => onClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Table size="small" sx={{ mb: 2 }}>
                    <TableBody>
                        {data.map(({ labelKey, value }, i) => (
                            <TableRow key={i}>
                                <TableCell sx={{ borderBottom: 'none', pl: 0 }}>
                                    <FormattedMessage
                                        id="common.label"
                                        values={{ label: intl.formatMessage({ id: labelKey }) }}
                                    />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', pr: 0 }}>
                                    <Chip label={value} variant="outlined" size="small" />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Stack direction="column" spacing={1} alignItems="center">
                    <MuiLink href={EXTERNAL_ROUTES.changelog(environment)} target="_blank" rel="noopener">
                        <FormattedMessage id="menu.about.changelog" />
                    </MuiLink>
                    <MuiLink href={EXTERNAL_ROUTES.privacyNotice} target="_blank" rel="noopener">
                        <FormattedMessage id="menu.about.privacy" />
                    </MuiLink>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export const Header: React.FunctionComponent<IHeaderProps> = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const intl = useIntl();
    const { isMobile } = useDeviceType();
    const { signoutRedirect: logoutHandler } = useAuth();
    const { environment, currentUser } = useApplicationSettings();
    const isLocalEnvironment = environment === 'LOCAL';
    const navigate = useNavigate();
    const { context } = usePageContext();

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [supportButtonsExpanded, setSupportButtonsExpanded] = useState(false);
    const [adminButtonsExpanded, setAdminButtonsExpanded] = useState(false);
    const [adminLocalizationButtonsExpanded, setAdminLocalizationButtonsExpanded] = useState(false);
    const [userPreferencesDialogOpen, setUserPreferencesDialogOpen] = useState(false);
    const [impersonationDialogOpen, setImpersonationDialogOpen] = useState(false);
    const [aboutDialogOpen, setAboutDialogOpen] = useState(false);

    const isAdmin = useAdminRole();
    const { canImpersonateUsers, isCurrentlyImpersonating, clearImpersonation, clearImpersonationAndReload } =
        useImpersonation();

    const closeMenu = () => setMenuAnchor(null);
    const withMenuClose =
        <T,>(f: () => T): (() => T) =>
        () => {
            closeMenu();
            return f();
        };

    return (
        <>
            <AppBar position="fixed" open={open} sx={{ width: '100%' }} elevation={0}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                    >
                        {open ? <KeyboardDoubleArrowLeft /> : <MenuIcon />}
                    </IconButton>
                    <ImpactLink
                        to="/"
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                        onClick={() => {
                            if (isMobile) handleDrawerClose();
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    marginRight: 1,
                                    height: '32px',
                                }}
                                src="/favicon.svg"
                            />
                            <Typography variant="h6" noWrap>
                                IMPACT
                            </Typography>
                        </Box>
                    </ImpactLink>
                    <Box sx={{ flexGrow: 1 }} />

                    {isLocalEnvironment && (
                        <>
                            <SearchBar />
                            <IconButton
                                onClick={() => {
                                    navigate(ROUTES.news);
                                }}
                                color="inherit"
                            >
                                <Notifications />
                            </IconButton>
                        </>
                    )}

                    <IconButton
                        onClick={(event) => {
                            setMenuAnchor(event.currentTarget);
                            setSupportButtonsExpanded(false);
                            setAdminButtonsExpanded(false);
                            setAdminLocalizationButtonsExpanded(false);
                        }}
                        color="inherit"
                    >
                        <Badge color="error" variant="dot" badgeContent=" " invisible={!isCurrentlyImpersonating}>
                            <MoreVert />
                        </Badge>
                    </IconButton>
                    <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
                        {isLocalEnvironment && (
                            <MenuItem onClick={withMenuClose(() => setUserPreferencesDialogOpen(true))}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <FormattedMessage id="menu.configuration" />
                            </MenuItem>
                        )}
                        {/*TODO: set the navigation to the support link*/}
                        <MenuItem onClick={() => setSupportButtonsExpanded(!supportButtonsExpanded)}>
                            <ListItemIcon>
                                <SupportAgent />
                            </ListItemIcon>
                            <FormattedMessage id="menu.support.title" />
                            <Box flexGrow={1} />
                            {supportButtonsExpanded ? <ExpandLess /> : <ExpandMore />}
                        </MenuItem>
                        <Collapse in={supportButtonsExpanded} timeout="auto">
                            <List disablePadding>
                                <MenuItem
                                    sx={{ pl: 4 }}
                                    component="a"
                                    target="_blank"
                                    rel="noopener"
                                    href={EXTERNAL_ROUTES.support.help(environment)}
                                    onClick={closeMenu}
                                >
                                    <ListItemIcon>
                                        <TextBoxSearch />
                                    </ListItemIcon>
                                    <FormattedMessage id="menu.support.documentation" />
                                </MenuItem>
                                <MenuItem
                                    sx={{ pl: 4 }}
                                    component="a"
                                    target="_blank"
                                    rel="noopener"
                                    href={EXTERNAL_ROUTES.support.question({ context, intl })}
                                    onClick={closeMenu}
                                >
                                    <ListItemIcon>
                                        <HelpOutline />
                                    </ListItemIcon>
                                    <FormattedMessage id="menu.support.question" />
                                </MenuItem>
                                <MenuItem
                                    sx={{ pl: 4 }}
                                    component="a"
                                    target="_blank"
                                    rel="noopener"
                                    href={EXTERNAL_ROUTES.support.incidentReport({ context, intl })}
                                    onClick={closeMenu}
                                >
                                    <ListItemIcon>
                                        <ErrorOutline />
                                    </ListItemIcon>
                                    <FormattedMessage id="menu.support.report" />
                                </MenuItem>
                            </List>
                        </Collapse>
                        <MenuItem onClick={withMenuClose(() => setAboutDialogOpen(true))}>
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <FormattedMessage id="menu.about" />
                        </MenuItem>
                        {(canImpersonateUsers || isCurrentlyImpersonating) && <Divider />}
                        {isAdmin && [
                            <MenuItem
                                key={0}
                                onClick={() => {
                                    setAdminButtonsExpanded(!adminButtonsExpanded);
                                }}
                            >
                                <ListItemIcon>
                                    <AdminPanelSettings />
                                </ListItemIcon>
                                <FormattedMessage id="menu.adminPages.title" />
                                <Box flexGrow={1} />
                                {adminButtonsExpanded ? <ExpandLess /> : <ExpandMore />}
                            </MenuItem>,
                            <Collapse key={1} in={adminButtonsExpanded} timeout="auto">
                                <List disablePadding>
                                    <MenuItem
                                        sx={{ pl: 4 }}
                                        component={Link}
                                        to={ROUTES.statistics}
                                        onClick={closeMenu}
                                    >
                                        <ListItemIcon>
                                            <BarChart />
                                        </ListItemIcon>
                                        <FormattedMessage id="menu.adminPages.statistics" />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            setAdminLocalizationButtonsExpanded(!adminLocalizationButtonsExpanded)
                                        }
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemIcon>
                                            <Language />
                                        </ListItemIcon>
                                        <FormattedMessage id="menu.adminPages.localization.title" />
                                        <Box flexGrow={1} />
                                        {adminLocalizationButtonsExpanded ? <ExpandLess /> : <ExpandMore />}
                                    </MenuItem>
                                    <Collapse in={adminLocalizationButtonsExpanded} timeout="auto">
                                        <List disablePadding>
                                            <MenuItem
                                                sx={{ pl: 8 }}
                                                component={Link}
                                                to={ROUTES.localization.values}
                                                onClick={closeMenu}
                                            >
                                                <ListItemIcon>
                                                    <FormatListNumbered />
                                                </ListItemIcon>
                                                <FormattedMessage id="menu.adminPages.localization.values" />
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ pl: 8 }}
                                                component={Link}
                                                to={ROUTES.localization.frontend}
                                                onClick={closeMenu}
                                            >
                                                <ListItemIcon>
                                                    <FormatListBulleted />
                                                </ListItemIcon>
                                                <FormattedMessage id="menu.adminPages.localization.frontend" />
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ pl: 8 }}
                                                component={Link}
                                                to={ROUTES.localization.backend}
                                                onClick={closeMenu}
                                            >
                                                <ListItemIcon>
                                                    <FormatListBulleted />
                                                </ListItemIcon>
                                                <FormattedMessage id="menu.adminPages.localization.backend" />
                                            </MenuItem>
                                        </List>
                                    </Collapse>
                                </List>
                            </Collapse>,
                        ]}
                        {(canImpersonateUsers || isCurrentlyImpersonating) && (
                            <MenuItem onClick={withMenuClose(() => setImpersonationDialogOpen(true))}>
                                <ListItemIcon>
                                    <Login />
                                </ListItemIcon>
                                <FormattedMessage id="menu.loginAs" />
                            </MenuItem>
                        )}
                        {isCurrentlyImpersonating && <Divider />}
                        {isCurrentlyImpersonating && (
                            <ListSubheader sx={{ lineHeight: '40px' }}>{currentUser.username}</ListSubheader>
                        )}
                        {isCurrentlyImpersonating && (
                            <MenuItem onClick={withMenuClose(clearImpersonationAndReload)}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <FormattedMessage id="menu.logout" />
                            </MenuItem>
                        )}
                        <Divider />
                        <ListSubheader sx={{ lineHeight: '40px' }}>
                            {!isCurrentlyImpersonating ? (
                                currentUser.username
                            ) : (
                                <FormattedMessage id="menu.impersonatorParenthesis" />
                            )}
                        </ListSubheader>
                        <MenuItem
                            onClick={withMenuClose(() => {
                                clearImpersonation();
                                return logoutHandler();
                            })}
                        >
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <FormattedMessage id="menu.logout" />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <UserPreferencesDialog open={userPreferencesDialogOpen} close={() => setUserPreferencesDialogOpen(false)} />
            <AboutDialog open={aboutDialogOpen} onClose={() => setAboutDialogOpen(false)} />
            <ImpersonationDialog open={impersonationDialogOpen} onClose={() => setImpersonationDialogOpen(false)} />
        </>
    );
};

import { getFirePermitFireDetections } from '../../api/documents/firePermits';
import { LocationDTO } from '../../api/dto';
import { Locale } from '../../i18n/Locale';
import { getLovLabel } from '../../lib/label';
import { translateValue } from '../../lib/language';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface FpFireDetectionTypePropertiesParameters {
    multiple?: boolean;
}

const fpFireDetectionTypeProperties = (
    { multiple }: FpFireDetectionTypePropertiesParameters,
    locale: Locale
): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'code',
    fetchOptions: (searchTerm, exactId, idField, values) =>
        getFirePermitFireDetections({ locationId: (values?.[0] as LocationDTO | null | undefined)?.id ?? null }),
    watchPaths: ['location'],
    fetchUseCases: [FetchUseCase.ON_OPEN, FetchUseCase.ON_LOAD], // "On open" since it depends on the FP location
    getOptionLabel: (value) => getLovLabel(translateValue(value, locale)),
    multiple,
});

interface FpFireDetectionSchemaParameters
    extends FpFireDetectionTypePropertiesParameters,
        Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const fpFireDetectionSchema = (
    { path, label, multiple, required }: FpFireDetectionSchemaParameters,
    locale: Locale
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: fpFireDetectionTypeProperties({ multiple }, locale),
});

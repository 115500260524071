import { FirePermitStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const FirePermitStatusColorMapping: Record<FirePermitStatusCode, StatusColor> = {
    [FirePermitStatusCode.NEW]: StatusColor.PRIMARY,
    [FirePermitStatusCode.SAVED]: StatusColor.PRIMARY,
    [FirePermitStatusCode.WAITING_APPROVAL]: StatusColor.WARNING,
    [FirePermitStatusCode.APPROVED]: StatusColor.SUCCESS,
    [FirePermitStatusCode.WAITING_END]: StatusColor.WARNING,
    [FirePermitStatusCode.FINISHED]: StatusColor.NORMAL,
    [FirePermitStatusCode.CLOSED]: StatusColor.NORMAL,
    [FirePermitStatusCode.REJECTED]: StatusColor.ERROR,
    [FirePermitStatusCode.CANCELLED]: StatusColor.ERROR,
};

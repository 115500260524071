import {
    ContractNimReadDTO,
    CsamSensorReadDTO,
    EgroupDTO,
    EquipmentReadDTO,
    LocationDTO,
    OrgUnitReadDTO,
    PersonReadDTO,
    SupplierReadDTO,
    SystemBsSrcReadDTO,
} from '../api/dto';
import { translateValue } from './language';

export const getPersonLabel = (person: PersonReadDTO): string => person.searchLabel;

export const getOrgUnitLabel = (orgUnit: OrgUnitReadDTO): string => orgUnit.code || '';

export const getLovLabel = (value: ReturnType<typeof translateValue>): string => value.label;

export const getCsamSensorLabel = (value: CsamSensorReadDTO): string => value.searchLabel || '';

export const getContractLabel = (value: ContractNimReadDTO): string =>
    value.contractNumber + ' - ' + value.employer?.name + ' - ' + value.cernContractResponsible?.searchLabel;

export const getLocationLabel = (location: LocationDTO): string => location.impactName;
export const getCompanyLabel = (company: SupplierReadDTO): string => company.name;

export const getEgroupLabel = (egroup: EgroupDTO): string => egroup.name || '';
export const getEquipmentLabel = (equipment: EquipmentReadDTO): string =>
    `${equipment.code}${equipment.description ? ` (${equipment.description})` : ''}`;

export const getSupplierLabel = (supplier: SupplierReadDTO): string => supplier.name ?? '';
export const getSupplierCodeLabel = (supplier: SupplierReadDTO): string => supplier.code ?? '';

export const getSystemLabel = (system: SystemBsSrcReadDTO): string => system.fullname ?? '';

import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ImpactHelmet } from '../components/ImpactHelmet';
import { ImpactLink } from '../components/ImpactLink';
import { ROUTES } from '../routes/routes';

interface NotFoundPageProps {}
export const NotFoundPage: React.FC<NotFoundPageProps> = ({}) => {
    const intl = useIntl();
    return (
        <Alert severity="error" sx={{ margin: 3 }}>
            <ImpactHelmet title={intl.formatMessage({ id: 'title.notFound' })} />
            <AlertTitle>
                <FormattedMessage id="notFound.title" />
            </AlertTitle>
            <FormattedMessage id="notFound.description" />{' '}
            <ImpactLink to={ROUTES.homepage}>
                <FormattedMessage id="notFound.backHome" />
            </ImpactLink>
        </Alert>
    );
};

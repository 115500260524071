import { AutocompleteProps } from '@mui/material';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement } from '../types';

interface DocumentTypePropertiesParameters<T> {
    fetchOptions: (searchTerm: string) => Promise<T[]>;
    getOptionLabel: (item: T) => string;
    label: string;
    multiple?: boolean;
    autocompletePropsOverrides?: Partial<AutocompleteProps<unknown, boolean | undefined, boolean | undefined, any>>;
}

export const documentTypeProperties = <T>({
    fetchOptions,
    getOptionLabel,
    label,
    multiple,
    autocompletePropsOverrides,
}: DocumentTypePropertiesParameters<T>): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: (searchTerm: string) => {
        const prefixLower = label.toLowerCase().trim();
        const searchLower = searchTerm.toLowerCase().trim();
        let strippedSearchValue: string;
        if (searchLower.startsWith(prefixLower)) {
            strippedSearchValue = searchLower.substring(prefixLower.length).trim();
        } else if (prefixLower.startsWith(searchLower)) {
            strippedSearchValue = '';
        } else {
            strippedSearchValue = searchTerm;
        }
        return fetchOptions(strippedSearchValue);
    },
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel,
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
        ...(autocompletePropsOverrides && { ...autocompletePropsOverrides }),
    },
    multiple: !!multiple,
});

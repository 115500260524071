import { useState } from 'react';

export interface UseDrawerReturnType {
    isDrawerOpen: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
    toggleDrawer: () => void;
}

export const useDrawerHook = (defaultState: boolean = false): UseDrawerReturnType => {
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(defaultState);

    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);
    const toggleDrawer = () => setDrawerOpen((prev) => !prev);

    return { isDrawerOpen, openDrawer, closeDrawer, toggleDrawer };
};

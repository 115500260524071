import React from 'react';
import { useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { FirePermitIcon } from '../../../lib/information/firePermit';
import { getFirePermitStatus } from '../../../lib/status';
import { FirePermitPageData } from './FirePermitPageData';
import { FirePermitPageVariant } from './FirePermitPageVariant';

interface FirePermitContentHeaderProps {
    data: FirePermitPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const FirePermitContentHeader: React.FC<FirePermitContentHeaderProps> = ({ data, ...rest }) => {
    const intl = useIntl();
    const { variant } = data;
    return (
        <DocumentHeader
            documentType={DocumentType.FP}
            documentId={variant === FirePermitPageVariant.READ ? data.firePermitData.id : null}
            icon={<FirePermitIcon />}
            title={
                variant === FirePermitPageVariant.READ
                    ? intl.formatMessage({ id: 'firePermit.titleExisting' }, { firePermitId: data.firePermitData.id })
                    : variant === FirePermitPageVariant.CREATE
                    ? intl.formatMessage({ id: 'firePermit.titleNew' })
                    : intl.formatMessage({ id: 'firePermit.titleNewCloning' }, { firePermitId: data.firePermitData.id })
            }
            status={variant === FirePermitPageVariant.READ ? getFirePermitStatus(data.firePermitData, intl) : undefined}
            creation={variant === FirePermitPageVariant.READ ? data.firePermitData : undefined}
            {...rest}
        />
    );
};

import { useQuery } from '@tanstack/react-query';
import React, { useLayoutEffect, useState } from 'react';
import { ResponsiveReadOnlyDialog } from './ResponsiveReadOnlyDialog';

interface PdfContainerProps {
    pdf: Blob | null;
}

const PdfContainer: React.FC<PdfContainerProps> = ({ pdf }) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    useLayoutEffect(() => {
        // `useEffect` to be able to perform GC
        if (pdf !== null) {
            const url = URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
            setPdfUrl(url);
            return () => URL.revokeObjectURL(url);
        } else {
            setPdfUrl(null);
        }
    }, [pdf, setPdfUrl]);
    return pdfUrl !== null ? <embed src={pdfUrl} type="application/pdf" width="100%" height="100%" /> : null;
};

interface PdfDialogProps {
    queryKey: (string | number)[];
    fetchPdf: () => Promise<Blob>;
    open: boolean;
    onClose: () => void;
}

export const PdfDialog: React.FC<PdfDialogProps> = ({ queryKey, fetchPdf, open, onClose }) => {
    const { isFetching, data } = useQuery(queryKey, fetchPdf, {
        enabled: open,
    });

    return (
        <ResponsiveReadOnlyDialog open={open} onClose={onClose} isLoading={isFetching} title={'PDF'}>
            <PdfContainer pdf={!isFetching ? data ?? null : null} />
        </ResponsiveReadOnlyDialog>
    );
};

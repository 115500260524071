import { colors, Stack } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { DocumentType } from '../../../api/dto';
import { getDocumentsCreated } from '../../../api/statistics';
import { FormGenerator } from '../../../forms/FormGenerator';
import { TFormElement } from '../../../forms/types';
import { UiSchemaType } from '../../../forms/UiSchemaType';
import { getDocumentTypeName } from '../../../lib/records/documents/document';

const stackStrategy = {
    stack: 'total',
    area: true,
    stackOffset: 'none', // To stack 0 on top of others
} as const;

const customize = {
    height: 500,
    margin: { top: 5 },
};

const documentColors: Partial<Record<DocumentType, Record<keyof typeof colors.red, string>>> = {
    [DocumentType.NDC]: colors.lightBlue,
    [DocumentType.WDP]: colors.blueGrey,
    [DocumentType.LOK]: colors.blue,
    [DocumentType.VIC]: colors.green,
    [DocumentType.FP]: colors.brown,
    [DocumentType.IS37]: colors.grey,
    //[DocumentType.IS37_CR]: colors.grey,
    [DocumentType.DIMR]: colors.purple,
    //[DocumentType.RPA]: colors.deepPurple,
    [DocumentType.ACTIVITY]: colors.red,
};

interface OptionsForm {
    percentage: boolean;
}

const formSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            element: {
                type: UiSchemaType.CHECKBOX,
                path: 'percentage',
                label: intl.formatMessage({ id: 'meta.statistics.field.percentage' }),
            },
        },
    ],
});

export const DocumentsCreatedChart: React.FC = () => {
    const intl = useIntl();
    const { data } = useQuery(['documents-created'], () => getDocumentsCreated());
    const uiSchema = useMemo(() => formSchema(intl), [intl]);
    const form = useForm<OptionsForm>({
        defaultValues: {
            percentage: false,
        },
    });
    const { percentage } = form.watch();
    const dataset = useMemo(
        () =>
            data !== undefined
                ? data.map(({ counts, ...o }) => {
                      const countsOrPercentages = !percentage
                          ? counts
                          : (() => {
                                const visibleCounts = Object.fromEntries(
                                    Object.entries(counts).filter(
                                        ([k]) => documentColors[k as DocumentType] !== undefined
                                    )
                                );
                                const total = Object.values(visibleCounts).reduce((a, b) => a + b, 0);
                                const denominator = (total > 0 ? total : 1) / 100; // Avoid division by zero
                                return Object.fromEntries(
                                    Object.entries(visibleCounts).map(([k, v]) => [k, v / denominator])
                                );
                            })();
                      return { ...o, ...countsOrPercentages };
                  })
                : undefined,
        [data, percentage]
    );
    const documentTypes = useMemo(() => {
        if (data !== undefined && data.length > 0) {
            const whitelist = new Set(Object.keys(data[0].counts));
            return Object.keys(documentColors).filter((k) => whitelist.has(k)) as DocumentType[];
        } else {
            return [];
        }
    }, [data]);
    const yValueFormatter = (axis: boolean) => (v: any) => {
        const value = v ?? 0;
        return !percentage ? intl.formatNumber(value) : `${value.toFixed(axis ? 0 : 1)} %`;
    };
    return dataset !== undefined ? (
        <Stack direction="column" spacing={2} alignItems="center">
            <FormGenerator rootElement={uiSchema} form={form} />
            <LineChart
                xAxis={[
                    {
                        dataKey: 'date',
                        scaleType: 'point',
                        valueFormatter: (value) =>
                            intl.formatDate(value, {
                                month: '2-digit',
                                year: 'numeric',
                            }),
                    },
                ]}
                yAxis={[
                    {
                        min: 0,
                        max: !percentage ? undefined : 100,
                        valueFormatter: yValueFormatter(true),
                    },
                ]}
                series={documentTypes.map((type) => ({
                    dataKey: type,
                    label: getDocumentTypeName(type, intl),
                    color: documentColors[type]!['400'],
                    showMark: false,
                    valueFormatter: yValueFormatter(false),
                    ...stackStrategy,
                }))}
                dataset={dataset}
                {...customize}
            />
        </Stack>
    ) : null;
};

import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const FirePermitRecommendationsContent: React.FC = () => {
    const intl = useIntl();

    return (
        <>
            <Typography variant="h5">
                <FormattedMessage id="firePermit.safety.title" />
            </Typography>
            <Typography variant="h6">
                <FormattedMessage id="firePermit.safety.before.title" />
            </Typography>
            <ul>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.operator" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.before.operator' }),
                        }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.appointee" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.before.appointee1' }),
                        }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.appointee" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.before.appointee2.parent' }),
                        }}
                    />
                    <ul>
                        <li>
                            <FormattedMessage id="firePermit.safety.before.appointee2.item1" />
                        </li>
                        <li>
                            <FormattedMessage id="firePermit.safety.before.appointee2.item2" />
                        </li>
                        <li>
                            <FormattedMessage id="firePermit.safety.before.appointee2.item3" />
                        </li>
                        <li>
                            <FormattedMessage id="firePermit.safety.before.appointee2.item4" />
                        </li>
                    </ul>
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.appointee" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.before.appointee3' }),
                        }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.appointee" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.before.appointee4' }),
                        }}
                    />
                </li>
            </ul>
            <Typography variant="h6">
                <FormattedMessage id="firePermit.safety.during.title" />
            </Typography>
            <ul>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.operatorOrFirewatcher" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.during.operatorOrFirewatcher1' }),
                        }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.operatorOrFirewatcher" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.during.operatorOrFirewatcher2' }),
                        }}
                    />
                </li>
            </ul>
            <Typography variant="h6">
                <FormattedMessage id="firePermit.safety.after.title" />
            </Typography>
            <ul>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.operator" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.after.operator1' }),
                        }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="common.dashValue"
                        values={{
                            label: (
                                <strong>
                                    <FormattedMessage id="firePermit.safety.who.operator" />
                                </strong>
                            ),
                            value: intl.formatMessage({ id: 'firePermit.safety.after.operator2' }),
                        }}
                    />
                </li>
            </ul>

            <Typography variant="h5">
                <FormattedMessage id="firePermit.important.title" />
            </Typography>
            <ul style={{ marginBottom: 0 }}>
                <li>
                    <FormattedMessage id="firePermit.important.recommendation1" />
                </li>
                <li>
                    <FormattedMessage id="firePermit.important.recommendation2" />
                </li>
                <li>
                    <FormattedMessage id="firePermit.important.recommendation3" />
                </li>
                <li>
                    <FormattedMessage id="firePermit.important.recommendation4" />
                </li>
                <li>
                    <FormattedMessage id="firePermit.important.recommendation5" />
                </li>
            </ul>
        </>
    );
};

/* eslint-disable import/no-extraneous-dependencies */
import { Editor } from '@tinymce/tinymce-react';
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import React from 'react';

// Taken from: https://www.tiny.cloud/docs/tinymce/6/react-pm-bundle/
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// Languages
import 'tinymce-i18n/langs/fr_FR';

// Plugins
// Uncomment the ones that are used
//import 'tinymce/plugins/advlist';
//import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
//import 'tinymce/plugins/autosave';
//import 'tinymce/plugins/charmap';
//import 'tinymce/plugins/code';
//import 'tinymce/plugins/codesample';
//import 'tinymce/plugins/directionality';
//import 'tinymce/plugins/emoticons';
//import 'tinymce/plugins/fullscreen';
//import 'tinymce/plugins/help';
//import 'tinymce/plugins/image';
//import 'tinymce/plugins/importcss';
//import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
//import 'tinymce/plugins/media';
//import 'tinymce/plugins/nonbreaking';
//import 'tinymce/plugins/pagebreak';
//import 'tinymce/plugins/preview';
//import 'tinymce/plugins/quickbars';
//import 'tinymce/plugins/save';
//import 'tinymce/plugins/searchreplace';
//import 'tinymce/plugins/table';
//import 'tinymce/plugins/template';
//import 'tinymce/plugins/visualblocks';
//import 'tinymce/plugins/visualchars';
//import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
// eslint-disable-next-line import/no-unresolved
import contentCss from 'tinymce/skins/content/default/content.min.css?inline';
// eslint-disable-next-line import/no-unresolved
import contentUiCss from 'tinymce/skins/ui/oxide/content.min.css?inline';

// Required
(window as Record<string, any>).tinymce = tinymce;

export const BundledEditor = (props: IAllProps) => {
    const { init, ...rest } = props;
    // note that skin and content_css is disabled to avoid the normal
    // loading process and is instead loaded as a string via content_style
    return (
        <Editor
            init={{
                ...init,
                skin: false,
                content_css: false,
                content_style: [contentCss, contentUiCss, init?.content_style || ''].join('\n'),
            }}
            {...rest}
        />
    );
};

import { sfLike, sfOr } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

export const createSupplierNameQuery = (name: string): string => {
    const searchLabelFieldName = 'name';
    const escapedInput = escapeSfqbString(name, true);
    return sfOr([
        sfLike(searchLabelFieldName, `${escapedInput}*`), // Label starts with
        sfLike(searchLabelFieldName, `* ${escapedInput}*`), // Or label contains a substring that starts with a space
    ]).toString();
};

export const createSupplierCodeQuery = (code: string): string =>
    sfLike('code', `*${escapeSfqbString(code, true)}*`).toString();

import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { OrgUnitReadDTO } from '../../api/dto';
import { getOrgUnitChildren, getOrgUnitRoots } from '../../api/orgUnits';
import { FilterTypeEnum } from '../../components/searching/filters';
import { TreeWidget } from '../../components/TreeWidget';
import { useDeviceType } from '../../hooks/useDeviceType';
import { fetchOrgUnits } from '../../lib/api';
import { getOrgUnitLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const getNodeId = (node: OrgUnitReadDTO) => String(node.code);

const orgUnitTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchOrgUnits,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getOrgUnitLabel,
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
    endAdornment: ({ field, updateValue, multiple, matchId, disabled, readOnly }) => {
        const intl = useIntl();
        const { isMobile } = useDeviceType();
        const { value } = field;
        return (
            <TreeWidget
                title={intl.formatMessage({ id: 'document.orgUnit.tooltip' })}
                disabled={disabled}
                readOnly={readOnly}
                TreeProps={{
                    loadRootNodes: () => getOrgUnitRoots(),
                    loadChildrenNodes: (node: OrgUnitReadDTO) => getOrgUnitChildren({ code: node.code }),
                    getNodeLabel: (node: OrgUnitReadDTO) => (
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            spacing={isMobile ? 0 : 1}
                            alignItems={isMobile ? 'left' : 'center'}
                        >
                            <Typography noWrap>{node?.code}</Typography>
                            <Typography color="textSecondary" fontSize="small">
                                {node?.description?.toLocaleUpperCase()}
                            </Typography>
                        </Stack>
                    ),
                    selectedNodes: value ? (multiple ? value : [value]) : [],
                    onSelect: (nodes: OrgUnitReadDTO[]) => {
                        if (!nodes || !nodes.length) return;
                        if (nodes.length === 1 && !multiple) {
                            updateValue && updateValue(matchId ? getNodeId(nodes[0]) : nodes[0]);
                        } else {
                            updateValue && updateValue(matchId ? nodes.map((e: any) => getNodeId(e)) : nodes);
                        }
                    },
                    getNodeId,
                    multiSelect: multiple,
                    isLeafNode: (node: OrgUnitReadDTO) => node.isLeaf,
                    keys: [field.name],
                }}
            />
        );
    },
});

interface OrgUnitSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const orgUnitSchema = ({ path, label, required }: OrgUnitSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: orgUnitTypeProperties(),
});

interface OrgUnitFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const orgUnitFilterSchema = ({ id, label }: OrgUnitFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.HIERARCHICAL_AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: OrgUnitReadDTO) => value.code,
    typeProperties: orgUnitTypeProperties(),
});

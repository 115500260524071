import { Circle } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import React from 'react';

type StatusChipProps = Omit<ChipProps, 'color' | 'icon'> & {
    color: string;
};

export const StatusChip: React.FC<StatusChipProps> = ({ label, color, ...rest }) => {
    return <Chip label={label} variant="outlined" icon={<Circle style={{ color }} />} {...rest} />;
};

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { findOIMRedirectionFor, REDIRECT_NOT_IMPLEMENTED_TO_OIM } from '../routes/oimRoutes';
import { useApplicationSettings } from './application/ApplicationSettingsProvider';

export const ImpactLink = <P extends any = {}>({
    to,
    ...props
}: Omit<MuiLinkProps<typeof RouterLink, P>, 'component'> & { to: string }): React.ReactElement => {
    const settings = useApplicationSettings();
    const parsedTo = useMemo(() => queryString.parseUrl(to), [to]);
    const redirection = !REDIRECT_NOT_IMPLEMENTED_TO_OIM
        ? undefined
        : findOIMRedirectionFor(parsedTo.url, parsedTo.query, window.location.origin, settings);
    if (redirection === undefined) {
        return <MuiLink component={RouterLink} to={to} {...props} />;
    } else {
        return <MuiLink href={redirection} {...props} />;
    }
};

import { Folder } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Leaf } from 'mdi-material-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { SystemBsSrcReadDTO } from '../../api/dto';
import { getSystemChildren, getSystemRoots } from '../../api/system';
import { FilterTypeEnum } from '../../components/searching/filters';
import { SystemInfoCard } from '../../components/SystemInfoCard';
import { TreeWidget } from '../../components/TreeWidget';
import { useDeviceType } from '../../hooks/useDeviceType';
import { fetchSystems } from '../../lib/api';
import { getSystemLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const getNodeId = (node: SystemBsSrcReadDTO) => String(node.id);

const isLeaf = (system: SystemBsSrcReadDTO) => system.childCount === 0;

const getSystemIcon = (system: SystemBsSrcReadDTO) => {
    if (isLeaf(system)) return <Leaf />;
    return <Folder />;
};

const systemTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchSystems,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getSystemLabel,
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
    endAdornment: ({ field, updateValue, multiple, matchId, disabled, readOnly }) => {
        const intl = useIntl();
        const { isMobile } = useDeviceType();
        const { value } = field;
        return (
            <TreeWidget
                title={intl.formatMessage({ id: 'document.system.tooltip' })}
                disabled={disabled}
                readOnly={readOnly}
                TreeProps={{
                    loadRootNodes: () => getSystemRoots(),
                    loadChildrenNodes: (system: SystemBsSrcReadDTO) => getSystemChildren({ code: system.id }),
                    getNodeLabel: (system: SystemBsSrcReadDTO) => (
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            spacing={isMobile ? 0 : 1}
                            alignItems={isMobile ? 'left' : 'center'}
                        >
                            <Typography noWrap>{system?.name}</Typography>
                        </Stack>
                    ),
                    selectedNodes: value ? (multiple ? value : [value]) : [],
                    onSelect: (systems: SystemBsSrcReadDTO[]) => {
                        if (!systems || !systems.length) return;
                        if (systems.length === 1 && !multiple) {
                            updateValue && updateValue(matchId ? getNodeId(systems[0]) : systems[0]);
                        } else {
                            updateValue && updateValue(matchId ? systems.map((e: any) => getNodeId(e)) : systems);
                        }
                    },
                    getNodeId,
                    multiSelect: multiple,
                    isLeafNode: isLeaf,
                    keys: [field.name],
                    isSelectable: (system) => system.visible && system.level > 1,
                    tooltipContent: (system) => <SystemInfoCard system={system} />,
                    icon: getSystemIcon,
                }}
            />
        );
    },
});

interface SystemSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const systemSchema = ({ path, label, required }: SystemSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: systemTypeProperties(),
});
interface SystemFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const systemFilterSchema = ({ id, label }: SystemFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: SystemBsSrcReadDTO) => value.id,
    typeProperties: systemTypeProperties(),
});

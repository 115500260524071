import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { PersonReadDTO } from './dto';
import { GetManyPaged } from './types';

const RESOURCE_PATH = 'persons';

export const getPersons: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH, 'selectable']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getFrsOfficers: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH, 'frsOfficers']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);
export const getPersonsForFiltering: GetManyPaged<PersonReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

import { Delete, Save, SaveAs } from '@mui/icons-material';
import { Alert, Button, IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingState } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import deepEqual from 'fast-deep-equal';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { SavedFilterType } from '../../api/dto';
import {
    createSavedFilter,
    deleteSavedFilter,
    getSavedFiltersForDocument,
    updateSavedFilter,
} from '../../api/savedFilters';
import { DeleteOne, GetManyUnpaged } from '../../api/types';
import { FormGenerator } from '../../forms/FormGenerator';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { BaseData } from './SearchPage';
import { SimpleFiltersDialog, SimpleFiltersDialogProps } from './SimpleFiltersDialog';

const editSearchFormSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.INPUT,
            label: intl.formatMessage({ id: 'search.saved.field.name' }),
            path: 'name',
        },
    ],
});

const savedSearchFormPath = 'search';

interface FiltersDialogProps extends SimpleFiltersDialogProps {
    setSorting: Dispatch<SetStateAction<SortingState>>;
    savedFilterType: SavedFilterType;
    savedSearchId: number | undefined;
    sorting: SortingState;
    onSaveSearchStore: (searchId: number | undefined) => void;
}

type SaveSearchProps<TSearchData extends BaseData> = {
    onSaveSearch: (params: any) => Promise<TSearchData>;
    onUpdateSearch: (params: any) => Promise<TSearchData>;
    onDeleteSearch: DeleteOne<{ id: number }>;
    fetchSavedSearches: GetManyUnpaged<{ filterType: SavedFilterType }, TSearchData>;
};

const savedSearchApi: SaveSearchProps<any> = {
    onSaveSearch: createSavedFilter,
    onUpdateSearch: updateSavedFilter,
    onDeleteSearch: deleteSavedFilter,
    fetchSavedSearches: getSavedFiltersForDocument,
};

export const FiltersDialog = <TSearchData extends BaseData>({
    title,
    open,
    form,
    formSchema,
    formGeneratorKey,
    savedFilterType,
    savedSearchId,
    setSorting,
    sorting,
    onClose,
    onCancel,
    onUpdateFilters,
    onResetFilters,
    onSaveSearchStore,
}: FiltersDialogProps): ReactElement => {
    const intl = useIntl();
    const {
        data: savedSearches = [],
        refetch: refetchSavedSearches,
        isLoading: isSavedSearchFetching,
    } = useQuery(['savedSearches'], () => savedSearchApi.fetchSavedSearches({ filterType: savedFilterType }));

    const savedSearchForm = useForm();
    const watchSavedSearch = savedSearchForm.watch(savedSearchFormPath);
    const editSearchForm = useForm({
        defaultValues: {
            name: watchSavedSearch?.name,
            createdBy: watchSavedSearch?.createdBy,
            subscribed: watchSavedSearch?.subscribed,
        },
    });

    const [searchSelectionVersion, setSearchSelectionVersion] = useState(0);
    //this is the only way we should interact with the saved searches selection form
    const setSavedSearchSelection = (search: TSearchData | undefined | null) => {
        //setValue is preferable to reset as it avoids weird bugs due to form's default values not being set
        savedSearchForm.setValue(savedSearchFormPath, search);
        //used to force a rerender using the key prop of the form generator
        setSearchSelectionVersion((previousValue) => previousValue + 1);
    };

    const {
        formState: { isDirty },
    } = form;

    // handles communication with the main filter form
    useEffect(() => {
        if (!isSavedSearchFetching) {
            if (watchSavedSearch === null) {
                onResetFilters();
                setSavedSearchSelection(null);
            }
            const filterFormSpecification = watchSavedSearch?.specification
                ? JSON.parse(watchSavedSearch.specification)
                : null;
            if (filterFormSpecification) {
                setSorting(watchSavedSearch?.sorting ? JSON.parse(watchSavedSearch?.sorting) : []);
                form.reset(filterFormSpecification);
                onUpdateFilters();
            }
            if (watchSavedSearch && savedSearchId !== watchSavedSearch?.id) {
                onSaveSearchStore(watchSavedSearch?.id);
            }
            editSearchForm.reset({
                name: watchSavedSearch?.name,
                createdBy: watchSavedSearch?.createdBy,
                subscribed: watchSavedSearch?.subscribed,
            });
        }
    }, [watchSavedSearch, isSavedSearchFetching]);

    // handles setting and resetting the values inside a saved search dropdown
    useEffect(() => {
        const currentSearch = savedSearches?.find((s) => s && deepEqual(JSON.parse(s.specification), form.getValues()));
        const savedSearch = savedSearches?.find((s) => s && s.id === savedSearchId);
        if (savedSearch) {
            setSavedSearchSelection(savedSearch);
            return;
        }
        if (currentSearch) {
            if (
                !(
                    watchSavedSearch &&
                    savedSearches?.find(
                        (s) => s && deepEqual(JSON.parse(watchSavedSearch.specification), form.getValues())
                    )
                )
            ) {
                setSavedSearchSelection(currentSearch);
            }
        } else {
            setSavedSearchSelection(undefined);
        }
    }, [savedSearches, formGeneratorKey]);

    const popupState = usePopupState({ variant: 'popover', popupId: 'saved-search-popover' });

    const queryClient = useQueryClient();

    const { mutate: mutateSavedSearch, isLoading: isSaveSearchLoading } = useMutation(savedSearchApi.onSaveSearch, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: ['autocomplete', 'search'] });
            popupState.close();
            setSavedSearchSelection(data);
            return refetchSavedSearches();
        },
        onError: (response: AxiosResponse) => {
            toast.error(response.data.message);
        },
    });

    const { mutate: mutateUpdateSearch, isLoading: isUpdateSearchLoading } = useMutation(
        savedSearchApi.onUpdateSearch,
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries({ queryKey: ['autocomplete', 'search'] });
                popupState.close();
                setSavedSearchSelection(data);
                onResetFilters();
                return refetchSavedSearches();
            },
            onError: (response: AxiosResponse) => {
                toast.error(response.data.message);
            },
        }
    );

    const { mutate: mutateDeleteSearch, isLoading: isDeleteSearchLoading } = useMutation(
        savedSearchApi.onDeleteSearch,
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries({ queryKey: ['autocomplete', 'search'] });
                popupState.close();
                setSavedSearchSelection(null);
                return refetchSavedSearches();
            },
            onError: () => {
                toast.error(intl.formatMessage({ id: 'search.saved.deleteError' }));
            },
        }
    );

    const isLoading = isSaveSearchLoading || isUpdateSearchLoading || isDeleteSearchLoading;

    const handleSaveSearch = editSearchForm.handleSubmit((formData) => {
        mutateSavedSearch({
            data: {
                name: formData.name,
                subscribed: formData.subscribed ?? false,
                specification: JSON.stringify(form.getValues()),
                filterType: savedFilterType,
                sorting: JSON.stringify(sorting),
            },
        });
    });

    const handleEditSearch = editSearchForm.handleSubmit((formData) => {
        mutateUpdateSearch({
            data: {
                name: formData.name,
                subscribed: formData.subscribed,
                specification: JSON.stringify(form.getValues()),
                sorting: JSON.stringify(sorting),
            },
            id: watchSavedSearch.id,
        });
    });

    const handleDeleteSearch = () => {
        mutateDeleteSearch({
            id: watchSavedSearch.id,
        });
    };

    const isSavable = watchSavedSearch || isDirty || (!watchSavedSearch && form.getValues()?.filters?.length > 0);

    const savedSearchFormSchema = useMemo<TFormElement>(
        () => ({
            type: UiSchemaType.LAYOUT_VERTICAL,
            elements: [
                {
                    type: UiSchemaType.AUTOCOMPLETE,
                    path: savedSearchFormPath,
                    label: '',
                    typeProperties: {
                        options: savedSearches,
                        getOptionLabel: (filter) => filter?.name,
                        textFieldProps: {
                            placeholder: intl.formatMessage({ id: 'search.saved.searches' }),
                        },
                        adornmentOutside: true,
                        endAdornment: () => {
                            return (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {isSavable ? (
                                        <>
                                            <Tooltip
                                                title={
                                                    <FormattedMessage
                                                        id={
                                                            watchSavedSearch
                                                                ? 'search.saved.tooltip.save'
                                                                : 'search.saved.tooltip.saveAsNew'
                                                        }
                                                    />
                                                }
                                            >
                                                <IconButton size="small" {...bindTrigger(popupState)}>
                                                    {watchSavedSearch ? <SaveAs /> : <Save />}
                                                </IconButton>
                                            </Tooltip>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Stack direction="column" spacing={1} p={2} pb={1}>
                                                    <FormGenerator
                                                        key={searchSelectionVersion}
                                                        form={editSearchForm}
                                                        rootElement={editSearchFormSchema(intl)}
                                                        disabled={isLoading}
                                                    />

                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        sx={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        {!!watchSavedSearch && (
                                                            <Button
                                                                onClick={handleDeleteSearch}
                                                                startIcon={<Delete />}
                                                                color="error"
                                                                disabled={isLoading}
                                                            >
                                                                <FormattedMessage id="search.saved.action.delete" />
                                                            </Button>
                                                        )}
                                                        <Button
                                                            onClick={handleSaveSearch}
                                                            startIcon={<Save />}
                                                            disabled={isLoading}
                                                        >
                                                            <FormattedMessage id="search.saved.action.saveAsNew" />
                                                        </Button>
                                                        {!!watchSavedSearch && (
                                                            <Button
                                                                onClick={handleEditSearch}
                                                                startIcon={<SaveAs />}
                                                                disabled={isLoading}
                                                            >
                                                                <FormattedMessage id="search.saved.action.save" />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            </Popover>
                                        </>
                                    ) : null}
                                </Stack>
                            );
                        },
                    },
                },
            ],
        }),
        [watchSavedSearch, isDirty, popupState, isLoading, savedSearches.length, searchSelectionVersion, isSavable]
    );

    return (
        <SimpleFiltersDialog
            title={title}
            open={open}
            form={form}
            formGeneratorKey={formGeneratorKey}
            formSchema={formSchema}
            onClose={onClose}
            onCancel={onCancel}
            onUpdateFilters={onUpdateFilters}
            onResetFilters={() => {
                onResetFilters();
                setSavedSearchSelection(null);
            }}
            savedSearchSlot={
                <FormGenerator
                    //this key forces a remount on the change of the value solving the issue IMPACT-865
                    key={searchSelectionVersion}
                    form={savedSearchForm}
                    rootElement={savedSearchFormSchema}
                    disabled={isLoading}
                />
            }
            alertSlot={
                form.formState.isDirty &&
                watchSavedSearch && (
                    <Alert severity="warning" sx={{ mb: 3 }}>
                        <FormattedMessage id="search.saved.unsavedChanges" />
                    </Alert>
                )
            }
            isLoading={isSavedSearchFetching}
        />
    );
};
